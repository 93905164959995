import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Settings} from '@mui/icons-material';
import * as actions from '../../store/actions';
import {useDispatch} from 'react-redux';
import HotelAttributeTable from '../tables/HotelAttributeTable';
import {SettingsNavigation} from '../components/SettingsNavigation';

const ViewSettingsHotelAttribute = withRouter((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getSettingsHotelAttribute())
  },[dispatch])

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Settings fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Settings</div>
        </div>
        <div> </div>
      </div>

      <SettingsNavigation />

      <HotelAttributeTable />

    </div>
  )
})


export default ViewSettingsHotelAttribute

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET
 */
export const getSettingsHotelAttribute = () => {
  return dispatch => {
    dispatch(getSettingsHotelAttributeStart())
    axios.get('/horus/hotelAttribute')
      .then ( response => {
        dispatch(getSettingsHotelAttributeSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSettingsHotelAttributeFail(error))
      })
  }
}

export const getSettingsHotelAttributeStart = () => {
  return {
    type: actionTypes.GET_SETTINGS_SETTINGS_HOTEL_ATTRIBUTE_START,
  }
}

export const getSettingsHotelAttributeSuccess = (res) => {
  return {
    type: actionTypes.GET_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS,
    res: res,
  }
}

export const getSettingsHotelAttributeFail = (error) => {
  return {
    type: actionTypes.GET_SETTINGS_HOTEL_ATTRIBUTE_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postSettingsHotelAttribute = (payload) => {
  return dispatch => {
    dispatch(postSettingsHotelAttributeStart())
    axios.post('/horus/hotelAttribute', payload)
      .then ( response => {
        dispatch(postSettingsHotelAttributeSuccess(response.data))
        dispatch(getSettingsHotelAttribute())
      })
      .catch(error => {
        dispatch(postSettingsHotelAttributeFail(error))
      })
  }
}

export const postSettingsHotelAttributeStart = () => {
  return {
    type: actionTypes.POST_SETTINGS_HOTEL_ATTRIBUTE_START,
  }
}

export const postSettingsHotelAttributeSuccess = (res) => {
  return {
    type: actionTypes.POST_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS,
    res: res,
  }
}

export const postSettingsHotelAttributeFail = (error) => {
  return {
    type: actionTypes.POST_SETTINGS_HOTEL_ATTRIBUTE_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putSettingsHotelAttribute = (id, payload) => {
  return dispatch => {
    dispatch(putSettingsHotelAttributeStart())
    axios.put('/horus/hotelAttribute/'+id, payload)
      .then ( response => {
        dispatch(putSettingsHotelAttributeSuccess(response.data))
        dispatch(getSettingsHotelAttribute())
      })
      .catch(error => {
        dispatch(putSettingsHotelAttributeFail(error))
      })
  }
}

export const putSettingsHotelAttributeStart = () => {
  return {
    type: actionTypes.PUT_SETTINGS_HOTEL_ATTRIBUTE_START,
  }
}

export const putSettingsHotelAttributeSuccess = (res) => {
  return {
    type: actionTypes.PUT_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS,
    res: res,
  }
}

export const putSettingsHotelAttributeFail = (error) => {
  return {
    type: actionTypes.PUT_SETTINGS_HOTEL_ATTRIBUTE_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteSettingsHotelAttribute = (id) => {
  return dispatch => {
    dispatch(deleteSettingsHotelAttributeStart())
    axios.delete('/horus/hotelAttribute/'+id)
      .then ( response => {
        dispatch(deleteSettingsHotelAttributeSuccess(response.data))
        dispatch(getSettingsHotelAttribute())
      })
      .catch(error => {
        dispatch(deleteSettingsHotelAttributeFail(error))
      })
  }
}

export const deleteSettingsHotelAttributeStart = () => {
  return {
    type: actionTypes.DELETE_SETTINGS_HOTEL_ATTRIBUTE_START,
  }
}

export const deleteSettingsHotelAttributeSuccess = (res) => {
  return {
    type: actionTypes.DELETE_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS,
    res: res,
  }
}

export const deleteSettingsHotelAttributeFail = (error) => {
  return {
    type: actionTypes.DELETE_SETTINGS_HOTEL_ATTRIBUTE_FAIL,
    error: error,
  }
}

import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Settings} from '@mui/icons-material';
import * as actions from '../../store/actions';
import {useDispatch} from 'react-redux';
import SubscriptionCategoryTable from '../tables/SubscriptionCategoryTable';
import {SettingsNavigation} from '../components/SettingsNavigation';
import SubscriptionDurationTable from '../tables/SubscriptionDurationTable';

const ViewSettingsSubscription = withRouter((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getSubscriptionCategory())
    dispatch(actions.getSubscriptionDuration())
  },[dispatch])

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Settings fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Settings</div>
        </div>
        <div> </div>
      </div>

      <SettingsNavigation />

      <SubscriptionCategoryTable />
      <br/><br/>
      <SubscriptionDurationTable />

    </div>
  )
})


export default ViewSettingsSubscription

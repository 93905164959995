import NumberFormat from 'react-number-format';
import React from 'react';
import {sub} from "date-fns";


// 30. Aug. 2020
export const formatDateLongMonth = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('de-DE', options)
}

// 2020-08-30
export const formatIsoDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().substring(0, 10)
}


// 30.08.2020
export const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  return dateString ? date.toLocaleDateString('de-DE', options) : ''
}

// 30.08.20, 18:59
export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('de-DE', options)
}


// 30. Aug. 2020, 18:59
export const formatDateTime2 = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return date.toLocaleDateString('de-DE', options)
}

/*
 * get DateArray x days from startDate (format: 2021-10-28)
 */
export const getDateArrayBetweenStartEnd = (startDate, endDate) => {
  const dateArray = []
  let newDate = new Date(startDate)
  let stopDate = new Date(endDate)
  while (newDate < stopDate) {
    newDate.setDate(newDate.getDate() + 1)
    dateArray.push(newDate.toISOString().substring(0, 10))
  }
  return dateArray
}


// show file size in MegaByte & co - format integer (a) and round decimales (b)
export const formatBytes = (a,b) => {if(0===a)return"0 Bytes";let c=1024,d=b||2,e=["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"],
  f=Math.floor(Math.log(a)/Math.log(c));return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]}


// 2546.30 to 2546,30 €
export const formatCurrency = (amount) => {
  return <div style={{textAlign: 'right'}}>{
    Number(amount)?.toFixed(2)?.replace('.', ',') || 0
  } €</div>
}

// 2546.30 to 2546,30 €
export const formatCurrencyInline = (amount) => {
  return <span>
    {Number(amount)?.toFixed(2)?.replace('.', ',') || 0} €
  </span>
}


// 2546.30 to 2546,30
//export const formatDecimalUsToEu = (num) => {
//  if (isNaN(num) || num === '') {
//    return ''
//  } else {
//    return Number(num)?.toFixed(2)?.replaceAll('.',',')
//  }
//}
export const formatDecimalUsToEu = (num) => {
  if (num === '?') { // ? as special character required for server side lookups
    return '?'
  }
  else if (num === '-') { // - as first character on entering a negative number
    return '-'
  } else if(isNaN(num) || num === '') {
    return ''
  } else {
    return num?.toString()?.replaceAll('.',',')
  }
}


// 2.546,30 to 2546.30
export const formatDecimalEuToUs = (num) => {
  //allow integer, float and negative values. filter space and e.g. € characters
  // eslint-disable-next-line
  return num.replaceAll('.','').replaceAll(',','.').replaceAll(/[^0-9.,?\-]/g,'')
}

export const formatStripeAmountToEur = (amount) => {
  return amount ? (amount / 100).toFixed(2).replace('.', ',') + ' €' : '0,00 €'
}

export const NumberFormatEuro = (props) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale="2"
      fixedDecimalScale
      suffix=" €"
    />
  )
}


export const NumberFormatPercent = (props) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale="1"
      fixedDecimalScale
      suffix=" %"
    />
  )
}


// format local time / timeString to UTC and set time value to 00:00:00. only keep date value
export const getDateAsCleanUTC = (dateString) => {
  if(dateString) {
    const date = new Date(dateString)
    const cleanDate = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()))
    cleanDate.setHours(0,0,0,0)
    const offset = date.getTimezoneOffset()
    //console.log({dateString:dateString,cleanDate:cleanDate, offset:offset, result: sub(cleanDate, {minutes: offset}), string: (sub(cleanDate, {minutes: offset})).toISOString() })
    return sub(cleanDate, {minutes: offset})
  } else {
    return null
  }
}


export const stringToColor = (str = '') => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}
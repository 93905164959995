import React, {useEffect} from 'react'
import {useParams, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Hotel} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import ReservationTable from '../tables/ReservationTable';
import DistrictAssignHotelModal from "../components/DistrictAssignHotelModal";
import BusinessGuestChangeModal from "../components/BusinessGuestChangeModal";


const ViewReservation = withRouter((props) => {
  const dispatch = useDispatch()
  const { reservationID } = useParams()

  useEffect(() => {
    //dispatch(actions.getReservation(100,1,'ok'))
    dispatch(actions.getOffer())
    dispatch(actions.getSubscription())
    dispatch(actions.getHotel())
  },[dispatch])

  const isLoading = useSelector(state => state.reservation.isLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Hotel fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Reservations</div>
        </div>
        <div>Reservation Management. Check and edit reservation details</div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <ReservationTable reservationID={reservationID} />}
        </Grid>
      </Grid>
      <DistrictAssignHotelModal />
      <BusinessGuestChangeModal />
    </div>
  )
})


export default ViewReservation

import * as actionTypes from '../actions/actionTypes'

const initialState = {
  hotels: [],
  hotelsLoading: false,
  specialDates: [],
  specialDatesLoading: false,
  specialDatesByCity: [],
}

const startLoadingHotels = (state) => {
  return {
    ...state,
    hotelsLoading: true,
  }
}

const startLoadingSpecialDates = (state) => {
  return {
    ...state,
    specialDates: [],
    specialDatesLoading: true,
  }
}

const setHotels = (state, action) => {
  return {
    ...state,
    hotels: action.res,
    hotelsLoading: false,
  }
}

const setSpecialDates = (state, action) => {
  return {
    ...state,
    specialDates: action.res,
    specialDatesLoading: false,
  }
}

const setSpecialDatesByCity = (state, action) => {
  return {
    ...state,
    specialDatesByCity: action.res,
    specialDatesLoading: false,
  }
}

const setHotelDetails = (state, action) => {
  let newHotelList = []

  if(state.hotels.filter(h => h.id === action.res.id).length > 0) {
    /* hotel already loaded, do update */
    newHotelList = state.hotels.map(h =>
      h.id === action.res.id
        ? action.res
        : h
    )
  } else {
    /* hotel not loaded, start new list */
    newHotelList = [...newHotelList, action.res]
  }

  return {
    ...state,
    hotels: newHotelList,
    hotelsLoading: false,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_HOTEL_START: return startLoadingHotels(state)
    case actionTypes.GET_HOTEL_SUCCESS: return setHotels(state, action)
    case actionTypes.GET_HOTEL_DETAILS_START: return startLoadingHotels(state)
    case actionTypes.GET_HOTEL_DETAILS_SUCCESS: return setHotelDetails(state, action)
    case actionTypes.GET_SPECIAL_DATE_BY_HOTEL_START: return startLoadingSpecialDates(state)
    case actionTypes.GET_SPECIAL_DATE_BY_HOTEL_SUCCESS: return setSpecialDates(state, action)
    case actionTypes.GET_SPECIAL_DATE_BY_ROOM_START: return startLoadingSpecialDates(state)
    case actionTypes.GET_SPECIAL_DATE_BY_ROOM_SUCCESS: return setSpecialDates(state, action)
    case actionTypes.GET_SPECIAL_DATE_BY_CITY_START: return startLoadingSpecialDates(state)
    case actionTypes.GET_SPECIAL_DATE_BY_CITY_SUCCESS: return setSpecialDatesByCity(state, action)
    default: return state
  }
}

export default reducer

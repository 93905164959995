import * as actionTypes from '../actions/actionTypes'

const initialState = {
  districts: [],
  districtsLoading: false,
  districtHotels: [],
  districtHotelsLoading: false,
}

const startLoadingDistricts = (state) => {
  return {
    ...state,
    districtsLoading: true,
  }
}

const startLoadingDistrictHotels = (state) => {
  return {
    ...state,
    districtHotels: [],
    districtHotelsLoading: true,
  }
}

const setDistricts = (state, action) => {
  return {
    ...state,
    districts: action.res,
    districtsLoading: false,
  }
}

const setDistrictHotels = (state, action) => {
  return {
    ...state,
    districtHotels: action.res,
    districtHotelsLoading: false,
  }
}

const setDistrictDetails = (state, action) => {
  let newDistrictList = []

  if(state.districts.filter(h => h.id === action.res.id).length > 0) {
    /* district already loaded, do update */
    newDistrictList = state.districts.map(h =>
      h.id === action.res.id
        ? action.res
        : h
    )
  } else {
    /* district not loaded, start new list */
    newDistrictList = [...newDistrictList, action.res]
  }

  return {
    ...state,
    districts: newDistrictList,
    districtsLoading: false,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_DISTRICT_START: return startLoadingDistricts(state)
    case actionTypes.GET_DISTRICT_SUCCESS: return setDistricts(state, action)
    case actionTypes.GET_DISTRICT_DETAILS_START: return startLoadingDistricts(state)
    case actionTypes.GET_DISTRICT_DETAILS_SUCCESS: return setDistrictDetails(state, action)
    case actionTypes.GET_DISTRICT_HOTEL_START: return startLoadingDistrictHotels(state)
    case actionTypes.GET_DISTRICT_HOTEL_SUCCESS: return setDistrictHotels(state, action)
    default: return state
  }
}

export default reducer

import * as actionTypes from '../actions/actionTypes'

const initialState = {
  snackbar: {
    message: '',
    open: false,
    variant: 'success'
  },
  isLoading: false,
  showSubscriptionSegmentModal: false,
  showDistrictAssignHotelModal: false,
  districtAssignmentData: null,
  showBusinessGuestChangeModal: false,
  businessGuestData: null,
  showSpecialDateModal: false,
  specialDateModalData: null,
  visTimelineWindow: null,
}

const showNotification = (state, action) => {
  return {
    ...state,
    snackbar: {
      message: action.message,
      open: true,
      variant: action.variant,
    },
  }
}

const hideNotification = (state) => {
  return {
    ...state,
    snackbar: {
      ...state.snackbar,
      message: '',
      open: false,
    },
  }
}

const setLoading = (state, action) => {
  //console.log('change load state - '+action)
  return {
    ...state,
    isLoading: action,
  }
}

const showSubscriptionSegmentModal = (state) => {
  return {
    ...state,
    showSubscriptionSegmentModal: true,
  }
}

const hideSubscriptionSegmentModal = (state) => {
  return {
    ...state,
    showSubscriptionSegmentModal: false,
  }
}

const showDistrictAssignHotelModal = (state, action) => {
  return {
    ...state,
    showDistrictAssignHotelModal: true,
    districtAssignmentData: action.props,
  }
}

const hideDistrictAssignHotelModal = (state) => {
  return {
    ...state,
    showDistrictAssignHotelModal: false,
    districtAssignmentData: null,
  }
}

const showBusinessGuestChangeModal = (state, action) => {
  return {
    ...state,
    showBusinessGuestChangeModal: true,
    businessGuestData: action.props,
  }
}

const hideBusinessGuestChangeModal = (state) => {
  return {
    ...state,
    showBusinessGuestChangeModal: false,
    businessGuestData: null,
  }
}

const showSpecialDateModal = (state, action) => {
  return {
    ...state,
    showSpecialDateModal: true,
    specialDateModalData: {
      action: action.props.action,
      hotelRangeArray: action.props.hotelRangeArray,
    },
    visTimelineWindow: action.props.visTimelineWindow
  }
}

const hideSpecialDateModal = (state) => {
  return {
    ...state,
    showSpecialDateModal: false,
    specialDateModalData: null,
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.SHOW_NOTIFICATION: return showNotification(state, action)
    case actionTypes.HIDE_NOTIFICATION: return hideNotification(state)
    case actionTypes.NAVIGATE_PAGE: return setLoading(state, true)
    case actionTypes.SHOW_SUBSCRIPTION_SEGMENT_MODAL: return showSubscriptionSegmentModal(state)
    case actionTypes.HIDE_SUBSCRIPTION_SEGMENT_MODAL: return hideSubscriptionSegmentModal(state)
    case actionTypes.SHOW_DISTRICT_ASSIGN_HOTEL_MODAL: return showDistrictAssignHotelModal(state, action)
    case actionTypes.HIDE_DISTRICT_ASSIGN_HOTEL_MODAL: return hideDistrictAssignHotelModal(state)
    case actionTypes.SHOW_BUSINESS_GUEST_CHANGE_MODAL: return showBusinessGuestChangeModal(state, action)
    case actionTypes.HIDE_BUSINESS_GUEST_CHANGE_MODAL: return hideBusinessGuestChangeModal(state)
    case actionTypes.SHOW_SPECIAL_DATE_MODAL: return showSpecialDateModal(state, action)
    case actionTypes.HIDE_SPECIAL_DATE_MODAL: return hideSpecialDateModal(state)
    default: return state
  }
}

export default reducer

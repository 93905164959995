import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET
 */
export const getQrSettings = () => {
  return dispatch => {
    dispatch(getQrSettingsStart())
    axios.get('/horus/qrSettings')
      .then ( response => {
        dispatch(getQrSettingsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getQrSettingsFail(error))
      })
  }
}

export const getQrSettingsStart = () => {
  return {
    type: actionTypes.GET_QR_SETTINGS_START,
  }
}

export const getQrSettingsSuccess = (res) => {
  return {
    type: actionTypes.GET_QR_SETTINGS_SUCCESS,
    res: res,
  }
}

export const getQrSettingsFail = (error) => {
  return {
    type: actionTypes.GET_QR_SETTINGS_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postQrSettings = (payload) => {
  return dispatch => {
    dispatch(postQrSettingsStart())
    axios.post('/horus/qrSettings', payload)
      .then ( response => {
        dispatch(postQrSettingsSuccess(response.data))
        dispatch(getQrSettings())
      })
      .catch(error => {
        dispatch(postQrSettingsFail(error))
      })
  }
}

export const postQrSettingsStart = () => {
  return {
    type: actionTypes.POST_QR_SETTINGS_START,
  }
}

export const postQrSettingsSuccess = (res) => {
  return {
    type: actionTypes.POST_QR_SETTINGS_SUCCESS,
    res: res,
  }
}

export const postQrSettingsFail = (error) => {
  return {
    type: actionTypes.POST_QR_SETTINGS_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putQrSettings = (id, payload) => {
  return dispatch => {
    dispatch(putQrSettingsStart())
    axios.put('/horus/qrSettings/'+id, payload)
      .then ( response => {
        dispatch(putQrSettingsSuccess(response.data))
        dispatch(getQrSettings())
      })
      .catch(error => {
        dispatch(putQrSettingsFail(error))
      })
  }
}

export const putQrSettingsStart = () => {
  return {
    type: actionTypes.PUT_QR_SETTINGS_START,
  }
}

export const putQrSettingsSuccess = (res) => {
  return {
    type: actionTypes.PUT_QR_SETTINGS_SUCCESS,
    res: res,
  }
}

export const putQrSettingsFail = (error) => {
  return {
    type: actionTypes.PUT_QR_SETTINGS_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteQrSettings = (id) => {
  return dispatch => {
    dispatch(deleteQrSettingsStart())
    axios.delete('/horus/qrSettings/'+id)
      .then ( response => {
        dispatch(deleteQrSettingsSuccess(response.data))
        dispatch(getQrSettings())
      })
      .catch(error => {
        dispatch(deleteQrSettingsFail(error))
      })
  }
}

export const deleteQrSettingsStart = () => {
  return {
    type: actionTypes.DELETE_QR_SETTINGS_START,
  }
}

export const deleteQrSettingsSuccess = (res) => {
  return {
    type: actionTypes.DELETE_QR_SETTINGS_SUCCESS,
    res: res,
  }
}

export const deleteQrSettingsFail = (error) => {
  return {
    type: actionTypes.DELETE_QR_SETTINGS_FAIL,
    error: error,
  }
}

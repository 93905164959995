import React, {useEffect} from 'react'
import {useParams, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Button, Grid} from '@mui/material';
import {ArrowBack, GridOn} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import DistrictDetailsForm from "../components/DistrictDetailsForm";


const ViewDistrictDetails = withRouter((props) => {
  const dispatch = useDispatch()
  const { districtId } = useParams()

  const districtList = useSelector(state => state.district?.districts)

  useEffect(() => {
    dispatch(actions.getDistrict())
    dispatch(actions.getDistrictHotel(districtId))
    dispatch(actions.getHotel())
  },[dispatch, districtId])

  const selectedDistrict = districtList?.filter(district => {
    return district?.id === parseInt(districtId)
  })?.[0] || null

  //const districtsLoading = useSelector(state => state.district.districtsLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <Button onClick={() => { props.history.push('/app/districts'); }}><ArrowBack /></Button>
        <div className="headlineIconBox">
          <GridOn fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">District Details</div>
        </div>
        <div><b>{selectedDistrict?.districtID}</b> &nbsp;&nbsp; {selectedDistrict?.name}</div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {selectedDistrict !== null ? <DistrictDetailsForm district={selectedDistrict} /> : <Loading />}
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewDistrictDetails

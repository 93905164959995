import React from 'react';
import {Box, Typography, Rating} from '@mui/material';
import {Star} from '@mui/icons-material';

const RatingInput = (props) => {

  const {rating, setRating, legend, readOnly} = props

  const labels = {
    0: '',
    1: '1-Stern',
    2: '2-Stern',
    3: '3-Stern',
    4: '4-Stern',
    5: '5-Stern',
  };

  const [value, setValue] = React.useState(0)
  const [hover, setHover] = React.useState(-1)

  return(
    <Box
      component="fieldset"
      border={1}
      borderColor="grey.400"
      borderRadius={1}
      style={{margin: 0, marginTop: 8}}
    >
      <Typography component="legend" sx={{fontSize: 12}}>{legend}</Typography>
      <Rating
        name="rating"
        //defaultValue={2}
        precision={1}
        max={5}
        readOnly={readOnly || false}
        style={{fontSize: '1.2rem'}}
        icon={<Star fontSize="inherit" />}
        //emptyIcon={null}
        value={rating}
        onChange={(event, newValue) => {
          setValue(newValue)
          setRating(newValue)
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover)
        }}
      />
      &nbsp; {value !== null && <small>{labels[hover !== -1 ? hover : value]}</small>}
    </Box>
  )
}

export default RatingInput

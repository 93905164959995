import React, {useEffect} from 'react'
import {useParams, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Hotel} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import OfferTable from "../tables/OfferTable";


const ViewOffer = withRouter((props) => {
  const dispatch = useDispatch()
  const { offerID } = useParams()

  useEffect(() => {
    dispatch(actions.getOffer())
    dispatch(actions.getSubscriptionCategory())
    dispatch(actions.getSubscriptionDuration())
    dispatch(actions.getSubscription())
    dispatch(actions.getOfferDiscount())
  },[dispatch])

  const isLoading = useSelector(state => state.offer.isLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Hotel fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Offers</div>
        </div>
        <div>Offer Management. Check and edit offer details</div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <OfferTable offerID={offerID} />}
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewOffer

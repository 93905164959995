import * as actionTypes from './actionTypes'
import axios from '../../axios.config'

export const getSearchAnalytics = () => {
  return dispatch => {
    dispatch(getSearchAnalyticsStart())
    axios.get('/horus/searchAnalytics/')
      .then ( response => {
        dispatch(getSearchAnalyticsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSearchAnalyticsFail(error))
      })
  }
}

export const getSearchAnalyticsStart = () => {
  return {
    type: actionTypes.GET_SEARCH_ANALYTICS_START,
  }
}

export const getSearchAnalyticsSuccess = (res) => {
  return {
    type: actionTypes.GET_SEARCH_ANALYTICS_SUCCESS,
    res: res,
  }
}

export const getSearchAnalyticsFail = (error) => {
  return {
    type: actionTypes.GET_SEARCH_ANALYTICS_FAIL,
    error: error,
  }
}

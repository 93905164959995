import { useSelector } from 'react-redux'

const CheckAuthorization = (key, value) => {

  //redux state
  const isLoggedIn = useSelector(state => state.login.isLoggedIn)
  const user = useSelector(state => state.login.user)

  if(isLoggedIn) {
    if(user.HorusUserAuthorizations) {
      return user.HorusUserAuthorizations.some(obj => (
        (obj.authKey === key) && (obj.authValue === value || obj.authValue === 'ADMIN')
      ))
    } else {
      //console.log('Auth-NOK. UserAuthorizations not found')
      return false
    }
  } else {
    //console.log('Auth-NOK. Not loggend in')
    return false
  }
}

export default CheckAuthorization

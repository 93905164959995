import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {QrCodeIcon} from '../../assets/SvgIcons';

const QrSettingsTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.qr.qrSettings)

  const qrId = {
    title: 'QR URL',
    field: 'qrId',
    render: rowData => <div>
      <b><a 
        href={`https://www.myflexhome.de/qr/${rowData.qrId}`} 
        target="_blank" 
        rel="noreferrer"
        style={{color: '#0e6fad'}}
      >{rowData.qrId}</a></b>
    </div>,
    defaultSort: 'asc',
  }

  const url = {
    title: 'Target URL',
    field: 'targetUrl',
    cellStyle: {
      width: 380,
    },
    headerStyle: {
      width: 380,
    },
    render: rowData => <div>
      <a 
        href={rowData.targetUrl} 
        target="_blank" 
        rel="noreferrer"
        style={{color: '#0e6fad'}}
      >{rowData.targetUrl}</a>
    </div>,
    editComponent: props => (
      <textarea
        rows="3" cols="40"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const description = {
    title: 'Description',
    field: 'description',
    cellStyle: {
      width: 380,
    },
    headerStyle: {
      width: 380,
    },
    render: rowData => <div style={{whiteSpace: 'pre-wrap'}}>{rowData.description}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="40"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width:90}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width:90}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
  }

  const username = {
    title: 'UpdatedBy',
    field: 'username',
    editable: 'never',
  }

  const countAll = {
    title: 'Count (all)',
    field: 'countAll',
    editable: 'never'
  }

  const countWeek = {
    title: 'Count (week)',
    field: 'countWeek',
    editable: 'never'
  }

  const countDay = {
    title: 'Count (day)',
    field: 'countDay',
    editable: 'never'
  }

  const qrCode = {
    title: 'QR-Code',
    render: rowData => <div>
      <a
        href={`/api/horus/qrSettings/getcode/${rowData.qrId}/qr.svg?size=600`}
        target="_blank" rel="noreferrer"
        style={{marginRight: 4}}
      ><QrCodeIcon style={{marginRight: 4}} /></a>
      <a
        href={`/api/horus/qrSettings/getcode/${rowData.qrId}/qr.svg?size=600&color=25A9D5`}
        target="_blank" rel="noreferrer"
        style={{marginRight: 4}}
      ><QrCodeIcon color={'#25A9D5'} /></a>
      <a
        href={`/api/horus/qrSettings/getcode/${rowData.qrId}/qr.svg?size=600&color=EC9BA6`}
        target="_blank" rel="noreferrer"
        style={{marginRight: 4}}
      ><QrCodeIcon color={'#EC9BA6'} /></a>
      <a
        href={`/api/horus/qrSettings/getcode/${rowData.qrId}/qr.svg?size=600&color=E5CC68`}
        target="_blank" rel="noreferrer"
        style={{marginRight: 4}}
      ><QrCodeIcon color={'#E5CC68'} /></a>
      <a
        href={`/api/horus/qrSettings/getcode/${rowData.qrId}/qr.svg?size=600&color=086381`}
        target="_blank" rel="noreferrer"
        style={{marginRight: 4}}
      ><QrCodeIcon color={'#086381'} /></a>
    </div>,
  }

  const columnSet = [
    qrId,
    url,
    description,
    qrCode,
    createdAt,
    updatedAt,
    username,
    countDay,
    countWeek,
    countAll,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postQrSettings(newData))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putQrSettings(oldData.id, newData))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteQrSettings(oldData.id))
              resolve()
            }),
        }}
        detailPanel={null}
        options={{
          search: true,
          filtering: false,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          cellStyle:{ padding: '6px 6px 0px 6px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default QrSettingsTable

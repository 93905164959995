import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../../store/actions';
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import {Close, LocationCity} from "@mui/icons-material";
import {Link, withRouter} from "react-router-dom";

const DistrictAssignHotelModal = withRouter((props) => {

  const dispatch = useDispatch()
  const showDistrictAssignHotelModal = useSelector(state => state.mainWindow.showDistrictAssignHotelModal)
  const districtAssignmentData = useSelector(state => state.mainWindow.districtAssignmentData)
  const districtHotels = useSelector(state => state.district.districtHotels) || []
  const hotelList = useSelector(state => state.hotel.hotels) || []
  const allHotels = hotelList.map(item => ({
    id: 100000 + item?.id,
    BookingHotel: {
      id: item?.id,
      hotelID: item?.hotelId,
      name: item?.BookingCity?.cityName + ' | ' + item?.name,
    }})).sort((a, b) => a.BookingHotel.name > b.BookingHotel.name ? 1 : 0)

  useEffect(() => {
    setAssignHotel(null)
    setFullHotelListToggle(false)
    if(districtAssignmentData?.districtId > 0) {
      dispatch(actions.getDistrictHotel(districtAssignmentData.districtId))
    }
  },[dispatch, districtAssignmentData])

  const [assignHotel, setAssignHotel] = useState(null)
  const [fullHotelListToggle, setFullHotelListToggle] = useState(false)

  const handleSave = () => {
    dispatch(actions.putTrip(districtAssignmentData.trip, {
      AssignHotel: assignHotel?.BookingHotel || null,
      BookingSubscription: districtAssignmentData?.BookingSubscription,
      BusinessUserInvitation: districtAssignmentData?.BusinessUserInvitation,
    }))
    districtAssignmentData.refreshHook()
    dispatch(actions.hideDistrictAssignHotelModal())
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgColor: 'background.paper',
    border: '1px solid #666',
    boxShadow: 24,
    backgroundColor: '#f6f4f0',
    p: 4,
  }

  return (
    <Modal
      open={showDistrictAssignHotelModal}
      onClose={() => dispatch(actions.hideDistrictAssignHotelModal())}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Assign District-Hotel
          </Typography>
          <IconButton aria-label="delete" onClick={() => dispatch(actions.hideDistrictAssignHotelModal())}>
            <Close />
          </IconButton>
        </div>

        <Autocomplete
          id="AssignHotelIdModal"
          options={fullHotelListToggle ? allHotels : districtHotels}
          disableClearable={true}
          getOptionLabel={(option) => option?.BookingHotel?.name || '-'}
          value={assignHotel || ''}
          onChange={(e, value) => {
            setAssignHotel(value)
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{fontSize: 13}}>{option?.BookingHotel?.name || '---'}</div>
            </li>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />

        <br/>
        <br/>

        <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
          <Button type="button" color="primary" variant="contained" onClick={handleSave}>
            Save
          </Button>
          <div>
            <Link to={'/app/districtDetails/' + districtAssignmentData?.districtId}>
              <LocationCity sx={{color: '#627f85', width: 14, height: 14, marginRight: 1}}/>
              Add/edit hotels in this district
            </Link>
            <br/>
            <FormGroup>
              <FormControlLabel control={
                <Switch
                  checked={fullHotelListToggle}
                  onChange={(e) => setFullHotelListToggle(!fullHotelListToggle)}
                />
              }
              label={'Full hotel list'}/>
            </FormGroup>
          </div>

        </div>

      </Box>
    </Modal>
  )
})

export default DistrictAssignHotelModal

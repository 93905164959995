import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Checkbox, Typography} from '@mui/material';
import {formatDate, getDateAsCleanUTC} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {Check, Close} from '@mui/icons-material';
import {GermanDatePicker} from "../components/LocalDateInput";
import {isBefore} from "date-fns";

const SpecialDateTable = withRouter((props) => {

  const {hotel} = props
  const dispatch = useDispatch()
  const data = useSelector(state => state.hotel.specialDates)

  const dateFrom = {
    title: 'Date From',
    field: 'dateFrom',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.dateFrom)}</div>,
    editComponent: props => <GermanDatePicker data={props} />,
    defaultSort: 'asc',
    customFilterAndSearch: (term, rowData) => {
      return isBefore(new Date(term),new Date(rowData.dateFrom))
    },
    defaultFilter: '01/01/' + new Date().getFullYear()
  }

  const dateTo = {
    title: 'Date To',
    field: 'dateTo',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.dateTo)}</div>,
    editComponent: props => <GermanDatePicker data={props} />,
    filtering: false,
  }

  const blackOut = {
    title: 'BlackOut',
    field: 'blackOut',
    render: rowData => (
      <div>{rowData.blackOut ? <Check style={{ color: '#3d9a19'}} /> : <Close style={{ color: '#c9c9c9'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value || false}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    filtering: false,
  }

  const horusFullyBooked = {
    title: 'Fully Booked (manually)',
    field: 'horusFullyBooked',
    render: rowData => (
      <div>{rowData.horusFullyBooked ? <Check style={{ color: '#3d9a19'}} /> : <Close style={{ color: '#c9c9c9'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value || false}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    filtering: false,
  }

  const description = {
    title: 'Description',
    field: 'description',
    render: rowData => <div style={{maxHeight: 100, overflow: 'hidden'}} className="fadeOutText" dangerouslySetInnerHTML={{__html: rowData.description}} />,
    editComponent: props => (
      <textarea
        rows="5" cols="35"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const columnSet = [
    dateFrom,
    dateTo,
    blackOut,
    horusFullyBooked,
    description,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={"Special Dates"}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postSpecialDate({
                ...newData,
                dateFrom: getDateAsCleanUTC(newData?.dateFrom),
                dateTo: getDateAsCleanUTC(newData?.dateTo),
                hotelId: hotel.id,
              }))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putSpecialDate(oldData.id, {
                ...newData,
                dateFrom: getDateAsCleanUTC(newData?.dateFrom),
                dateTo: getDateAsCleanUTC(newData?.dateTo),
                hotelId: hotel.id,
              }))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteSpecialDate(oldData.id, hotel.id))
              resolve()
            }),
        }}
        detailPanel={null}
        options={{
          search: false,
          filtering: true,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#fff" : "#f9fcff",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default SpecialDateTable

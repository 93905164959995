import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router";
import {Link, withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Checkbox, Tooltip, Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import * as actions from '../../store/actions'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {shortenText} from '../../services/stringHelper';
import {Check, Close} from '@mui/icons-material';


const CompanyTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.company.companies)

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    name: searchQuery.get('name') || '',
    companyID: searchQuery.get('companyID') || '',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const name = {
    title: 'Name',
    field: 'name',
    render: rowData => <div>
      <Link className="tableActionButton" to={`/app/companyDetails/${rowData.id}`}>{rowData.name}</Link>
    </div>,
    defaultFilter: query.name,
  }

  const companyID = {
    title: 'Company ID',
    field: 'companyID',
    render: rowData => <div style={{width: 60}}>{rowData.companyID}</div>,
    editable: 'never',
    defaultFilter: query.companyID,
  }

  const internalComment = {
    title: 'Internal Comment',
    field: 'internalComment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 190}}>{shortenText(rowData.internalComment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  //const vatId = {
  //  title: 'VAT ID',
  //  field: 'vatId',
  //  render: rowData => <div>{rowData.vatId}</div>,
  //}

  //const accountingNumber = {
  //  title: 'Accounting Number',
  //  field: 'accountingNumber',
  //  render: rowData => <div>{rowData.accountingNumber}</div>,
  //}

  const bookingCustomer = {
    title: 'Admin',
    field: 'BookingCustomers',
    editable: 'never',
    render: rowData => {
      const admin = rowData.BookingCustomers?.[0]
      if(admin) {
        return(<div>
          <Link to={'/app/users/'+admin.customerID}>{admin?.customerID}</Link>
          <div style={{fontSize: 11}}>{admin?.firstName} {admin?.lastName}</div>
          <div style={{fontSize: 11}}>{admin?.email}</div>
        </div>)
      } else {
        return(<div>-</div>)
      }
    },
  }

  const userProduct = {
    title: 'Account Type',
    field: 'BookingCustomers',
    editable: 'never',
    render: rowData => {
      const admin = rowData.BookingCustomers?.[0]
      const color = (admin?.userProduct === 'business') ? 'blue' : 'gray'
      if(admin) {
        return(<div>
          <div style={{fontSize: 11, color:color}}>{admin?.userProduct}</div>
        </div>)
      } else {
        return(<div>-</div>)
      }
    },
    customFilterAndSearch: (term, rowData) => {
      const admin = rowData.BookingCustomers?.[0]
      const searchString = admin?.userProduct || ''
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
  }

  const countSubscriptions = {
    title: 'Count Subscriptions',
    field: 'countSubscriptions',
    editable: 'never',
    render: rowData => <div>
      {rowData.countSubscriptions > 0
        ? <Link to={`/app/subscriptions/?customer=${rowData?.BookingCustomers?.[0]?.customerID}`}>{rowData.countSubscriptions}</Link>
        : '0'
      }
    </div>,
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.countSubscriptions
      return (term.startsWith('!') ? !searchString.toString().includes(term.toLowerCase().slice(1)) : searchString.toString().includes(term.toLowerCase()))
    },
  }

  const businessUsers = {
    title: 'Invited Users',
    field: 'countBusinessUserInvitations',
    editable: 'never',
    render: rowData => {
      if(rowData.countBusinessUserInvitations > 0) {
        return <Link to={`/app/companyDetails/${rowData.id}`}>{rowData.countBusinessUserInvitations}</Link>
      } else {
        return '0'
      }
    },
  }

  const active = {
    title: <Tooltip title={"Used for HORUS-internal filtering. To hide hotel for sale use visibility settings."}>
      <div>Active</div>
    </Tooltip>,
    field: 'active',
    render: rowData => (
      <div>{rowData.active ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.active || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.active,
  }

  const columnSet = [
    name,
    companyID,
    active,
    bookingCustomer,
    userProduct,
    businessUsers,
    countSubscriptions,
    //vatId,
    //accountingNumber,
    internalComment,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData => {
            return new Promise((resolve, reject) => {
              dispatch(actions.postCompany({
                ...newData,
              }))
              resolve()
            })
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise(resolve => {
              dispatch(actions.putCompany(oldData.id, {
                ...newData,
              }))
              resolve()
            })
          },

          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteCompany(oldData.id))
              resolve()
            }),
        }}
        options={{
          search: true,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          //cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default CompanyTable

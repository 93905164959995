import {useDispatch} from "react-redux";
import React, {useState} from "react";
import * as actions from "../../store/actions";
import {Button, Paper, TextField, Typography} from "@mui/material";

export const HotelDetailsFormSearch = (props) => {

  const dispatch = useDispatch()
  const {hotel} = props

  const [imageRibbon, setImageRibbon] = useState(hotel.imageRibbon || '')

  const setSearchAddons = () => {
    const payload = {imageRibbon: imageRibbon}
    dispatch(actions.putHotel(hotel.id, payload))
  }

  return(
    <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8 }}>
      <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
        Search Add-Ons
      </Typography>
      <div className="inputGrid">
        <TextField
          sx={{width: 300}}
          id="hotelSearchRibbon"
          variant="outlined"
          label="Hotel Image Label"
          placeholder=""
          size="small"
          value={imageRibbon}
          onChange={(e) => setImageRibbon(e.target.value)}
        />

        <Button onClick={setSearchAddons} variant="outlined" color="secondary">
          Save
        </Button>
      </div>
    </Paper>
  )
}
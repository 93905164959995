import React from 'react';
import {withRouter} from 'react-router-dom';
import {Paper} from '@mui/material';
import {PeopleOutline} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {makeStyles} from '@mui/styles';

const ViewProfile = withRouter((props) => {
  const classes = useStyles()
  const user = useSelector(state => state.login.user)

  return (
    <div className="contentWindow">
      <div className="headlineBox">
        <div className="headlineIconBox">
          <PeopleOutline fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Profile</div>
        </div>
        <div>User Account</div>
      </div>

      <Paper className={classes.cardPaper}>
        <div><PeopleOutline className={classes.cardPic}/></div>
        <p><b>Name:</b> {user.name}</p>
        <p><b>E-Mail:</b> {user.email}</p>
      </Paper>

    </div>
  )
})


const useStyles = makeStyles(theme => ({
  cardPic: {
    fontSize:100,
    color: 'gray',
    border: '1px solid gray',
    padding: 10,
  },
  cardPaper: {
    width: 300,
    padding: 10,
  },
  cardImage: {
    width: 280,
    padding: 10,
  }
}))

export default ViewProfile

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";

/*
 * GET all offers
 */
export const getOffer = () => {
  return dispatch => {
    dispatch(getOfferStart())
    axios.get('/horus/offer')
      .then ( response => {
        dispatch(getOfferSuccess(response.data))
      })
      .catch(error => {
        dispatch(getOfferFail(error))
      })
  }
}

export const getOfferStart = () => {
  return {
    type: actionTypes.GET_OFFER_START,
  }
}

export const getOfferSuccess = (res) => {
  return {
    type: actionTypes.GET_OFFER_SUCCESS,
    res: res,
  }
}

export const getOfferFail = (error) => {
  return {
    type: actionTypes.GET_OFFER_FAIL,
    error: error,
  }
}


/*
 * GET offers by room Id
 */
export const getOfferByRoom = (roomId) => {
  return dispatch => {
    dispatch(getOfferByRoomStart())
    axios.get('/horus/offer/byRoom/'+roomId)
      .then ( response => {
        dispatch(getOfferByRoomSuccess(response.data))
      })
      .catch(error => {
        dispatch(getOfferByRoomFail(error))
      })
  }
}

export const getOfferByRoomStart = () => {
  return {
    type: actionTypes.GET_OFFER_BY_ROOM_START,
  }
}

export const getOfferByRoomSuccess = (res) => {
  return {
    type: actionTypes.GET_OFFER_BY_ROOM_SUCCESS,
    res: res,
  }
}

export const getOfferByRoomFail = (error) => {
  return {
    type: actionTypes.GET_OFFER_BY_ROOM_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postOffer = (payload, roomId=null) => {
  return dispatch => {
    dispatch(postOfferStart())
    dispatch(showNotification('Saving offer...','info'))
    axios.post('/horus/offer', payload)
      .then ( response => {
        dispatch(postOfferSuccess(response.data))
        roomId ? dispatch(getOfferByRoom(roomId)) : dispatch(getOffer())
        dispatch(showNotification('Offer created successfully','success'))
      })
      .catch(error => {
        dispatch(showNotification('Offer creation failed','error'))
        dispatch(postOfferFail(error))
      })
  }
}

export const postOfferStart = () => {
  return {
    type: actionTypes.POST_OFFER_START,
  }
}

export const postOfferSuccess = (res) => {
  return {
    type: actionTypes.POST_OFFER_SUCCESS,
    res: res,
  }
}

export const postOfferFail = (error) => {
  return {
    type: actionTypes.POST_OFFER_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putOffer = (id, payload, roomId=null) => {
  return dispatch => {
    dispatch(putOfferStart())
    dispatch(showNotification('Saving offer...','info'))
    axios.put('/horus/offer/'+id, payload)
      .then ( response => {
        dispatch(putOfferSuccess(response.data))
        roomId ? dispatch(getOfferByRoom(roomId)) : dispatch(getOffer())
        dispatch(showNotification('Offer updated successfully','success'))
      })
      .catch(error => {
        dispatch(showNotification('Offer update failed','error'))
        dispatch(putOfferFail(error))
      })
  }
}

export const putOfferStart = () => {
  return {
    type: actionTypes.PUT_OFFER_START,
  }
}

export const putOfferSuccess = (res) => {
  return {
    type: actionTypes.PUT_OFFER_SUCCESS,
    res: res,
  }
}

export const putOfferFail = (error) => {
  return {
    type: actionTypes.PUT_OFFER_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteOffer = (id, roomId) => {
  return dispatch => {
    dispatch(deleteOfferStart())
    dispatch(showNotification('Deleting offer...','info'))
    axios.delete('/horus/offer/'+id)
      .then ( response => {
        dispatch(deleteOfferSuccess(response.data))
        dispatch(getOfferByRoom(roomId))
        dispatch(showNotification('Offer deleted successfully','success'))
      })
      .catch(error => {
        dispatch(deleteOfferFail(error))
        dispatch(showNotification('Offer deletion failed','error'))
      })
  }
}

export const deleteOfferStart = () => {
  return {
    type: actionTypes.DELETE_OFFER_START,
  }
}

export const deleteOfferSuccess = (res) => {
  return {
    type: actionTypes.DELETE_OFFER_SUCCESS,
    res: res,
  }
}

export const deleteOfferFail = (error) => {
  return {
    type: actionTypes.DELETE_OFFER_FAIL,
    error: error,
  }
}

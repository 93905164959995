import React from 'react';
import {useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Typography} from '@mui/material';
import {formatCurrency, formatCurrencyInline, formatDate, stringToColor} from '../../services/unitFormater';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {
  AddCircle,
  Exposure,
  Functions, InfoOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
  RemoveCircle,
  StopCircle, WarningAmber
} from "@mui/icons-material";
import {getTripStatusColor} from "../../services/enum";

const SubscriptionEvaluationTable = withRouter((props) => {

  const evaluation = useSelector(state => state.subscription.evaluation)
  const data = evaluation.subscriptionEvaluation
  const expiredNights = evaluation.expiredNights
  const segmentList = evaluation?.subscriptionData?.BookingSubscriptionSegments || []
  const customer = evaluation?.subscriptionData?.BookingCustomer
  const lexofficeResponse = useSelector(state => state.subscription.lexoffice)
  const invoiceList = lexofficeResponse?.invoiceList || []

  const cycle = {
    title: 'Cycle',
    field: 'cycle',
    headerStyle: {
      maxWidth: 60,
    },
  }

  const segmentType = {
    title: 'Type',
    field: 'segment.segmentType',
    headerStyle: {
      maxWidth: 60,
    },
    render: rowData => <div>
      {rowData.firstCycleInSegment
        ? <>
          {rowData.segment.segmentType === 'subscription' ? <PlayCircleFilled sx={{color: '#25a9d5'}} titleAccess="Subscription" /> : ''}
          {rowData.segment.segmentType === 'breakMonth' ? <PauseCircleFilled sx={{color: '#90d954'}} titleAccess="Month Break" /> : ''}
          {rowData.segment.segmentType === 'breakCustom' ? <PauseCircleFilled sx={{color: '#d9b054'}} titleAccess="Custom Break - change in subscription cycle" /> : ''}
          {rowData.segment.segmentType === 'termination' ? <StopCircle sx={{color: '#b01b37'}} titleAccess="Termination" /> : ''}
        </> : null}
    </div>,
    editable: 'never',
  }

  const segmentID = {
    title: 'SegmentID',
    field: 'segment.segmentID',
    render: rowData => {
      return(
        <div>{
          rowData.firstCycleInSegment ? <>
            <div style={{color: '#0f6581'}}>{rowData.segment.segmentID}</div>
            <div>{rowData.segment.nightsPerMonth} Nächte</div>
          </> : null
        }</div>
      )
    }
  }

  const label = {
    title: 'Time Axis Label',
    field: 'label',
  }

  const dateFrom = {
    title: 'From',
    field: 'dateFrom',
    type: 'date',
    render: rowData => <div style={{width: 80}}>{formatDate(rowData.dateFrom)}</div>,
  }

  const dateTo = {
    title: 'To',
    field: 'dateTo',
    type: 'date',
    render: rowData => <div style={{width: 80}}>{formatDate(rowData.dateTo)}</div>,
  }

  const carryoverNightsFromPreviousCycle = {
    title: 'Carryover Nights Previous Cycle',
    field: 'carryoverNightsFromPreviousCycle',
    headerStyle: {
      maxWidth: 110,
    },
    render: rowData => <div style={{color: '#0b6d8f'}}>{rowData.carryoverNightsFromPreviousCycle}</div>,
  }

  const newNightsThisCycle = {
    title: 'New Nights',
    field: 'segment.nightsPerMonth',
    headerStyle: {
      maxWidth: 110,
    },
    render: rowData => <div style={{color: '#0b6d8f'}}>{rowData?.segment?.nightsPerMonth}</div>,
  }

  const expiredThisCycle = {
    title: 'Expired Nights',
    field: 'expiredThisCycle',
    render: rowData => <div style={{color: '#930000'}}>{rowData.expiredThisCycle}</div>,
  }

  const nightsOffset = {
    title: 'Nights Offset',
    field: 'nightsOffsetTotal',
    headerStyle: {
      maxWidth: 110,
    },
    render: rowData => <>
      <div style={{color: '#0b6d8f'}}>{rowData.nightsOffsetTotal}</div>
      {rowData?.nightsOffsetManual ? <div style={{fontSize: 9}}>{rowData.nightsOffsetManual} at {formatCurrencyInline(rowData.cyclePrice.nightsOffsetManualSalesPrice)}</div> : ''}
      {rowData?.nightsOffsetTransferCredit ? <div style={{fontSize: 9}}>{rowData.nightsOffsetTransferCredit} transfer</div> : ''}
    </>,
  }

  const availableNightsThisCycle = {
    title: 'Available',
    field: 'availableNightsThisCycle',
    headerStyle: {
      maxWidth: 110,
    },
    render: rowData => <div style={{fontWeight: 'bold'}}>{rowData.availableNightsThisCycle}</div>,
  }

  const reservationsThisCycle = {
    title: 'Reservations',
    field: 'reservationsThisCycle',
    headerStyle: {
      maxWidth: 110,
    },
    render: rowData => <div style={{fontWeight: 'bold'}}>{rowData.reservationsThisCycle}</div>,
  }

  const remainingNightsThisCycle = {
    title: 'Remaining',
    field: 'remainingNightsThisCycle',
  }

  const nightsAboveMinimum = {
    title: 'Nights Above Minimum Purchase (Business)',
    field: 'nightsAboveMinimum',
  }

  const BookingOfferComponent = (props) => {
    const segmentDetails = segmentList.filter(s => s.segmentID === props.rowData?.segment?.segmentID)?.[0]
    const offer = segmentDetails?.BookingOffer
    const category = offer?.BookingSubscriptionCategory?.name
    const shareable = offer?.BookingSubscriptionCategory?.shareable
    const activeSale = offer?.activeSale
    const discount = offer?.BookingOfferDiscount
    const duration = offer?.BookingSubscriptionDuration?.name
    const roomName = offer?.BookingRoom?.name
    const hotelName = offer?.BookingRoom?.BookingHotel?.name
    const price = offer?.price
    const pricePerMonth = offer?.pricePerMonth

    return(offer?.offerID
      ? <div style={{fontSize: 13, width: 220}}>
          {['breakMonth','breakCustom'].includes(segmentDetails?.segmentType) ?
            <div style={{marginBottom: 3, fontSize: 12, color: '#8c6614', display: 'flex', alignItems: 'center' }}>
              <InfoOutlined fontSize="small" />
              <span style={{marginLeft: 3}}>Subscription paused</span>
            </div>
            : ''
          }
          {['termination'].includes(segmentDetails?.segmentType) ?
            <div style={{marginBottom: 3, fontSize: 12, color: '#8c6614', display: 'flex', alignItems: 'center' }}>
              <InfoOutlined fontSize="small" />
              <span style={{marginLeft: 3}}>Subscription terminated</span>
            </div>
            : ''
          }
          {(!segmentDetails) ?
            <div style={{marginBottom: 3, fontSize: 12, color: '#8c1414', display: 'flex', alignItems: 'center' }}>
              <WarningAmber />
              <span style={{marginLeft: 3}}>No segment found. Subscription not fully initialized.</span>
            </div>
            : ''
          }
          <div>{category} @ {hotelName}</div>
          <div style={{fontSize: 11}}>{roomName} | {duration} Laufzeit</div>
          <div style={{fontSize: 11}}>
            <Link to={'/app/offers/'+offer?.offerID}>{offer?.offerID}</Link>
          </div>
          <div style={{fontSize: 11}}>{formatCurrencyInline(price)}/Nacht | {formatCurrencyInline(pricePerMonth)}/Monat</div>
          <div style={{fontSize: 11}}>{
            shareable ? <span style={{color:"blue"}}>business</span> : 'single'
          } | {
            activeSale ? 'active' : <span style={{color:"red"}}>inactive</span>
          } | {
            discount > 0 ? <span style={{color:"green"}}>{formatCurrencyInline(discount)} discount</span> : 'no discount'
          }</div>
        </div>
      : 'no offer found'
    )
  }

  const columnSet = [
    cycle,
    segmentType,
    label,
    segmentID,
    dateFrom,
    dateTo,
    reservationsThisCycle,
    availableNightsThisCycle,
    newNightsThisCycle,
    carryoverNightsFromPreviousCycle,
    nightsOffset,
    remainingNightsThisCycle,
    nightsAboveMinimum,
    expiredThisCycle,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel()
        }}
        detailPanel={({ rowData }) => {
          const cycleInvoice = invoiceList.filter(x => x?.shippingDate?.substring(0,10) === rowData?.dateFrom?.substring(0,10))?.[0] || null
          const cycleExpiredNights = expiredNights.filter(x => x?.bookCycle === rowData.cycle) || []
          return (
            <div
              style={{
                fontSize: 12,
                textAlign: "left",
                padding: 5,
              }}
              className="cleanTable"
            >
              <table style={{marginBottom: 16, backgroundColor: '#f6f6f5'}}>
                <thead>
                <tr>
                  <th colspan="100%" style={{textAlign: 'center'}}>Monthly Invoice</th>
                </tr>
                <tr>
                  <th>Offer</th>
                  <th>Offer Baseline</th>
                  <th><div style={{display: 'flex', alignItems: 'center'}}><RemoveCircle style={{color: '#9f8f90', marginRight: 3}}/> Discount</div></th>
                  <th><div style={{display: 'flex', alignItems: 'center'}}><AddCircle style={{color: '#939f8f', marginRight: 3}}/> Surcharges</div></th>
                  <th><div style={{display: 'flex', alignItems: 'center'}}><Exposure style={{color: '#939f8f', marginRight: 3}}/> Nights Offset Price</div></th>
                  <th><div style={{display: 'flex', alignItems: 'center'}}><AddCircle style={{color: '#939f8f', marginRight: 3}}/> Nights Above Min. Purchase</div></th>
                  <th><div style={{display: 'flex', alignItems: 'center'}}><Functions style={{color: '#849f9c', marginRight: 3}}/> Horus Invoice Total</div></th>
                  <th><div style={{display: 'flex', alignItems: 'center'}}><Functions style={{color: '#849f9c', marginRight: 3}}/> Lexoffice Invoice Total</div></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <BookingOfferComponent rowData={rowData} />
                  </td>
                  <td>{formatCurrency(rowData.cyclePrice.offerPricePerMonth)}</td>
                  <td>
                    <div>{formatCurrency(rowData.cyclePrice.discountEurPerMonth)}</div>
                    <div style={{color: '#999', textAlign:'right'}}>Cycles: {rowData.cyclePrice.discountedCycles.join(',') || 'n/a'}</div>
                  </td>
                  <td>{formatCurrency(rowData.cyclePrice.surchargesPerMonth)}</td>
                  <td>{formatCurrency(rowData.cyclePrice.nightsOffsetManualSalesPrice)}</td>
                  <td>{formatCurrency(rowData.cyclePrice.nightsAboveMinimumPrice)}</td>
                  <td>{formatCurrency(rowData.cyclePrice.totalPerMonth)}</td>
                  <td>{cycleInvoice
                    ? <div style={{textAlign: 'right'}}>
                      <a href={'https://app.lexoffice.de/vouchers#!/VoucherView/Invoice/' + cycleInvoice.voucherId}
                         target="_blank"
                         rel="noreferrer">{cycleInvoice.voucherNumber}</a>
                      <div>{formatCurrency(cycleInvoice.totalAmount)}</div>
                      <div><a href={'/api/horus/lexoffice/invoicePdf/' + cycleInvoice.invoiceFileId} target="_blank"
                             rel="noreferrer">PDF</a></div>
                    </div>
                    : 'n/a'}</td>
                </tr>
                </tbody>
              </table>

              <table>
              <thead>
                <tr>
                  <th>Reservation ID</th>
                  <th>Arrival Date</th>
                  <th>Status</th>
                  <th>Active<br/>Res No.</th>
                  <th>Trip</th>
                  <th>Hotel Booking Number</th>
                  <th>Hotel Purchase Rate</th>
                  <th>Commission</th>
                  <th>Sell Price Per Night</th>
                  <th>Margin</th>
                  <th>Assign Hotel</th>
                  <th>Rebook Hotel</th>
                  <th>Sell Cycle</th>
                  <th>Guest</th>
                  <th>Message To Customer</th>
                </tr>
              </thead>
              <tbody>
                {rowData.reservationsInCycleExtract.map(res => <tr key={res.reservationID}>
                  <td>
                    <Link className="tableActionButton"
                          to={`/app/reservations/?reservationID=${res.reservationID}`}>{res.reservationID}</Link>
                  </td>
                  <td>{formatDate(res.arrivalDate)}</td>
                  <td>
                    <span style={{
                      backgroundColor: getTripStatusColor(res.status),
                      color: '#FFFFFF',
                      padding: '3px 5px',
                      borderRadius: 5,
                      flex: 1
                    }}>{res.status}</span>
                  </td>
                  <td>
                    {res.index ? <span style={{borderRadius: 20, padding: '4px 7px', color: '#4f6781', backgroundColor: '#dee8f3'}}
                    >{res.index}</span> : ''}
                  </td>
                  <td>
                    <div style={{
                      width: 65,
                      wordBreak: 'break-word',
                      fontSize: 12,
                      paddingLeft: 6,
                      borderLeft: '5px solid ' + stringToColor(res?.BookingTrip?.tripID)
                    }}>
                      <Link to={'/app/trips/?trip=' + res?.BookingTrip?.tripID}>{res?.BookingTrip?.tripID}</Link>
                    </div>
                  </td>
                  <td>{res.hotelBookingNumber}</td>
                  <td>{res.hotelBookingRate !== null ? formatCurrency(res.hotelBookingRate) : ''}</td>
                  <td>{res.commission !== null ? formatCurrency(res.commission) : ''}</td>
                  <td>
                    <div style={{minWidth: 120}}>{res.sellPricePerNight ? formatCurrency(res.sellPricePerNight) : ''}</div>
                    {res.sellPricePerNight && res.surchargePrice ? <div className="currencyCaption">incl.  {formatCurrencyInline(res.surchargePrice)} surcharge</div> : ''}
                    {res.sellDiscountPerNight ? <div className="currencyCaption">incl. {formatCurrencyInline(res.sellDiscountPerNight * -1)} avg. discount</div> : ''}
                    {res.sellOffsetDeltaPerNight ? <div className="currencyCaption">incl. {formatCurrencyInline(res.sellOffsetDeltaPerNight)} avg. offset</div> : ''}
                  </td>
                  <td><div style={{minWidth: 80}}>{res.margin !== null ? formatCurrency(res.margin) : ''}</div></td>
                  <td>{res.AssignHotel?.name}</td>
                  <td>{res.RebookHotel?.name}</td>
                  <td>{res.sellCycle ? <span className="sellCycleIcon">{res.sellCycle}</span> : ''}</td>
                  <td>{res.BusinessUserInvitation?.invitationID
                    ? <>
                    {res?.BusinessUserInvitation?.firstName} {res?.BusinessUserInvitation?.lastName}
                        <div style={{fontSize: 11}}>(Guest) <Link to={'/app/companyDetails/'+res?.BusinessUserInvitation?.BusinessCompanyId}>{res?.BusinessUserInvitation?.invitationID}</Link></div>
                      </>
                    : <>
                        {customer?.firstName} {customer?.lastName}
                        <div style={{fontSize: 11}}>(Admin) <Link to={'/app/users/' + customer?.customerID}>{customer?.customerID}</Link></div>
                      </>
                  }</td>
                  <td>{res.messageToCustomer}</td>
                </tr>)
                }

                {cycleExpiredNights.map(night => <tr key={'expired_'+night.id}>
                  <td></td>
                  <td style={{height: 37}}>{formatDate(night.arrivalDate)}</td>
                  <td><span style={{
                    backgroundColor: '#910606',
                    color: '#FFFFFF',
                    padding: '3px 5px',
                    borderRadius: 5,
                    flex: 1
                  }}>expired</span></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <div
                      style={{minWidth: 120}}>{night.sellPricePerNight ? formatCurrency(night.sellPricePerNight) : ''}</div>
                    {night.sellPricePerNight && night.surchargePrice ? <div
                      className="currencyCaption">incl. {formatCurrencyInline(night.surchargePrice)} surcharge</div> : ''}
                    {night.sellDiscountPerNight ? <div
                      className="currencyCaption">incl. {formatCurrencyInline(night.sellDiscountPerNight * -1)} avg.
                      discount</div> : ''}
                    {night.sellOffsetDeltaPerNight ?
                      <div className="currencyCaption">incl. {formatCurrencyInline(night.sellOffsetDeltaPerNight)} avg.
                        offset</div> : ''}
                  </td>
                  <td>
                    <div
                      style={{minWidth: 80}}>{night.sellPricePerNight ? formatCurrency(night.sellPricePerNight) : ''}</div>
                  </td>
                  <td></td>
                  <td></td>
                  <td>{night.sellCycle ? <span className="sellCycleIcon">{night.sellCycle}</span> : ''}</td>
                  <td></td>
                  <td></td>
                </tr>)}

              </tbody>
              </table>
            </div>
          )
        }}
        renderSummaryRow={
          ({column, data}) => {
            if (column.field === "expiredThisCycle") {
              return ({
                value: data.reduce((agg, row) => agg + row.expiredThisCycle, 0),
                style: {background: '#FFF', color: '#969fad'},
              })
            } else if (column.field === "reservationsThisCycle") {
              return ({
                value: data.reduce((agg, row) => agg + row.reservationsThisCycle, 0),
                style: {background: '#FFF', color: '#969fad'},
              })
            } else if (column.field === "segment.nightsPerMonth") {
              return ({
                value: data.reduce((agg, row) => agg + row.segment.nightsPerMonth, 0),
              style: { background: '#FFF', color: '#0b6d8f' },
            })
          } else if(column.field === "nightsOffsetTotal") {
            return ({
              value: data.reduce((agg, row) => agg + row.nightsOffsetTotal, 0),
              style: { background: '#FFF', color: '#0b6d8f' },
            })
          } else if(column.field === "remainingNightsThisCycle") {
            return ({
              value: data.reduce((agg, row) => {
                if(row.label !== 'historic') { return (agg + row.remainingNightsThisCycle) }
                else { return agg }
              }, 0) + " (active and future)",
              style: { background: '#FFF', color: '#969fad' },
            })
          } else {
            return undefined
          }
        }}
        options={{
          search: false,
          filtering: false,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          idSynonym: 'cycle',
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              rowData.label === 'active' ? '#e3f6e1' :
              rowData.label === 'futureBookable' ? '#f5fff4' :
              index % 2 === 0 ? '#e9f0f6' : '#f4f9fc',
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default SubscriptionEvaluationTable

import React, {useEffect} from 'react'
import {Link, useParams, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Hotel} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import CustomerTable from '../tables/CustomerTable';


const ViewCustomer = withRouter((props) => {
  const dispatch = useDispatch()
  const { customerID } = useParams()

  useEffect(() => {
    dispatch(actions.getCustomer())
    dispatch(actions.getCompany())
  },[dispatch])

  const isLoading = useSelector(state => state.customer.isLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Hotel fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Users</div>
        </div>
        <div>Customer Management. Check and edit customer details</div>
        &nbsp; | &nbsp; 👨‍💼 <Link to={'/app/newBusinessAccount/'}>Add B2B User Account</Link>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <CustomerTable customerID={customerID} />}
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewCustomer

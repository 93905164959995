import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography} from '@mui/material';
import {Home} from '@mui/icons-material';
import dashboard_hotel from '../../assets/dashboard_hotel.jpg';
import dashboard_offer from '../../assets/dashboard_offer.jpg';
import dashboard_reservation from '../../assets/dashboard_reservation.jpg';
import dashboard_trips from '../../assets/dashboard_trips.webp';
import dashboard_user from '../../assets/dashboard_user2.jpg';
import dashboard_subscription from '../../assets/dashboard_subscription2.svg';
import dashboard_specialDates from '../../assets/dashboard_specialDates.webp';
import dashboard_lexoffice from '../../assets/dashboard_lexoffice.webp';
import dashboard_qr from '../../assets/dashboard_qr.jpg';
import dashboard_link from '../../assets/dashboard_link.jpg';
import dashboard_search from '../../assets/dashboard_search.jpg';
import dashboard_priceAnalytics from '../../assets/dashboard_priceAnalytics.jpg';
import dashboard_newsletter from '../../assets/dashboard_newsletter.jpg';
import dashboard_settings from '../../assets/dashboard.jpg';
import CheckAuthorization from '../../services/authorization';
import {styled} from "@mui/styles";

const ViewStart = withRouter((props) => {

  const dashboard = [
    {
      name: 'Hotels',
      description: 'Create, edit and delete hotel listings',
      target: '/app/hotels',
      image: dashboard_hotel,
      auth: ['nav','hotels'],
    },
    {
      name: 'Offers',
      description: 'List all offers from all hotels',
      target: '/app/offers',
      image: dashboard_offer,
      auth: ['nav','offers'],
    },
    {
      name: 'Reservations',
      description: 'List and edit all reservations from all customers',
      target: '/app/reservations',
      image: dashboard_reservation,
      auth: ['nav','reservations'],
    },
    {
      name: 'Trips',
      description: 'List and edit all trips (grouped reservations)',
      target: '/app/trips',
      image: dashboard_trips,
      auth: ['nav','reservations'],
    },
    {
      name: 'Users',
      description: 'Customer Management. Check and edit customer details',
      target: '/app/users',
      image: dashboard_user,
      auth: ['nav','users'],
    },
    {
      name: 'Subscriptions',
      description: 'List and edit all subscriptions',
      target: '/app/subscriptions',
      image: dashboard_subscription,
      auth: ['nav','subscriptions'],
    },
    {
      name: 'Special Dates',
      description: 'Black out, fully booked or surcharges in a city wide overview',
      target: '/app/specialDatesByCity/?cityId=muenchen',
      image: dashboard_specialDates,
      auth: ['nav','specialDatesByCity'],
    },
    {
      name: 'Lexoffice',
      description: 'Data mirror of all lexoffice contacs, invoices and more',
      target: '/app/lexoffice/contact/',
      image: dashboard_lexoffice,
      auth: ['nav','lexoffice'],
    },
    {
      name: '--linebreak--',
      target: '#1',
      auth: ['',''],
    },
    {
      name: 'Newsletter Analytics',
      description: 'Track newsletter registrations via website',
      target: '/app/newsletter',
      image: dashboard_newsletter,
      auth: ['nav','newsletter'],
    },
    {
      name: 'Search Analytics',
      description: 'Count searches via website, grouped by city and nights',
      target: '/app/searchAnalytics',
      image: dashboard_search,
      auth: ['nav','searchAnalytics'],
    },
    {
      name: 'QR Management',
      description: 'Create and track marketing QR codes',
      target: '/app/qr',
      image: dashboard_qr,
      auth: ['nav','qr'],
    },
    {
      name: 'Link Management',
      description: 'Create and track marketing links',
      target: '/app/links',
      image: dashboard_link,
      auth: ['nav','links'],
    },
    {
      name: 'Price Analytics',
      description: 'Analyze hotel market prices',
      target: '/app/priceAnalytics',
      image: dashboard_priceAnalytics,
      auth: ['nav','priceAnalytics'],
    },
    {
      name: '--linebreak--',
      target: '#2',
      auth: ['',''],
    },
    {
      name: 'Settings',
      description: 'Define cities, hotel services, room categories and subscription packages',
      target: '/app/settings',
      image: dashboard_settings,
      auth: ['nav','settings'],
    }
  ]

  const StyledCard = styled(Card)(({ theme }) => ({
    width: 150,
    marginRight: 8,
    marginBottom: 8,
    [theme.breakpoints.up('sm')]: {
      width: 240,
      margin: 8,
    }
  }))
  const StyledCardContent = styled(CardContent)(({ theme }) => ({
    '& h2': {
      fontSize: 18,
    },
    height: 100,
    [theme.breakpoints.up('sm')]: {
      '& h2': {
        fontSize: 20,
      },
      height: 80,
    }
  }))
  const StyledCardMedia= styled(CardMedia)(({ theme }) => ({
    height: 80,
    [theme.breakpoints.up('sm')]: {
      height: 100,
    }
  }))

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Home fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Start</div>
        </div>
        <div>MyFlexHome Admin</div>
      </div>

      <div style={{display: 'flex', flexWrap: 'wrap',}}>
      {
        dashboard.map(card => {
          if (card.name === '--linebreak--') {
            return (<div style={{flexBasis: '100%'}} key={card.target}> </div>)
          } else if (CheckAuthorization(card.auth[0],card.auth[1])) {
            return(
              <Link to={card.target} key={card.target} style={{textDecoration: 'none'}}>
                <StyledCard >
                  <CardActionArea>
                    <StyledCardMedia
                      image={card.image}
                      title={card.description}
                    />
                    <StyledCardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {card.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {card.description}
                      </Typography>
                    </StyledCardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary">
                      More
                    </Button>
                  </CardActions>
                </StyledCard>
              </Link>

            )
          } else {
            return ('')
          }
        })
      }
      </div>

    </div>
  )
})


export default ViewStart

import Chart from 'react-apexcharts';
import {useSelector} from 'react-redux';

const PriceAnalyticsGraph = () => {

  const optionsLine = {
    chart: {
      height: 400,
      type: 'line',
      id: 'apexchart-example',
      toolbar: {
        show: true
      }
    },
    stroke: {
      curve: 'straight'
    },
    dataLabels: {
      enabled: true,
    },
    markers: {
      size: 1
    },
    xaxis: {
      type: 'datetime'
    },
    tooltip: {
      shared: true,
    },
    title: {
      text: 'Pricing Graph'
    },
  }

  const optionsHeatmap = {
    chart: {
      height: 350,
      type: 'heatmap',
      toolbar: {
        autoSelected: 'pan',
        show: false
      },
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      heatmap: {
        radius: 0,
          enableShades: false,
          colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: '#cccbc1'
            },
            {
              from: 0.01,
              to: 50.00,
              color: '#33b746'
            },
            {
              from: 50.01,
              to: 75.00,
              color: '#4fd364'
            },
            {
              from: 75.01,
              to: 100.00,
              color: '#96e363'
            },
            {
              from: 100.01,
              to: 150.00,
              color: '#d5dc00'
            },
            {
              from: 150.01,
              to: 200.00,
              color: '#e8c100'
            },
            {
              from: 200.01,
              to: 250.00,
              color: '#f5a700'
            },
            {
              from: 250.01,
              to: 300.00,
              color: '#da7400'
            },
            {
              from: 300.01,
              to: 350.00,
              color: '#da4c00'
            },
            {
              from: 350.01,
              to: 400.00,
              color: '#c20000'
            },
          ],
        },

      }
    },
    dataLabels: {
      enabled: false,
        style: {
        colors: ['#fff']
      }
    },
    xaxis: {
      type: 'datetime'
    },
    title: {
      text: 'Pricing Heatmap'
    },
  }

  const series = useSelector(state => state.analytics.priceAnalytics)

  return(
    <div>
      <div id="chart1">
        <Chart
          options={optionsLine}
          series={series}
          type="line"
          width={1600}
          height={400}
        />
      </div>

      <div id="chart2" style={{marginTop: 30}}>
        <Chart
          options={optionsHeatmap}
          series={series}
          type="heatmap"
          width={1600}
          height={400}
        />
      </div>

    </div>
  )
}

export default PriceAnalyticsGraph

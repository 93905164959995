import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import * as actions from "../../store/actions";
import {Autocomplete, Button, Paper, TextField, Typography} from "@mui/material";

export const HotelDetailsFormLocation = (props) => {

  const dispatch = useDispatch()
  const {hotel} = props
  const districts = useSelector(state => state.district.districts) || []
  const [locationLat, setLocationLat] = useState(hotel.locationLat || '')
  const [locationLong, setLocationLong] = useState(hotel.locationLong || '')
  const [district, setDistrict] = useState(hotel.BookingDistrict || null)

  const setLocation = () => {
    const payload = {
      locationLat: locationLat,
      locationLong: locationLong,
      BookingDistrict: (district?.id > 0) ? district : null
    }
    dispatch(actions.putHotel(hotel.id, payload))
  }

  return(
    <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8 }}>
      <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
        Location
      </Typography>
      <div className="inputGrid">

        <TextField
          id="locationLat"
          variant="outlined"
          label="Location Lat"
          placeholder=""
          size="small"
          value={locationLat}
          onChange={(e) => setLocationLat(e.target.value)}
        />

        <TextField
          id="locationLong"
          variant="outlined"
          label="Location Long"
          placeholder=""
          size="small"
          value={locationLong}
          onChange={(e) => setLocationLong(e.target.value)}
        />

        {hotel?.hotelOfferType === 'district'
          ? <div>
            <Autocomplete
              id="BookingDistrict"
              options={districts}
              getOptionLabel={(option) => option?.name || ''}
              value={district || ''}
              onChange={(e, value) => {
                setDistrict({id:value?.id, name:value?.name})
              }}
              autoHighlight={true} // required to select entry without submitting entire material-table row
              //onKeyDown={e => handleKey(e)}
              isOptionEqualToValue={(option, value) => {
                return option?.id === value?.id
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  <div style={{fontSize: 13}}>{option.name || '---'}</div>
                </li>
              )}
              size="small"
              renderInput={(params) => {
                params.InputProps.style = {fontSize: 13, width: 180}
                return(
                  <TextField
                    {...params}
                    label={"District Polygon"}
                    placeholder=""
                  />
                )
              }}
            />
          </div>
          : ''
        }

        <Button onClick={setLocation} variant="outlined" color="secondary">
          Save
        </Button>
      </div>
      <div style={{fontSize: 12, padding: 4}}>
        e.g. Munich Lat 48.139 Long 11.575 | Berlin Lat 52.518 Long 13.405
      </div>
    </Paper>
  )
}
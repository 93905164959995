import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Grid} from '@mui/material';
import {AccountTree} from '@mui/icons-material';
import {Loading} from '../components/Loading';
import * as actions from '../../store/actions';
import QrSettingsTable from '../tables/QrSettingsTable';


const ViewQrSettings = withRouter((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getQrSettings())
  },[dispatch])

  const isLoading = useSelector(state => state.qr.isLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <AccountTree fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">QR Management</div>
        </div>
        <div>Create and track marketing QR codes</div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <QrSettingsTable /> }
        </Grid>
      </Grid>

    </div>
  )
})


export default ViewQrSettings

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";

/*
 * GET all districts
 */
export const getDistrict = () => {
  return dispatch => {
    dispatch(getDistrictStart())
    axios.get('/horus/district')
      .then ( response => {
        dispatch(getDistrictSuccess(response.data))
      })
      .catch(error => {
        dispatch(getDistrictFail(error))
      })
  }
}

export const getDistrictStart = () => {
  return {
    type: actionTypes.GET_DISTRICT_START,
  }
}

export const getDistrictSuccess = (res) => {
  return {
    type: actionTypes.GET_DISTRICT_SUCCESS,
    res: res,
  }
}

export const getDistrictFail = (error) => {
  return {
    type: actionTypes.GET_DISTRICT_FAIL,
    error: error,
  }
}


/*
 * GET district details by districtId
 */
export const getDistrictDetails = (id) => {
  return dispatch => {
    dispatch(getDistrictDetailsStart())
    axios.get('/horus/district/byId/'+id)
      .then ( response => {
        dispatch(getDistrictDetailsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getDistrictDetailsFail(error))
      })
  }
}

export const getDistrictDetailsStart = () => {
  return {
    type: actionTypes.GET_DISTRICT_DETAILS_START,
  }
}

export const getDistrictDetailsSuccess = (res) => {
  return {
    type: actionTypes.GET_DISTRICT_DETAILS_SUCCESS,
    res: res,
  }
}

export const getDistrictDetailsFail = (error) => {
  return {
    type: actionTypes.GET_DISTRICT_DETAILS_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postDistrict = (payload) => {
  return dispatch => {
    dispatch(postDistrictStart())
    axios.post('/horus/district', payload)
      .then ( response => {
        dispatch(postDistrictSuccess(response.data))
        dispatch(getDistrict())
      })
      .catch(error => {
        dispatch(postDistrictFail(error))
      })
  }
}

export const postDistrictStart = () => {
  return {
    type: actionTypes.POST_DISTRICT_START,
  }
}

export const postDistrictSuccess = (res) => {
  return {
    type: actionTypes.POST_DISTRICT_SUCCESS,
    res: res,
  }
}

export const postDistrictFail = (error) => {
  return {
    type: actionTypes.POST_DISTRICT_FAIL,
    error: error,
  }
}


/*
 * PUT district
 */
export const putDistrict = (id, payload) => {
  return dispatch => {
    dispatch(putDistrictStart())
    axios.put('/horus/district/'+id, payload)
      .then ( response => {
        dispatch(putDistrictSuccess(response.data))
        dispatch(getDistrictDetails(id))
        dispatch(showNotification('Changes saved successfully','success'))
      })
      .catch(error => {
        dispatch(showNotification('Saving changes failed','error'))
        dispatch(putDistrictFail(error))
      })
  }
}

export const putDistrictStart = () => {
  return {
    type: actionTypes.PUT_DISTRICT_START,
  }
}

export const putDistrictSuccess = (res) => {
  return {
    type: actionTypes.PUT_DISTRICT_SUCCESS,
    res: res,
  }
}

export const putDistrictFail = (error) => {
  return {
    type: actionTypes.PUT_DISTRICT_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteDistrict = (id) => {
  return dispatch => {
    dispatch(deleteDistrictStart())
    axios.delete('/horus/district/'+id)
      .then ( response => {
        dispatch(deleteDistrictSuccess(response.data))
        dispatch(getDistrict())
      })
      .catch(error => {
        dispatch(deleteDistrictFail(error))
      })
  }
}

export const deleteDistrictStart = () => {
  return {
    type: actionTypes.DELETE_DISTRICT_START,
  }
}

export const deleteDistrictSuccess = (res) => {
  return {
    type: actionTypes.DELETE_DISTRICT_SUCCESS,
    res: res,
  }
}

export const deleteDistrictFail = (error) => {
  return {
    type: actionTypes.DELETE_DISTRICT_FAIL,
    error: error,
  }
}

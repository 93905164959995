import * as React from 'react';
import * as vistimeline from 'vis-timeline/standalone';
import 'vis-timeline/dist/vis-timeline-graph2d.min.css';


export const VisTimeline = (props) => {

  const { items, groups, options, timeline, setTimeline} = props

  const [element, setElement] = React.useState()

  React.useEffect(() => {
    if (element) {
      const tl = new vistimeline.Timeline(element, [])
      setTimeline(tl)
      return () => tl.destroy()
    }
  }, [element, setTimeline])

  React.useEffect(() => {
    timeline?.setGroups(groups)
  }, [timeline, groups])

  React.useEffect(() => {
    timeline?.setItems(items)
  }, [timeline, items])

  React.useEffect(() => {
    if (options && timeline) {
      timeline?.setOptions(options)
    }
  }, [timeline, options])

  return <div ref={r => setElement(r)} />
}

import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../../store/actions';
import {Box, Button, IconButton, InputAdornment, Modal, TextField, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import {withRouter} from "react-router-dom";
import {formatDate, formatDecimalEuToUs, formatDecimalUsToEu} from "../../services/unitFormater";

const SpecialDateModal = withRouter((props) => {

  const dispatch = useDispatch()
  const showSpecialDateModal = useSelector(state => state.mainWindow.showSpecialDateModal)
  const specialDateModalData = useSelector(state => state.mainWindow.specialDateModalData) || []
  const hotelList = useSelector(state => state.hotel.hotels)

  const newDatesSelected = specialDateModalData?.hotelRangeArray?.length > 0 || false

  const [description, setDescription] = useState('')
  const [surchargePerRoom, setSurchargePerRoom] = useState({})

  const updateSurchargePerRoom = (hotelId, roomId, value) => {
    setSurchargePerRoom({
      ...surchargePerRoom,
      [hotelId]: {
        ...surchargePerRoom[hotelId],
        [roomId]: value,
      }
    })
  }

  const handleSave = () => {

    // case: add --> dispatch postMultipleSpecialDates
    if(specialDateModalData.action === 'horusFullyBooked' || specialDateModalData.action === 'blackOut' || specialDateModalData.action === 'tradeFair') {
      dispatch(actions.postMultipleSpecialDates({
        ...specialDateModalData,
        description: description,
        surchargePerRoom: surchargePerRoom,
      }, props.cityId))
    }

    // case: delete --> dispatch deleteMultipleSpecialDates
    if(specialDateModalData.action === 'delete') {
      dispatch(actions.deleteMultipleSpecialDates({
        ...specialDateModalData,
      }, props.cityId))
    }

    hideModal()
  }

  const hideModal = () => {
    dispatch(actions.hideSpecialDateModal())
    setDescription('')
    setSurchargePerRoom({})
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgColor: 'background.paper',
    border: '1px solid #666',
    boxShadow: 24,
    backgroundColor: '#f6f4f0',
    p: 4,
    maxHeight: '80%',
    overflow: 'scroll'
  }

  return (
    <Modal
      open={showSpecialDateModal}
      onClose={hideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {specialDateModalData.action === 'tradeFair' ? <span className="buttonSurcharge timelineLabel">Set Trade Fair with Surcharge</span> : ''}
            {specialDateModalData.action === 'horusFullyBooked' ? <span className="buttonHorusFullyBooked timelineLabel">Set Fully Booked (manually)</span> : ''}
            {specialDateModalData.action === 'blackOut' ? <span className="buttonBlackOut timelineLabel">Set BlackOut</span> : ''}
            {specialDateModalData.action === 'delete' ? <span className="buttonDelete timelineLabel">Delete special date(s)</span> : ''}
          </Typography>
          <IconButton aria-label="delete" onClick={hideModal}>
            <Close />
          </IconButton>
        </div>

        <div style={{display: 'table'}}>
        {newDatesSelected
          ? specialDateModalData.hotelRangeArray.map(rangeObj => {
              const hotelDetails = hotelList.filter(h => h.id === rangeObj.hotelId)?.[0]
              return (
                <div key={rangeObj.id}>
                  <div style={{display: 'table-row'}}>
                    <div style={{display: 'table-cell', padding: '3px 10px'}}>
                      • <b>{hotelDetails.name}</b>
                    </div>
                    <div style={{display: 'table-cell'}}>
                      {formatDate(rangeObj.start)} {(rangeObj.end !== rangeObj.start)
                      ? ' - ' + formatDate(rangeObj.end)
                      : ''
                    }
                    </div>
                  </div>
                  {specialDateModalData.action === 'tradeFair' && hotelDetails?.BookingRooms?.map(room => {
                    return (
                      <div style={{display: 'table-row'}} key={room?.id}>
                        <div style={{display: 'table-cell', padding: '0px 20px'}}>
                          • <span>{room?.name}</span>
                        </div>
                        <div style={{display: 'table-cell'}}>
                          <TextField
                            id="surcharge"
                            label="Surcharge"
                            size="small"
                            value={formatDecimalUsToEu(surchargePerRoom?.[rangeObj.hotelId]?.[room?.id] || '')}
                            placeholder=""
                            InputProps={{
                              endAdornment: <InputAdornment position="end">€</InputAdornment>,
                              style: {fontSize: 13, marginBottom: 5}
                            }}
                            onChange={(e) => updateSurchargePerRoom(rangeObj.hotelId, room?.id, formatDecimalEuToUs(e.target.value))}
                          />
                        </div>
                      </div>)
                  })}
                </div>
              )
          })
          : <div>No dates selected</div>
        }
        </div>

        {specialDateModalData.action === 'tradeFair'
          ? <div style={{paddingTop: 20, paddingBottom: 20}}>
              <TextField
                id="tradeFairDesc"
                variant="outlined"
                label="Trade Fair with Surcharge Description"
                placeholder=""
                size="small"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <small>(Visible for customer)</small>
            </div>
          : ''
        }

        {specialDateModalData.action === 'blackOut'
          ? <div style={{paddingTop: 20, paddingBottom: 20}}>
              <TextField
                id="blackOutDesc"
                variant="outlined"
                label="BlackOut Description"
                placeholder=""
                size="small"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <small>(Visible for customer)</small>
            </div>
          : ''
        }

        {specialDateModalData.action === 'horusFullyBooked'
          ? <div style={{paddingTop: 20, paddingBottom: 20}}>
              <TextField
                id="fullyBookedDesc"
                variant="outlined"
                label="Fully Booked Description"
                placeholder=""
                size="small"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <small>(Not visible for customer)</small>
            </div>
          : ''
        }

        <br/>

        <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
          <Button type="button" color="primary" variant="contained" onClick={handleSave} disabled={!newDatesSelected}>
            Save
          </Button>
        </div>

      </Box>
    </Modal>
  )
})

export default SpecialDateModal

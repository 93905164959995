import {useDispatch} from "react-redux";
import React, {useState} from "react";
import * as actions from "../../store/actions";
import {Button, FormControlLabel, FormGroup, Paper, Switch, Typography} from "@mui/material";

export const HotelDetailsFormEmailSettings = (props) => {

  const dispatch = useDispatch()
  const {hotel} = props

  const [sendGuestDetailsEMail, setSendGuestDetailsEMail] = useState(hotel.sendGuestDetailsEMail || false)
  const [sendSubscriptionDetails, setSendSubscriptionDetails] = useState(hotel.sendSubscriptionDetails || false)
  const [sendOpenTripsReminderEMail, setSendOpenTripsReminderEMail] = useState(hotel.sendOpenTripsReminderEMail || false)

  const saveChanges = () => {
    const payload = {
      sendGuestDetailsEMail: sendGuestDetailsEMail,
      sendSubscriptionDetails: sendSubscriptionDetails,
      sendOpenTripsReminderEMail: sendOpenTripsReminderEMail,
    }
    dispatch(actions.putHotel(hotel.id, payload))
  }

  return(
    <Paper style={{padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8}}>
      <Typography variant="h6" style={{paddingTop: 8, paddingBottom: 8}}>
        E-Mail Settings
      </Typography>
      <div style={{fontSize: 14}}>What should be included in booking request e-mail to this hotel?</div>
      <div className="inputGrid">

        <FormGroup>
          <FormControlLabel control={<Switch
            checked={sendGuestDetailsEMail}
            onChange={(e) => setSendGuestDetailsEMail(e.target.checked)}/>} label="Guest E-mail"/>
        </FormGroup>

        <FormGroup>
          <FormControlLabel control={<Switch
            checked={sendSubscriptionDetails}
            onChange={(e) => setSendSubscriptionDetails(e.target.checked)}/>} label="Subscription Details"/>
        </FormGroup>

      </div>

      <br/>

      <div style={{fontSize: 14}}>Which system mails should be send?</div>
      <div className="inputGrid">

        <FormGroup>
          <FormControlLabel control={<Switch
            checked={sendOpenTripsReminderEMail}
            onChange={(e) => setSendOpenTripsReminderEMail(e.target.checked)}/>}
                            label="Reminder on open trips after 24h"/>
        </FormGroup>

      </div>
      <div>
        <Button onClick={saveChanges} variant="outlined" color="secondary">
          Save
        </Button>
      </div>
    </Paper>
  )
}
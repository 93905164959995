import React from 'react';
import {useDispatch} from 'react-redux';
import * as actions from '../../store/actions';
import {Button, Card, CardActions, CardContent, Grid, TextField, Typography} from '@mui/material';
import portalLogo from '../../assets/horus_logo_05.png';


const ViewLogin = () => {
  const dispatch = useDispatch()

  const [values, setValues] = React.useState({
    email:'',
    password:''
  })

  const loginHandler = (event) => {
    event.preventDefault()
    dispatch(actions.login(values))
    return true
  }

  const inputChangedHandler = (event) => {
  setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <Grid container spacing={0} style={{padding: 10}}>
      <Grid container item xs={12} justifyContent="center">
        <div style={{display: 'block', textAlign: 'center'}}>
          <div>
            <img src={portalLogo} alt="MyFlexHome HORUS" style={{width: 300, marginTop: 20}} />
          </div>
        </div>

        <Grid container spacing={0}>
          <Grid container item xs={12}
                alignItems="center"
                justifyContent="center">
            <form id="loginform" autoComplete="on" onSubmit={loginHandler}>
              <Card style={{margin: 40}}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Login
                  </Typography>
                  <TextField
                    autoFocus
                    id="email"
                    name="email"
                    type="text"
                    label={"e-Mail"}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => inputChangedHandler(event)}
                    style={{width: '100%', marginBottom: 5}}
                    margin="normal"
                  />
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    label={"Passwort"}
                    variant="outlined"
                    fullWidth
                    onChange={(event) => inputChangedHandler(event)}
                    style={{width: '100%', marginBottom: 5}}
                    margin="normal"
                  />
                </CardContent>
                <CardActions style={{display: 'block', textAlign: 'center', marginBottom: 15}}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{width: '70%'}}
                  >
                    Anmelden
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  )
}


export default ViewLogin

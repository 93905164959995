
export const shortenText = (input, maxLength, endWithDots=true) => {
  let text = ''
  if(input !== null && input !== undefined) {
    text = input.toString().substring(0,maxLength)
    if((input.length > maxLength) && endWithDots) {
      text += '...'
    }
  }
  return text
}


export const removeSpecialCharacters = (str) => {
  return str.trim()
    .replaceAll('ä','ae')
    .replaceAll('Ä','Ae')
    .replaceAll('ü','ue')
    .replaceAll('Ü','Ue')
    .replaceAll('ö','oe')
    .replaceAll('Ö','Oe')
    .replaceAll('ß','ss')
    .replaceAll('é','e')
    .replaceAll('è','e')
    .replaceAll('õ','o')
    .replaceAll('ô','o')
    .replaceAll('ó','o')
    .replaceAll('ò','o')
    .replaceAll('ç','c')
    .replaceAll(' ','_')
    .replaceAll(/[^a-zA-Z0-9_]/g, '')
    .replaceAll('___','_')
    .replaceAll('__','_')
}


export const stringToColour = (str) => {
  let stringUniqueHash = [...str].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}


export const isJsonString = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
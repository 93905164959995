import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Autocomplete, InputAdornment, TextField, Typography} from '@mui/material';
import {
  formatCurrency,
  formatCurrencyInline,
  formatDate,
  formatDecimalEuToUs,
  formatDecimalUsToEu,
  getDateAsCleanUTC
} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {HtmlEditorControlled} from "../components/HtmlEditorControlled";
import {shortenText} from "../../services/stringHelper";
import {GermanDatePicker} from "../components/LocalDateInput";
import {Check, Close} from "@mui/icons-material";
import {useLocation} from "react-router";

const RoomDetailsTable = withRouter((props) => {

  const {room} = props
  const dispatch = useDispatch()
  const data = useSelector(state => state.offer.offers)
  const subscriptionCategoryList = useSelector(state => state.settings.subscriptionCategory)
  const subscriptionDurationList = useSelector(state => state.settings.subscriptionDuration)
  const offerDiscountList = useSelector(state => state.settings.offerDiscount)

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    activeSale: searchQuery.get('activeSale') ?? '',
    category: searchQuery.get('category') ?? '',
    countSegments: searchQuery.get('countSegments') ?? '',
  }

  const price = {
    title: 'Baseline Price',
    field: 'price',
    render: rowData => formatCurrency(rowData.price || ''),
    headerStyle: {
      maxWidth: 120,
    },
    editComponent: props => (
      <TextField
        //label="Baseline Price"
        size={'small'}
        //value={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(grossPriceWithMargin)}
        value={formatDecimalUsToEu(props.value || '')}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
          style: {fontSize: 13}
        }}
        //variant={'default'}
        onChange={e => props.onChange(formatDecimalEuToUs(e.target.value))}
      />
    )
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const subscriptionCategory = {
    title: 'Subscription Category',
    field: 'BookingSubscriptionCategory',
    render: rowData => <div>
      {
        rowData.BookingSubscriptionCategory.name
      } {
        rowData.BookingSubscriptionCategory.nightsScope === 'year' ? '(year)' : ''
      } {
        rowData.BookingSubscriptionCategory.shareable ? '(shareable)' : ''
      }
    </div>,
    headerStyle: {
      maxWidth: 120,
    },
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.BookingSubscriptionCategory.name
        + ' '
        + (rowData.BookingSubscriptionCategory.shareable ? 'shareable business' : 'single')
        + ' '
        + (rowData.BookingSubscriptionCategory.nightsScope === 'year' ? 'year' : 'month')
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
    editable: 'onAdd',
    editComponent: props => (
      <div style={{width: 110}}>
        <Autocomplete
          id="SubscriptionCategory"
          options={subscriptionCategoryList}
          getOptionLabel={(option) => option.name || '-'}
          value={props.value}
          onChange={(e, value) => {
            props.onChange({id:value?.id, name:value?.name})
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderOption={(props, option) => {
            const shareable = option.shareable ? '(shareable)' : ''
            const year = option.nightsScope === 'year' ? '(year)' : ''
            return(
            <li {...props}>
              <div style={{fontSize: 13}}>{option.name + ' ' + year + shareable || '---'}</div>
            </li>
            )
          }}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    defaultFilter: query.category,
  }

  const pricePerMonth = {
    title: 'Price per month',
    field: 'pricePerMonth',
    render: rowData => formatCurrency(rowData.pricePerMonth || ''),
    editable: 'never',
  }

  const offerDiscount = {
    title: 'Discount',
    field: 'BookingOfferDiscount',
    render: rowData => rowData?.BookingOfferDiscount?.offerDiscountID
      ? <div>
        <div style={{textDecoration:"underline", cursor:"pointer", color:"#045ebe"}}
             onClick={() => { props.history.push(`/app/settings/offerDiscount`) }}>{rowData?.BookingOfferDiscount?.offerDiscountID || ''}</div>
        <div style={{fontSize: 10}}>{rowData?.BookingOfferDiscount?.name}</div>
        <br/>
        <div style={{fontSize: 10}}><b>Months:</b> {rowData?.BookingOfferDiscount?.numberOfMonths} {rowData?.BookingOfferDiscount?.duration === 'forever' ? 'forever' : ''}</div>
        <div style={{fontSize: 10}}><b>PricePerMonth:</b> {formatCurrencyInline(rowData?.pricePerMonthWithDiscount)}</div>
        <div style={{fontSize: 10}}><b>Discount:</b> {formatCurrencyInline(rowData?.discount)}</div>
      </div>
      : '',
    headerStyle: {
      maxWidth: 210,
    },
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData?.BookingOfferDiscount?.offerDiscountID + ' ' + rowData?.BookingOfferDiscount?.name
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
    editComponent: props => (
      <div style={{width: 160}}>
        <Autocomplete
          id="OfferDiscount"
          options={offerDiscountList}
          disableClearable={false}
          getOptionLabel={(option) => option.offerDiscountID || '-'}
          value={props.value || null} // null required to avoid jump from uncontrolled to controlled state
          onChange={(e, value) => {
            props.onChange({id:value?.id, offerDiscountID:value?.offerDiscountID})
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderOption={(props, option) => (
            <div {...props}>
              <div>
                <div style={{fontSize: 13}}>{option.offerDiscountID}</div>
                <div style={{fontSize: 10}}>{option.name}</div>
              </div>

            </div>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    )
  }

  const subscriptionDuration = {
    title: 'Subscription Duration',
    field: 'BookingSubscriptionDuration',
    render: rowData => <div>{rowData.BookingSubscriptionDuration.name}</div>,
    headerStyle: {
      maxWidth: 120,
    },
    editable: 'onAdd',
    editComponent: props => (
      <div style={{width: 110}}>
        <Autocomplete
          id="SubscriptionDuration"
          options={subscriptionDurationList}
          getOptionLabel={(option) => option.name || '-'}
          value={props.value}
          onChange={(e, value) => {
            props.onChange({id:value?.id, name:value?.name})
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{fontSize: 13}}>{option.name || '---'}</div>
            </li>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.BookingSubscriptionDuration.name
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
  }

  const cancellationPeriodHours = {
    title: 'Cancellation Period (h)',
    field: 'cancellationPeriodHours',
    type: 'numeric',
    headerStyle: {
      maxWidth: 110,
    },
  }

  const hotelReferenceCode = {
    title: 'Booking Reference Code',
    field: 'hotelReferenceCode',
  }

  const stripe = {
    title: 'Stripe',
    field: 'stripeProduct',
    editable: 'never',
    render: rowData => <div>
      <div>{rowData.stripeProduct}</div>
      <div>{rowData.stripePrice}</div>
      {rowData?.stripeCoupon ? <div>Coupon: {rowData.stripeCoupon}</div> : ''}
    </div>,
    filtering: true,
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.stripeProduct + ' ' + rowData.stripePrice + ' ' + rowData?.stripeCoupon
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
  }

  const description = {
    title: 'Duration Terms (overwrite)',
    field: 'description',
    render: rowData => <div style={{maxHeight: 100, overflow: 'hidden'}} className="fadeOutText" dangerouslySetInnerHTML={{__html: rowData.description}} />,
    editComponent: props => (
      <HtmlEditorControlled
        value={props.value}
        width={'95%'}
        height={200}
        editorChangeCallback={e => props.onChange(e)}
      />
    ),
    filtering: false,
    headerStyle: {
      minWidth: 200,
    }
  }

  const comment = {
    title: 'Internal Comment',
    field: 'comment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 190}}>{shortenText(rowData.comment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const startDate = {
    title: 'Start Date',
    field: 'startDate',
    type: 'date',
    render: rowData => <div>{rowData.startDate ? formatDate(rowData.startDate) : ''}</div>,
    headerStyle: {
      maxWidth: 140,
    },
    editComponent: props => <GermanDatePicker data={props} />,
    customFilterAndSearch: (term, rowData) => {
      const a = getDateAsCleanUTC(term)
      const b = getDateAsCleanUTC(rowData?.startDate)
      return a instanceof Date && b instanceof Date && a.toDateString() === b.toDateString()
    },
  }

  const endDate = {
    title: 'End Date',
    field: 'endDate',
    type: 'date',
    render: rowData => <div>{rowData.endDate ? formatDate(rowData.endDate) : ''}</div>,
    headerStyle: {
      maxWidth: 140,
    },
    editComponent: props => <GermanDatePicker data={props} />,
    customFilterAndSearch: (term, rowData) => {
      const a = getDateAsCleanUTC(term)
      const b = getDateAsCleanUTC(rowData?.endDate)
      return a instanceof Date && b instanceof Date && a.toDateString() === b.toDateString()
    },
  }

  const activeSale = {
    title: 'Active Sale',
    field: 'activeSale',
    render: rowData => <div>{rowData.activeSale
      ? <Check style={{ color: '#5c9846'}} titleAccess="yes" />
      : <Close style={{ color: '#862626'}} titleAccess="no" />}</div>,
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData.activeSale].includes(term.toLowerCase()))
    },
    editable: 'never',
    defaultFilter: query.activeSale,
  }

  //const createdAt = {
  //  title: 'CreatedAt',
  //  field: 'createdAt',
  //  type: 'datetime',
  //  render: rowData => <span>{formatDateTime(rowData.createdAt)}</span>,
  //  editable: 'never',
  //}

  //const updatedAt = {
  //  title: 'UpdatedAt',
  //  field: 'updatedAt',
  //  type: 'datetime',
  //  render: rowData => <span>{formatDateTime(rowData.updatedAt)}</span>,
  //  editable: 'never',
  //}

  const savings = {
    title: 'Savings',
    field: 'savingsInEur',
    render: rowData => <div>
      {rowData.savingsInPercent !== null && rowData.savingsInPercent >= 0
        ? <div>
          {formatCurrency(rowData.savingsInEur)}
          <div style={{textAlign:'right'}}>({rowData.savingsInPercent}%)</div>
          </div>
        : 'n/a'
      }
    </div>,
    editable: 'never',
  }

  const offerID = {
    title: 'OfferID',
    field: 'offerID',
    editable: 'never',
  }

  const countActiveSegments = {
    title: 'Active Seg.',
    field: 'countActiveSegments',
    headerStyle: {
      maxWidth: 80,
    },
    editable: 'never',
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.countActiveSegments
      return (term.startsWith('!') ? !searchString.toString().includes(term.toLowerCase().slice(1)) : searchString.toString().includes(term.toLowerCase()))
    },
  }

  const countFutureSegments = {
    title: 'Future Seg.',
    field: 'countFutureSegments',
    headerStyle: {
      maxWidth: 80,
    },
    editable: 'never',
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.countFutureSegments
      return (term.startsWith('!') ? !searchString.toString().includes(term.toLowerCase().slice(1)) : searchString.toString().includes(term.toLowerCase()))
    },
  }

  const countHistoricSegments = {
    title: 'Historic Seg.',
    field: 'countHistoricSegments',
    headerStyle: {
      maxWidth: 80,
    },
    editable: 'never',
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.countHistoricSegments
      return (term.startsWith('!') ? !searchString.toString().includes(term.toLowerCase().slice(1)) : searchString.toString().includes(term.toLowerCase()))
    },
  }

  const countOverallSegments = {
    title: 'Overall Seg.',
    field: 'countActiveSegments',
    headerStyle: {
      maxWidth: 80,
    },
    editable: 'never',
    render: rowData => <div>
      {rowData.countActiveSegments + rowData.countFutureSegments + rowData.countHistoricSegments}
    </div>,
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.countActiveSegments + rowData.countFutureSegments + rowData.countHistoricSegments
      return (term.startsWith('!') ? !searchString.toString().includes(term.toLowerCase().slice(1)) : searchString.toString().includes(term.toLowerCase()))
    },
    defaultFilter: query.countSegments,
  }

  const columnSet = [
    offerID,
    price,
    subscriptionCategory,
    pricePerMonth,
    offerDiscount,
    subscriptionDuration,
    startDate,
    endDate,
    activeSale,
    countOverallSegments,
    countActiveSegments,
    countFutureSegments,
    countHistoricSegments,
    cancellationPeriodHours,
    hotelReferenceCode,
    description,
    comment,
    savings,
    stripe,
    //createdAt,
    //updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={"Offers"}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postOffer({
                ...newData,
                BookingRoomId: room.id,
                startDate: getDateAsCleanUTC(newData?.startDate),
                endDate: getDateAsCleanUTC(newData?.endDate),
              }, room.id))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putOffer(oldData.id, {
                ...newData,
                startDate: getDateAsCleanUTC(newData?.startDate),
                endDate: getDateAsCleanUTC(newData?.endDate),
              }, room.id))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteOffer(oldData.id, room.id))
              resolve()
            }),
        }}
        options={{
          search: false,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default RoomDetailsTable

import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../../store/actions';
import {Box, IconButton, Modal, Typography} from "@mui/material";
import SubscriptionSegmentForm from "./SubscriptionSegmentForm";
import {Close} from "@mui/icons-material";

const SubscriptionSegmentModal = (props) => {

  const {selectedSubscription} = props

  const dispatch = useDispatch()
  const showSubscriptionSegmentModal = useSelector(state => state.mainWindow.showSubscriptionSegmentModal)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgColor: 'background.paper',
    border: '1px solid #666',
    boxShadow: 24,
    backgroundColor: '#f6f4f0',
    p: 4,
  }

  return (
    <Modal
      open={showSubscriptionSegmentModal}
      onClose={() => dispatch(actions.hideSubscriptionSegmentModal())}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Segment
          </Typography>
          <IconButton aria-label="delete" onClick={() => dispatch(actions.hideSubscriptionSegmentModal())}>
            <Close />
          </IconButton>
        </div>

        <SubscriptionSegmentForm selectedSubscription={selectedSubscription} />

      </Box>
    </Modal>
  )
}

export default SubscriptionSegmentModal

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";

/*
 * GET all companies
 */
export const getCompany = () => {
  return dispatch => {
    dispatch(getCompanyStart())
    axios.get('/horus/company')
      .then ( response => {
        dispatch(getCompanySuccess(response.data))
      })
      .catch(error => {
        dispatch(getCompanyFail(error))
      })
  }
}

export const getCompanyStart = () => {
  return {
    type: actionTypes.GET_COMPANY_START,
  }
}

export const getCompanySuccess = (res) => {
  return {
    type: actionTypes.GET_COMPANY_SUCCESS,
    res: res,
  }
}

export const getCompanyFail = (error) => {
  return {
    type: actionTypes.GET_COMPANY_FAIL,
    error: error,
  }
}


/*
 * GET company details by id
 */
export const getCompanyDetails = (companyID) => {
  return dispatch => {
    dispatch(getCompanyDetailsStart())
    axios.get('/horus/company/byId/'+companyID)
      .then ( response => {
        dispatch(getCompanyDetailsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getCompanyDetailsFail(error))
      })
  }
}

export const getCompanyDetailsStart = () => {
  return {
    type: actionTypes.GET_COMPANY_DETAILS_START,
  }
}

export const getCompanyDetailsSuccess = (res) => {
  return {
    type: actionTypes.GET_COMPANY_DETAILS_SUCCESS,
    res: res,
  }
}

export const getCompanyDetailsFail = (error) => {
  return {
    type: actionTypes.GET_COMPANY_DETAILS_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postCompany = (payload) => {
  return dispatch => {
    dispatch(postCompanyStart())
    axios.post('/horus/company', payload)
      .then ( response => {
        dispatch(postCompanySuccess(response.data))
        dispatch(showNotification('Company created successfully','success'))
        dispatch(getCompany())
      })
      .catch(error => {
        dispatch(showNotification('Creating company failed','error'))
        dispatch(postCompanyFail(error))
      })
  }
}

export const postCompanyStart = () => {
  return {
    type: actionTypes.POST_COMPANY_START,
  }
}

export const postCompanySuccess = (res) => {
  return {
    type: actionTypes.POST_COMPANY_SUCCESS,
    res: res,
  }
}

export const postCompanyFail = (error) => {
  return {
    type: actionTypes.POST_COMPANY_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putCompany = (id, payload) => {
  return dispatch => {
    dispatch(putCompanyStart())
    axios.put('/horus/company/'+id, payload)
      .then ( response => {
        dispatch(putCompanySuccess(response.data))
        dispatch(showNotification('Changes saved successfully','success'))
        dispatch(getCompany())
      })
      .catch(error => {
        dispatch(showNotification('Saving changes failed','error'))
        dispatch(putCompanyFail(error))
      })
  }
}

export const putCompanyStart = () => {
  return {
    type: actionTypes.PUT_COMPANY_START,
  }
}

export const putCompanySuccess = (res) => {
  return {
    type: actionTypes.PUT_COMPANY_SUCCESS,
    res: res,
  }
}

export const putCompanyFail = (error) => {
  return {
    type: actionTypes.PUT_COMPANY_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteCompany = (id) => {
  return dispatch => {
    dispatch(deleteCompanyStart())
    axios.delete('/horus/company/'+id)
      .then ( response => {
        dispatch(deleteCompanySuccess(response.data))
        dispatch(showNotification('Company deleted successfully','success'))
        dispatch(getCompany())
      })
      .catch(error => {
        dispatch(showNotification('Deleting company failed','error'))
        dispatch(deleteCompanyFail(error))
      })
  }
}

export const deleteCompanyStart = () => {
  return {
    type: actionTypes.DELETE_COMPANY_START,
  }
}

export const deleteCompanySuccess = (res) => {
  return {
    type: actionTypes.DELETE_COMPANY_SUCCESS,
    res: res,
  }
}

export const deleteCompanyFail = (error) => {
  return {
    type: actionTypes.DELETE_COMPANY_FAIL,
    error: error,
  }
}

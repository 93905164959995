import React, {useEffect, useState} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Business} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  createFilterOptions,
  FormControlLabel,
  Grid,
  Paper,
  TextField
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import * as actions from '../../store/actions';
import countryList from "../../assets/countryList.json"
import deLocale from "date-fns/locale/de";
import {add} from "date-fns";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {formatCurrencyInline} from "../../services/unitFormater";


const ViewNewBusinessAccount = withRouter((props) => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getOffer())
  },[dispatch])

  const isLoading = useSelector(state => state.company.companiesIsLoading)
  const offerList = useSelector(state => state.offer.offers)
  const [showUsersLink, setShowUsersLink] = useState(false)

  /* react hook form */
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      email: '',
      password: Math.random().toString(36).substring(2, 10),
      firstName: '',
      lastName: '',
      company: '',
      addressStreet: '',
      addressZip: '',
      addressCity: '',
      addressCountry: '',
      comment: '',
      mailNotification: false,
      sendInvitationMail: true,
      createSubscription: false,
      subscriptionStartDate: new Date(),
      assignOffer: false,
      offer: '',
    }
  })

  const watchSendInvitationMail = watch('sendInvitationMail')
  const watchCreateSubscription = watch('createSubscription')
  const watchAssignOffer = watch('assignOffer')

  const onSubmit = data => {
    const payload = {
      ...data,
      registrationSource: 'horusBusinessAccount',
      product: 'business',
    }
    dispatch(actions.postCustomerNewBusiness(payload))
    setShowUsersLink(true)
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Business fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">New Business Account</div>
        </div>
        <div>Create company account and invite admin user</div>
      </div>


      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>

            <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8}}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiTextField-root': {m: 1, p: 0, width: 300},
                }}
              >
                <div style={{fontWeight: 'bold', margin: 10}}>Admin Account (required)</div>

                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'E-Mail required for new account',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please check for valid E-Mail"
                    }
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="Admin E-Mail"
                      size="small"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: 'First name required',
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="First Name"
                      size="small"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: 'Last name required',
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      size="small"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: 'Password required',
                    minLength: {
                      value: 8,
                      message: 'Secure password with length >= 8 required',
                    },
                    maxLength: 99,
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="Password"
                      size="small"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />



                <div style={{fontWeight: 'bold', margin: 10, paddingTop: 20}}>Company (required)</div>
                <Controller
                  name="company"
                  control={control}
                  rules={{
                    required: 'Company name required',
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="Company name"
                      size="small"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />



                <div style={{fontWeight: 'bold', margin: 10, paddingTop: 20}}>Details</div>

                <Controller
                  name="addressStreet"
                  control={control}
                  rules={{
                    //required: 'Street with number required',
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="Street with number"
                      size="small"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  name="addressZip"
                  control={control}
                  rules={{
                    //required: 'ZIP code required',
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please check for valid ZIP code",
                    }
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="ZIP"
                      size="small"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  name="addressCity"
                  control={control}
                  rules={{
                    //required: 'City required',
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="City"
                      size="small"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />

                <Controller
                  name="addressCountry"
                  control={control}
                  rules={{
                    //required: 'Country required',
                  }}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      id="countryAutocomplete"
                      options={countryList}
                      disableClearable={false}
                      getOptionLabel={(option) => option || ''}
                      value={field?.value}
                      onChange={(e, value) => {
                        field.onChange(value)
                      }}
                      autoHighlight={true} // required to select entry without submitting entire material-table row
                      onKeyDown={e => handleKey(e)}
                      isOptionEqualToValue={(option, value) => {
                        return option === value
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props}>
                            <div>{option || '---'}</div>
                          </li>
                        )
                      }}
                      size="small"
                      renderInput={(params) => {
                        params.InputProps.style = {}
                        return (
                          <TextField
                            {...params}
                            label="Country"
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                          />
                        )
                      }}
                    />
                  )}
                />

              </Box>
            </Paper>
          </Grid>



          <Grid item xs={12} md={6}>
            <Paper style={{padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8}}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiTextField-root': {m: 1, p: 0, width: 300},
                }}
              >

                <div style={{fontWeight: 'bold', margin: 10}}>Internal Notes</div>

                <Controller
                  name="comment"
                  control={control}
                  rules={{
                    //required: 'City required',
                  }}
                  render={({field, fieldState}) => (
                    <TextField
                      {...field}
                      label="Comment"
                      multiline
                      rows={3}
                      size="small"
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      variant="outlined"
                    />
                  )}
                />

                <div style={{fontWeight: 'bold', margin: 10, paddingTop: 20}}>Settings</div>
                <Box sx={{m: 1}}>

                  <Controller
                    name="sendInvitationMail"
                    control={control}
                    render={({field, fieldState}) => (
                      <FormControlLabel
                        control={<Checkbox
                          {...field}
                          checked={field.value}
                        />}
                        label={
                          <div>Send e-mail invitation</div>
                        }
                      />
                    )}
                  />
                  {watchSendInvitationMail
                    ? ''
                    : <div style={{marginLeft: 40, color: '#6b6262', fontSize: 13}}>
                        Initial password is sent to user in default invitation e-mail.<br/>
                        In case of individual invitation please copy credentials from password field.<br/>
                        Not visible later.
                      </div>
                  }

                  <br/>

                  <Controller
                    name="mailNotification"
                    control={control}
                    render={({field, fieldState}) => (
                      <FormControlLabel
                        control={<Checkbox
                          {...field}
                          checked={field.value}
                        />}
                        label={
                          <div>Newsletter</div>
                        }
                      />
                    )}
                  />
                  <br/>

                  <Controller
                    name="createSubscription"
                    control={control}
                    render={({field, fieldState}) => (
                      <FormControlLabel
                        control={<Checkbox
                          {...field}
                          checked={field.value}
                        />}
                        label={
                          <div>Create Subscription</div>
                        }
                      />
                    )}
                  />
                  <br/>

                  {watchCreateSubscription
                    ? <div>
                        <Controller
                          name="subscriptionStartDate"
                          control={control}
                          render={({field, fieldState}) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                              <DatePicker
                                {...field}
                                mask={'__.__.____'}
                                format="dd.MM.yyyy"
                                minDate={new Date()}
                                maxDate={add(new Date(), {days: 120})}
                                //shouldDisableDate={}
                                id="subscriptionStartDate"
                                label="Subscription Start Date"
                                value={field.value}
                                onChange={field.onChange}
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : null}
                                slotProps={{
                                  textField: {
                                    InputProps: {
                                      className: "dateField",
                                      size: "small",
                                      error: !!fieldState.error,
                                      style: {
                                        width: 210,
                                        backgroundColor: "#FFF",
                                        fontSize: 14
                                      },
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </div>
                    : ''
                  }

                  <Controller
                    name="assignOffer"
                    control={control}
                    render={({field, fieldState}) => (
                      <FormControlLabel
                        control={<Checkbox
                          {...field}
                          checked={field.value && watchCreateSubscription}
                          disabled={!watchCreateSubscription}
                        />}
                        label={
                          <div>Assign Offer</div>
                        }
                      />
                    )}
                  />
                  <br/>

                  {watchAssignOffer && watchCreateSubscription
                    ? <Controller
                      name="offer"
                      control={control}
                      render={({field, fieldState}) => (
                        <Autocomplete
                          {...field}
                          id="offerAutocomplete"
                          options={offerList}
                          disableClearable={false}
                          getOptionLabel={(option) => {
                            return(option?.offerID || '')
                          }}
                          value={field?.value}
                          onChange={(e, value) => {
                            field.onChange({id:value?.id, offerID:value?.offerID})
                          }}
                          autoHighlight={true} // required to select entry without submitting entire material-table row
                          onKeyDown={e => handleKey(e)}
                          isOptionEqualToValue={(option, value) => {
                            return option?.offerID === value?.offerID
                          }}
                          renderOption={(props, option) => {
                            const offerDetails = offerList.filter(o => o.offerID === option.offerID)?.[0]
                            const category = offerDetails?.BookingSubscriptionCategory?.name
                            const shareable = offerDetails?.BookingSubscriptionCategory?.shareable
                            const year = offerDetails?.BookingSubscriptionCategory?.nightsScope === 'year'
                            const activeSale = offerDetails?.activeSale
                            const discount = offerDetails?.discount
                            const duration = offerDetails?.BookingSubscriptionDuration?.name
                            const roomName = offerDetails?.BookingRoom?.name
                            const hotelName = offerDetails?.BookingRoom?.BookingHotel?.name
                            const price = option?.price?.toFixed(2)?.replace('.',',') || 'n/a'
                            const pricePerMonth = offerDetails?.pricePerMonth?.toFixed(2)?.replace('.',',')
                            return(
                              <div {...props}>
                                <div style={{fontSize: 13}}>
                                  <div>{category} @ {hotelName}</div>
                                  <div style={{fontSize: 10}}>{roomName} | {duration} Laufzeit</div>
                                  <div style={{fontSize: 10}}>{option.offerID}</div>
                                  <div style={{fontSize: 10}}>{price} €/Nacht | {pricePerMonth} €/Monat</div>
                                  <div style={{fontSize: 10}}>{
                                    shareable ? <span style={{color:"blue"}}>business</span> : 'single'
                                  } | {
                                    year ? <span style={{color:"purple"}}>year</span> : 'month'
                                  } | {
                                    activeSale ? 'active' : <span style={{color:"red"}}>inactive</span>
                                  } | {
                                    discount > 0 ? <span style={{color:"green"}}>{formatCurrencyInline(discount)} discount</span> : 'no discount'
                                  }</div>
                                </div>
                              </div>
                            )
                          }}
                          filterOptions={createFilterOptions({
                            matchFrom: 'any',
                            stringify: (option) => {
                              const offerID = option?.offerID
                              const category = offerList.filter(o => o.id === option.id)[0]?.BookingSubscriptionCategory?.name
                              const hotelName = offerList.filter(o => o.id === option.id)[0]?.BookingRoom?.BookingHotel?.name
                              return(offerID + ' ' + category + ' ' + hotelName)
                            },
                          })}
                          size="small"
                          renderInput={(params) => {
                            params.InputProps.style = {}
                            return (
                              <TextField
                                {...params}
                                label="Offer"
                                error={!!fieldState.error}
                                helperText={fieldState.error ? fieldState.error.message : null}
                              />
                            )
                          }}
                        />
                      )}
                    />
                    : ''
                  }

                </Box>

              </Box>
            </Paper>
          </Grid>
        </Grid>

        <div style={{margin: 10, display: 'flex', justifyContent: 'end'}}>
          {isLoading
            ? <><CircularProgress size={20} style={{marginLeft: 5}}/></>
            : ''
          }
          <button
            type="submit"
            className="formActionButton"
          >
            Create account
          </button>
        </div>
        {showUsersLink && !isLoading
          ? <div style={{marginLeft: 10, marginTop: 75}}>
              Go to <Link to={'/app/users/'}>Users</Link> | <Link to={'/app/companies/'}>Companies</Link>
            </div>
          : ''
        }
      </form>


    </div>
  )
})


export default ViewNewBusinessAccount

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET all rooms
 */
export const getRoom = () => {
  return dispatch => {
    dispatch(getRoomStart())
    axios.get('/horus/room')
      .then ( response => {
        dispatch(getRoomSuccess(response.data))
      })
      .catch(error => {
        dispatch(getRoomFail(error))
      })
  }
}

export const getRoomStart = () => {
  return {
    type: actionTypes.GET_ROOM_START,
  }
}

export const getRoomSuccess = (res) => {
  return {
    type: actionTypes.GET_ROOM_SUCCESS,
    res: res,
  }
}

export const getRoomFail = (error) => {
  return {
    type: actionTypes.GET_ROOM_FAIL,
    error: error,
  }
}


/*
 * GET one room by id
 */
export const getRoomById = (id) => {
  return dispatch => {
    dispatch(getRoomByIdStart())
    axios.get('/horus/room/'+id)
      .then ( response => {
        dispatch(getRoomByIdSuccess(response.data))
      })
      .catch(error => {
        dispatch(getRoomByIdFail(error))
      })
  }
}

export const getRoomByIdStart = () => {
  return {
    type: actionTypes.GET_ROOM_BY_ID_START,
  }
}

export const getRoomByIdSuccess = (res) => {
  return {
    type: actionTypes.GET_ROOM_BY_ID_SUCCESS,
    res: res,
  }
}

export const getRoomByIdFail = (error) => {
  return {
    type: actionTypes.GET_ROOM_BY_ID_FAIL,
    error: error,
  }
}


/*
 * GET all rooms by hotelId
 */
export const getRoomByHotel = (hotelId) => {
  return dispatch => {
    dispatch(getRoomByHotelStart())
    axios.get('/horus/room/byHotel/'+hotelId)
      .then ( response => {
        dispatch(getRoomByHotelSuccess(response.data))
      })
      .catch(error => {
        dispatch(getRoomByHotelFail(error))
      })
  }
}

export const getRoomByHotelStart = () => {
  return {
    type: actionTypes.GET_ROOM_BY_HOTEL_START,
  }
}

export const getRoomByHotelSuccess = (res) => {
  return {
    type: actionTypes.GET_ROOM_BY_HOTEL_SUCCESS,
    res: res,
  }
}

export const getRoomByHotelFail = (error) => {
  return {
    type: actionTypes.GET_ROOM_BY_HOTEL_FAIL,
    error: error,
  }
}


/*
 * POST room
 */
export const postRoom = (payload, hotelId) => {
  return dispatch => {
    dispatch(postRoomStart())
    axios.post('/horus/room', payload)
      .then ( response => {
        dispatch(postRoomSuccess(response.data))
        dispatch(getRoomByHotel(hotelId))
      })
      .catch(error => {
        dispatch(postRoomFail(error))
      })
  }
}

export const postRoomStart = () => {
  return {
    type: actionTypes.POST_ROOM_START,
  }
}

export const postRoomSuccess = (res) => {
  return {
    type: actionTypes.POST_ROOM_SUCCESS,
    res: res,
  }
}

export const postRoomFail = (error) => {
  return {
    type: actionTypes.POST_ROOM_FAIL,
    error: error,
  }
}


/*
 * PUT room
 */
export const putRoom = (id, payload, hotelId) => {
  return dispatch => {
    dispatch(putRoomStart())
    axios.put('/horus/room/'+id, payload)
      .then ( response => {
        dispatch(putRoomSuccess(response.data))
        dispatch(getRoomByHotel(hotelId))
      })
      .catch(error => {
        dispatch(putRoomFail(error))
      })
  }
}

export const putRoomStart = () => {
  return {
    type: actionTypes.PUT_ROOM_START,
  }
}

export const putRoomSuccess = (res) => {
  return {
    type: actionTypes.PUT_ROOM_SUCCESS,
    res: res,
  }
}

export const putRoomFail = (error) => {
  return {
    type: actionTypes.PUT_ROOM_FAIL,
    error: error,
  }
}


/*
 * DELETE room
 */
export const deleteRoom = (id, hotelId) => {
  return dispatch => {
    dispatch(deleteRoomStart())
    axios.delete('/horus/room/'+id)
      .then ( response => {
        dispatch(deleteRoomSuccess(response.data))
        dispatch(getRoomByHotel(hotelId))
      })
      .catch(error => {
        dispatch(deleteRoomFail(error))
      })
  }
}

export const deleteRoomStart = () => {
  return {
    type: actionTypes.DELETE_ROOM_START,
  }
}

export const deleteRoomSuccess = (res) => {
  return {
    type: actionTypes.DELETE_ROOM_SUCCESS,
    res: res,
  }
}

export const deleteRoomFail = (error) => {
  return {
    type: actionTypes.DELETE_ROOM_FAIL,
    error: error,
  }
}

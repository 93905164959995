import {Tab, Tabs} from '@mui/material';
import React from 'react';
import {withRouter} from 'react-router-dom';

export const LexofficeNavigation = withRouter((props) => {

  const urlParts = props.location.pathname.split('/')
  const [value, setValue] = React.useState(urlParts[urlParts.length - 2] || 'contacts')

  const handleChange = (event, newValue) => {
    setValue(newValue)
    props.history.push('/app/lexoffice/'+newValue+'/')
  };

  return(
    <div style={{ marginBottom: 15 }}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label="Contacts" value="contact" />
        <Tab label="Sale Invoices" value="invoice" />
        <Tab label="Purchase Invoice" value="purchaseinvoice" />
        <Tab label="Sale Credit Notes" value="creditNote" />
        <Tab label="Purchase Credit Notes" value="purchasecreditnote" />
      </Tabs>
    </div>
  )
})

import React from 'react'
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Typography} from '@mui/material';
import {localization, tableIcons} from '../components/TableSharedConfig'

const SearchAnalyticsTable = withRouter((props) => {

  const data = useSelector(state => state.analytics.searchAnalytics)

  const city = {
    title: 'City',
    field: 'city',
  }

  const nights = {
    title: 'Nights',
    field: 'nights',
  }

  const count = {
    title: 'Count',
    field: 'count',
    defaultSort: 'desc',
  }

  const columnSet = [
    city,
    nights,
    count,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={null}
        detailPanel={null}
        options={{
          search: true,
          filtering: false,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 100, 500],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#fff" : "#f9fcff",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default SearchAnalyticsTable

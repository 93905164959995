import {Button, Paper, Typography} from "@mui/material";
import Editor from "@monaco-editor/react";
import React, {useRef} from "react";
import * as actions from "../../store/actions";
import {useDispatch} from "react-redux";


export const HotelDetailsFormDistrictEditor = ({district}) => {

  const dispatch = useDispatch()

  const initPolygon = ((typeof district?.locationPolygon === 'object') && district?.locationPolygon !== null)
    ? district?.locationPolygon
    : {note:'/// no config found ///'}
  const samplePolygon = {
    polygon: [[11.540331, 48.188840],[11.5620837, 48.1915594]],
    hotels: [{id: "Test Hotel 1",long: 11.55,lat: 48.189},{id: "Test Hotel 2",long: 11.59,lat: 48.169}]
  }
  const districtConfigEditorRef = useRef(null)

  const handleDistrictConfigEditorDidMount = (editor, monaco) => {
    districtConfigEditorRef.current = editor
    districtConfigEditorRef.current.setValue(JSON.stringify(initPolygon, null, '\t'))
  }
  const loadSamplePolygon = () => {
    districtConfigEditorRef.current.setValue(JSON.stringify(samplePolygon, null, '\t'))
  }
  const setHotelDistrictConfig = () => {
    const json = JSON.parse(districtConfigEditorRef.current.getValue())
    const payload = {locationPolygon: json}
    dispatch(actions.putDistrict(district?.id, payload))
  }

  return(
    <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 0, marginBottom: 0 }}>
      <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
        Hotel District Config
      </Typography>
      <div style={{width: '95%'}}>
        {initPolygon
          ? <Editor
            height="400px"
            defaultLanguage="json"
            //defaultValue={initPolygon.toString()}
            onMount={handleDistrictConfigEditorDidMount}
            //onChange={(value, event) => setDistrictConfigValue(JSON.parse(value))}
            options={{
              minimap: {enabled: false},
            }}
          />
          : ''}

        <div style={{ textAlign: 'right', marginTop: 5 }}>
          <Button onClick={loadSamplePolygon} variant="outlined" color="secondary">
            Load Sample Polygon
          </Button>
          &nbsp;&nbsp;
          <Button onClick={setHotelDistrictConfig} variant="outlined" color="secondary">
            Save
          </Button>
        </div>
      </div>
    </Paper>
  )
}
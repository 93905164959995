import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Settings} from '@mui/icons-material';
import * as actions from '../../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {SettingsNavigation} from '../components/SettingsNavigation';

const ViewSettingsCache = withRouter((props) => {
  const dispatch = useDispatch()
  const cacheStats = useSelector(state => state.settings.cacheStats)

  useEffect(() => {
    dispatch(actions.getCacheStats())
  },[dispatch])

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Settings fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Settings</div>
        </div>
        <div> </div>
      </div>

      <SettingsNavigation />

      <div style={{background: 'white', border: '1px solid #DDD', padding: 20}}>
        <div><b>Cache Stats</b></div><br/>
        <div>Hits: {cacheStats.hits}</div>
        <div>Misses: {cacheStats.misses}</div>
        <div>Keys: {cacheStats.keys}</div>
        <div>Key Size: {cacheStats.ksize}</div>
        <div>Value Size: {cacheStats.vsize}</div>

        <br/><br/>

        <div><b>Clear cache</b></div><br/>
        <button
          className="formActionButton"
          onClick={() => {
            dispatch(actions.postCacheFlushData())
          }}
        >Remove data</button>
      </div>


    </div>
  )
})


export default ViewSettingsCache

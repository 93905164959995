import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Autocomplete, InputAdornment, TextField, Typography} from '@mui/material';
import {formatCurrency, formatDateTime, formatDecimalEuToUs, formatDecimalUsToEu} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {shortenText} from '../../services/stringHelper';

const OfferDiscountTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.settings.offerDiscount)

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const id = {
    title: 'ID',
    field: 'offerDiscountID',
    editable: 'never',
  }

  const name = {
    title: 'Name',
    field: 'name',
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const duration = {
    title: 'Duration',
    field: 'duration',
    editable: 'onAdd',
    editComponent: props => {
      return(
        <div style={{width: 120}}>
          <Autocomplete
            id="duration"
            options={[
              'repeating',
              'forever',
            ]}
            disableClearable={true}
            getOptionLabel={(option) => option || '(required)'}
            value={props.value || ''}
            autoHighlight={true} // required to select entry without submitting entire material-table row
            onKeyDown={e => handleKey(e)}
            onChange={(e, value) => {
              console.log(value, {value})
              console.log(props, {rowData: props.rowData})
              props.onChange(value)
              if(value==='forever') {
                numberOfMonths.editable = 'never'
                const data = { ...props.rowData }
                data.numberOfMonths = ''
                data.duration = 'forever'
                props.onRowDataChange(data)
              }
              if(value==='repeating') numberOfMonths.editable = 'onAdd'
            }}
            isOptionEqualToValue={(option, value) => {
              return option === value
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <div style={{fontSize: 13}}>{option}</div>
              </li>
            )}
            size="small"
            renderInput={(params) => {
              params.InputProps.style = {fontSize: 13}
              return(
                <TextField
                  {...params}
                  placeholder=""
                />
              )
            }}
          />
        </div>
      )
    },
  }

  const numberOfMonths = {
    title: 'Number Of Months',
    field: 'numberOfMonths',
    editable: 'never',
  }

  const discountPercentage = {
    title: 'Discount (%)',
    field: 'discountPercentage',
    editable: 'onAdd',
    render: rowData => <div style={{textAlign: 'right', marginRight: 10}}>{rowData.discountPercentage ? rowData.discountPercentage+'%' : ''}</div>,
    editComponent: props => (
      <TextField
        size={'small'}
        value={props.value || null}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          style: {fontSize: 13}
        }}
        onChange={e => props.onChange(e.target.value.replaceAll('.','').replaceAll(',',''))}
      />
    )
  }

  const discountEur = {
    title: 'Discount (€)',
    field: 'discountEur',
    editable: 'onAdd',
    headerStyle: {
      maxWidth: 120,
    },
    render: rowData => <div style={{marginRight: 10}}>{rowData.discountEur ? formatCurrency(rowData.discountEur) : ''}</div>,
    editComponent: props => (
      <TextField
        size={'small'}
        value={formatDecimalUsToEu(props.value) || null}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
          style: {fontSize: 13}
        }}
        onChange={e => props.onChange(formatDecimalEuToUs(e.target.value))}
      />
    )
  }

  const description = {
    title: 'Description',
    field: 'description',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 210}}>{shortenText(rowData.description, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const countOffers = {
    title: 'Count Offers',
    field: 'countOffers',
    editable: 'never',
  }

  const internalComment = {
    title: 'Internal Comment',
    field: 'internalComment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 190}}>{shortenText(rowData.internalComment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const columnSet = [
    id,
    name,
    description,
    duration,
    numberOfMonths,
    discountEur,
    discountPercentage,
    countOffers,
    internalComment,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={"Offer Discounts"}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postOfferDiscount(newData))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putOfferDiscount(oldData.id, newData))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteOfferDiscount(oldData.id))
              resolve()
            }),
        }}
        options={{
          search: false,
          filtering: false,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default OfferDiscountTable

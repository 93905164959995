import React, {useRef} from "react";
import {Editor} from '@tinymce/tinymce-react';
import tinymce from 'tinymce/tinymce';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';


export const HtmlEditorControlled = (props) => {
  const {value, editorChangeCallback=null, width=300, height=200} = props
  const editorRef = useRef(null)

  return(
    <div style={{ width: width, marginTop: 5, marginBottom: 5 }}>
      <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        value={value}
        onEditorChange={(value, editor) => editorChangeCallback(value)}
        init={{
          width: '100%',
          height: height,
          menubar: false,
          statusbar: false,
          plugins: [
            'advlist autolink lists link image charmap anchor',
            'searchreplace code fullscreen',
            'insertdatetime media table code help'
          ],
          toolbar: 'undo redo | ' +
            'bold italic | bullist numlist | alignleft aligncenter ' +
            'alignright alignjustify | backcolor media link | ' +
            ' removeformat code | help',
          skin: false,
          content_css: false,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:13px }',
          initEngine: tinymce
        }}
      />
    </div>

  )
}
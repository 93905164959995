import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  customers: [],
  customerBlacklist: [],
}

const startLoading = (state) => {
  return {
    ...state,
    isLoading: true,
  }
}

const stopLoading = (state) => {
  return {
    ...state,
    isLoading: false,
  }
}

const setCustomers = (state, action) => {
  return {
    ...state,
    customers: action.res,
    isLoading: false,
  }
}

const setCustomerBlacklist = (state, action) => {
  return {
    ...state,
    customerBlacklist: action.res,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_CUSTOMER_START: return startLoading(state)
    case actionTypes.GET_CUSTOMER_SUCCESS: return setCustomers(state, action)
    case actionTypes.GET_CUSTOMER_BLACKLIST_SUCCESS: return setCustomerBlacklist(state, action)
    case actionTypes.POST_CUSTOMER_NEW_BUSINESS_START: return startLoading(state)
    case actionTypes.POST_CUSTOMER_NEW_BUSINESS_SUCCESS: return stopLoading(state)
    case actionTypes.POST_CUSTOMER_NEW_BUSINESS_FAIL: return stopLoading(state)
    default: return state
  }
}

export default reducer

import {Autocomplete, TextField} from "@mui/material";
import React, {useState} from "react";


const fetchOptions = (id, callback) => {
  // Example API call with parameter
  fetch(`/api/horus/businessUserInvitation/byCompanyId/${id}`)
    .then((response) => response.json())
    .then((data) => callback(data))
    .catch((error) => console.error('Error fetching data:', error))
}


const AutocompleteBusinessUserInvite = (props) => {

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const handleInputChange = () => {
    setLoading(true)
    // Fetch options dynamically based on input value
    //console.log('props.data.rowData?.BookingSubscription', props.data.rowData?.BookingSubscription)
    const companyId = props.data.rowData?.BookingSubscription?.BookingCustomer?.BusinessCompany?.id || 0
    fetchOptions(companyId, (data) => {
      setOptions(data)
      setLoading(false)
    })
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  return(
    <Autocomplete
      id="AutocompleteInvite"
      open={open}
      onOpen={() => {handleInputChange(); setOpen(true)}}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      options={options}
      loading={loading}
      getOptionLabel={(option) => {
        if(option?.invitationID) {
          return(option?.firstName + ' ' + option?.lastName + ' (' + option?.invitationID + ')')
        } else {
          return('Admin')
        }
      }}
      value={props.data.value || ''}
      onChange={(e, value) => {
        props.data.onChange(value)
      }}
      autoHighlight={true} // required to select entry without submitting entire material-table row
      onKeyDown={e => handleKey(e)}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id
      }}
      renderOption={(props, option) => (
        <li {...props} key={option?.invitationID}>
          <div style={{fontSize: 13}}>{option?.firstName} {option?.lastName} ({option?.invitationID})</div>
        </li>
      )}
      size="small"
      renderInput={(params) => {
        params.InputProps.style = {fontSize: 13}
        return(
          <TextField
            {...params}
            placeholder=""
          />
        )
      }}
    />
  )
}

export default AutocompleteBusinessUserInvite
import {CircularProgress} from '@mui/material';
import React from 'react';

export const Loading = () => {

  return (
    <div style={{margin:20}}>
      <CircularProgress />
    </div>
  )

}

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET
 */
export const getLinkSettings = () => {
  return dispatch => {
    dispatch(getLinkSettingsStart())
    axios.get('/horus/linkSettings')
      .then ( response => {
        dispatch(getLinkSettingsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getLinkSettingsFail(error))
      })
  }
}

export const getLinkSettingsStart = () => {
  return {
    type: actionTypes.GET_LINK_SETTINGS_START,
  }
}

export const getLinkSettingsSuccess = (res) => {
  return {
    type: actionTypes.GET_LINK_SETTINGS_SUCCESS,
    res: res,
  }
}

export const getLinkSettingsFail = (error) => {
  return {
    type: actionTypes.GET_LINK_SETTINGS_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postLinkSettings = (payload) => {
  return dispatch => {
    dispatch(postLinkSettingsStart())
    axios.post('/horus/linkSettings', payload)
      .then ( response => {
        dispatch(postLinkSettingsSuccess(response.data))
        dispatch(getLinkSettings())
      })
      .catch(error => {
        dispatch(postLinkSettingsFail(error))
      })
  }
}

export const postLinkSettingsStart = () => {
  return {
    type: actionTypes.POST_LINK_SETTINGS_START,
  }
}

export const postLinkSettingsSuccess = (res) => {
  return {
    type: actionTypes.POST_LINK_SETTINGS_SUCCESS,
    res: res,
  }
}

export const postLinkSettingsFail = (error) => {
  return {
    type: actionTypes.POST_LINK_SETTINGS_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putLinkSettings = (id, payload) => {
  return dispatch => {
    dispatch(putLinkSettingsStart())
    axios.put('/horus/linkSettings/'+id, payload)
      .then ( response => {
        dispatch(putLinkSettingsSuccess(response.data))
        dispatch(getLinkSettings())
      })
      .catch(error => {
        dispatch(putLinkSettingsFail(error))
      })
  }
}

export const putLinkSettingsStart = () => {
  return {
    type: actionTypes.PUT_LINK_SETTINGS_START,
  }
}

export const putLinkSettingsSuccess = (res) => {
  return {
    type: actionTypes.PUT_LINK_SETTINGS_SUCCESS,
    res: res,
  }
}

export const putLinkSettingsFail = (error) => {
  return {
    type: actionTypes.PUT_LINK_SETTINGS_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteLinkSettings = (id) => {
  return dispatch => {
    dispatch(deleteLinkSettingsStart())
    axios.delete('/horus/linkSettings/'+id)
      .then ( response => {
        dispatch(deleteLinkSettingsSuccess(response.data))
        dispatch(getLinkSettings())
      })
      .catch(error => {
        dispatch(deleteLinkSettingsFail(error))
      })
  }
}

export const deleteLinkSettingsStart = () => {
  return {
    type: actionTypes.DELETE_LINK_SETTINGS_START,
  }
}

export const deleteLinkSettingsSuccess = (res) => {
  return {
    type: actionTypes.DELETE_LINK_SETTINGS_SUCCESS,
    res: res,
  }
}

export const deleteLinkSettingsFail = (error) => {
  return {
    type: actionTypes.DELETE_LINK_SETTINGS_FAIL,
    error: error,
  }
}

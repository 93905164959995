import React, {useEffect} from 'react'
import {Link, useParams, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Segment} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import SubscriptionSegmentTable from "../tables/SubscriptionSegmentTable";
import SubscriptionEvaluationTable from "../tables/SubscriptionEvaluationTable";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import SubscriptionSegmentTimeline from "../components/SubscriptionSegmentTimeline";
import SubscriptionSegmentModal from "../components/SubscriptionSegmentModal";
import {formatDate} from "../../services/unitFormater";
import {SubscriptionSegmentActions} from "../components/SubscriptionSegmentActions";
import {SubscriptionStripe} from "../components/SubscriptionStripe";
import {SubscriptionLexoffice} from "../components/SubscriptionLexoffice";


const ViewSubscriptionSegment = withRouter((props) => {
  const dispatch = useDispatch()
  const { subscriptionID } = useParams()

  useEffect(() => {
    //dispatch(actions.getSubscription())
    dispatch(actions.getSubscriptionEvaluation(subscriptionID))
    dispatch(actions.getSubscriptionStripe(subscriptionID))
    dispatch(actions.getSubscriptionLexoffice(subscriptionID))
    dispatch(actions.getCustomer())
    dispatch(actions.getOffer())
  },[dispatch, subscriptionID])

  const isLoadingSubscription = useSelector(state => state.subscription.isLoadingSubscription)
  const isLoadingEvaluation = useSelector(state => state.subscription.isLoadingEvaluation)
  //const subscriptions = useSelector(state => state.subscription.subscriptions)
  //const selectedSubscription = subscriptions.filter(sub => sub.subscriptionID === subscriptionID)?.[0] || {}
  const evaluation = useSelector(state => state.subscription.evaluation)
  const selectedSubscription = evaluation?.subscriptionData || {}
  const hotel = selectedSubscription?.BookingHotel
  const room = selectedSubscription?.BookingRoom
  const customer = selectedSubscription?.BookingCustomer

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Segment fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Subscription Segments</div>
        </div>
        <div style={{margin: '0 20px', fontWeight: 'bold'}}>{subscriptionID}</div>
        <div style={{margin: '0 10px'}}>{formatDate(selectedSubscription?.startDate)} — {formatDate(selectedSubscription?.endDate) || '∞'}</div>
        <div style={{margin: '0 10px'}}>{'//'}</div>
        <Link to={`/app/hotelDetails/${hotel?.id}`}>{hotel?.name}</Link>
        <div style={{margin: '0 10px'}}>{'//'}</div>
        <Link to={`/app/roomDetails/${room?.id}`}>{room?.name}</Link>
        <div style={{margin: '0 10px'}}>{'//'}</div>
        <Link to={`/app/users/${customer?.customerID}`}>{customer?.firstName} {customer?.lastName} ({customer?.customerID})</Link>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoadingSubscription || isLoadingEvaluation ? <Loading /> : <div>
            <br />
            <div className="grayH2">Settings</div>
            <SubscriptionSegmentTable />
            <br />
            <SubscriptionSegmentActions />
            <br />
            <div className="grayH2">Stripe</div>
            <SubscriptionStripe />
            <div className="grayH2">Lexoffice</div>
            <SubscriptionLexoffice />
            <div className="grayH2">Evaluation</div>
            <SubscriptionEvaluationTable />
            <br /><br />
            <div className="grayH2">Timeline</div>
            <SubscriptionSegmentTimeline />
          </div>}

          <SubscriptionSegmentModal selectedSubscription={selectedSubscription} />
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewSubscriptionSegment

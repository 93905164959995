import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Hotel} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import HotelTable from '../tables/HotelTable';
import CitySelector from "../components/CitySelector";
import {useLocation} from "react-router";


const ViewHotel = withRouter((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getHotel())
    dispatch(actions.getCity())
    dispatch(actions.getSettingsHotelAttribute())
  },[dispatch])

  const hotelsLoading = useSelector(state => state.hotel.hotelsLoading)

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    searchCity: searchQuery.get('searchCity') || '',
  }

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Hotel fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Hotel Management</div>
        </div>
        <CitySelector
          selectedCityId={query.searchCity}
          redirectQueryParam={'searchCity'}
          addAllOption={true}
        />
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {hotelsLoading ? <Loading /> : <HotelTable />}
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewHotel

import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Typography, Checkbox} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import * as actions from '../../store/actions'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {shortenText} from '../../services/stringHelper';
import {Check, Close} from '@mui/icons-material';

const CityTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.settings.city)

  const name = {
    title: 'Name',
    field: 'cityName',
    render: rowData => <div style={{fontWeight: 'bold'}}>{rowData.cityName}</div>,
    defaultSort: 'asc',
  }

  const cityId = {
    title: 'Shortcode',
    field: 'cityId',
  }

  const locationLat = {
    title: 'City Center Lat',
    field: 'cityCenterLat',
    render: rowData => <div>{shortenText(rowData.cityCenterLat, 8)}</div>,
  }

  const locationLong = {
    title: 'City Center Long',
    field: 'cityCenterLong',
    render: rowData => <div>{shortenText(rowData.cityCenterLong, 8)}</div>,
  }

  const visible = {
    title: 'Visible',
    field: 'visible',
    render: rowData => (
      <div>{rowData.visible ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    )
  }

  const zoomLevel = {
    title: 'ZoomLevel',
    field: 'zoomLevel',
  }

  const population = {
    title: 'Population (ranking)',
    field: 'population',
  }

  const brevoListId = {
    title: 'Brevo List Id',
    field: 'brevoListId',
  }

  const hotelRequestModulePosition = {
    title: 'Hotel Request Module Rank',
    field: 'hotelRequestModulePosition',
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 120}}>{formatDateTime(rowData.createdAt)}</div>,
    cellStyle: {
      width: 40,
    },
    headerStyle: {
      width: 40,
    },
    editable: 'never',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 120}}>{formatDateTime(rowData.updatedAt)}</div>,
    cellStyle: {
      width: 40,
    },
    headerStyle: {
      width: 40,
    },
    editable: 'never',
  }

  const columnSet = [
    name,
    cityId,
    visible,
    locationLat,
    locationLong,
    zoomLevel,
    population,
    brevoListId,
    hotelRequestModulePosition,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={"Cities"}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postCity(newData))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putCity(oldData.id, newData))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteCity(oldData.id))
              resolve()
            }),
        }}
        detailPanel={null}
        options={{
          search: true,
          filtering: false,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#fff" : "#f9fcff",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default CityTable

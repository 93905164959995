import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {FormControl, Grid, InputLabel, MenuItem, Select} from '@mui/material';
import {Timeline} from '@mui/icons-material';
import {Loading} from '../components/Loading';
import * as actions from '../../store/actions';
import PriceAnalyticsGraph from '../components/PriceAnalyticsGraph';
import {getDateArrayBetweenStartEnd} from '../../services/unitFormater';


const ViewPriceAnalytics = withRouter((props) => {
  const dispatch = useDispatch()
  const tzoffset = (new Date()).getTimezoneOffset() * 60000 //offset in milliseconds
  const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1)

  const today = localISOTime.substring(0, 10)
  const [hotelId, setHotelId] = useState('MUNSCH')
  const [selectedCheckedAt, setSelectedCheckedAt] = useState([today])
  const [startGraphDate, setStartGraphDate] = useState(today)
  const checkedAtOptions = getDateArrayBetweenStartEnd('2021-10-16', today)

  useEffect(() => {
    dispatch(actions.getPriceAnalytics( {
      hotelId: hotelId,
      checkedAt: selectedCheckedAt,
      range: {
        from: startGraphDate,
        days: 365
      }
    }))
  },[dispatch, hotelId, selectedCheckedAt, startGraphDate])

  const handleHotelIdChange = (event) => {
    setHotelId(event.target.value)
  }

  const handleCheckedAtChange = (event) => {
    setSelectedCheckedAt(event.target.value)
  }

  const handleStartGraphDateChange = (event) => {
    setStartGraphDate(event.target.value)
  }

  const isLoading = useSelector(state => state.analytics.isLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Timeline fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Price Analytics</div>
        </div>
        <div>Analyze hotel market prices</div>
      </div>

      <Grid container spacing={0}>

        <Grid item xs={12} md={4} style={{ padding: 20 }}>
          <FormControl variant="outlined">
            <InputLabel id="hotelIdLabel" htmlFor="hotelId">
              Hotel
            </InputLabel>
            <Select
              labelId="hotelIdLabel"
              labelWidth={60}
              value={hotelId}
              inputProps={{
                name: 'hotelId',
                id: 'hotelId',
              }}
              onChange={handleHotelIdChange}
              MenuProps={{
                style: {zIndex: 6000}
              }}
            >
              <MenuItem value={'BERAIR'}>PremierInn Berlin Airport</MenuItem>
              <MenuItem value={'BERALX'}>PremierInn Berlin Alexanderplatz</MenuItem>
              <MenuItem value={'BERCIT'}>PremierInn Berlin City Centre</MenuItem>
              <MenuItem value={'BERWIL'}>PremierInn Berlin City Wilmersdorf</MenuItem>
              <MenuItem value={'HAMOST'}>PremierInn Hamburg City Alster</MenuItem>
              <MenuItem value={'HAMHEI'}>PremierInn Hamburg City Centre</MenuItem>
              <MenuItem value={'HAMHOF'}>PremierInn Hamburg City Hammerbrook</MenuItem>
              <MenuItem value={'HAMKLT'}>PremierInn Hamburg City Klostertor</MenuItem>
              <MenuItem value={'HAMMES'}>PremierInn Hamburg City Millerntor</MenuItem>
              <MenuItem value={'HAMSTP'}>PremierInn Hamburg St. Pauli</MenuItem>
              <MenuItem value={'MUNSCH'}>PremierInn München City Schwabing</MenuItem>
              <MenuItem value={'MUNOST'}>PremierInn München Airport Ost</MenuItem>
              <MenuItem value={'MUNAIR'}>PremierInn München Airport Süd</MenuItem>
              <MenuItem value={'MUNCIT'}>PremierInn München City Zentrum</MenuItem>
              <MenuItem value={'MUNACO'}>PremierInn München Messe</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4} style={{ padding: 20 }}>
          <FormControl variant="outlined" style={{ minWidth: 300 }}>
            <InputLabel id="dateLabel" htmlFor="checkedAt">
              Checked Prices at (multi-select)
            </InputLabel>
            <Select
              multiple
              labelWidth={250}
              value={selectedCheckedAt}
              labelId="dateLabel"
              inputProps={{
                name: 'checkedAt',
                id: 'checkedAt',
              }}
              onChange={handleCheckedAtChange}
              MenuProps={{
                style: {zIndex: 6000}
              }}
            >
              {checkedAtOptions.map((date) => (
                <MenuItem key={date} value={date}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4} style={{ padding: 20 }}>
          <FormControl variant="outlined" style={{ minWidth: 300 }}>
            <InputLabel id="startGraphLabel" htmlFor="startGraphDate">
              Start 1-year graph from
            </InputLabel>
            <Select
              labelWidth={210}
              labelId="startGraphLabel"
              value={startGraphDate}
              inputProps={{
                name: 'startGraphDate',
                id: 'startGraphDate',
              }}
              onChange={handleStartGraphDateChange}
              MenuProps={{
                style: {zIndex: 6000}
              }}
            >
              {checkedAtOptions.map((date) => (
                <MenuItem key={date} value={date}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

      </Grid>

      <div>
        {isLoading ? <Loading /> : <PriceAnalyticsGraph /> }
      </div>

    </div>
  )
})


export default ViewPriceAnalytics

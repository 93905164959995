import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  formatCurrency,
  formatCurrencyInline,
  formatDate,
  formatDateTime,
  formatDecimalEuToUs,
  formatDecimalUsToEu,
  getDateAsCleanUTC,
  stringToColor
} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {shortenText} from "../../services/stringHelper";
import {GermanDatePicker} from "../components/LocalDateInput";
import {useLocation} from "react-router";
import {Check, Close, GridOn, InfoOutlined, RemoveRedEye, Replay, WarningAmber} from "@mui/icons-material";
import {FilterIcon} from "../../assets/SvgIcons";
import AutocompleteBusinessUserInvite from "../components/AutocompleteBusinessUserInvite";

const ReservationTable = withRouter((props) => {

  const dispatch = useDispatch()
  const tableRef = React.useRef()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    reservationID: searchQuery.get('reservationID') || '',
    tripID: searchQuery.get('tripID') || '',
    status: searchQuery.get('status') || '',
    subscription: searchQuery.get('subscription') || '',
    offer: searchQuery.get('offer') || '',
  }

  const subscriptionList = useSelector(state => state.subscription.subscriptions)
  const offerList = useSelector(state => state.offer.offers)
  const hotelList = useSelector(state => state.hotel.hotels) || []

  const refreshHook = () => {
    tableRef.current && tableRef.current.onQueryChange()
  }

  const createdAt = {
    title: 'Created',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const updatedAt = {
    title: 'Updated',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const reservationID = {
    title: 'Res ID',
    field: 'reservationID',
    editable: 'never',
    defaultFilter: query.reservationID,
    render: rowData => <div style={{width:55, wordBreak:'break-word'}}>{rowData.reservationID}</div>,
  }

  const tripID = {
    title: 'Trip',
    field: 'BookingTrip',
    render: rowData => <div style={{width:65, wordBreak:'break-word', fontSize: 12, paddingLeft: 6, borderLeft: '5px solid '+stringToColor(rowData?.BookingTrip?.tripID)}}>
      {rowData?.BookingTrip?.tripID ?
        <div>
          <Link to={'/app/trips/?trip='+rowData?.BookingTrip?.tripID}>{rowData?.BookingTrip?.tripID}</Link><br/>
          <Link to={'/app/reservations/?tripID=' + rowData?.BookingTrip?.tripID} title={"Filter by trip ID"} style={{color: '#7e888f', display: 'flex', alignItems: 'center', marginTop: 2}}>
            <FilterIcon />
            {rowData?.BookingTrip?.nights} nights
          </Link>
        </div> : '' }
    </div>,
    editable: 'never',
    defaultFilter: query?.tripID,
}

  const arrivalDate = {
    title: 'Arrival Date',
    field: 'arrivalDate',
    type: 'date',
    validate: (rowData) => (rowData.arrivalDate || "").length !== 0,
    render: rowData => <div>{formatDate(rowData.arrivalDate)}</div>,
    editComponent: props => <GermanDatePicker data={props} />,
  }

  const surchargePrice = {
    title: 'Surcharge Price',
    field: 'surchargePrice',
    headerStyle: {
      maxWidth: 140,
    },
    render: rowData => formatCurrency(rowData?.surchargePrice || 0),
    editComponent: props => (
      <div style={{display: 'flex', justifyItems: 'center', width: 140}}>
        <TextField
          id="reservationTableSurchargePrice"
          size={'small'}
          value={formatDecimalUsToEu(props?.value) ?? ''}
          placeholder=""
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
            style: {fontSize: 13}
          }}
          //variant={'default'}
          onChange={e => props.onChange(formatDecimalEuToUs(e.target.value))}
        />
        <IconButton onClick={() => props.onChange('?')}>
          <RemoveRedEye />
        </IconButton>
      </div>
    )
  }

  const surchargeDescription= {
    title: 'Surcharge Description',
    field: 'surchargeDescription',
    render: rowData => <div>{rowData?.surchargeDescription || ''}</div>,
    editComponent: props => (
      <TextField
        size={'small'}
        value={props.value || ''}
        placeholder="Surcharge Description"
        InputProps={{
          style: {fontSize: 13}
        }}
        variant={'standard'}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const status = {
    title: 'Status',
    field: 'status',
    //validate: (rowData) => rowData.status?.length > 0,
    render: rowData => <div>
      {rowData.status}
      {isHotelAssigmentMissing(rowData)
        ? <HotelAssigmentMissingNotice rowData={rowData}/>
        : ''
      }
      {isSelfCheckInDataMissing(rowData) && !['canceled_refunded','canceled_deducted'].includes(rowData.status)
        ? <SelfCheckInMissingNotice rowData={rowData}/>
        : ''
      }
      {isRebookCustomerInformationMissing(rowData)
        ? <RebookInformationMissingNotice rowData={rowData} />
        : ''
      }
    </div>,
    //editable: 'onAdd',
    editComponent: props => (
      <div style={{width: 160}}>
        <Autocomplete
          id="status"
          options={[
            'new',
            'canceled_refunded',
            'canceled_deducted',
            'confirmed_hotel',
            'confirmed_guarantee',
            'declined',
          ]}
          disableClearable={true}
          //disableCloseOnSelect
          getOptionLabel={(option) => option || '(required)'}
          value={props.value}
          defaultValue={'new'}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          onChange={(e, value) => {
            props.onChange(value)
          }}
          isOptionEqualToValue={(option, value) => {
            return option === value
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{fontSize: 13}}>{option}</div>
            </li>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    defaultFilter: query.status,
  }

  const BookingSubscriptionId = {
    title: 'Subscription',
    field: 'BookingSubscription',
    validate: (rowData) => rowData?.BookingSubscription?.subscriptionID?.length > 0,
    render: rowData => {
      const subscriptionID = rowData.BookingSubscription?.subscriptionID
      const subscriptionDetails = subscriptionList.filter(o => o.id === rowData?.BookingSubscription?.id)?.[0] || {}
      const startDate = subscriptionDetails?.startDate
      const endDate = subscriptionDetails?.endDate
      const customer = subscriptionDetails?.BookingCustomer
      return(
        <div>
          <Link to={`/app/subscriptionSegments/${subscriptionID}`}>{subscriptionID}</Link>
          <Link to={'/app/reservations/?subscription=' + subscriptionID} title={"Filter by subscription ID"}>
            <FilterIcon />
          </Link>
          <div style={{fontSize: 11}}>{subscriptionID
            ? <>{formatDate(startDate)} - {endDate ? formatDate(endDate) : '∞'}</>
            : ''
          }</div>
          <Link to={`/app/users/${customer?.customerID}`}>{customer?.customerID}</Link>
          <Link to={'/app/reservations/?subscription=' + customer?.customerID} title={"Filter by customer ID"}>
            <FilterIcon />
          </Link>
          <div style={{fontSize: 11}}>{customer?.firstName} {customer?.lastName}</div>
          <div style={{fontSize: 11}}><Link to={'/app/companies/?companyID='+customer?.BusinessCompany?.companyID}>{customer?.BusinessCompany?.name}</Link></div>
        </div>
      )},
    customFilterAndSearch: (term, rowData) => {
      const subscriptionID = rowData.BookingSubscription?.subscriptionID
      const customer = subscriptionList.filter(o => o.id === rowData.BookingSubscription.id)[0]?.BookingCustomer
      const bookingOffer = subscriptionList.filter(o => o.id === rowData.BookingSubscription.id)[0]?.BookingOffer
      const nightsName = offerList.filter(o => o.id === bookingOffer?.id)[0]?.BookingSubscriptionCategory?.name
      const hotelName = offerList.filter(o => o.id === bookingOffer?.id)[0]?.BookingRoom?.BookingHotel?.name
      const pricePerNight = bookingOffer?.price?.toFixed(2)?.replace('.',',')
      const searchString = subscriptionID + ' ' + customer?.firstName + ' ' + customer?.lastName + ' '
        + bookingOffer + ' ' + nightsName + ' ' + hotelName + ' ' + pricePerNight
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
    editComponent: props => (
      <div style={{width: 220}}>
        <Autocomplete
          id="BookingSubscriptionId"
          options={subscriptionList}
          disableClearable={true}
          getOptionLabel={(option) => {
            return(option.subscriptionID || '(required)')
          }}
          value={props.value || ''}
          onChange={(e, value) => {
            props.onChange({ id: value?.id, subscriptionID: value?.subscriptionID })
            let data = { ...props.rowData }
            data.BookingSubscription = value
            data.BusinessUserInvitation = null
            props.onRowDataChange(data)
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id
          }}
          renderOption={(props, option) => {
            const subscription = subscriptionList.filter(o => o.id === option.id)?.[0]
            const customer = subscription?.BookingCustomer || ''
            const companyID = subscription?.BookingCustomer?.BusinessCompany?.companyID || ''
            const companyName = subscription?.BookingCustomer?.BusinessCompany?.name || ''
            const hotelName = subscription?.hotel?.name || ''
            const roomName = subscription?.room?.name || ''
            return(
              <div {...props}>
                <div style={{fontSize: 13}}>
                  {option.subscriptionID || '---'}
                  <div style={{fontSize: 10}}>{customer?.firstName} {customer?.lastName} ({customer?.customerID})</div>
                  {companyID ? <div style={{fontSize: 10}}>{companyName} ({companyID})</div> : ''}
                  {hotelName ? <div style={{fontSize: 10}}>{hotelName} | {roomName}</div> : ''}
                  <div style={{fontSize: 10}}>
                    {formatDate(subscription.startDate)} - {subscription.endDate
                    ? formatDate(subscription.endDate)
                    : '∞'} ({subscription?.status})
                  </div>

                </div>
              </div>
            )
          }}
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            stringify: (option) => {
              const subscription = subscriptionList.filter(o => o.id === option.id)?.[0]
              const subscriptionID = subscription?.subscriptionID
              const customer = subscription?.BookingCustomer || ''
              const hotelName = subscription?.hotel?.name || ''
              const roomName = subscription?.room?.name || ''
              const companyID = subscription?.BookingCustomer?.BusinessCompany?.companyID || ''
              const companyName = subscription?.BookingCustomer?.BusinessCompany?.name || ''
              return(subscriptionID + ' ' + customer.customerID + ' ' + customer.firstName + ' '+ customer.lastName
                + ' ' + hotelName + ' ' + roomName + ' ' + companyID + ' ' + companyName)
            },
          })}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    defaultFilter: query.subscription,
  }

  const offer = {
    title: 'Offer',
    field: 'offerID', //this field doesn't exist in response and is just as filter parameter
    render: rowData => {
      const subscriptionID = rowData.BookingSubscription?.subscriptionID
      const segment = rowData.BookingSubscription?.BookingSubscriptionSegments
      const offerID = segment?.BookingOffer?.offerID
      const offer = offerList.filter(o => o.offerID === offerID)?.[0] || {}
      const category = offer?.BookingSubscriptionCategory?.name
      const shareable = offer?.BookingSubscriptionCategory?.shareable
      const year = offer?.BookingSubscriptionCategory?.nightsScope === 'year'
      const activeSale = offer?.activeSale
      const discount = offer?.discount
      const duration = offer?.BookingSubscriptionDuration?.name
      const roomName = offer?.BookingRoom?.name
      const hotelName = offer?.BookingRoom?.BookingHotel?.name
      const price = offer?.price?.toFixed(2)?.replace('.',',')
      const pricePerMonth = offer?.pricePerMonth?.toFixed(2)?.replace('.',',')
      const hotelOfferType = offer?.BookingRoom?.BookingHotel?.hotelOfferType
      const districtId = segment?.BookingOffer?.BookingRoom?.BookingHotel?.BookingDistrictId
      //const districtName = districts.filter(d => d.id === districtId)?.[0]?.name || ''
      return(<>
        {['breakMonth','breakCustom'].includes(segment?.segmentType) ?
          <div style={{marginBottom: 3, fontSize: 12, color: '#8c6614', display: 'flex', alignItems: 'center' }}>
            <InfoOutlined fontSize="small" />
            <span style={{marginLeft: 3}}>Subscription paused</span>
          </div>
          : ''
        }
        {['termination'].includes(segment?.segmentType) ?
          <div style={{marginBottom: 3, fontSize: 12, color: '#8c6614', display: 'flex', alignItems: 'center' }}>
            <InfoOutlined fontSize="small" />
            <span style={{marginLeft: 3}}>Subscription terminated</span>
          </div>
          : ''
        }
        {(!segment) ?
          <div style={{marginBottom: 3, fontSize: 12, color: '#8c1414', display: 'flex', alignItems: 'center' }}>
            <WarningAmber />
            <span style={{marginLeft: 3}}>No segment found. Subscription not fully initialized.</span>
          </div>
          : ''
        }
        {offerID ?
          <div style={{fontSize: 13, width: 180}}>
            <div>
              {category} @ <Link to={'/app/hotels/?name='+hotelName} title="Jump to hotel config">{hotelName}</Link>
              {hotelOfferType === 'district' ? <span> <Link to={'/app/districtDetails/'+districtId} title="Jump to district config"><GridOn sx={{color: '#627f85', width: 16, height: 16}}/></Link>
              </span> : ''}
            </div>

            <div style={{fontSize: 10}}>{roomName} | {duration} Laufzeit</div>
            <div style={{fontSize: 10}}>
              <Link to={`/app/offers/${offerID}`}>{offerID}</Link>&nbsp;|&nbsp;
              <Link to={`/app/subscriptionSegments/${subscriptionID}`}>{segment?.segmentID}</Link>
            </div>
            <div style={{fontSize: 10}}>{price} €/Nacht | {pricePerMonth} €/Monat</div>
            <div style={{fontSize: 10}}>{
              shareable ? <span style={{color:"blue"}}>business</span> : 'single'
            } | {
              year ? <span style={{color:"purple"}}>year</span> : 'month'
            } | {
              activeSale ? 'active' : <span style={{color:"red"}}>inactive</span>
            } | {
              discount > 0 ? <span style={{color:"green"}}>{formatCurrencyInline(discount)} discount</span> : 'no discount'
            }</div>
          </div>
        : ''}

      </>)

    },
    editable: 'never',
    defaultFilter: query.offer,
  }

  const businessUserInvitation = {
    title: 'Guest (Business)',
    field: 'BusinessUserInvitation',
    render: rowData => {
      const customer = rowData.BookingSubscription?.BookingCustomer
      const segment = rowData.BookingSubscription?.BookingSubscriptionSegments
      const shareable = segment?.BookingOffer?.BookingSubscriptionCategory?.shareable
      const companyId = rowData?.BookingSubscription?.BookingCustomer?.BusinessCompany?.id || null
      const invitationID = rowData?.BusinessUserInvitation?.invitationID || null

      if(shareable) {
        if(invitationID) {
          return(
            <div style={{fontSize: 11}}>
              <div>
                <Link to={'/app/companyDetails/' + companyId}>{invitationID}</Link>
              </div>
              <div style={{fontSize: 11}}>
                {rowData.BusinessUserInvitation?.firstName} {rowData.BusinessUserInvitation?.lastName}
              </div>
            </div>
          )
        } else {
          return (
            <div>
              <div style={{fontSize: 11}}>
                {customer?.firstName} {customer?.lastName} (Admin)
              </div>
            </div>
          )
        }
      } else {
        return (<div></div>)
      }
    },
    editComponent: props => {
      return(<div style={{width: 200}}>
        <AutocompleteBusinessUserInvite data={props} />
      </div>)
    },
  }

  const internalComment = {
    title: 'Internal Comment',
    field: 'internalComment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 140}}>{shortenText(rowData.internalComment, 160)}</div>,
    editable: 'never',
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const forceNewTripStart = {
    title: 'Force New Trip',
    field: 'forceNewTripStart',
    render: rowData => (
      <div>{rowData.forceNewTripStart ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.forceNewTripStart || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.forceNewTripStart,
  }

  /* javascript function to extract domain name from url */
  const getDomainName = (url) => {
    // remove 'http://' or 'https://' from the beginning of the URL
    url = url.replace(/^https?:\/\//,'')
    // remove everything after the domain name, if present
    url = url.replace(/\/.*$/,'')
    // return the domain name
    return url
  }

  /* return true if current hotel (default, assigned or rebooked) requires self check-in and the necessary url is missing */
  const isSelfCheckInDataMissing = (rowData) => {
    const hotel = rowData?.BookingSubscription?.BookingSubscriptionSegments?.BookingOffer?.BookingRoom?.BookingHotel
    const assignHotel = hotelList.filter(hotel => hotel.id === rowData.AssignHotel?.id)?.[0] || null
    const rebookHotel = hotelList.filter(hotel => hotel.id === rowData.RebookHotel?.id)?.[0] || null
    const hotelRequiresSelfCheckIn = (hotel?.selfCheckIn && !assignHotel && !rebookHotel)
      || (assignHotel?.selfCheckIn && !rebookHotel)
      || (rebookHotel?.selfCheckIn)
    return hotelRequiresSelfCheckIn && !rowData.selfCheckInUrl
  }

  /* return true if current reservation is part of a district subscription and hotel assignment is missing */
  const isHotelAssigmentMissing = (rowData) => {
    const assignHotel = hotelList.filter(hotel => hotel.id === rowData.AssignHotel?.id)?.[0]
    const status = ['new','confirmed_hotel','confirmed_guarantee'].includes(rowData.status)
    return rowData?.reservationType === 'district' && !assignHotel && status
  }

  /* return true if current reservation was rebooked and customer information mail is missing */
  const isRebookCustomerInformationMissing = (rowData) => {
    const rebookHotel = hotelList.filter(hotel => hotel.id === rowData.RebookHotel?.id)?.[0]
    const informed = ((rowData.rebookHotelSetAt <= rowData.confirmedByBackofficeAt) || (rowData.rebookHotelSetAt <= rowData.hotelResponseDate) || rowData.rebookHotelSetAt <= rowData.rebookConfirmToCustomerAt)
    return rebookHotel && !informed
  }

  const SelfCheckInMissingNotice = (props) => {
    return(
      <div style={{color: '#8c1414', display: 'flex', alignItems: 'center' }}>
        <WarningAmber />
        <span style={{marginLeft: 3, fontSize: 11}}>{props?.text ? props.text : 'Self Check-In Url missing'}</span>
      </div>
    )
  }

  const HotelAssigmentMissingNotice = (props) => {
    return(
      <div style={{color: '#8c1414', display: 'flex', alignItems: 'center' }}>
        <WarningAmber />
        <span style={{marginLeft: 3, fontSize: 11}}>Hotel Assigment missing</span>
      </div>
    )
  }

  const RebookInformationMissingNotice = (props) => {
    return(
      <div style={{color: '#8c1414', display: 'flex', alignItems: 'center' }}>
        <WarningAmber />
        <span style={{marginLeft: 3, fontSize: 11}}>Customer not informed about rebooking</span>
      </div>
    )
  }

  const selfCheckInUrl = {
    title: 'Self CheckIn Url',
    field: 'selfCheckInUrl',
    render: rowData => {
      return(
        rowData.selfCheckInUrl
        ? <div><a href={rowData.selfCheckInUrl}>{getDomainName(rowData.selfCheckInUrl)}</a></div>
        : isSelfCheckInDataMissing(rowData)
          ? <div style={{fontSize: 11, color: '#8c1414'}}>Check-In URL required</div>
          : ''
      )
    },
    editable: 'never',
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const messageToCustomer = {
    title: 'Message To Customer',
    field: 'messageToCustomer',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 140}}>{shortenText(rowData.messageToCustomer, 160)}</div>,
    editable: 'never',
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const hotelBookingNumber = {
    title: 'Booking Number',
    field: 'hotelBookingNumber',
    editable: 'never',
    editComponent: props => (
      <TextField
        size={'small'}
        value={props.value || ''}
        placeholder="Booking Number"
        InputProps={{
          style: {fontSize: 13}
        }}
        variant={'standard'}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const hotelBookingRate = {
    title: 'Hotel Purchase Rate',
    field: 'hotelBookingRate',
    render: rowData => formatCurrency(rowData?.hotelBookingRate || 0),
    editComponent: props => (
      <TextField
        size={'small'}
        value={formatDecimalUsToEu(props?.value || 0) ?? ''}
        placeholder=""
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
          style: {width: 100, fontSize: 13}
        }}
        onChange={e => props.onChange(formatDecimalEuToUs(e.target.value))}
      />
    )
  }

  const commission = {
    title: 'Commission',
    field: 'commission',
    render: rowData => formatCurrency(rowData?.commission || 0),
    editComponent: props => (
      <TextField
        size={'small'}
        value={formatDecimalUsToEu(props?.value || 0) ?? ''}
        placeholder=""
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
          style: {width: 100, fontSize: 13}
        }}
        onChange={e => props.onChange(formatDecimalEuToUs(e.target.value))}
      />
    )
  }

  //const reservationType = {
  //  title: 'Type',
  //  field: 'reservationType',
  //  render: rowData =>
  //    <Tooltip title={rowData.reservationType} enterTouchDelay={0} placement="top">
  //      <span>
  //        {rowData.reservationType === 'standard' ? <Bungalow sx={{color: '#4498ce', width: 18, height: 18}} /> : ''}
  //        {rowData.reservationType === 'district' ? <HolidayVillage sx={{color: '#627f85', width: 18, height: 18}} /> : ''}
  //      </span>
  //    </Tooltip>,
  //  editable: 'never',
  //}

  const assignHotel = {
    title: 'Assign Hotel',
    field: 'AssignHotel',
    editable: 'never',
    render: rowData => {
      const hotel = rowData?.BookingSubscription?.BookingSubscriptionSegments?.BookingOffer?.BookingRoom?.BookingHotel
      const assignHotelName = hotelList.filter(hotel => hotel.id === rowData.AssignHotel?.id)?.[0]?.name || ''
      return(
        <div>{
          hotel?.BookingDistrictId > 0 //= 'district'
            ? (rowData?.AssignHotel?.id > 0)
              ? <div>
                  <Link to={'/app/hotels/?name='+assignHotelName}>{assignHotelName}</Link>
                </div>
              : <div style={{fontSize: 11, color: '#8c1414'}}>required</div>
            : ''
        }
        </div>
      )
    },
  }

  const rebookHotel = {
    title: 'Rebook Hotel',
    field: 'RebookHotel',
    render: rowData => {
      const rebookHotelName = hotelList.filter(hotel => hotel.id === rowData.RebookHotel?.id)?.[0]?.name || null
      if(rebookHotelName) {
        return(<div>
          <Link to={'/app/hotels/?name=' + rebookHotelName}>{rebookHotelName}</Link>
          <br/>
          {/*
          <button onClick={() => dispatch(actions.postMailRebookInformationToCustomer(rowData.id, refreshHook))} style={{cursor:'pointer'}}>
            Inform customer
          </button><br/>
          */}
          <div style={{fontSize: 10}}>{rowData.rebookConfirmToCustomerAt
            ? <div>Last rebook mail: {formatDateTime(rowData.rebookConfirmToCustomerAt)}</div>
            : ((rowData.rebookHotelSetAt <= rowData.confirmedByBackofficeAt) || (rowData.rebookHotelSetAt <= rowData.hotelResponseDate))
              ? <div>Informed with initial confirmation</div>
              : <div style={{fontSize: 11, color: '#8c1414'}}>Not yet informed</div>
          }</div>
        </div>)
      } else { return ('') }
    },
    editable: 'never',
    editComponent: props => {
      /* rebook not available for district reservations which are not confirmed yet */
      if(props?.rowData?.reservationType === 'district' && props?.rowData?.status === 'new') {
        return(<div></div>)
      } else {
        return(
        <div style={{width: 140}}>
          <Autocomplete
            id="RebookHotelId"
            options={hotelList}
            getOptionLabel={(option) => option.name || '-'}
            value={props.value || ''}
            onChange={(e, value) => {
              props.onChange({id:value?.id, name:value?.name})
            }}
            autoHighlight={true} // required to select entry without submitting entire material-table row
            onKeyDown={e => handleKey(e)}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <div style={{fontSize: 13}}>{option.name || '---'}</div>
              </li>
            )}
            size="small"
            renderInput={(params) => {
              params.InputProps.style = {fontSize: 13}
              return(
                <TextField
                  {...params}
                  placeholder=""
                />
              )
            }}
          />
        </div>
        )
      }
    }
  }

  const columnSet = [
    reservationID,
    tripID,
    arrivalDate,
    status,
    BookingSubscriptionId,
    offer,
    businessUserInvitation,
    surchargePrice,
    surchargeDescription,
    hotelBookingRate,
    commission,
    hotelBookingNumber,
    //reservationType,
    assignHotel,
    rebookHotel,
    selfCheckInUrl,
    messageToCustomer,
    internalComment,
    forceNewTripStart,
    createdAt,
    updatedAt,
  ]

  const delay = t => new Promise(resolve => setTimeout(resolve, t))

  const [page, setPage] = useState(0)
  const handlePageChange = (page) => {
    setPage(page)
  }

  // Effect to update table filters based on URL query parameters
  useEffect(() => {
    if (tableRef.current) {

      delay(500).then(() =>{
        tableRef.current.state.query.filters = [{
          column: {
            title: 'Trip',
            field: 'BookingTrip',
          },
          operator: '=',
          value: query.tripID,
        },{
          column: {
            title: 'Subscription',
            field: 'BookingSubscription',
          },
          operator: '=',
          value: query.subscription,
        },{
          column: {
            title: 'Status',
            field: 'status',
          },
          operator: '=',
          value: query.status,
        },{
          column: {
            title: 'Res ID',
            field: 'reservationID',
          },
          operator: '=',
          value: query.reservationID,
        }
        ]
        setPage(0) //reset paging to show filter results from page 0
        //console.log('useEffect dataManager', tableRef.current.dataManager)
        //console.log('useEffect tableRef', tableRef.current)
        // Trigger a query change to refetch data with the new filter
        tableRef.current.onQueryChange()
      })

      //console.log('useEffect start')
      // Find the column you want to filter
      const columnId = tableRef.current.props.columns.findIndex(c => c.field === 'BookingTrip')
      //console.log('useEffect columnID',columnId)
      if (columnId !== -1) {
        //tableRef.current.dataManager.changeFilterValue(columnId, query.tripID);
        //tableRef.current.dataManager.changeFilterOperator(columnId, '=');

        // Update the internal state of Material Table to include the new filter
        //tableRef.current.dataManager.columns[columnId].tableData.filterValue = query.tripID

        // Manually set the filter model if needed
        //tableRef.current.dataManager.filterModel = {
        //  items: [
        //    { column: tableRef.current.dataManager.columns[columnId], operator: '=', value: query.tripID },
        //  ],
        //}

      }
    }
  }, [query.tripID, query.subscription, query.status, query.reservationID, setPage])


  //const CustomEditRow = (props) => {
  //  const dispatch = useDispatch()
  //  const companyId = props?.data?.BookingSubscription?.BookingCustomer?.BusinessCompany?.id || null
//
  //  React.useEffect(() => {
  //    //console.log('companyId', companyId)
  //    //dispatch(actions.getBusinessUserInvitationByCompanyId(companyId))
  //    //console.log('init edit row', {...props})
  //  }, [dispatch, companyId])
//
  //  return <MTableEditRow {...props} />
  //}


  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={<div>
          <Tooltip title={"Reload"} enterTouchDelay={0}>
            <IconButton aria-label="Reload" onClick={refreshHook}><Replay /></IconButton>
          </Tooltip>
          <Tooltip title={"Clear Filter"} enterTouchDelay={0}>
            <IconButton aria-label="Clear Filter" onClick={() => {
              props.history.push('/app/reservations/') }}><FilterIcon color={'#757575'} size={22} /></IconButton>
          </Tooltip>
        </div>}
        tableRef={tableRef}
        columns={columnSet}
        onPageChange={handlePageChange}
        page={page}
        //onFilterChange={(appliedFilter) => {
        //  console.log('selected Filters : ', appliedFilter)
        //}}
        data={qry =>
          new Promise((resolve, reject) => {
            //console.log('dataQuery',qry)
            //console.log('dataQuery dataManager', tableRef.current.dataManager)
            //console.log('dataQuery state', tableRef.current.state)
            let url = "/api/horus/reservation?cache=clear&"
            url += `page=${qry.page + 1}`
            url += `&perPage=${qry.pageSize}`
            if (qry.search) {
              url += `&q=${qry.search}`
            }
            if (qry.orderBy) {
              url += `&sort=${qry.orderBy.field}&order=${qry.orderDirection}`
            }
            if (qry.filters.length) {
              const filter = qry.filters.map((filter) => {
                return `&${filter.column.field}${filter.operator}${filter.value}`
              })
              url += filter.join("")
            }
            fetch(url)
              .then((resp) => resp.json())
              .then((resp) => {
                resolve({
                  data: resp.data,
                  page: resp.page - 1,
                  totalCount: resp.total,
                })
              })
          })
        }
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postReservation({
                ...newData,
                arrivalDate: getDateAsCleanUTC(newData?.arrivalDate),
              }))
              //forces table to reload data after update
              tableRef.current && tableRef.current.onQueryChange()
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putReservation(oldData.id, {
                ...newData,
                arrivalDate: getDateAsCleanUTC(newData?.arrivalDate),
              }))
              //forces table to reload data after update
              tableRef.current && tableRef.current.onQueryChange()
              resolve()
            }),
          //onBulkUpdate: (newData, oldData) =>
          //  new Promise(resolve => {
          //    dispatch(actions.putReservation(oldData.id, {
          //      ...newData,
          //      arrivalDate: getDateAsCleanUTC(newData?.arrivalDate),
          //    }))
          //    //forces table to reload data after update
          //    tableRef.current && tableRef.current.onQueryChange()
          //    resolve()
          //  }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteReservation(oldData.id))
              //forces table to reload data after update
              tableRef?.current && tableRef?.current?.onQueryChange()
              resolve()
            }),
        }}
        //components={{
        //  EditRow: props => {
        //    console.log('test')
        //    const companyId = props?.data?.BookingSubscription?.BookingCustomer?.BusinessCompany?.id || null
        //    dispatch(actions.getBusinessUserInvitationByCompanyId(companyId))
        //    return(<CustomEditRow {...props} />)
        //  }
        //}}
        options={{
          debounceInterval: 700,
          search: true,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 50, 500, 5000],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          //tableLayout: 'fixed',
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '3px 3px 3px 3px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default ReservationTable

import {useDispatch} from "react-redux";
import React, {useRef, useState} from "react";
import * as actions from "../../store/actions";
import {Button, FormControlLabel, FormGroup, Paper, Switch, TextField, Typography} from "@mui/material";
import {Editor} from "@tinymce/tinymce-react";
import tinymce from "tinymce";

export const HotelDetailsFormCheckIn = (props) => {

  const dispatch = useDispatch()
  const {hotel} = props

  const [checkInTime, setCheckInTime] = useState(hotel.checkInTime || '')
  const [checkOutTime, setCheckOutTime] = useState(hotel.checkOutTime || '')
  const [selfCheckIn, setSelfCheckIn] = useState(hotel.selfCheckIn || false)
  const [selfCheckInDescription, setSelfCheckInDescription] = useState(hotel.selfCheckInDescription || '')
  const editorRef = useRef(null)

  const setCheckInAndOut = () => {
    const payload = {
      checkInTime: checkInTime,
      checkOutTime: checkOutTime,
      selfCheckIn: selfCheckIn,
      selfCheckInDescription: selfCheckInDescription,
    }
    dispatch(actions.putHotel(hotel.id, payload))
  }

  return(
    <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8 }}>
      <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
        Check-In / Check-Out
      </Typography>
      <div className="inputGrid">

        <TextField
          id="hotelCheckIn"
          variant="outlined"
          label="Check-In"
          placeholder=""
          size="small"
          value={checkInTime}
          onChange={(e) => setCheckInTime(e.target.value)}
        />

        <TextField
          id="hotelCheckOut"
          variant="outlined"
          label="Check-Out"
          placeholder=""
          size="small"
          value={checkOutTime}
          onChange={(e) => setCheckOutTime(e.target.value)}
        />

        <FormGroup>
          <FormControlLabel control={<Switch
            checked={selfCheckIn}
            onChange={(e) => setSelfCheckIn(e.target.checked)} />} label="Self-CheckIn" />
        </FormGroup>

        { selfCheckIn
          ? <Editor
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={hotel.selfCheckInDescription || ''}
              onEditorChange={(value, editor) => setSelfCheckInDescription(value)}
              init={{
                width: '100%',
                height: 200,
                menubar: false,
                statusbar: false,
                plugins: [
                  'advlist autolink lists link image charmap anchor',
                  'searchreplace code fullscreen',
                  'insertdatetime media table code help'
                ],
                toolbar: 'undo redo | ' +
                  'bold italic | forecolor fontsizeselect | bullist numlist | link | ' +
                  ' removeformat code',
                fontsize_formats: "8px 10px 12px 14px 16px 18px 20px 24px",
                skin: false,
                content_css: false,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:13px }',
                initEngine: tinymce
              }}
            />
          : ''
        }


      </div>
      <div>
        <Button onClick={setCheckInAndOut} variant="outlined" color="secondary">
          Save
        </Button>
      </div>
    </Paper>
  )
}
import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router";
import {Link, withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import * as actions from '../../store/actions'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {shortenText} from '../../services/stringHelper';


const DistrictTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.district.districts)

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    districtID: searchQuery.get('districtID') || '',
    name: searchQuery.get('name') || '',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const districtID = {
    title: 'DistrictID',
    field: 'districtID',
    editable: 'never',
    render: rowData => <div style={{fontWeight: 'bold'}}>
      <Link className="tableActionButton" to={`/app/districtDetails/${rowData.id}`}>{rowData.districtID}</Link>
    </div>,
    defaultFilter: query.districtID,
  }

  const name = {
    title: 'Internal Name',
    field: 'name',
    render: rowData => <div>{rowData.name}</div>,
    defaultFilter: query.name,
  }

  const description = {
    title: 'Description',
    field: 'description',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 190}}>{shortenText(rowData.description, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const connectedHotel = {
    title: 'Connected Hotel',
    field: 'BookingHotel',
    editable: 'never',
    render: rowData => <div>
      <Link
        to={`/app/hotels/?name=${rowData.BookingHotel?.name || ''}`}
        style={{color: "#045ebe"}}>{rowData.BookingHotel?.name || ''}</Link>
    </div>,
  }

  const internalComment = {
    title: 'Internal Comment',
    field: 'internalComment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 190}}>{shortenText(rowData.internalComment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const columnSet = [
    districtID,
    name,
    connectedHotel,
    description,
    internalComment,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData => {
            return new Promise((resolve, reject) => {
              dispatch(actions.postDistrict({
                ...newData,
              }))
              resolve()
            })
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise(resolve => {
              dispatch(actions.putDistrict(oldData.id, {
                ...newData,
              }))
              resolve()
            })
          },

          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteDistrict(oldData.id))
              resolve()
            }),
        }}
        options={{
          search: true,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default DistrictTable

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import * as actions from "./index";


/*
 * POST subscription segment
 */
export const postSubscriptionSegment = (payload, subscriptionID) => {
  return dispatch => {
    dispatch(postSubscriptionSegmentStart())
    axios.post('/horus/subscriptionSegment', payload)
      .then ( response => {
        dispatch(postSubscriptionSegmentSuccess(response.data))
        dispatch(actions.getSubscriptionEvaluation(subscriptionID))
        dispatch(actions.hideSubscriptionSegmentModal())
      })
      .catch(error => {
        dispatch(postSubscriptionSegmentFail(error))
      })
  }
}

export const postSubscriptionSegmentStart = () => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_SEGMENT_START,
  }
}

export const postSubscriptionSegmentSuccess = (res) => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_SEGMENT_SUCCESS,
    res: res,
  }
}

export const postSubscriptionSegmentFail = (error) => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_SEGMENT_FAIL,
    error: error,
  }
}


/*
 * PUT subscription segment
 */
export const putSubscriptionSegment = (segmentID, payload, subscriptionID) => {
  return dispatch => {
    dispatch(putSubscriptionSegmentStart())
    axios.put('/horus/subscriptionSegment/'+segmentID, payload)
      .then ( response => {
        dispatch(putSubscriptionSegmentSuccess(response.data))
        //dispatch(getSubscription())
        dispatch(actions.getSubscriptionEvaluation(subscriptionID))
      })
      .catch(error => {
        dispatch(putSubscriptionSegmentFail(error))
      })
  }
}

export const putSubscriptionSegmentStart = () => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_SEGMENT_START,
  }
}

export const putSubscriptionSegmentSuccess = (res) => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_SEGMENT_SUCCESS,
    res: res,
  }
}

export const putSubscriptionSegmentFail = (error) => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_SEGMENT_FAIL,
    error: error,
  }
}


/*
 * DELETE subscription segment
 */
export const deleteSubscriptionSegment = (segmentID, subscriptionID) => {
  return dispatch => {
    dispatch(deleteSubscriptionSegmentStart())
    axios.delete('/horus/subscriptionSegment/'+segmentID)
      .then ( response => {
        dispatch(deleteSubscriptionSegmentSuccess(response.data))
        //dispatch(getSubscription())
        dispatch(actions.getSubscriptionEvaluation(subscriptionID))
      })
      .catch(error => {
        dispatch(deleteSubscriptionSegmentFail(error))
      })
  }
}

export const deleteSubscriptionSegmentStart = () => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_SEGMENT_START,
  }
}

export const deleteSubscriptionSegmentSuccess = (res) => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_SEGMENT_SUCCESS,
    res: res,
  }
}

export const deleteSubscriptionSegmentFail = (error) => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_SEGMENT_FAIL,
    error: error,
  }
}

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";

/*
 * GET
 */
export const getSubscriptionCategory = () => {
  return dispatch => {
    dispatch(getSubscriptionCategoryStart())
    axios.get('/horus/subscriptionCategory')
      .then ( response => {
        dispatch(getSubscriptionCategorySuccess(response.data))
      })
      .catch(error => {
        dispatch(getSubscriptionCategoryFail(error))
      })
  }
}

export const getSubscriptionCategoryStart = () => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_CATEGORY_START,
  }
}

export const getSubscriptionCategorySuccess = (res) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_CATEGORY_SUCCESS,
    res: res,
  }
}

export const getSubscriptionCategoryFail = (error) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_CATEGORY_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postSubscriptionCategory = (payload) => {
  return dispatch => {
    dispatch(postSubscriptionCategoryStart())
    axios.post('/horus/subscriptionCategory', payload)
      .then ( response => {
        dispatch(postSubscriptionCategorySuccess(response.data))
        dispatch(getSubscriptionCategory())
        dispatch(showNotification('Changes saved successfully','success'))
      })
      .catch(error => {
        dispatch(postSubscriptionCategoryFail(error))
        dispatch(showNotification('Saving changes failed','error'))
      })
  }
}

export const postSubscriptionCategoryStart = () => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_CATEGORY_START,
  }
}

export const postSubscriptionCategorySuccess = (res) => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_CATEGORY_SUCCESS,
    res: res,
  }
}

export const postSubscriptionCategoryFail = (error) => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_CATEGORY_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putSubscriptionCategory = (id, payload) => {
  return dispatch => {
    dispatch(putSubscriptionCategoryStart())
    axios.put('/horus/subscriptionCategory/'+id, payload)
      .then ( response => {
        dispatch(putSubscriptionCategorySuccess(response.data))
        dispatch(getSubscriptionCategory())
        dispatch(showNotification('Changes saved successfully','success'))
      })
      .catch(error => {
        dispatch(putSubscriptionCategoryFail(error))
        dispatch(showNotification('Saving changes failed','error'))
      })
  }
}

export const putSubscriptionCategoryStart = () => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_CATEGORY_START,
  }
}

export const putSubscriptionCategorySuccess = (res) => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_CATEGORY_SUCCESS,
    res: res,
  }
}

export const putSubscriptionCategoryFail = (error) => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_CATEGORY_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteSubscriptionCategory = (id) => {
  return dispatch => {
    dispatch(deleteSubscriptionCategoryStart())
    axios.delete('/horus/subscriptionCategory/'+id)
      .then ( response => {
        dispatch(deleteSubscriptionCategorySuccess(response.data))
        dispatch(getSubscriptionCategory())
        dispatch(showNotification('Deleted successfully','success'))
      })
      .catch(error => {
        dispatch(deleteSubscriptionCategoryFail(error))
        dispatch(showNotification('Deleting failed','error'))
      })
  }
}

export const deleteSubscriptionCategoryStart = () => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_CATEGORY_START,
  }
}

export const deleteSubscriptionCategorySuccess = (res) => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_CATEGORY_SUCCESS,
    res: res,
  }
}

export const deleteSubscriptionCategoryFail = (error) => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_CATEGORY_FAIL,
    error: error,
  }
}

import {useDispatch} from "react-redux";
import React, {useState} from "react";
import * as actions from "../../store/actions";
import {Button, Paper, TextField, Typography} from "@mui/material";

export const HotelDetailsFormInvoiceMatch = (props) => {

  const dispatch = useDispatch()
  const {hotel} = props

  const [nameAlias1, setNameAlias1] = useState(hotel.nameAlias1 || '')
  const [nameAlias2, setNameAlias2] = useState(hotel.nameAlias2 || '')

  const handleSave = () => {
    const payload = {
      nameAlias1: nameAlias1 ? nameAlias1 : null,
      nameAlias2: nameAlias2 ? nameAlias2 : null,
    }
    dispatch(actions.putHotel(hotel.id, payload))
  }

  return(
    <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8 }}>
      <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
        Invoice Match
      </Typography>
      <div className="inputGrid">

        <TextField
          label="Hotel Name Alias 1"
          size={'small'}
          value={nameAlias1}
          InputProps={{
            style: {width: 250, fontSize: 13}
          }}
          onChange={e => setNameAlias1(e.target.value)}
        />

        <TextField
          label="Hotel Name Alias 2"
          size={'small'}
          value={nameAlias2}
          InputProps={{
            style: {width: 250, fontSize: 13}
          }}
          onChange={e => setNameAlias2(e.target.value)}
        />

        <Button onClick={handleSave} variant="outlined" color="secondary">
          Save
        </Button>
      </div>
    </Paper>
  )
}
import * as actionTypes from './actionTypes';

export const showNotification = (message, variant='success') => {
  /* message variants are: error (red), warning (yellow), info (blue) and success (green) */
  return {
    type: actionTypes.SHOW_NOTIFICATION,
    message: message,
    variant: variant,
  }
}

export const hideNotification = () => {
  return {
    type: actionTypes.HIDE_NOTIFICATION,
  }
}

export const showSubscriptionSegmentModal = () => {
  return {
    type: actionTypes.SHOW_SUBSCRIPTION_SEGMENT_MODAL,
  }
}

export const hideSubscriptionSegmentModal = () => {
  return {
    type: actionTypes.HIDE_SUBSCRIPTION_SEGMENT_MODAL,
  }
}

export const showBusinessGuestChangeModal = (props) => {
  return {
    type: actionTypes.SHOW_BUSINESS_GUEST_CHANGE_MODAL,
    props: props,
  }
}

export const hideBusinessGuestChangeModal = () => {
  return {
    type: actionTypes.HIDE_BUSINESS_GUEST_CHANGE_MODAL,
  }
}

export const showDistrictAssignHotelModal = (props) => {
  return {
    type: actionTypes.SHOW_DISTRICT_ASSIGN_HOTEL_MODAL,
    props: props,
  }
}

export const hideDistrictAssignHotelModal = () => {
  return {
    type: actionTypes.HIDE_DISTRICT_ASSIGN_HOTEL_MODAL,
  }
}

export const showSpecialDateModal = (props) => {
  return {
    type: actionTypes.SHOW_SPECIAL_DATE_MODAL,
    props: props,
  }
}

export const hideSpecialDateModal = () => {
  return {
    type: actionTypes.HIDE_SPECIAL_DATE_MODAL,
  }
}
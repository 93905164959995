import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Checkbox, IconButton, Tooltip, Typography} from '@mui/material';
import {formatCurrency, formatCurrencyInline, formatDate, formatDateTime,} from '../../services/unitFormater';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {useHistory, useLocation} from "react-router";
import {Cancel, Check, CheckBox, Close, Description, HelpCenter, Replay} from "@mui/icons-material";
import {FilterIcon} from "../../assets/SvgIcons";
import {updateCurrentUrlParams} from "../../services/url";
import lexofficeIcon from "../../assets/lexoffice.webp"
import * as actions from "../../store/actions";
import {useDispatch} from "react-redux";
import {shortenText} from "../../services/stringHelper";

const LexofficeVoucherTable = withRouter((props) => {

  const dispatch = useDispatch()
  const tableRef = React.useRef()
  const history = useHistory()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    resourceId: searchQuery.get('resourceId') || '',
    type: searchQuery.get('type') || props?.props?.type || '',
    voucherNumber: searchQuery.get('voucherNumber') || '',
    orderBy: searchQuery.get('orderBy') || 'updatedDate',
    orderDirection: searchQuery.get('orderDirection') || 'desc',
  }

  const refreshHook = () => {
    tableRef?.current && tableRef?.current?.onQueryChange()
  }


  const resourceId = {
    title: 'Resource ID',
    field: 'resourceId',
    editable: 'never',
    defaultFilter: query.resourceId,
    render: rowData => <div style={{fontSize: 12, width: 140, wordBreak:'break-word', padding: 5}}>{rowData.resourceId}</div>,
  }

  const remark = {
    title: 'Remark',
    field: 'remark',
    editable: 'never',
    render: rowData => <div style={{width: 180}}>{rowData.remark}</div>,
    filtering: true,
  }

  const version = {
    title: 'Version',
    field: 'version',
    editable: 'never',
    filtering: false,
  }

  const contact = {
    title: 'Contact',
    field: 'contactId',
    render: rowData => <div style={{width: 50}}>
        <Link to={'/app/lexoffice/contact/?resourceId='+rowData.contactId}>LINK</Link>
      </div>,
    editable: 'never',
    filtering: false,
  }

  const voucherDate = {
    title: 'Voucher Date',
    field: 'voucherDate',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDate(rowData.voucherDate)}</div>,
    editable: 'never',
    filtering: false,
  }

  const totalGrossAmount = {
    title: 'Total Gross Amount',
    field: 'totalGrossAmount',
    render: rowData => <div style={{width: 80}}>{formatCurrency(rowData.totalGrossAmount)}</div>,
    editable: 'never',
    filtering: false,
    defaultFilter: query.totalGrossAmount,
  }

  const tripBookingRateDelta = {
    title: 'Trip Invoice/Rate Delta',
    field: 'tripBookingRateDelta',
    render: rowData => {
      return(<div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{marginRight: 5}}>{
          rowData?.tripBookingRateDelta === null
            ? <Tooltip title="Invoice missing" enterTouchDelay={0}><HelpCenter style={{color: '#d8b116'}} /></Tooltip>
            : rowData?.tripBookingRateDelta === 0
              ? <Tooltip title="OK" enterTouchDelay={0}><CheckBox style={{color: '#0fa14a'}} /></Tooltip>
              : rowData?.tripBookingRateDeltaSoftOk
                ? <Tooltip title="SOFT OK" enterTouchDelay={0}><CheckBox style={{color: '#0fa14a'}} /></Tooltip>
                : <Tooltip title="NOT OK - Invoice and purchase price do not match" enterTouchDelay={0}><Cancel style={{color: '#ae5108'}} /></Tooltip>
        }</div>
        <div>{rowData?.tripBookingRateDelta > 0 ? '+':''}{rowData?.tripBookingRateDelta !== null
          ? formatCurrencyInline(rowData?.tripBookingRateDelta)
          : 'missing'
        }</div>
      </div>)
    },
    editable: 'never',
    //filtering: false,
  }

  const associatedTrips = {
    title: 'Associated Trips (with purchase rate)',
    field: 'tripBookingRateSum',
    render: rowData => <div style={{width: 180}}>
      {rowData?.BookingTrips?.length > 0
        ? <ol
            style={{margin: 5, padding: 0, listStyleType: 'upper-roman', color: '#888', paddingBottom: 3, borderBottom: '2px solid #DDD'}}>
            {rowData?.BookingTrips?.map(trip => <li>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Link to={'/app/trips/?trip=' + trip.tripID}>{trip.tripID}</Link> <span
                style={{color: '#888'}}>{formatCurrencyInline(trip.hotelBookingRateSum)}</span>
              </div>
            </li>)}
          </ol>
        : ''}
      <div style={{textAlign: 'right', color: '#888', margin: 5}}>
        <span style={{color: '#000'}}>{formatCurrencyInline(rowData.tripBookingRateSum)}</span>
      </div>
    </div>,
    editable: 'never',
    filtering: false,
  }

  const voucherNumber = {
    title: 'Voucher Number',
    field: 'voucherNumber',
    editable: 'never',
    defaultFilter: query.voucherNumber,
  }

  const type = {
    title: 'Voucher Type',
    field: 'type',
    editable: 'never',
    defaultFilter: query.type,
  }

  const lexofficeLink = {
    title: 'Lexoffice Link + PDF',
    field: 'voucherNumber',
    render: rowData => <div style={{width: 120}}>
      <a href={`https://app.lexoffice.de/vouchers#!/VoucherView/PurchaseInvoice/${rowData.resourceId}`} target="_blank"
         rel="noreferrer"><img src={lexofficeIcon} alt="Lexoffice" with={25} height={20}/></a>
      &nbsp;&nbsp;
      {rowData.files?.[0]
        ? <a href={'/api/horus/lexoffice/invoicePdf/' + rowData.files?.[0]} target="_blank"
             rel="noreferrer"><Description/></a>
        : ''}
    </div>,
    editable: 'never',
    filtering: false,
  }

  const voucherStatus = {
    title: 'Voucher Status',
    field: 'voucherStatus',
    editable: 'never',
    defaultFilter: query.voucherStatus,
  }

  const tripBookingRateDeltaSoftOk = {
    title: 'Soft OK',
    field: 'tripBookingRateDeltaSoftOk',
    render: rowData => (
      <div>{rowData.tripBookingRateDeltaSoftOk ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#636363'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.tripBookingRateDeltaSoftOk || false].includes(term.toLowerCase()))
    },
    filtering: false,
    defaultFilter: query.tripBookingRateDeltaSoftOk,
  }

  const internalComment = {
    title: 'Internal Comment',
    field: 'internalComment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 170}}>{shortenText(rowData.internalComment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const archived = {
    title: 'Archived',
    field: 'archived',
    render: rowData => (
      <div>{rowData.archived ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#636363'}}/>}</div>
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.archived || false].includes(term.toLowerCase()))
    },
    filtering: false,
    editable: 'never',
    defaultFilter: query.archived,
  }

  const createdDate = {
    title: 'Created',
    field: 'createdDate',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdDate)}</div>,
    editable: 'never',
    filtering: false,
  }

  const updatedDate = {
    title: 'Updated',
    field: 'updatedDate',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedDate)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const columnSet = [
    voucherNumber,
    type,
    voucherStatus,
    remark,
    totalGrossAmount,
    associatedTrips,
    tripBookingRateDelta,
    tripBookingRateDeltaSoftOk,
    internalComment,
    voucherDate,
    contact,
    lexofficeLink,
    archived,
    version,
    resourceId,
    createdDate,
    updatedDate,
  ]

  const delay = t => new Promise(resolve => setTimeout(resolve, t))

  const [page, setPage] = useState(0)
  const handlePageChange = (page) => {
    setPage(page)
  }

  // Effect to update table filters based on URL query parameters
  useEffect(() => {
    if (tableRef?.current) {
      delay(500).then(() =>{
        tableRef.current.state.query.filters = [
          {
            column: { field: 'resourceId' },
            operator: '=',
            value: query.resourceId,
          },{
            column: { field: 'type' },
            operator: '=',
            value: query.type,
          },{
            column: { field: 'voucherNumber' },
            operator: '=',
            value: query.voucherNumber,
          }
        ]

        const defaultSortColumnName = tableRef.current.dataManager.columns[query.orderBy]?.field || ''
        const newOrderByCollection = []

        for (let i = 0; i < tableRef.current.dataManager.columns.length; i++) {
          const columnIsActive = (query.orderBy === (''+i)) // compare string to string
          newOrderByCollection.push({
            orderBy: i,
            orderDirection: columnIsActive ? query.orderDirection : '',
            sortOrder: columnIsActive ? 1 : null,
          })
        }

        tableRef.current.dataManager.orderByCollection = newOrderByCollection // the real thing - for table controls
        tableRef.current.state.query.orderBy = {field: defaultSortColumnName} // the real thing - for data fetching
        tableRef.current.state.query.orderDirection = query.orderDirection
        setPage(0) //reset paging to show filter results from page 0
        tableRef.current.onQueryChange()
      })

    }
  }, [query.resourceId, query.type, query.voucherNumber, query.orderBy, query.orderDirection, setPage])

  const onOrderCollectionChange = (orderByCollection) => {
    const orderBy = tableRef?.current?.state?.orderByCollection?.[0]?.orderBy ?? ''
    const orderDirection = tableRef?.current?.state?.orderByCollection?.[0]?.orderDirection ?? ''
    updateCurrentUrlParams(history, {orderBy, orderDirection})
  }

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={<div>
          <Tooltip title={"Reload"} enterTouchDelay={0}>
            <IconButton aria-label="Reload" onClick={refreshHook}><Replay /></IconButton>
          </Tooltip>
          <Tooltip title={"Clear Filter"} enterTouchDelay={0}>
            <IconButton aria-label="Clear Filter" onClick={() => {
              props.history.push('/app/lexoffice/voucher/?resourceId=&voucherNumber=')
            }}><FilterIcon color={'#757575'} size={22} /></IconButton>
          </Tooltip>
        </div>}
        tableRef={tableRef}
        columns={columnSet}
        onPageChange={handlePageChange}
        page={page}
        data={qry =>
          new Promise((resolve, reject) => {
            //console.log(qry)
            let url = "/api/horus/lexoffice/vouchers?cache=clear&"
            url += `page=${qry.page + 1}`
            url += `&perPage=${qry.pageSize}`
            if (qry.search) {
              url += `&q=${qry.search}`
            }
            if (qry.orderBy) {
              url += `&sort=${qry.orderBy.field}&order=${qry.orderDirection}`
            }
            if (qry.filters.length) {
              const filter = qry.filters.map((filter) => {
                return `&${filter.column.field}${filter.operator}${filter.value}`
              })
              url += filter.join("")
            }
            fetch(url)
              .then((resp) => resp.json())
              .then((resp) => {
                resolve({
                  data: resp.data,
                  page: resp.page - 1,
                  totalCount: resp.total,
                })
              })
          })
        }
        icons={tableIcons}
        detailPanel={null}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putLexofficeVoucher(oldData.id, {
                ...newData,
              }, refreshHook))
              resolve()
            }),
        }}
        onOrderCollectionChange={onOrderCollectionChange}
        options={{
          debounceInterval: 700,
          search: false,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          thirdSortClick: false,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 50, 500, 5000],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          //tableLayout: 'fixed',
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '3px 3px 3px 3px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default LexofficeVoucherTable

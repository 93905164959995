import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip
} from '@mui/material';
import {
  BusinessSharp,
  ChevronRight,
  DateRange,
  DocumentScanner,
  EventBusy,
  ExitToApp,
  GridOn,
  Group,
  Home,
  Hotel,
  LocalOffer,
  MailOutline,
  MonetizationOn,
  Payments,
  PeopleOutline,
  Settings,
  Today,
} from '@mui/icons-material';

import * as actions from '../../store/actions';
import CheckAuthorization from '../../services/authorization';
import versions from '../../versions.json';
import logoImg from "../../assets/horus_logo_05.png";

const Navigation = () => {

  const dispatch = useDispatch()

  const drawerState = useSelector(state => state.navigation.drawerState)
  const user = useSelector(state => state.login.user)
  const build_date = versions?.build_date || 'n/a'
  const commit_hash = versions?.commit_hash || '0000'
  const version = build_date.substring(0,10) +' (v '+commit_hash.substr(-4)+')'

  const handleDrawerClose = () => {
    dispatch(actions.closeDrawer())
  }

  const handleLogout = () => {
    dispatch(actions.logout())
  }

  const StyledDrawer = styled(Drawer)(({ theme }) => ({
    zIndex: 7000,
    '& .MuiDrawer-paper': {
      backgroundColor: '#e3e3e3'
    }
  }))

  const DrawerContent = withRouter((props) => {
    return (
      <div style={{width:205}}>
        { props.mobile
          ? <div>
              <List>
                <ListItem>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={handleDrawerClose}
                    startIcon={<ChevronRight />}
                  >
                    zurück
                  </Button>
                </ListItem>
              </List>
              <Divider />
            </div>
          : null
        }

        <Box display="flex" alignItems="center" style={{ fontSize: 12, backgroundColor: '#6e7379', padding: 0, paddingLeft: 8, paddingTop: 7, color: '#FFFFFF'}}>
          Hey&nbsp;<b>{user.name}</b>
          <div style={{flexGrow: 1, flexFlow: 'row nowrap'}}>
            <div style={{display: 'flex', marginLeft: 10, justifyContent: 'flex-end'}}>
              <Tooltip title="User profile" enterTouchDelay={0}>
                <IconButton onClick={() => {
                  props.history.push('/app/profile/'); handleDrawerClose()
                }}><PeopleOutline style={{width: 20, color: '#FFFFFF'}} /></IconButton>
              </Tooltip>
              <Tooltip title="Logout" enterTouchDelay={0}>
                <IconButton onClick={() => {
                  handleLogout()
                }}><ExitToApp style={{width: 20, color: '#FFFFFF'}} /></IconButton>
              </Tooltip>
            </div>
          </div>
        </Box>

        <List>
          <ListItem button component={Link} to={'/app/'} onClick={() => handleDrawerClose()}>
            <ListItemIcon><Home /></ListItemIcon>
            <ListItemText primary={"Start"} />
          </ListItem>
          <Divider />
          { CheckAuthorization('nav','hotels')
            ? <ListItem button component={Link} to={'/app/hotels/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><Hotel /></ListItemIcon>
                <ListItemText primary={"Hotels"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','districts')
            ? <ListItem button component={Link} to={'/app/districts/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><GridOn /></ListItemIcon>
                <ListItemText primary={"Districts"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','offers')
            ? <ListItem button component={Link} to={'/app/offers/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><LocalOffer /></ListItemIcon>
                <ListItemText primary={"Offers"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','reservations')
            ? <ListItem button component={Link} to={'/app/reservations/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><DateRange /></ListItemIcon>
                <ListItemText primary={"Reservations"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','reservations')
            ? <ListItem button component={Link} to={'/app/trips/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><Today /></ListItemIcon>
                <ListItemText primary={"Trips"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','users')
            ? <ListItem button component={Link} to={'/app/users/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><Group /></ListItemIcon>
                <ListItemText primary={"Users"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','companies')
            ? <ListItem button component={Link} to={'/app/companies/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><BusinessSharp /></ListItemIcon>
                <ListItemText primary={"Companies"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','subscriptions')
            ? <ListItem button component={Link} to={'/app/subscriptions/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><MonetizationOn /></ListItemIcon>
                <ListItemText primary={"Subscriptions"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','specialDatesByCity')
            ? <ListItem button component={Link} to={'/app/specialDatesByCity/?cityId=muenchen'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><EventBusy /></ListItemIcon>
                <ListItemText primary={"Special Dates"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','lexoffice')
            ? <ListItem button component={Link} to={'/app/lexoffice/contact/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><Payments /></ListItemIcon>
                <ListItemText primary={"Lexoffice"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','reports')
            ? <ListItem button component={Link} to={'/app/reports/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><DocumentScanner /></ListItemIcon>
                <ListItemText primary={"Reports"} />
              </ListItem>
            : '' }
          { CheckAuthorization('nav','newsletter')
            ? <ListItem button component={Link} to={'/app/newsletter/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><MailOutline /></ListItemIcon>
                <ListItemText primary={"Newsletter"} />
              </ListItem>
            : '' }
          <Divider />
          { CheckAuthorization('nav','settings')
            ? <ListItem button component={Link} to={'/app/settings/'} onClick={() => handleDrawerClose()}>
                <ListItemIcon><Settings /></ListItemIcon>
                <ListItemText primary={"Settings"} />
              </ListItem>
            : '' }
        </List>

        <div style={{fontSize: 11, padding: 10}}>
          <small>Version: {version}
          </small>
        </div>
      </div>
    )
  })

  return (
    <div>
      <StyledDrawer
        sx={{ display: { xs: 'block', md: 'none' }, zIndex: 6000 }}
        variant="temporary"
        anchor="right"
        open={drawerState}
        onClose={handleDrawerClose}
      >
        <DrawerContent mobile={true} />
      </StyledDrawer>
      <StyledDrawer
        sx={{display: {xs: 'none', md: 'block'}, width: 205, flexShrink: 0}}
        variant="permanent"
        open
      >
        <div style={{paddingTop: 20, paddingBottom: 20, display: 'flex', justifyContent: 'center', backgroundColor: window.location.hostname === 'horus.myflexhome.de' ? '#d5dfe7' : '#efddd2',}}>
          <img src={logoImg} alt="MyFlexHome Horus" style={{width: 190, objectFit: 'contain'}}/>
        </div>
        <DrawerContent mobile={false}/>
      </StyledDrawer>
    </div>
  )

}


export default Navigation

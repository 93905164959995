import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Autocomplete, createFilterOptions, TextField, Typography} from '@mui/material';
import {formatDate, formatDateTime, getDateAsCleanUTC} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {shortenText} from "../../services/stringHelper";
import {GermanDatePicker} from "../components/LocalDateInput";
import {useLocation} from "react-router";

const SubscriptionTable = withRouter((props) => {

  const dispatch = useDispatch()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    subscription: searchQuery.get('subscription') || '',
    customer: searchQuery.get('customer') || '',
    status: searchQuery.get('status') || '',
    hotel: searchQuery.get('hotel') || '',
    room: searchQuery.get('room') || '',
  }

  const data = useSelector(state => state.subscription.subscriptions)
  const customerList = useSelector(state => state.customer.customers)

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const startDate = {
    title: 'Start Date',
    field: 'startDate',
    type: 'date',
    validate: (rowData) => (rowData.startDate || "").length !== 0,
    render: rowData => <div>{formatDate(rowData.startDate)}</div>,
    editComponent: props => <GermanDatePicker data={props} />,
  }


  const endDate = {
    title: 'End Date',
    field: 'endDate',
    render: rowData => <div>{formatDate(rowData.endDate)}</div>,
    type: 'date',
    editComponent: props => <GermanDatePicker data={props} />,
  }

  const hotel = {
    title: 'Hotel',
    field: 'hotel.name',
    editable: 'never',
    defaultFilter: query.hotel,
  }

  const room = {
    title: 'Room',
    field: 'room.name',
    editable: 'never',
    defaultFilter: query.room,
  }

  const business = {
    title: 'Business',
    field: 'shareable',
    editable: 'never',
  }

  const status = {
    title: 'Status',
    field: 'status',
    editable: 'never',
    defaultFilter: query.status,
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.status
      return (term.startsWith('!') ? !searchString.toString().includes(term.toLowerCase().slice(1)) : searchString.toString().includes(term.toLowerCase()))
    },
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const subscriptionID = {
    title: 'SubscriptionID',
    field: 'subscriptionID',
    editable: 'never',
    render: rowData => <div style={{fontWeight: 'bold'}}>
      <Link className="tableActionButton" to={`/app/subscriptionSegments/${rowData.subscriptionID}`}>{rowData.subscriptionID}</Link>
    </div>,
    defaultFilter: query.subscription,
  }

  const BookingCustomerId = {
    title: 'User',
    field: 'BookingCustomer',
    validate: (rowData) => (rowData.BookingCustomer || "").length !== 0,
    render: rowData => {
      //const customer = customerList.filter(o => o.id === rowData.BookingCustomer?.id)?.[0]
      const customer = rowData.BookingCustomer
      return(
        <div>
          <div><Link to={`/app/users/${customer?.customerID}`}>{customer?.customerID}</Link></div>
          <div style={{fontSize: 10}}>{customer?.firstName} {customer?.lastName}</div>
          <div style={{fontSize: 10}}>{customer?.email}</div>
          <div style={{fontSize: 10}}><Link to={'/app/companies/?companyID='+customer?.BusinessCompany?.companyID}>{customer?.BusinessCompany?.name}</Link></div>
        </div>
      )
    },
    customFilterAndSearch: (term, rowData) => {
      const customerID = rowData.BookingCustomer?.customerID
      const firstName = rowData.BookingCustomer?.firstName
      const lastName = rowData.BookingCustomer?.lastName
      const customerEmail = customerList.filter(o => o.id === rowData.BookingCustomer?.id)?.[0]?.email
      const searchString = customerID + ' ' + firstName + ' ' + lastName + ' ' + customerEmail
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
    editComponent: props => (
      <div style={{width: 140}}>
        <Autocomplete
          id="BookingCustomerId"
          autoComplete={false}
          options={customerList}
          disableClearable={true}
          getOptionLabel={(option) => option.customerID || ''}
          value={props.value || null} // null required to avoid jump from uncontrolled to controlled state
          onChange={(e, value) => {
            props.onChange({id:value?.id, customerID:value?.customerID})
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id
          }}
          renderOption={(props, option) => (
            <div {...props}>
              <div style={{fontSize: 13}}>
                {option.customerID || '---'}
                <div style={{fontSize: 9}}>{option.firstName} {option.lastName}</div>
                <div style={{fontSize: 9}}>{option.email}</div>
              </div>
            </div>
          )}
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            stringify: (option) => {
              const customerID = option?.customerID
              const name = option.firstName + ' ' + option.lastName
              const email = option.email
              return(customerID + ' ' + name + ' ' + email)
            },
          })}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    defaultFilter: query.customer,
  }

  const internalComment = {
    title: 'Internal Comment',
    field: 'internalComment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 190}}>{shortenText(rowData.internalComment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const countReservations = {
    title: 'Count Reservations',
    field: 'countReservations',
    editable: 'never',
    render: rowData => <div>
      {rowData.countReservations > 0
        ? <span style={{textDecoration: 'underline', color: '#045ebe', cursor: 'pointer'}} onClick={() => {
          props.history.push(`/app/reservations/?subscription=${rowData.subscriptionID}`)
        }}>{rowData.countReservations}</span>
        : '0'
      }
    </div>,
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.countReservations
      return (term.startsWith('!') ? !searchString.toString().includes(term.toLowerCase().slice(1)) : searchString.toString().includes(term.toLowerCase()))
    },
  }

  const columnSet = [
    subscriptionID,
    startDate,
    endDate,
    status,
    BookingCustomerId,
    hotel,
    room,
    business,
    internalComment,
    countReservations,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postSubscription({
                ...newData,
                startDate: getDateAsCleanUTC(newData.startDate),
                cancellationDate: getDateAsCleanUTC(newData.cancellationDate),
                endDate: getDateAsCleanUTC(newData.endDate),
              }))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putSubscription(oldData.id, {
                ...newData,
                startDate: getDateAsCleanUTC(newData.startDate),
                cancellationDate: getDateAsCleanUTC(newData.cancellationDate),
                endDate: getDateAsCleanUTC(newData.endDate),
              }))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteSubscription(oldData.id))
              resolve()
            }),
        }}
        options={{
          search: true,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default SubscriptionTable

import React, {useEffect} from 'react'
import {useParams, withRouter} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {Grid} from '@mui/material';
import {Hotel} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import DistrictAssignHotelModal from "../components/DistrictAssignHotelModal";
import BusinessGuestChangeModal from "../components/BusinessGuestChangeModal";
import TripTable from "../tables/TripTable";
import {updateCurrentUrlParams} from "../../services/url";
import {useHistory, useLocation} from "react-router";


const ViewTrips = withRouter((props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { tripID } = useParams()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    status: searchQuery.get('status') ?? '',
    orderBy: searchQuery.get('orderBy') ?? '',
    countSegments: searchQuery.get('countSegments') ?? '',
  }

  useEffect(() => {
    dispatch(actions.getOffer())
    dispatch(actions.getSubscription())
    dispatch(actions.getHotel())
  },[dispatch])

  //const isLoading = useSelector(state => state.reservation.isLoading)
  const isLoading = false

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Hotel fontSize={'small'} className="headlineIcon"/>
          <div className="headlineIconText">Trips</div>
        </div>
        <div>Group customer reservations to trips</div>
        <div style={{marginLeft: 30, padding: 6, backgroundColor: '#f1f5ec'}}>
          <span style={{fontWeight: 'bold', color: '#5b8328'}}>FILTER:</span>
          <span style={{marginLeft: 10}}>ToDos: &nbsp;
            <button style={query.status === 'new|partially_confirmed' && query.orderBy === '1' ? {fontWeight: 'bold'} : {}}
                    title="status = (new OR partially_confirmed) AND order by = arrival"
                    onClick={() => updateCurrentUrlParams(history, {status: 'new|partially_confirmed', orderBy: '1', orderDirection: 'asc'})}>next arrivals</button>
            <button style={query.status === 'new|partially_confirmed' && query.orderBy === '21' ? {fontWeight: 'bold'} : {}}
                    title="status = (new OR partially_confirmed) AND order by = updated"
                    onClick={() => updateCurrentUrlParams(history, {status: 'new|partially_confirmed', orderBy: '21', orderDirection: 'desc'})}>latest changes</button>
          </span>
          <span style={{marginLeft: 10}}>Status &nbsp;

            <button style={query.status === 'new' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {status: 'new'})}>new</button>
            <button style={query.status === 'partially_confirmed' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {status: 'partially_confirmed'})}>partially_confirmed</button>
            <button style={query.status === 'confirmed' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {status: 'confirmed'})}>confirmed</button>
            <button style={query.status === 'canceled_refunded' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {status: 'canceled_refunded'})}>canceled_refunded</button>
            <button style={query.status === 'canceled_deducted' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {status: 'canceled_deducted'})}>canceled_deducted</button>
            <button style={query.status === '' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {status: ''})}>all</button>
          </span>
          <span style={{marginLeft: 10}}>Sort by &nbsp;
            <button style={query.orderBy === '1' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {orderBy: '1', orderDirection: 'asc'})}>arrival</button>
            <button style={query.orderBy === '21' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {orderBy: '21', orderDirection: 'desc'})}>created</button>
            <button style={query.orderBy === '22' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {orderBy: '22', orderDirection: 'desc'})}>updated</button>

          </span>
        </div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading/> : <TripTable tripID={tripID}/>}
        </Grid>
      </Grid>
      <DistrictAssignHotelModal/>
      <BusinessGuestChangeModal/>
    </div>
  )
})


export default ViewTrips

import React, {useState} from 'react';
import {Autocomplete, Grid, Paper, TextField, Tooltip, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import * as actions from '../../store/actions';
import RatingInput from './RatingInput';
import UniversalUploadField from './UniversalUploadField';
import FsLightbox from 'fslightbox-react';
import {Delete, Filter1, Filter2, Filter3} from '@mui/icons-material'
import RatingInputGreen from './RatingInputGreen';
import RoomTable from '../tables/RoomTable';
import SpecialDateTable from '../tables/SpecialDateTable';
import {HtmlEditor} from "./HtmlEditor";
import {HotelDetailsFormLocation} from "./HotelDetailsFormLocation";
import {HotelDetailsFormCheckIn} from "./HotelDetailsFormCheckIn";
import {HotelDetailsFormSearch} from "./HotelDetailsFormSearch";
import {HotelDetailsFormTripadvisor} from "./HotelDetailsFormTripadvisor";
import {HotelDetailsFormAddress} from "./HotelDetailsFormAddress";
import {HotelDetailsFormGooglePrice} from "./HotelDetailsFormGooglePrice";
import {HotelDetailsFormEmailSettings} from "./HotelDetailsFormEmailSettings";
import {HotelDetailsFormInvoiceMatch} from "./HotelDetailsFormInvoiceMatch";

const HotelDetailsForm = (props) => {
  const dispatch = useDispatch()
  const {hotel, attributeList} = props

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  })

  const openLightboxOnSlide = (uuid) => {
    const isActiveImage = (element) => element.uuid === uuid
    const number = hotel.BookingMedia.findIndex(isActiveImage)+1
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number
    })
  }

  const lightBoxSources = hotel.BookingMedia ? hotel.BookingMedia.map(img => '/api/horus/media/'+img.uuid+'/image.jpg?width=900') : []

  const setUpload = () => {
    dispatch(actions.getHotelDetails(hotel.id))
  }

  const setStarRating = (value) => {
    dispatch(actions.putHotel(hotel.id, {ratingStars: value}))
  }
  const setTripadvisorRating = (value) => {
    dispatch(actions.putHotel(hotel.id, {ratingTripadvisor: value}))
  }

  const setHotelDetails = (value) => {
    const payload = {descriptionDE: value}
    dispatch(actions.putHotel(hotel.id, payload))
  }
  const setSubscriptionTerms = (value) => {
    const payload = {subscriptionTerms: value}
    dispatch(actions.putHotel(hotel.id, payload))
  }

  const basicServices = hotel.BookingHotelAttributes?.filter(x => x.category !== 'super') || []
  const superServices = hotel.BookingHotelAttributes?.filter(x => x.category === 'super') || []

  return(
    <div>

      <Grid container spacing={3}>

        <Grid item xs={12} md={6} lg={6}>

          <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8}}>
            <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
              Hotel
            </Typography>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{ fontSize: 22, fontWeight: 100, marginBottom: 10, }}>{hotel.name}</div>
              <div style={{ padding: 8, fontSize: 13, display: 'flex' }}>
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none"
                     stroke="#086381" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                     className="feather feather-eye" style={{marginRight: 3}}>
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
                <a
                  href={`https://app.myflexhome.de/hotel/${hotel.BookingCity.cityId}/${hotel.hotelId}/`}
                  target="_blank"
                  rel="noreferrer"
                  style={{color: '#086381'}}
                >Preview</a>
              </div>
            </div>
          </Paper>

          <HotelDetailsFormAddress hotel={hotel} />

          <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8 }}>
            <div style={{display: 'flex', justifyContent:'space-between'}}>
              <Typography variant="h6" >
                Description
              </Typography>
            </div>

            <HtmlEditor
              initialValue={hotel.descriptionDE}
              width={'95%'}
              height={290}
              saveButtonCallback={e => setHotelDetails(e)}
            />
          </Paper>

          <HotelDetailsFormLocation hotel={hotel} />

          <HotelDetailsFormCheckIn hotel={hotel} />

          <HotelDetailsFormEmailSettings hotel={hotel} />

        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8}}>
            <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
              Services & Rating
            </Typography>

            <div style={{ marginTop: 10, marginRight: 25, marginBottom: 10 }}>
              <Autocomplete
                multiple
                id="hotelAttributes"
                options={attributeList}
                disableCloseOnSelect
                //groupBy={(option) => option.status}
                getOptionLabel={(option) => option.name || '---'}
                value={superServices}
                onChange={(e, value) => { dispatch(actions.putHotelSuperAttribute(hotel.id, value)) }}
                isOptionEqualToValue={(option, value) => { return option.id === value.id }} //required for loading
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <div style={{display: 'flex'}}>
                      <img
                        src={`/api/horus/hotelAttribute/svg/${option.id}`}
                        alt={option.name}
                        style={{width: 25, height: 25, marginRight: 10}}
                      />
                      <div>{option.name || '---'}</div>
                    </div>
                  </li>
                )}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Super Services" placeholder="" />
                )}
              />

              <Autocomplete
                style={{ marginTop: 20 }}
                multiple
                id="hotelAttributes"
                options={attributeList}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name || '---'}
                value={basicServices}
                onChange={(e, value) => { dispatch(actions.putHotelBasicAttribute(hotel.id, value)) }}
                isOptionEqualToValue={(option, value) => { return option.id === value.id }} //required for loading
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <div style={{display: 'flex'}}>
                      <img
                        src={`/api/horus/hotelAttribute/svg/${option.id}`}
                        alt={option.name}
                        style={{width: 25, height: 25, marginRight: 10}}
                      />
                      <div>{option.name || '---'}</div>
                    </div>
                  </li>
                )}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Basic Services" placeholder="" />
                )}
              />

              <RatingInput
                rating={hotel.ratingStars}
                setRating={setStarRating}
                legend="Star Rating" />

              <RatingInputGreen
                rating={hotel.ratingTripadvisor}
                setRating={setTripadvisorRating}
                legend="Tripadvisor Rating" />

            </div>
          </Paper>

          <HotelDetailsFormTripadvisor hotel={hotel} />

          <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8 }}>
            <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
              Subscription Terms
            </Typography>

            <div>
              <HtmlEditor
                initialValue={hotel.subscriptionTerms}
                width={'95%'}
                height={280}
                saveButtonCallback={e => setSubscriptionTerms(e)}
              />
            </div>
          </Paper>

          <HotelDetailsFormSearch hotel={hotel} />

          <HotelDetailsFormGooglePrice hotel={hotel} />

          <HotelDetailsFormInvoiceMatch hotel={hotel} />
        </Grid>

      </Grid>

      <Paper style={{ padding: 6, paddingLeft: 16, paddingBottom: 16, marginTop: 16, marginBottom: 24 }}>
        <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
          Hotel Images
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={12} md={4} lg={4}>
            <UniversalUploadField
              uploadFieldType={'image'}
              uploadFieldNumber={'1'}
              uploadSetState={setUpload}
              apiUrl={'/api/horus/media/hotel/'+hotel.id}
              mimeType={'image/jpeg, image/png, image/webp, image/gif, image/jxl, image/avif'}
              text1="Hotel Foto Upload"
              text2="JPG, PNG, WEBP, AVIF, JPEG-XL, GIF  -  Klick oder Drag&Drop"
              height="300px"
            />
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {hotel.BookingMedia ? hotel.BookingMedia.map(img => <div
                style={{ width:309, height:183, overflow: 'hidden', display:'flex', alignItems: 'bottom', margin: 5 }}
                key={img.uuid}>
                <figure>
                  <img src={`/api/horus/media/${img.uuid}/image.jpg?width=309`}
                       alt="Hotel"
                       style={{ width: 309, cursor:'pointer' }}
                       onClick={() => openLightboxOnSlide(img.uuid)}
                  />
                  <figcaption>
                    <Tooltip title="Use picture on position 1" enterTouchDelay={0}>
                      <Filter1 className="figIcon" onClick={() => dispatch(actions.putMedia(img.uuid, {position:'1'}, hotel.id))} />
                    </Tooltip> &nbsp;
                    <Tooltip title="Use picture on position 2" enterTouchDelay={0}>
                      <Filter2 className="figIcon" onClick={() => dispatch(actions.putMedia(img.uuid, {position:'2'}, hotel.id))} />
                    </Tooltip> &nbsp;
                    <Tooltip title="Use picture on position 3" enterTouchDelay={0}>
                      <Filter3 className="figIcon" onClick={() => dispatch(actions.putMedia(img.uuid, {position:'3'}, hotel.id))} />
                    </Tooltip> &nbsp; &nbsp; &nbsp;
                    <Tooltip title="Delete picture" enterTouchDelay={0}>
                      <Delete className="figIcon" onClick={() => dispatch(actions.deleteMedia(img.uuid, hotel.id))} />
                    </Tooltip>
                  </figcaption>
                  {img.position ?  <Tooltip title="Picture position" enterTouchDelay={0}>
                    <div className="figMarker">{img.position}</div>
                  </Tooltip> : ''}
                </figure>
              </div> ) : ''}
            </div>
          </Grid>

        </Grid>
      </Paper>

      <RoomTable hotel={hotel} />

      <div style={{marginTop: 25}}>
        <SpecialDateTable hotel={hotel} />
      </div>

      <FsLightbox
        toggler={lightboxController.toggler}
        sources={lightBoxSources}
        slide={lightboxController.slide}
      />

    </div>
  )
}

export default HotelDetailsForm

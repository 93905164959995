import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import * as actions from '../../store/actions'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {HtmlEditorControlled} from "../components/HtmlEditorControlled";

const SubscriptionDurationTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.settings.subscriptionDuration)

  const name = {
    title: 'Name',
    field: 'name',
    render: rowData => <div style={{fontWeight: 'bold'}}>{rowData.name}</div>,
  }

  const months = {
    title: 'Months',
    field: 'months',
    defaultSort: 'asc',
  }

  const description = {
    title: 'Description',
    field: 'description',
    render: rowData => <div style={{maxHeight: 100, overflow: 'hidden'}} className="fadeOutText" dangerouslySetInnerHTML={{__html: rowData.description}} />,
    editComponent: props => (
      <textarea
        rows="5" cols="35"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const defaultTerms = {
    title: 'Default Terms',
    field: 'defaultTerms',
    render: rowData => <div style={{maxHeight: 100, overflow: 'hidden'}} className="fadeOutText" dangerouslySetInnerHTML={{__html: rowData.defaultTerms}} />,
    editComponent: props => (
      <HtmlEditorControlled
        value={props.value}
        width={'95%'}
        height={200}
        editorChangeCallback={e => props.onChange(e)}
      />
    ),
    filtering: false,
    headerStyle: {
      minWidth: 200,
    }
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <span>{formatDateTime(rowData.createdAt)}</span>,
    cellStyle: {
      width: 40,
    },
    headerStyle: {
      width: 40,
    },
    editable: 'never',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <span>{formatDateTime(rowData.updatedAt)}</span>,
    cellStyle: {
      width: 40,
    },
    headerStyle: {
      width: 40,
    },
    editable: 'never',
  }

  const columnSet = [
    name,
    months,
    description,
    defaultTerms,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={"Subscription Duration"}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postSubscriptionDuration(newData))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putSubscriptionDuration(oldData.id, newData))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteSubscriptionDuration(oldData.id))
              resolve()
            }),
        }}
        detailPanel={null}
        options={{
          search: false,
          filtering: false,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#fff" : "#f9fcff",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default SubscriptionDurationTable

import * as actionTypes from './actionTypes'
import axios from '../../axios.config'

export const getPriceAnalytics = (payload) => {
  return dispatch => {
    dispatch(getPriceAnalyticsStart())
    axios.post('/horus/monitor/price/filter', payload)
      .then ( response => {
        dispatch(getPriceAnalyticsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getPriceAnalyticsFail(error))
      })
  }
}

export const getPriceAnalyticsStart = () => {
  return {
    type: actionTypes.GET_PRICE_ANALYTICS_START,
  }
}

export const getPriceAnalyticsSuccess = (res) => {
  return {
    type: actionTypes.GET_PRICE_ANALYTICS_SUCCESS,
    res: res,
  }
}

export const getPriceAnalyticsFail = (error) => {
  return {
    type: actionTypes.GET_PRICE_ANALYTICS_FAIL,
    error: error,
  }
}

import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  qrSettings: [],
}


const setQrSettings = (state, action) => {
  return {
    ...state,
    qrSettings: action.res,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_QR_SETTINGS_SUCCESS: return setQrSettings(state, action)
    default: return state
  }
}

export default reducer

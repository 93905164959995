import React from 'react';
import {useDispatch} from 'react-redux';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import {CloudUpload} from '@mui/icons-material';
import * as actions from '../../store/actions';

const UniversalUploadField = (props) => {
  const dispatch = useDispatch()

  const { uploadFieldType, uploadFieldNumber, apiUrl, mimeType, text1, text2, uploadSetState, height=60 } = props;
  const getUploadParams = () => {
    return { url: apiUrl }
  };

  const handleChangeStatus= (fileWithMeta, status) => {
    switch(status) {
      case 'uploading': {
        dispatch(actions.uploadStart())
        break;
      }
      case 'headers_received': {
        break;
      }
      case 'aborted': case 'error_upload': {
        dispatch(actions.showNotification('Upload failed','error'))
        dispatch(actions.uploadFail())
        break;
      }
      case 'done': {
        const uploadResponse = JSON.parse(fileWithMeta.xhr.response) || {JsonParsingError:true}
        if(uploadResponse) uploadSetState(uploadResponse)
        dispatch(actions.uploadSuccess(uploadFieldType, uploadFieldNumber, uploadResponse))
        dispatch(actions.showNotification('Upload successful','success'))
        fileWithMeta.remove();
        break;
      }
      default: {
        break;
      }
    }
  }

  const UploadArea = () => {
    return (
      <div style={{fontSize:13, color:'#6e6d71'}} key={uploadFieldType+uploadFieldNumber+'area'}>
        <CloudUpload style={{marginRight:4, fontSize: 19, color: '#6e6d71'}} /> {text1 || 'Upload'}<br/>
        <small style={{color:'#6e6d71', fontWeight:'normal'}}>{text2 || 'Klick oder Drag&Drop'}</small>
      </div>
    );
  };

  return (
    <Dropzone
      key={uploadFieldType+uploadFieldNumber+'zone'}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxFiles={1}
      multiple={false}
      canCancel={false}
      inputContent={<UploadArea key={uploadFieldType+uploadFieldNumber}/>}
      accept={mimeType}
      styles={{
        dropzone: { width: '100%', minHeight: 40, height: height, marginBottom: 5, padding: 0, overflow: 'hidden',
          backgroundColor: '#F0F0F0', borderColor: 'lightgrey', borderBottomWidth:2, borderLeftWidth: 2, borderRightWidth: 2 },
        dropzoneActive: { borderColor: 'green' },
        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
        preview: { padding: 0 }
      }}
    />
  )
}

export default UniversalUploadField

import {useDispatch} from "react-redux";
import React, {useState} from "react";
import * as actions from "../../store/actions";
import {Button, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import {formatDecimalEuToUs, formatDecimalUsToEu} from "../../services/unitFormater";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {getDateAsUTC} from "../../services/dateHelper";

export const HotelDetailsFormGooglePrice = (props) => {

  const dispatch = useDispatch()
  const {hotel} = props

  const [avgGooglePrice, setAvgGooglePrice] = useState(hotel.avgGooglePrice || '')
  const [googlePriceCheckedAt, setGooglePriceCheckedAt] = useState(hotel.googlePriceCheckedAt || '')

  const handleSave = () => {
    const payload = {
      avgGooglePrice: avgGooglePrice,
      googlePriceCheckedAt: googlePriceCheckedAt ? getDateAsUTC(googlePriceCheckedAt) : null,
    }
    dispatch(actions.putHotel(hotel.id, payload))
  }

  return(
    <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8 }}>
      <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
        Google Hotel Price
      </Typography>
      <div className="inputGrid">

        <TextField
          label="Price per night"
          size={'small'}
          //value={new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(grossPriceWithMargin)}
          value={formatDecimalUsToEu(avgGooglePrice || '')}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
            style: {width: 150, fontSize: 13}
          }}
          //variant={'default'}
          onChange={e => setAvgGooglePrice(formatDecimalEuToUs(e.target.value))}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Checked at"
            key="endDateCustom"
            inputFormat="dd.MM.yyyy"
            format="dd.MM.yyyy"
            clearable={true}
            value={googlePriceCheckedAt ? new Date(googlePriceCheckedAt) : null}
            //minDate={startDateMonthWatch?.dateFrom || startDateCustomWatch}
            //maxDate={subscriptionEndDate}
            onChange={(e) => {
              setGooglePriceCheckedAt(e)
            }}
            slotProps={{
              textField: {
                InputProps: {
                  className: "dateField",
                  size: "small",
                  //error: !!fieldState.error,
                  style: {
                    width: 180,
                    backgroundColor: "#FFF",
                    fontSize: 14
                  },
                },
              },
            }}
          />
        </LocalizationProvider>

        <Button onClick={handleSave} variant="outlined" color="secondary">
          Save
        </Button>
      </div>
    </Paper>
  )
}
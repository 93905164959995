import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Autocomplete, createFilterOptions, TextField, Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {shortenText} from '../../services/stringHelper';

const CustomerTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.customer.customers)
  const companies = useSelector(state => state.company.companies)

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const lastName = {
    title: 'Last Name',
    field: 'lastName',
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.firstName + ' ' + rowData.lastName
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
  }

  const firstName = {
    title: 'First Name',
    field: 'firstName',
  }

  const alias = {
    title: 'LexOffice Full Name Alias',
    field: 'fullNameAlias',
  }

  const street = {
    title: 'Street',
    field: 'addressStreet',
  }

  const zip = {
    title: 'ZIP',
    field: 'addressZip',
  }

  const city = {
    title: 'City',
    field: 'addressCity',
  }

  const country = {
    title: 'Country',
    field: 'addressCountry',
  }

  const billingLastName = {
    title: 'Billing Last Name',
    field: 'billingLastName',
  }

  const billingFirstName = {
    title: 'Billing First Name',
    field: 'billingFirstName',
  }

  const billingCompany = {
    title: 'Billing Company',
    field: 'billingCompany',
  }

  const billingStreet = {
    title: 'Billing Street',
    field: 'billingStreet',
  }

  const billingZip = {
    title: 'Billing ZIP',
    field: 'billingZip',
  }

  const billingCity = {
    title: 'Billing City',
    field: 'billingCity',
  }

  const billingCountry = {
    title: 'Billing Country',
    field: 'billingCountry',
  }

  const contactPhone = {
    title: 'Phone',
    field: 'contactPhone',
  }

  const customerID = {
    title: 'CustomerID',
    field: 'customerID',
    editable: 'never',
    defaultFilter: props.customerID || ''
  }

  const contactEMail = {
    title: 'E-Mail',
    field: 'email',
    render: rowData => <div style={{overflowWrap: 'break-word'}}>{rowData.email}</div>,
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.email
      return (term.startsWith('!') ? !searchString.toString().includes(term.toLowerCase().slice(1)) : searchString.toString().includes(term.toLowerCase()))
    },
  }

  const customerAccountingNumber = {
    title: 'LexOffice Customer ID',
    field: 'customerAccountingNumber',
    render: rowData => <div style={{overflowWrap: 'break-word'}}>{rowData.customerAccountingNumber}</div>,
  }

  const comment = {
    title: 'Internal Comment',
    field: 'comment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 190}}>{shortenText(rowData.comment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const countSubscriptions = {
    title: 'Count Subscriptions',
    field: 'countSubscriptions',
    editable: 'never',
    render: rowData => <div>
      {rowData.countSubscriptions > 0
        ? <Link to={`/app/subscriptions/?customer=${rowData.customerID}`}>{rowData.countSubscriptions}</Link>
        : '0'
      }
    </div>,
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.countSubscriptions
      return (term.startsWith('!') ? !searchString.toString().includes(term.toLowerCase().slice(1)) : searchString.toString().includes(term.toLowerCase()))
    },
  }

  const registrationSource = {
    title: 'Registration Source',
    field: 'registrationSource',
    editable: 'never',
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const company = {
    title: 'Company',
    field: 'BusinessCompany',
    render: rowData => {
      return(
        <div><Link to={'/app/companies/?companyID='+rowData?.BusinessCompany?.companyID}>{rowData?.BusinessCompany?.name}</Link></div>
      )
    },
    customFilterAndSearch: (term, rowData) => {
      const company = companies.filter(comp => comp.id === rowData?.BusinessCompanyId)?.[0] || {}
      const searchString = company?.name + ' ' + company?.companyID
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
    editComponent: props => (
      <div style={{width: 140}}>
        <Autocomplete
          id="BookingCustomerId"
          autoComplete={false}
          options={companies}
          disableClearable={true}
          getOptionLabel={(option) => option.name || ''}
          value={props.value || null} // null required to avoid jump from uncontrolled to controlled state
          onChange={(e, value) => {
            props.onChange({id:value?.id, companyID:value?.companyID, name:value?.name})
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          isOptionEqualToValue={(option, value) => {
            return option.companyID === value.companyID
          }}
          renderOption={(props, option) => (
            <div {...props}>
              <div style={{fontSize: 13}}>
                {option.name || '---'}
                <div style={{fontSize: 9}}>{option.companyID}</div>
              </div>
            </div>
          )}
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            stringify: (option) => {
              const companyID = option?.companyID
              const name = option.name
              return(companyID + ' ' + name)
            },
          })}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
  }

  const mailNotification = {
    title: 'Newsletter',
    field: 'mailNotification',
    render: rowData => <div>{rowData.mailNotification ? 'yes' : 'no'}</div>,
    editable: 'never',
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.mailNotification || false].includes(term.toLowerCase()))
    },
  }

  const columnSet = [
    customerID,
    firstName,
    lastName,
    company,
    countSubscriptions,
    street,
    zip,
    city,
    country,
    contactPhone,
    contactEMail,
    billingFirstName,
    billingLastName,
    billingCompany,
    billingStreet,
    billingZip,
    billingCity,
    billingCountry,
    comment,
    customerAccountingNumber,
    alias,
    mailNotification,
    registrationSource,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putCustomer(oldData.id, newData))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteCustomer(oldData.id))
              resolve()
            }),
        }}
        options={{
          search: true,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default CustomerTable

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Autocomplete, TextField, Typography} from '@mui/material';
import {formatDate, formatDateTime} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {HtmlEditorControlled} from "../components/HtmlEditorControlled";
import {GermanDatePicker} from "../components/LocalDateInput";
import {Check, Close} from "@mui/icons-material";

const RoomTable = withRouter((props) => {

  const {hotel} = props
  const dispatch = useDispatch()
  const data = useSelector(state => state.room.rooms)
  const roomCategoryList = useSelector(state => state.settings.roomCategory)

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <span>{formatDateTime(rowData.createdAt)}</span>,
    editable: 'never',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <span>{formatDateTime(rowData.updatedAt)}</span>,
    editable: 'never',
  }

  const name = {
    title: 'Name',
    field: 'name',
    render: rowData => <div style={{fontWeight: 'bold'}}>
      <Link className="tableActionButton" to={`/app/roomDetails/${rowData.id}?activeSale=1`}>{rowData.name}</Link>
    </div>,
    defaultSort: 'asc',
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const roomCategory = {
    title: 'Room Category',
    field: 'BookingRoomCategory',
    render: rowData => <div>{rowData.BookingRoomCategory?.name}</div>,
    editComponent: props => (
      <div style={{width: 120}}>
        <Autocomplete
          id="roomCategories"
          options={roomCategoryList}
          getOptionLabel={(option) => option.name || '-'}
          value={props.value}
          onChange={(e, value) => {
            props.onChange({id:value?.id, name:value?.name})
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{fontSize: 13}}>{option.name || '---'}</div>
            </li>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    )
  }

  const description = {
    title: 'Description',
    field: 'description',
    render: rowData => <div style={{maxHeight: 100, overflow: 'hidden'}} className="fadeOutText" dangerouslySetInnerHTML={{__html: rowData.description}} />,
    editComponent: props => (
      <HtmlEditorControlled
        value={props.value}
        width={'95%'}
        height={200}
        editorChangeCallback={e => props.onChange(e)}
      />
    ),
    filtering: false,
  }

  const displayOrder = {
    title: 'Display Order',
    field: 'displayOrder',
  }

  const startDate = {
    title: 'Start Date',
    field: 'startDate',
    type: 'date',
    render: rowData => <div>{rowData.startDate ? formatDate(rowData.startDate) : ''}</div>,
    headerStyle: {
      maxWidth: 140,
    },
    editComponent: props => <GermanDatePicker data={props} />,
  }

  const endDate = {
    title: 'End Date',
    field: 'endDate',
    type: 'date',
    render: rowData => <div>{rowData.endDate ? formatDate(rowData.endDate) : ''}</div>,
    headerStyle: {
      maxWidth: 140,
    },
    editComponent: props => <GermanDatePicker data={props} />,
  }

  const activeSale = {
    title: 'Active Sale',
    field: 'activeSale',
    render: rowData => <div>{rowData.activeSale
      ? <Check style={{ color: '#5c9846'}} titleAccess="yes" />
      : <Close style={{ color: '#862626'}} titleAccess="no" />}</div>,
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData.activeSale].includes(term.toLowerCase()))
    },
    editable: 'never',
  }

  const columnSet = [
    name,
    roomCategory,
    description,
    displayOrder,
    startDate,
    endDate,
    activeSale,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={"Rooms"}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postRoom({...newData, BookingHotelId: hotel.id}, hotel.id))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putRoom(oldData.id, newData, hotel.id))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteRoom(oldData.id, hotel.id))
              resolve()
            }),
        }}
        options={{
          search: false,
          filtering: false,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default RoomTable

import React, {useRef, useState} from "react";
import {Editor} from '@tinymce/tinymce-react';
import {Button} from "@mui/material";
import tinymce from 'tinymce/tinymce';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';
import 'tinymce/plugins/paste';


export const HtmlEditor = (props) => {
  const {initialValue='', saveButtonCallback, width=300, height=200} = props
  const editorRef = useRef(null)

  const [value, setValue] = useState(initialValue)
  const saveChange = () => {
    saveButtonCallback(value)
  }

  return(
    <div style={{ width: width, marginTop: 10, marginBottom: 8 }}>
      <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={initialValue}
        onEditorChange={(value, editor) => setValue(value)}
        init={{
          width: '100%',
          height: height,
          menubar: false,
          statusbar: false,
          plugins: [
            'paste',
            'advlist autolink lists link image charmap anchor',
            'searchreplace code fullscreen',
            'insertdatetime media table code help'
          ],
          toolbar: 'undo redo | ' +
            'bold italic | bullist numlist | alignleft aligncenter ' +
            'alignright alignjustify | backcolor media link | ' +
            ' removeformat code | help',
          skin: false,
          paste_as_text: true,
          content_css: false,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:13px }',
          initEngine: tinymce
        }}
      />
      <div style={{ textAlign: 'right', marginTop: 5 }}>
        <Button onClick={saveChange} variant="outlined" color="secondary">
          Save
        </Button>
      </div>

    </div>

  )
}
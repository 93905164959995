import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";

/*
 * GET all hotels
 */
export const getHotel = () => {
  return dispatch => {
    dispatch(getHotelStart())
    axios.get('/horus/hotel')
      .then ( response => {
        dispatch(getHotelSuccess(response.data))
      })
      .catch(error => {
        dispatch(getHotelFail(error))
      })
  }
}

export const getHotelStart = () => {
  return {
    type: actionTypes.GET_HOTEL_START,
  }
}

export const getHotelSuccess = (res) => {
  return {
    type: actionTypes.GET_HOTEL_SUCCESS,
    res: res,
  }
}

export const getHotelFail = (error) => {
  return {
    type: actionTypes.GET_HOTEL_FAIL,
    error: error,
  }
}


/*
 * GET hotel details by hotelId
 */
export const getHotelDetails = (id) => {
  return dispatch => {
    dispatch(getHotelDetailsStart())
    axios.get('/horus/hotel/byId/'+id)
      .then ( response => {
        dispatch(getHotelDetailsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getHotelDetailsFail(error))
      })
  }
}

export const getHotelDetailsStart = () => {
  return {
    type: actionTypes.GET_HOTEL_DETAILS_START,
  }
}

export const getHotelDetailsSuccess = (res) => {
  return {
    type: actionTypes.GET_HOTEL_DETAILS_SUCCESS,
    res: res,
  }
}

export const getHotelDetailsFail = (error) => {
  return {
    type: actionTypes.GET_HOTEL_DETAILS_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postHotel = (payload) => {
  return dispatch => {
    dispatch(postHotelStart())
    axios.post('/horus/hotel', payload)
      .then ( response => {
        dispatch(postHotelSuccess(response.data))
        dispatch(showNotification('Hotel created successfully','success'))
        dispatch(getHotel())
      })
      .catch(error => {
        dispatch(showNotification('Creating hotel failed','error'))
        dispatch(postHotelFail(error))
      })
  }
}

export const postHotelStart = () => {
  return {
    type: actionTypes.POST_HOTEL_START,
  }
}

export const postHotelSuccess = (res) => {
  return {
    type: actionTypes.POST_HOTEL_SUCCESS,
    res: res,
  }
}

export const postHotelFail = (error) => {
  return {
    type: actionTypes.POST_HOTEL_FAIL,
    error: error,
  }
}


/*
 * PUT hotel
 */
export const putHotel = (id, payload) => {
  return dispatch => {
    dispatch(putHotelStart())
    axios.put('/horus/hotel/'+id, payload)
      .then ( response => {
        dispatch(putHotelSuccess(response.data))
        dispatch(getHotelDetails(id))
        dispatch(showNotification('Changes saved successfully','success'))
      })
      .catch(error => {
        dispatch(showNotification('Saving changes failed','error'))
        dispatch(putHotelFail(error))
      })
  }
}

export const putHotelStart = () => {
  return {
    type: actionTypes.PUT_HOTEL_START,
  }
}

export const putHotelSuccess = (res) => {
  return {
    type: actionTypes.PUT_HOTEL_SUCCESS,
    res: res,
  }
}

export const putHotelFail = (error) => {
  return {
    type: actionTypes.PUT_HOTEL_FAIL,
    error: error,
  }
}


/*
 * PUT hotel attributes (basic)
 */
export const putHotelBasicAttribute = (id, payload) => {
  return dispatch => {
    dispatch(putHotelBasicAttributeStart())
    axios.put('/horus/hotel/attribute/'+id+'/basic', payload)
      .then ( response => {
        dispatch(putHotelBasicAttributeSuccess(response.data))
        dispatch(getHotelDetails(id))
      })
      .catch(error => {
        dispatch(putHotelBasicAttributeFail(error))
      })
  }
}

export const putHotelBasicAttributeStart = () => {
  return {
    type: actionTypes.PUT_HOTEL_BASIC_ATTRIBUTE_START,
  }
}

export const putHotelBasicAttributeSuccess = (res) => {
  return {
    type: actionTypes.PUT_HOTEL_BASIC_ATTRIBUTE_SUCCESS,
    res: res,
  }
}

export const putHotelBasicAttributeFail = (error) => {
  return {
    type: actionTypes.PUT_HOTEL_BASIC_ATTRIBUTE_FAIL,
    error: error,
  }
}


/*
 * PUT hotel attributes (super)
 */
export const putHotelSuperAttribute = (id, payload) => {
  return dispatch => {
    dispatch(putHotelSuperAttributeStart())
    axios.put('/horus/hotel/attribute/'+id+'/super', payload)
      .then ( response => {
        dispatch(putHotelSuperAttributeSuccess(response.data))
        dispatch(getHotelDetails(id))
      })
      .catch(error => {
        dispatch(putHotelSuperAttributeFail(error))
      })
  }
}

export const putHotelSuperAttributeStart = () => {
  return {
    type: actionTypes.PUT_HOTEL_SUPER_ATTRIBUTE_START,
  }
}

export const putHotelSuperAttributeSuccess = (res) => {
  return {
    type: actionTypes.PUT_HOTEL_SUPER_ATTRIBUTE_SUCCESS,
    res: res,
  }
}

export const putHotelSuperAttributeFail = (error) => {
  return {
    type: actionTypes.PUT_HOTEL_SUPER_ATTRIBUTE_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteHotel = (id) => {
  return dispatch => {
    dispatch(deleteHotelStart())
    axios.delete('/horus/hotel/'+id)
      .then ( response => {
        dispatch(deleteHotelSuccess(response.data))
        dispatch(showNotification('Hotel deleted successfully','success'))
        dispatch(getHotel())
      })
      .catch(error => {
        dispatch(showNotification('Deleting hotel failed','error'))
        dispatch(deleteHotelFail(error))
      })
  }
}

export const deleteHotelStart = () => {
  return {
    type: actionTypes.DELETE_HOTEL_START,
  }
}

export const deleteHotelSuccess = (res) => {
  return {
    type: actionTypes.DELETE_HOTEL_SUCCESS,
    res: res,
  }
}

export const deleteHotelFail = (error) => {
  return {
    type: actionTypes.DELETE_HOTEL_FAIL,
    error: error,
  }
}

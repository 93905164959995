import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router";
import {Link, withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Autocomplete, Checkbox, TextField, Tooltip, Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import * as actions from '../../store/actions'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {removeSpecialCharacters} from '../../services/stringHelper';
import {Check, Close} from '@mui/icons-material';


const HotelTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.hotel.hotels)
  const cityList = useSelector(state => state.settings.city)

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    name: searchQuery.get('name') || '',
    searchCity: searchQuery.get('searchCity') || '',
    hotelOfferType: searchQuery.get('hotelOfferType') || '',
    active: searchQuery.get('active') || '',
    visibleSearch: searchQuery.get('visibleSearch') || '',
    visibleHotelDetails: searchQuery.get('visibleHotelDetails') || '',
    visibleHotelBookingButton: searchQuery.get('visibleHotelBookingButton') || '',
    membersOnly: searchQuery.get('membersOnly') || '',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const name = {
    title: 'Name',
    field: 'name',
    render: rowData => <div>
      <Link className="tableActionButton" to={`/app/hotelDetails/${rowData.id}`}>{rowData.name}</Link>
    </div>,
    defaultSort: 'asc',
    defaultFilter: query.name,
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const searchCity = {
    title: 'Search City',
    field: 'BookingCity',
    render: rowData => <div>{rowData.BookingCity.cityName}</div>,
    editComponent: props => (
      <div style={{width: 120}}>
        <Autocomplete
          //multiple
          id="cities"
          options={cityList}
          //disableCloseOnSelect
          disableClearable={true}
          getOptionLabel={(option) => option.cityName || '-'}
          value={props.value || ''}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          onChange={(e, value) => {
            props.onChange({id:value?.id, cityName:value?.cityName})
          }}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{fontSize: 13}}>{option.cityName || '---'}</div>
            </li>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.BookingCity.cityName + ' ' + rowData.BookingCity.cityId
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
    defaultFilter: query.searchCity,
  }

  const hotelOfferType = {
    title: 'Offer Type',
    field: 'hotelOfferType',
    render: rowData => <div>
      {rowData.hotelOfferType}
      {rowData?.BookingDistrict?.districtID
        ? <div>
            <Link
              style={{fontSize: 13, color:'#045ebe'}}
              to={'/app/districts?districtID='+rowData.BookingDistrict.districtID}
            >{rowData.BookingDistrict.districtID}</Link>
          </div>
        : ''
      }
    </div>,
    editComponent: props => (
      <div style={{width: 120}}>
        <Autocomplete
          id="status"
          options={[
            'standard',
            'district',
            'rebook',
          ]}
          disableClearable={true}
          getOptionLabel={(option) => option || '(required)'}
          value={props.value || ''}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          onChange={(e, value) => {
            props.onChange(value)
          }}
          isOptionEqualToValue={(option, value) => {
            return option === value
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{fontSize: 13}}>{option}</div>
            </li>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    defaultFilter: query.hotelOfferType,
  }

  const active = {
    title: <Tooltip title={"Used for HORUS-internal filtering. To hide hotel for sale use visibility settings."}>
      <div>Active (Horus)</div>
    </Tooltip>,
    field: 'active',
    render: rowData => (
      <div>{rowData.active ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.active || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.active,
  }

  const visibleSearch = {
    title: 'Visible Search',
    field: 'visibleSearch',
    render: rowData => (
      <div>{rowData.visibleSearch ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.visibleSearch || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.visibleSearch,
  }

  const visibleHotelDetails = {
    title: 'Visible HotelDetails',
    field: 'visibleHotelDetails',
    render: rowData => (
      <div>{rowData.visibleHotelDetails ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.visibleHotelDetails || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.visibleHotelDetails,
  }

  const visibleHotelBookingButton = {
    title: 'Visible BookingButton',
    field: 'visibleHotelBookingButton',
    render: rowData => (
      <div>{rowData.visibleHotelBookingButton ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.visibleHotelBookingButton || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.visibleHotelBookingButton,
  }

  const membersOnly = {
    title: 'Members Only',
    field: 'membersOnly',
    render: rowData => (
      <div>{rowData.membersOnly ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.membersOnly || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.membersOnly,
  }

  const searchPriority = {
    title: 'Display Order',
    field: 'searchPriority',
    render: rowData => <div style={{width: 60}}>{rowData.searchPriority}</div>,
  }

  const columnSet = [
    name,
    searchCity,
    hotelOfferType,
    active,
    visibleSearch,
    visibleHotelDetails,
    visibleHotelBookingButton,
    membersOnly,
    searchPriority,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData => {
            const hotelId = removeSpecialCharacters(newData.name)
            return new Promise((resolve, reject) => {
              dispatch(actions.postHotel({
                ...newData,
                hotelId: hotelId,
              }))
              resolve()
            })
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise(resolve => {
              dispatch(actions.putHotel(oldData.id, {
                ...newData,
              }))
              resolve()
            })
          },

          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteHotel(oldData.id))
              resolve()
            }),
        }}
        options={{
          search: true,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          //cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default HotelTable

import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router";
import {Link, withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Autocomplete, Checkbox, TextField, Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import * as actions from '../../store/actions'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {shortenText} from '../../services/stringHelper';
import {Check, Close} from '@mui/icons-material';


const DistrictTable = withRouter(({district}) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.district.districtHotels)
  const hotelList = useSelector(state => state.hotel.hotels) || []
  const hotelListDistrict = hotelList.filter(h => h?.hotelOfferType !== 'district')
  const districtId = district?.id

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    visible: searchQuery.get('visisble') || '',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const visible = {
    title: 'Visible',
    field: 'visible',
    render: rowData => (
      <div>{rowData.visible ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.visible || false].includes(term.toLowerCase()))
    },
    defaultFilter: query.visible,
  }

  const displayOrder = {
    title: 'Display Order',
    field: 'displayOrder',
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const bookingHotel = {
    title: 'Hotel',
    field: 'BookingHotel',
    render: rowData => {
      return(
        <div>
          <Link
            to={`/app/hotels/?name=${rowData.BookingHotel?.name || ''}`}
            style={{textDecoration: "underline", cursor: "pointer", color: "#045ebe"}}>{rowData.BookingHotel?.name || ''}</Link>
        </div>
      )
    },
    editComponent: props => (
      <div style={{width: 140}}>
        <Autocomplete
          id="BookingHotel"
          options={hotelListDistrict}
          getOptionLabel={(option) => option?.name || ''}
          value={props.value || ''}
          onChange={(e, value) => {
            props.onChange({id:value?.id, name:value?.name})
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          isOptionEqualToValue={(option, value) => {
            return option?.id === value?.id
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{fontSize: 13}}>{option.name || '---'}</div>
            </li>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = rowData.BookingHotel?.name + ' ' + rowData.BookingHotel?.hotelID
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
  }

  const internalComment = {
    title: 'Internal Comment',
    field: 'internalComment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 190}}>{shortenText(rowData.internalComment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const columnSet = [
    bookingHotel,
    visible,
    displayOrder,
    internalComment,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData => {
            return new Promise((resolve, reject) => {
              dispatch(actions.postDistrictHotel({
                ...newData,
                BookingDistrict: {id: districtId},
              }, districtId))
              resolve()
            })
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise(resolve => {
              dispatch(actions.putDistrictHotel(oldData.id, {
                ...newData,
              }, districtId))
              resolve()
            })
          },

          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteDistrictHotel(oldData.id, districtId))
              resolve()
            }),
        }}
        options={{
          search: true,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default DistrictTable

import {add, differenceInCalendarDays, eachDayOfInterval, sub} from "date-fns";

export const mergeMultipleDateRangesToArray = (rangeArr) => {
  const result = []
  rangeArr.forEach(rangeObj => {
    //console.log('mergeMultipleDateRangesToArray', {start:new Date(rangeObj.start), end:new Date(rangeObj.end)})
    //spread to push single dates instead of array
    result.push(...eachDayOfInterval({start:new Date(rangeObj.start), end:new Date(rangeObj.end)}))
  })
  return result
}

export const getDateAsUTC = (date) => {
  const cleanDate = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()))
  cleanDate.setHours(0,0,0,0)
  const offset = date.getTimezoneOffset()
  return sub(cleanDate, {minutes: offset})
}

export const removeTimezoneOffset = (date) => {
  const offset = date.getTimezoneOffset()
  return sub(date, {minutes: offset})
}

export const addTimezoneOffset = (date) => {
  const offset = date.getTimezoneOffset()
  return add(date, {minutes: offset})
}

export const compareDates = (date1, date2) => {
  const utcDate1 = new Date(Date.UTC(date1.getFullYear(),date1.getMonth(), date1.getDate()))
  const utcDate2 = new Date(Date.UTC(date2.getFullYear(),date2.getMonth(), date2.getDate()))
  return utcDate1.getTime() === utcDate2.getTime()
}

export const compareDateToArray = (date, arr) => {
  const cleanDate = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()))
  return arr.some(item => {
    const cleanArrDate = new Date(Date.UTC(item.getFullYear(),item.getMonth(), item.getDate()))
    return cleanDate.getTime() === cleanArrDate.getTime()
  })
}

export const formatDateISO = (dateString) => {
  const date = new Date(dateString)
  return date.toISOString().substring(0, 10)
}


export const groupConsecutiveDates = (dateStringArray) => {

  // convert all date strings into JavaScript dates
  const dates = dateStringArray.map(x => new Date(x))

  // Sort the array of dates in ascending order
  dates.sort((a, b) => a - b)

  // Create an empty array to store the groups of consecutive dates
  const groups = []

  // Loop through the sorted array of dates
  for (let i = 0; i < dates.length; i++) {
    // If this is the first date, create a new group and add it to the array
    if (i === 0) {
      groups.push([dates[i]])
    } else {
      // Calculate the difference in days between the current date and the previous date
      const diff = differenceInCalendarDays(dates[i], dates[i - 1])

      // If the difference is exactly one day, add the current date to the current group
      if (diff === 1) {
        groups[groups.length - 1].push(dates[i])
      } else {
        // Otherwise, create a new group and add the current date to it
        groups.push([dates[i]])
      }
    }
  }

  return groups
}

export const getStartAndEndFromConsecutiveDates = (dateArray) => {
  return ({start: dateArray[0], end: dateArray[dateArray.length-1]})
}
import {useDispatch} from "react-redux";
import React, {useState} from "react";
import * as actions from "../../store/actions";
import {Button, Paper, TextField, Typography} from "@mui/material";

export const HotelDetailsFormTripadvisor = (props) => {

  const dispatch = useDispatch()
  const {hotel} = props

  const [ratingTripadvisorUri, setRatingTripadvisorUri] = useState(hotel.ratingTripadvisorUri || '')

  const setTripadvisorUrl = () => {
    const payload = {ratingTripadvisorUri: ratingTripadvisorUri}
    dispatch(actions.putHotel(hotel.id, payload))
  }

  return(
    <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8 }}>
      <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
        Tripadvisor
      </Typography>
      <div className="inputGrid">
        <TextField
          sx={{width: 400}}
          id="ratingTripadvisorUri"
          variant="outlined"
          label="Tripadvisor Url"
          placeholder=""
          size="small"
          value={ratingTripadvisorUri}
          onChange={(e) => setRatingTripadvisorUri(e.target.value)}
        />
        <Button onClick={setTripadvisorUrl} variant="outlined" color="secondary">
          Save
        </Button>
      </div>
    </Paper>
  )
}
import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";

/*
 * GET all districtHotels
 */
export const getDistrictHotel = (districtId=null) => {
  const filter = districtId ? '?districtId='+districtId : ''

  return dispatch => {
    dispatch(getDistrictHotelStart())
    axios.get('/horus/districtHotel'+filter)
      .then ( response => {
        dispatch(getDistrictHotelSuccess(response.data))
      })
      .catch(error => {
        dispatch(getDistrictHotelFail(error))
      })
  }
}

export const getDistrictHotelStart = () => {
  return {
    type: actionTypes.GET_DISTRICT_HOTEL_START,
  }
}

export const getDistrictHotelSuccess = (res) => {
  return {
    type: actionTypes.GET_DISTRICT_HOTEL_SUCCESS,
    res: res,
  }
}

export const getDistrictHotelFail = (error) => {
  return {
    type: actionTypes.GET_DISTRICT_HOTEL_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postDistrictHotel = (payload, districtId=null) => {
  return dispatch => {
    dispatch(postDistrictHotelStart())
    axios.post('/horus/districtHotel', payload)
      .then ( response => {
        dispatch(postDistrictHotelSuccess(response.data))
        dispatch(getDistrictHotel(districtId))
      })
      .catch(error => {
        dispatch(postDistrictHotelFail(error))
      })
  }
}

export const postDistrictHotelStart = () => {
  return {
    type: actionTypes.POST_DISTRICT_HOTEL_START,
  }
}

export const postDistrictHotelSuccess = (res) => {
  return {
    type: actionTypes.POST_DISTRICT_HOTEL_SUCCESS,
    res: res,
  }
}

export const postDistrictHotelFail = (error) => {
  return {
    type: actionTypes.POST_DISTRICT_HOTEL_FAIL,
    error: error,
  }
}


/*
 * PUT districtHotel
 */
export const putDistrictHotel = (id, payload, districtId=null) => {
  return dispatch => {
    dispatch(putDistrictHotelStart())
    axios.put('/horus/districtHotel/'+id, payload)
      .then ( response => {
        dispatch(putDistrictHotelSuccess(response.data))
        dispatch(getDistrictHotel(districtId))
        dispatch(showNotification('Changes saved successfully','success'))
      })
      .catch(error => {
        dispatch(showNotification('Saving changes failed','error'))
        dispatch(putDistrictHotelFail(error))
      })
  }
}

export const putDistrictHotelStart = () => {
  return {
    type: actionTypes.PUT_DISTRICT_HOTEL_START,
  }
}

export const putDistrictHotelSuccess = (res) => {
  return {
    type: actionTypes.PUT_DISTRICT_HOTEL_SUCCESS,
    res: res,
  }
}

export const putDistrictHotelFail = (error) => {
  return {
    type: actionTypes.PUT_DISTRICT_HOTEL_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteDistrictHotel = (id, districtId=null) => {
  return dispatch => {
    dispatch(deleteDistrictHotelStart())
    axios.delete('/horus/districtHotel/'+id)
      .then ( response => {
        dispatch(deleteDistrictHotelSuccess(response.data))
        dispatch(getDistrictHotel(districtId))
      })
      .catch(error => {
        dispatch(deleteDistrictHotelFail(error))
      })
  }
}

export const deleteDistrictHotelStart = () => {
  return {
    type: actionTypes.DELETE_DISTRICT_HOTEL_START,
  }
}

export const deleteDistrictHotelSuccess = (res) => {
  return {
    type: actionTypes.DELETE_DISTRICT_HOTEL_SUCCESS,
    res: res,
  }
}

export const deleteDistrictHotelFail = (error) => {
  return {
    type: actionTypes.DELETE_DISTRICT_HOTEL_FAIL,
    error: error,
  }
}

import React from 'react';
import * as actions from '../../store/actions';
import {Snackbar, Alert} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

const Notifications = () => {

  const dispatch = useDispatch()

  const snackbar = useSelector(state => state.mainWindow.snackbar)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(actions.hideNotification())
  }

  const CustomAlert = React.forwardRef(function CustomAlert(props, ref) {
    return <Alert elevation={6} ref={ref} variant="filled" {...props} />
  })

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}>
      <CustomAlert onClose={handleClose} severity={snackbar.variant} sx={{ width: '100%' }}>
        {snackbar.message}
      </CustomAlert>
    </Snackbar>
  )
}


export default Notifications

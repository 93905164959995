import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Link} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import LinkSettingsTable from '../tables/LinkSettingsTable'


const ViewLinkSettings = withRouter((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getLinkSettings())
  },[dispatch])

  const isLoading = useSelector(state => state.link.isLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Link fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Link Management</div>
        </div>
        <div>Create and track marketing links</div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <LinkSettingsTable /> }
        </Grid>
      </Grid>

    </div>
  )
})


export default ViewLinkSettings

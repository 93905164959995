import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Pageview} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import SearchAnalyticsTable from '../tables/SearchAnalyticsTable';


const ViewSearchAnalytics = withRouter((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getSearchAnalytics())
  },[dispatch])

  const isLoading = useSelector(state => state.link.isLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Pageview fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Search Analytics</div>
        </div>
        <div>Count searches via website, grouped by city and nights</div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <SearchAnalyticsTable /> }
        </Grid>
      </Grid>

    </div>
  )
})


export default ViewSearchAnalytics

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import './css/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import navigationReducer from './store/reducers/navigation'
import loginReducer from './store/reducers/login'
import mainWindowReducer from './store/reducers/mainWindow'
import qrReducer from './store/reducers/qr'
import linkReducer from './store/reducers/link'
import hotelReducer from './store/reducers/hotel'
import districtReducer from './store/reducers/district'
import roomReducer from './store/reducers/room'
import offerReducer from './store/reducers/offer'
import settingsReducer from './store/reducers/settings'
import analyticsReducer from './store/reducers/analytics'
import customerReducer from './store/reducers/customer'
import reservationReducer from './store/reducers/reservation'
import subscriptionReducer from './store/reducers/subscription'
import companyReducer from './store/reducers/company'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  navigation: navigationReducer,
  login: loginReducer,
  mainWindow: mainWindowReducer,
  qr: qrReducer,
  link: linkReducer,
  hotel: hotelReducer,
  district: districtReducer,
  room: roomReducer,
  offer: offerReducer,
  settings: settingsReducer,
  analytics: analyticsReducer,
  customer: customerReducer,
  reservation: reservationReducer,
  subscription: subscriptionReducer,
  company: companyReducer,
})

const history = createBrowserHistory()

const store = createStore(rootReducer(history), composeEnhancers(
  applyMiddleware(
    routerMiddleware(history),
    thunk
  )
))

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App history={history}/>
    </ConnectedRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";

/*
 * GET all specialDates
 */
export const getSpecialDate = () => {
  return dispatch => {
    dispatch(getSpecialDateStart())
    axios.get('/horus/specialDate')
      .then ( response => {
        dispatch(getSpecialDateSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSpecialDateFail(error))
      })
  }
}

export const getSpecialDateStart = () => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_START,
  }
}

export const getSpecialDateSuccess = (res) => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_SUCCESS,
    res: res,
  }
}

export const getSpecialDateFail = (error) => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_FAIL,
    error: error,
  }
}


/*
 * GET specialDate by hotel
 */
export const getSpecialDateByHotel = (id) => {
  return dispatch => {
    dispatch(getSpecialDateByHotelStart())
    axios.get('/horus/specialDate/byHotelId/'+id)
      .then ( response => {
        dispatch(getSpecialDateByHotelSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSpecialDateByHotelFail(error))
      })
  }
}

export const getSpecialDateByHotelStart = () => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_BY_HOTEL_START,
  }
}

export const getSpecialDateByHotelSuccess = (res) => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_BY_HOTEL_SUCCESS,
    res: res,
  }
}

export const getSpecialDateByHotelFail = (error) => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_BY_HOTEL_FAIL,
    error: error,
  }
}


/*
 * GET specialDate by room
 */
export const getSpecialDateByRoom = (id) => {
  return dispatch => {
    dispatch(getSpecialDateByRoomStart())
    axios.get('/horus/specialDate/byRoomId/'+id)
      .then ( response => {
        dispatch(getSpecialDateByRoomSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSpecialDateByRoomFail(error))
      })
  }
}

export const getSpecialDateByRoomStart = () => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_BY_ROOM_START,
  }
}

export const getSpecialDateByRoomSuccess = (res) => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_BY_ROOM_SUCCESS,
    res: res,
  }
}

export const getSpecialDateByRoomFail = (error) => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_BY_ROOM_FAIL,
    error: error,
  }
}


/*
 * POST specialDate
 */
export const postSpecialDate = (payload) => {
  return dispatch => {
    dispatch(postSpecialDateStart())
    axios.post('/horus/specialDate', payload)
      .then ( response => {
        dispatch(postSpecialDateSuccess(response.data))
        dispatch(getSpecialDateByHotel(payload.hotelId))
      })
      .catch(error => {
        dispatch(postSpecialDateFail(error))
      })
  }
}

export const postSpecialDateStart = () => {
  return {
    type: actionTypes.POST_SPECIAL_DATE_START,
  }
}

export const postSpecialDateSuccess = (res) => {
  return {
    type: actionTypes.POST_SPECIAL_DATE_SUCCESS,
    res: res,
  }
}

export const postSpecialDateFail = (error) => {
  return {
    type: actionTypes.POST_SPECIAL_DATE_FAIL,
    error: error,
  }
}


/*
 * PUT specialDate
 */
export const putSpecialDate = (id, payload) => {
  return dispatch => {
    dispatch(putSpecialDateStart())
    axios.put('/horus/specialDate/'+id, payload)
      .then ( response => {
        dispatch(putSpecialDateSuccess(response.data))
        dispatch(getSpecialDateByHotel(payload.hotelId))
      })
      .catch(error => {
        dispatch(putSpecialDateFail(error))
      })
  }
}

export const putSpecialDateStart = () => {
  return {
    type: actionTypes.PUT_SPECIAL_DATE_START,
  }
}

export const putSpecialDateSuccess = (res) => {
  return {
    type: actionTypes.PUT_SPECIAL_DATE_SUCCESS,
    res: res,
  }
}

export const putSpecialDateFail = (error) => {
  return {
    type: actionTypes.PUT_SPECIAL_DATE_FAIL,
    error: error,
  }
}



/*
 * PUT surcharge
 */
export const putSpecialDateSurcharge = (payload) => {
  return dispatch => {
    dispatch(putSpecialDateSurchargeStart())
    axios.put('/horus/specialDate/surcharge/', payload)
      .then ( response => {
        dispatch(putSpecialDateSurchargeSuccess(response.data))
        dispatch(getSpecialDateByRoom(payload.roomId))
      })
      .catch(error => {
        dispatch(putSpecialDateSurchargeFail(error))
      })
  }
}

export const putSpecialDateSurchargeStart = () => {
  return {
    type: actionTypes.PUT_SPECIAL_DATE_SURCHARGE_START,
  }
}

export const putSpecialDateSurchargeSuccess = (res) => {
  return {
    type: actionTypes.PUT_SPECIAL_DATE_SURCHARGE_SUCCESS,
    res: res,
  }
}

export const putSpecialDateSurchargeFail = (error) => {
  return {
    type: actionTypes.PUT_SPECIAL_DATE_SURCHARGE_FAIL,
    error: error,
  }
}


/*
 * DELETE specialDate
 */
export const deleteSpecialDate = (id, hotelId) => {
  return dispatch => {
    dispatch(deleteSpecialDateStart())
    axios.delete('/horus/specialDate/byId/'+id)
      .then ( response => {
        dispatch(deleteSpecialDateSuccess(response.data))
        dispatch(getSpecialDateByHotel(hotelId))
      })
      .catch(error => {
        dispatch(deleteSpecialDateFail(error))
      })
  }
}

export const deleteSpecialDateStart = () => {
  return {
    type: actionTypes.DELETE_SPECIAL_DATE_START,
  }
}

export const deleteSpecialDateSuccess = (res) => {
  return {
    type: actionTypes.DELETE_SPECIAL_DATE_SUCCESS,
    res: res,
  }
}

export const deleteSpecialDateFail = (error) => {
  return {
    type: actionTypes.DELETE_SPECIAL_DATE_FAIL,
    error: error,
  }
}


/*
 * DELETE surcharge
 */
export const deleteSpecialDateSurcharge = (id, hotelId) => {
  return dispatch => {
    dispatch(deleteSpecialDateSurchargeStart())
    axios.delete('/horus/specialDate/surcharge/'+id)
      .then ( response => {
        dispatch(deleteSpecialDateSurchargeSuccess(response.data))
        dispatch(getSpecialDateByHotel(hotelId))
      })
      .catch(error => {
        dispatch(deleteSpecialDateSurchargeFail(error))
      })
  }
}

export const deleteSpecialDateSurchargeStart = () => {
  return {
    type: actionTypes.DELETE_SPECIAL_DATE_SURCHARGE_START,
  }
}

export const deleteSpecialDateSurchargeSuccess = (res) => {
  return {
    type: actionTypes.DELETE_SPECIAL_DATE_SURCHARGE_SUCCESS,
    res: res,
  }
}

export const deleteSpecialDateSurchargeFail = (error) => {
  return {
    type: actionTypes.DELETE_SPECIAL_DATE_SURCHARGE_FAIL,
    error: error,
  }
}



/*
 * GET specialDates by city, grouped by hotel, optimized for VisTimeline
 */
export const getSpecialDateByCityId = (cityId) => {
  return dispatch => {
    dispatch(getSpecialDateByCityIdStart())
    axios.get('/horus/specialDate/byCity/'+cityId+'/?active=1&hotelOfferType=standard|district')
      .then ( response => {
        dispatch(getSpecialDateByCityIdSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSpecialDateByCityIdFail(error))
        dispatch(showNotification('Loading special dates failed','error'))
      })
  }
}

export const getSpecialDateByCityIdStart = () => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_BY_CITY_START,
  }
}

export const getSpecialDateByCityIdSuccess = (res) => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_BY_CITY_SUCCESS,
    res: res,
  }
}

export const getSpecialDateByCityIdFail = (error) => {
  return {
    type: actionTypes.GET_SPECIAL_DATE_BY_CITY_FAIL,
    error: error,
  }
}


/*
 * POST multiple specialDates
 */
export const postMultipleSpecialDates = (payload, cityId) => {
  return dispatch => {
    dispatch(postMultipleSpecialDatesStart())
    axios.post('/horus/specialDate/multiple/', payload)
      .then ( response => {
        dispatch(postMultipleSpecialDatesSuccess(response.data))
        dispatch(getSpecialDateByCityId(cityId))
      })
      .catch(error => {
        dispatch(postMultipleSpecialDatesFail(error))
      })
  }
}

export const postMultipleSpecialDatesStart = () => {
  return {
    type: actionTypes.POST_MULTIPLE_SPECIAL_DATES_START,
  }
}

export const postMultipleSpecialDatesSuccess = (res) => {
  return {
    type: actionTypes.POST_MULTIPLE_SPECIAL_DATES_SUCCESS,
    res: res,
  }
}

export const postMultipleSpecialDatesFail = (error) => {
  return {
    type: actionTypes.POST_MULTIPLE_SPECIAL_DATES_FAIL,
    error: error,
  }
}


/*
 * DELETE multiple specialDates
 */
export const deleteMultipleSpecialDates = (payload, cityId) => {
  return dispatch => {
    dispatch(deleteMultipleSpecialDatesStart())
    axios.delete('/horus/specialDate/multiple/', {data: payload}) // special payload config on delete
      .then ( response => {
        dispatch(deleteMultipleSpecialDatesSuccess(response.data))
        dispatch(getSpecialDateByCityId(cityId))
      })
      .catch(error => {
        dispatch(deleteMultipleSpecialDatesFail(error))
      })
  }
}

export const deleteMultipleSpecialDatesStart = () => {
  return {
    type: actionTypes.DELETE_MULTIPLE_SPECIAL_DATES_START,
  }
}

export const deleteMultipleSpecialDatesSuccess = (res) => {
  return {
    type: actionTypes.DELETE_MULTIPLE_SPECIAL_DATES_SUCCESS,
    res: res,
  }
}

export const deleteMultipleSpecialDatesFail = (error) => {
  return {
    type: actionTypes.DELETE_MULTIPLE_SPECIAL_DATES_FAIL,
    error: error,
  }
}
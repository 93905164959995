import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";

/*
 * GET all business user invitations by company id
 */
export const getBusinessUserInvitationByCompanyId = (companyId) => {
  return dispatch => {
    dispatch(getBusinessUserInvitationByCompanyIdStart())
    axios.get('/horus/businessUserInvitation/byCompanyId/'+companyId)
      .then ( response => {
        dispatch(getBusinessUserInvitationByCompanyIdSuccess(response.data))
      })
      .catch(error => {
        dispatch(getBusinessUserInvitationByCompanyIdFail(error))
      })
  }
}

export const getBusinessUserInvitationByCompanyIdStart = () => {
  return {
    type: actionTypes.GET_BUSINESS_USER_INVITATION_BY_COMPANY_START,
  }
}

export const getBusinessUserInvitationByCompanyIdSuccess = (res) => {
  return {
    type: actionTypes.GET_BUSINESS_USER_INVITATION_BY_COMPANY_SUCCESS,
    res: res,
  }
}

export const getBusinessUserInvitationByCompanyIdFail = (error) => {
  return {
    type: actionTypes.GET_BUSINESS_USER_INVITATION_BY_COMPANY_FAIL,
    error: error,
  }
}


/*
 * POST create business user invitation
 */
export const postBusinessUserInvitation = (payload) => {
  return dispatch => {
    dispatch(postBusinessUserInvitationStart())
    axios.post('/horus/businessUserInvitation/user/', payload)
      .then ( response => {
        dispatch(postBusinessUserInvitationSuccess(response.data))
        dispatch(showNotification('Business user created successfully','success'))
        dispatch(getBusinessUserInvitationByCompanyId(payload.BusinessCompanyId))
      })
      .catch(error => {
        const message = error?.response?.data?.message || 'Creating business user failed'
        dispatch(postBusinessUserInvitationFail(error))
        dispatch(showNotification(message,'error'))
      })
  }
}

export const postBusinessUserInvitationStart = () => {
  return {
    type: actionTypes.POST_BUSINESS_USER_INVITATION_START,
  }
}

export const postBusinessUserInvitationSuccess = (res) => {
  return {
    type: actionTypes.POST_BUSINESS_USER_INVITATION_SUCCESS,
    res: res,
  }
}

export const postBusinessUserInvitationFail = (error) => {
  return {
    type: actionTypes.POST_BUSINESS_USER_INVITATION_FAIL,
    error: error,
  }
}


/*
 * PUT update business user invitation
 */
export const putBusinessUserInvitation = (id, payload) => {
  return dispatch => {
    dispatch(putBusinessUserInvitationStart())
    axios.put('/horus/businessUserInvitation/user/'+id, payload)
      .then ( response => {
        dispatch(putBusinessUserInvitationSuccess(response.data))
        dispatch(showNotification('Changes saved successfully','success'))
        dispatch(getBusinessUserInvitationByCompanyId(payload.BusinessCompanyId))
      })
      .catch(error => {
        dispatch(showNotification('Saving changes failed','error'))
        dispatch(putBusinessUserInvitationFail(error))
      })
  }
}

export const putBusinessUserInvitationStart = () => {
  return {
    type: actionTypes.PUT_BUSINESS_USER_INVITATION_START,
  }
}

export const putBusinessUserInvitationSuccess = (res) => {
  return {
    type: actionTypes.PUT_BUSINESS_USER_INVITATION_SUCCESS,
    res: res,
  }
}

export const putBusinessUserInvitationFail = (error) => {
  return {
    type: actionTypes.PUT_BUSINESS_USER_INVITATION_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteBusinessUserInvitation = (invitationId, companyId) => {
  return dispatch => {
    dispatch(deleteBusinessUserInvitationStart())
    axios.delete('/horus/businessUserInvitation/user/'+invitationId)
      .then ( response => {
        dispatch(deleteBusinessUserInvitationSuccess(response.data))
        dispatch(showNotification('Business user deleted successfully','success'))
        dispatch(getBusinessUserInvitationByCompanyId(companyId))
      })
      .catch(error => {
        dispatch(showNotification('Deleting business user failed','error'))
        dispatch(deleteBusinessUserInvitationFail(error))
      })
  }
}

export const deleteBusinessUserInvitationStart = () => {
  return {
    type: actionTypes.DELETE_BUSINESS_USER_INVITATION_START,
  }
}

export const deleteBusinessUserInvitationSuccess = (res) => {
  return {
    type: actionTypes.DELETE_BUSINESS_USER_INVITATION_SUCCESS,
    res: res,
  }
}

export const deleteBusinessUserInvitationFail = (error) => {
  return {
    type: actionTypes.DELETE_BUSINESS_USER_INVITATION_FAIL,
    error: error,
  }
}



import * as actionTypes from '../actions/actionTypes'

const initialState = {
  companies: [],
  companiesIsLoading: false,
  businessUserInvitationsByCompany: [],
  businessUserInvitationsByCompanyIsLoading: false,
}

const setCompanies = (state, action) => {
  return {
    ...state,
    companies: action.res,
    companiesIsLoading: false,
  }
}

const setCompaniesLoading = (state) => {
  return {
    ...state,
    companiesIsLoading: true,
  }
}

const setBusinessUserInvitationsByCompany = (state, action) => {
  return {
    ...state,
    businessUserInvitationsByCompany: action.res,
    businessUserInvitationsByCompanyIsLoading: false,
  }
}

const setBusinessUserInvitationsByCompanyLoading = (state) => {
  return {
    ...state,
    businessUserInvitationsByCompany: [],
    businessUserInvitationsByCompanyIsLoading: true,
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_COMPANY_START: return setCompaniesLoading(state)
    case actionTypes.GET_COMPANY_SUCCESS: return setCompanies(state, action)
    case actionTypes.GET_BUSINESS_USER_INVITATION_BY_COMPANY_START: return setBusinessUserInvitationsByCompanyLoading(state)
    case actionTypes.GET_BUSINESS_USER_INVITATION_BY_COMPANY_SUCCESS: return setBusinessUserInvitationsByCompany(state, action)
    default: return state
  }
}

export default reducer

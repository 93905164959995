import {Grid} from "@mui/material";
import React from "react";
import {HotelDetailsFormDistrictEditor} from "./HotelDetailsFormDistrictEditor";
import DistrictHotelsTable from "../tables/DistrictHotelsTable";

const DistrictDetailsForm = ({district}) => {

  return(
    <div>

      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={4}>
          {district ? <HotelDetailsFormDistrictEditor district={district} /> : ''}
        </Grid>

        <Grid item xs={12} md={12} lg={8}>
          <DistrictHotelsTable district={district} />
        </Grid>

      </Grid>

    </div>
  )
}

export default DistrictDetailsForm
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  newsletter: [],
  searchAnalytics: [],
  priceAnalytics: [],
}

const startLoading = (state, action) => {
  return {
    ...state,
    isLoading: true,
  }
}

const setNewsletter = (state, action) => {
  return {
    ...state,
    newsletter: action.res,
  }
}

const setSearchAnalytics = (state, action) => {
  return {
    ...state,
    searchAnalytics: action.res,
  }
}

const setPriceAnalytics = (state, action) => {
  return {
    ...state,
    isLoading: false,
    priceAnalytics: action.res,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_NEWSLETTER_SUCCESS: return setNewsletter(state, action)
    case actionTypes.GET_SEARCH_ANALYTICS_SUCCESS: return setSearchAnalytics(state, action)
    case actionTypes.GET_PRICE_ANALYTICS_START: return startLoading(state, action)
    case actionTypes.GET_PRICE_ANALYTICS_SUCCESS: return setPriceAnalytics(state, action)
    default: return state
  }
}

export default reducer

import {Autocomplete, TextField} from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {updateCurrentUrlParams} from "../../services/url";

const CitySelector = withRouter((props) => {

  const {redirectQueryParam, selectedCityId, addAllOption} = props
  const cityList = useSelector(state => state.settings.city)
    .sort((a, b) => a.cityName > b.cityName ? 1 : 0)
  const selectedCityObject = cityList?.filter(city => city.cityId === selectedCityId)?.[0]

  const cityListWithAll = [
    {
      id: 0,
      cityName: 'All Cities',
      cityId: 'all'
    },
    ...cityList
  ]

  return(
    <div style={{width: 160}}>
      <Autocomplete
        id="cities"
        options={addAllOption ? cityListWithAll : cityList}
        //disableCloseOnSelect
        disableClearable={true}
        getOptionLabel={(option) => option.cityName || 'All Cities'}
        value={selectedCityObject || ''}
        autoHighlight={true} // required to select entry without submitting entire material-table row
        //onKeyDown={e => handleKey(e)}
        onChange={(e, value) => {
          if(value?.cityId === 'all') {
            updateCurrentUrlParams(props.history, {[redirectQueryParam]: ''})
          } else {
            updateCurrentUrlParams(props.history, {[redirectQueryParam]: value?.cityId})
          }
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <div style={{fontSize: 13}}>{option.cityName || '---'}</div>
          </li>
        )}
        size="small"
        renderInput={(params) => {
          params.InputProps.style = {fontSize: 13}
          return(
            <TextField
              {...params}
              placeholder=""
            />
          )
        }}
      />
    </div>
  )
})

export default CitySelector
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoadingSubscription: false,
  isLoadingEvaluation: false,
  subscriptions: [],
  evaluation: {},
  stripe: {},
  lexoffice: {},
  lexofficeLoading: false,
  subscriptionMonthsStart: [],
  subscriptionMonthsEnd: [],
}

const startLoadingSubscription = (state) => {
  return {
    ...state,
    isLoadingSubscription: true,
  }
}
const startLoadingEvaluation = (state) => {
  return {
    ...state,
    isLoadingEvaluation: true,
  }
}

const setSubscriptions = (state, action) => {
  return {
    ...state,
    subscriptions: action.res,
    isLoadingSubscription: false,
  }
}

const setSubscriptionEvaluation = (state, action) => {
  return {
    ...state,
    evaluation: action.res || {},
    isLoadingEvaluation: false,
  }
}

const setSubscriptionMonths = (state, action) => {
  if(action.target === 'start')
    return {
      ...state,
      subscriptionMonthsStart: [
        ...action.res
      ],
    }
  if(action.target === 'end')
    return {
      ...state,
      subscriptionMonthsEnd: [
        ...action.res
      ],
    }
}

const setSubscriptionStripe = (state, action) => {
  return {
    ...state,
    stripe: action?.res || {},
  }
}

const setSubscriptionLexofficeLoading = (state, action) => {
  return {
    ...state,
    lexoffice: {},
    lexofficeLoading: true,
  }
}

const setSubscriptionLexoffice = (state, action) => {
  return {
    ...state,
    lexoffice: action?.res || {},
    lexofficeLoading: false,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_SUBSCRIPTION_START: return startLoadingSubscription(state)
    case actionTypes.GET_SUBSCRIPTION_SUCCESS: return setSubscriptions(state, action)
    case actionTypes.GET_SUBSCRIPTION_EVALUATION_START: return startLoadingEvaluation(state, action)
    case actionTypes.GET_SUBSCRIPTION_EVALUATION_SUCCESS: return setSubscriptionEvaluation(state, action)
    case actionTypes.GET_SUBSCRIPTION_MONTHS_SUCCESS: return setSubscriptionMonths(state, action)
    case actionTypes.GET_SUBSCRIPTION_STRIPE_START: return setSubscriptionStripe(state, {})
    case actionTypes.GET_SUBSCRIPTION_STRIPE_SUCCESS: return setSubscriptionStripe(state, action)
    case actionTypes.GET_SUBSCRIPTION_LEXOFFICE_START: return setSubscriptionLexofficeLoading(state, action)
    case actionTypes.GET_SUBSCRIPTION_LEXOFFICE_SUCCESS: return setSubscriptionLexoffice(state, action)
    default: return state
  }
}

export default reducer

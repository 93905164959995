import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Payments} from '@mui/icons-material';
import {useDispatch} from 'react-redux';
import {LexofficeNavigation} from "../components/LexofficeNavigation";
import LexofficeInvoiceTable from "../tables/LexofficeInvoiceTable";

const ViewLexofficeInvoice = withRouter((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
  },[dispatch])

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Payments fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Lexoffice</div>
        </div>
        <div></div>
      </div>

      <LexofficeNavigation />

      <LexofficeInvoiceTable />
    </div>
  )
})


export default ViewLexofficeInvoice

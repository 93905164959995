import axios from 'axios';
import versionJson from './versions.json';

const instance = axios.create({
  baseURL: '/api',
  headers: {
    "x-horus-client" : versionJson.version
  }
})

instance.interceptors.response.use((response) => {
  if (response.headers['x-horus-upgrade'] === 'true') {
    console.log('Upgrade!')
    //dispatch(actions.showUpgradeModal())
    if (window.confirm('New Horus Version available')) {
      // confirmed
      window.location.reload()
    } else {
      // Do nothing!
      window.location.reload()
    }
  }
  return response
}, (error) => {
  return Promise.reject(error)
})

export default instance;

import React from "react";
import {useSelector} from "react-redux";
import {formatDate, formatStripeAmountToEur} from "../../services/unitFormater";
import {fromUnixTime} from "date-fns";


export const SubscriptionStripe = () => {

  const stripeResponse = useSelector(state => state.subscription.stripe)

  if (stripeResponse?.status === 'ok') {
    const subscription = stripeResponse?.subscription
    return (
      <>
        <div style={{fontSize: 14, textAlign: "left", padding: 5,}} className="cleanTable">

          <div style={{fontWeight: 'bold', color: '#31597a', marginBottom: 5}}>Subscription</div>
          <table style={{borderColor: '#CCC'}}>
            <thead>
            <tr style={{color: '#575757'}}>
              <th>Start Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Billing Cycle Anchor</th>
              <th>Current Period Start</th>
              <th>Current Period End</th>
              <th>ID</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{subscription?.start_date ? formatDate(fromUnixTime(subscription.start_date)) : ''}</td>
              <td>{subscription?.plan?.amount ? (subscription.plan.amount / 100).toFixed(2).replace('.', ',') + ' €' : ''}</td>
              <td>{subscription?.status}</td>
              <td>{subscription?.billing_cycle_anchor ? formatDate(fromUnixTime(subscription.billing_cycle_anchor)) : ''}</td>
              <td>{subscription?.current_period_start ? formatDate(fromUnixTime(subscription.current_period_start)) : ''}</td>
              <td>{subscription?.current_period_end ? formatDate(fromUnixTime(subscription.current_period_end)) : ''}</td>
              <td><a href={'https://dashboard.stripe.com/subscriptions/'+subscription?.id} target="_blank" rel="noreferrer">{subscription?.id}</a></td>
            </tr>
            </tbody>
          </table>

          <div style={{fontWeight: 'bold', color: '#31597a', marginTop: 15, marginBottom: 5}}>Last 10 payments</div>
          <table>
            <thead>
            <tr style={{color: '#575757'}}>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Description</th>
              <th>ID</th>
              <th>Charges</th>
            </tr>
            </thead>
            <tbody>
            {stripeResponse?.paymentIntents?.data?.map(x =>
              <tr key={x.id}>
                <td>{formatDate(fromUnixTime(x.created))}</td>
                <td>{formatStripeAmountToEur(x.amount)}</td>
                <td>{x.status}</td>
                <td>{x.description}</td>
                <td><a href={'https://dashboard.stripe.com/payments/'+x?.id} target="_blank" rel="noreferrer">{x?.id}</a></td>
                <td>{x?.charges?.data?.map(ch => <div key={ch.id}>
                    <div>{formatDate(fromUnixTime(ch.created))}</div>
                    <div>Captured {formatStripeAmountToEur(ch.amount_captured)} | Refunded {formatStripeAmountToEur(ch.amount_refunded)}</div>
                </div>
                )}</td>
              </tr>)}
            </tbody>
          </table>
        </div>
        <br/><br/>
      </>
    )
  } else if (stripeResponse?.status === 'nok') {
    return (<>
      <div style={{fontSize: 14, textAlign: "left", padding: 5,}}>No Stripe data available</div>
      <br/><br/>
    </>)
  } else {
    return (<>
      <div style={{fontSize: 14, textAlign: "left", padding: 5,}}>...loading</div>
      <br/><br/>
    </>)
  }

}
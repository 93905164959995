import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET
 */
export const getOfferDiscount = () => {
  return dispatch => {
    dispatch(getOfferDiscountStart())
    axios.get('/horus/offerDiscount')
      .then ( response => {
        dispatch(getOfferDiscountSuccess(response.data))
      })
      .catch(error => {
        dispatch(getOfferDiscountFail(error))
      })
  }
}

export const getOfferDiscountStart = () => {
  return {
    type: actionTypes.GET_OFFER_DISCOUNT_START,
  }
}

export const getOfferDiscountSuccess = (res) => {
  return {
    type: actionTypes.GET_OFFER_DISCOUNT_SUCCESS,
    res: res,
  }
}

export const getOfferDiscountFail = (error) => {
  return {
    type: actionTypes.GET_OFFER_DISCOUNT_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postOfferDiscount = (payload) => {
  return dispatch => {
    dispatch(postOfferDiscountStart())
    axios.post('/horus/offerDiscount', payload)
      .then ( response => {
        dispatch(postOfferDiscountSuccess(response.data))
        dispatch(getOfferDiscount())
      })
      .catch(error => {
        dispatch(postOfferDiscountFail(error))
      })
  }
}

export const postOfferDiscountStart = () => {
  return {
    type: actionTypes.POST_OFFER_DISCOUNT_START,
  }
}

export const postOfferDiscountSuccess = (res) => {
  return {
    type: actionTypes.POST_OFFER_DISCOUNT_SUCCESS,
    res: res,
  }
}

export const postOfferDiscountFail = (error) => {
  return {
    type: actionTypes.POST_OFFER_DISCOUNT_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putOfferDiscount = (id, payload) => {
  return dispatch => {
    dispatch(putOfferDiscountStart())
    axios.put('/horus/offerDiscount/'+id, payload)
      .then ( response => {
        dispatch(putOfferDiscountSuccess(response.data))
        dispatch(getOfferDiscount())
      })
      .catch(error => {
        dispatch(putOfferDiscountFail(error))
      })
  }
}

export const putOfferDiscountStart = () => {
  return {
    type: actionTypes.PUT_OFFER_DISCOUNT_START,
  }
}

export const putOfferDiscountSuccess = (res) => {
  return {
    type: actionTypes.PUT_OFFER_DISCOUNT_SUCCESS,
    res: res,
  }
}

export const putOfferDiscountFail = (error) => {
  return {
    type: actionTypes.PUT_OFFER_DISCOUNT_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteOfferDiscount = (id) => {
  return dispatch => {
    dispatch(deleteOfferDiscountStart())
    axios.delete('/horus/offerDiscount/'+id)
      .then ( response => {
        dispatch(deleteOfferDiscountSuccess(response.data))
        dispatch(getOfferDiscount())
      })
      .catch(error => {
        dispatch(deleteOfferDiscountFail(error))
      })
  }
}

export const deleteOfferDiscountStart = () => {
  return {
    type: actionTypes.DELETE_OFFER_DISCOUNT_START,
  }
}

export const deleteOfferDiscountSuccess = (res) => {
  return {
    type: actionTypes.DELETE_OFFER_DISCOUNT_SUCCESS,
    res: res,
  }
}

export const deleteOfferDiscountFail = (error) => {
  return {
    type: actionTypes.DELETE_OFFER_DISCOUNT_FAIL,
    error: error,
  }
}

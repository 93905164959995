import {useDispatch} from "react-redux";
import React, {useState} from "react";
import * as actions from "../../store/actions";
import {Button, Paper, TextField, Typography} from "@mui/material";

export const HotelDetailsFormAddress = (props) => {

  const dispatch = useDispatch()
  const {hotel} = props

  const [addressZip, setAddressZip] = useState(hotel.addressZip || '')
  const [addressCity, setAddressCity] = useState(hotel.addressCity || '')
  const [addressDistrict, setAddressDistrict] = useState(hotel.addressDistrict || '')
  const [addressStreet, setAddressStreet] = useState(hotel.addressStreet || '')
  const [contactPhone, setContactPhone] = useState(hotel.contactPhone || '')
  const [contactEMail, setContactEMail] = useState(hotel.contactEMail || '')
  const [bookingTeamEMailNew, setBookingTeamEMailNew] = useState(hotel.bookingTeamEMailNew || '')
  const [bookingTeamEMailChange, setBookingTeamEMailChange] = useState(hotel.bookingTeamEMailChange || '')
  const [bookingTeamEMailCancellation, setBookingTeamEMailCancellation] = useState(hotel.bookingTeamEMailCancellation || '')

  const setCheckInAndOut = () => {
    const payload = {
      addressZip: addressZip,
      addressCity: addressCity,
      addressDistrict: addressDistrict,
      addressStreet: addressStreet,
      contactPhone: contactPhone,
      contactEMail: contactEMail,
      bookingTeamEMailNew: bookingTeamEMailNew,
      bookingTeamEMailChange: bookingTeamEMailChange,
      bookingTeamEMailCancellation: bookingTeamEMailCancellation,
    }
    dispatch(actions.putHotel(hotel.id, payload))
  }

  return(
    <Paper style={{ padding: 6, paddingLeft: 16, marginTop: 12, marginBottom: 8 }}>
      <Typography variant="h6" style={{ paddingTop: 8, paddingBottom: 8 }}>
        Address & Contact
      </Typography>
      <div className="inputGrid">
        <TextField
          id="addressZip"
          variant="outlined"
          label="ZIP"
          placeholder=""
          size="small"
          value={addressZip}
          onChange={(e) => setAddressZip(e.target.value)}
        />

        <TextField
          id="addressCity"
          variant="outlined"
          label="City"
          placeholder=""
          size="small"
          value={addressCity}
          onChange={(e) => setAddressCity(e.target.value)}
        />

        <TextField
          id="addressStreet"
          variant="outlined"
          label="Street"
          placeholder=""
          size="small"
          value={addressStreet}
          onChange={(e) => setAddressStreet(e.target.value)}
        />
      </div>

      <div className="inputGrid">
        <TextField
          id="addressDistrict"
          variant="outlined"
          label="District"
          placeholder=""
          size="small"
          value={addressDistrict}
          onChange={(e) => setAddressDistrict(e.target.value)}
        />
      </div>

      <div className="inputGrid">
        <TextField
          id="contactPhone"
          variant="outlined"
          label="Phone"
          placeholder=""
          size="small"
          value={contactPhone}
          onChange={(e) => setContactPhone(e.target.value)}
        />

        <TextField
          id="contactEMail"
          variant="outlined"
          label="E-Mail (Contact)"
          placeholder=""
          size="small"
          value={contactEMail}
          onChange={(e) => setContactEMail(e.target.value)}
        />

      </div>

      <div className="inputGrid">
        <TextField
          id="contactPhone"
          variant="outlined"
          label="E-Mail (Booking - New)"
          placeholder=""
          size="small"
          value={bookingTeamEMailNew}
          onChange={(e) => setBookingTeamEMailNew(e.target.value)}
        />

        <TextField
          id="bookingTeamEMailChange"
          variant="outlined"
          label="E-Mail (Booking - Change)"
          placeholder=""
          size="small"
          value={bookingTeamEMailChange}
          onChange={(e) => setBookingTeamEMailChange(e.target.value)}
        />

        <TextField
          id="bookingTeamEMailCancellation"
          variant="outlined"
          label="E-Mail (Booking - Cancellation)"
          placeholder=""
          size="small"
          value={bookingTeamEMailCancellation}
          onChange={(e) => setBookingTeamEMailCancellation(e.target.value)}
        />
      </div>

      <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'right', gap:8, width: '95%', marginBottom: 6}}>
        <Button onClick={setCheckInAndOut} variant="outlined" color="secondary">
          Save
        </Button>
      </div>

    </Paper>
  )
}
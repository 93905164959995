import React from "react";
import {useSelector} from "react-redux";
import {formatCurrency, formatDate} from "../../services/unitFormater";


export const SubscriptionLexoffice = () => {

  const lexofficeResponse = useSelector(state => state.subscription.lexoffice)
  const lexofficeLoading = useSelector(state => state.subscription.lexofficeLoading)
  const invoiceList = lexofficeResponse?.invoiceList || []

  if (invoiceList.length > 0) {
    return (
      <>
        <div style={{fontSize: 14, textAlign: "left", padding: 5,}} className="cleanTable">

          <table>
            <thead>
              <tr style={{color: '#575757'}}>
                <th>Date</th>
                <th>Amount</th>
                <th>Month</th>
                <th>Type</th>
                <th>Status</th>
                <th>Lex Invoice</th>
                <th>PDF</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>
            {invoiceList.map(x =>
              <tr key={x.voucherId} style={x.relatedVoucherType !== '' ? {color: '#838383'} : {}}>
                <td>{formatDate(x.voucherDate)}</td>
                <td style={{width: 69}}>{formatCurrency(x.totalAmount)}</td>
                <td>
                  {x.shippingDate ? formatDate(x.shippingDate) : ''}
                  {x.shippingEndDate ? <>- {formatDate(x.shippingEndDate)}</> : ''}
                </td>
                <td>
                  { (x.relatedVoucherType === '')
                    ? <div>{x.voucherType}</div>
                    : <div style={{fontSize: 11}}>{x.voucherType}<br/>({x.relatedVoucherNumber})</div>
                  }
                </td>
                <td>{x.voucherStatus}</td>
                <td><a href={'https://app.lexoffice.de/vouchers#!/VoucherView/Invoice/'+x.voucherId} target="_blank" rel="noreferrer">{x.voucherNumber}</a></td>
                <td><a href={'/api/horus/lexoffice/invoicePdf/'+x.invoiceFileId} target="_blank" rel="noreferrer">PDF</a></td>
                <td style={{fontSize: 11}}> {x.lineItem}</td>
              </tr>)}
            </tbody>
          </table>
        </div>
        <br/><br/>
      </>
    )
  } else if (lexofficeLoading) {
    return (<>
      <div style={{fontSize: 14, textAlign: "left", padding: 5,}}>...loading</div>
      <br/><br/>
    </>)
  } else if (invoiceList.length === 0) {
    return (<>
      <div style={{fontSize: 14, textAlign: "left", padding: 5,}}>No Lexoffice data available</div>
      <br/><br/>
    </>)
  }

}
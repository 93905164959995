import React from 'react'
import {useDispatch} from 'react-redux';
import {Box, IconButton, styled} from '@mui/material'
import * as actions from '../../store/actions'
import {Menu} from '@mui/icons-material';
import logoImg from '../../assets/horus_logo_05.png'

const Header = () => {

  const dispatch = useDispatch()

  const handleDrawerOpen = () => {
    dispatch(actions.openDrawer())
  }

  const IconButtonBlue = styled(IconButton)({
    color: '#086381'
  })

  const HamburgerButton = () => {
    return(
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <IconButtonBlue
          edge="start"
          color="inherit"
          onClick={handleDrawerOpen}
          aria-label="menu">
          <Menu/>
        </IconButtonBlue>
      </Box>
    )
  }

  return (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
      <div style={{display: 'flex', justifyContent: 'space-between', backgroundColor: window.location.hostname === 'horus.myflexhome.de' ? '#d5dfe7' : '#efddd2'}}>
        <img src={logoImg} alt="MyFlexHome Horus" style={{width: 160, objectFit: 'contain'}}/>
        <HamburgerButton/>
      </div>
    </Box>
  )
}

export default Header

import {Button} from "@mui/material";
import * as actions from "../../store/actions";
import React from "react";
import {useDispatch, useSelector} from "react-redux";


export const SubscriptionSegmentActions = () => {

  const dispatch = useDispatch()

  const evaluation = useSelector(state => state.subscription.evaluation)
  const subscriptionID = evaluation?.subscriptionData?.subscriptionID
  const selectedSubscription = evaluation?.subscriptionData || {}
  const segments = selectedSubscription?.BookingSubscriptionSegments || []

  const deleteLastSegment = () => {
    /* Prevent deletion of last remaining segment in list.
     * This is the initial segment selected by customer which provides hotel and room selection for whole subscription
     */
    const lastSegment = segments[segments.length - 1]
    const secondLastSegment = segments[segments.length - 2]
    if(lastSegment) {
      dispatch(actions.deleteSubscriptionSegment(lastSegment.segmentID, subscriptionID))
      dispatch(actions.showNotification(lastSegment.segmentID+ ' deleted', 'success'))
    }
    if(secondLastSegment) {
      dispatch(actions.putSubscriptionSegment(secondLastSegment.segmentID, {...secondLastSegment, endDate: null}, subscriptionID))
    }
    /* deletion of termination re-opens whole subscription */
    if(lastSegment?.segmentType === 'termination') {
      dispatch(actions.putSubscription(selectedSubscription.id, {
        endDate: null,
      }))
    }

  }

  return(
    <>
      <Button onClick={() => dispatch(actions.showSubscriptionSegmentModal())} color="primary" variant="contained">
        Add segment
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button onClick={() => deleteLastSegment()} color="secondary" variant="contained">
        Delete last segment
      </Button>
      <br /><br />
    </>
  )
}
import React from 'react';
import {Box, Typography, Rating} from '@mui/material';
import {Stars} from '@mui/icons-material';
import {withStyles} from "@mui/styles";

const RatingInputGreen = (props) => {
  const {rating, setRating, legend, readOnly} = props

  const labels = {
    0.5: 'Ungenügend',
    1: 'Ungenügend+',
    1.5: 'Mangelhaft',
    2: 'Mangelhaft+',
    2.5: 'Befriedigend',
    3: 'Befriedigend+',
    3.5: 'Sehr gut',
    4: 'Sehr gut+',
    4.5: 'Ausgezeichnet',
    5: 'Ausgezeichnet+',
  };

  const [value, setValue] = React.useState(0)
  const [hover, setHover] = React.useState(-1)

  const StyledRating = withStyles({
    iconFilled: {
      color: '#00aa6c',
    },
    iconHover: {
      color: '#00bb76',
    },
  })(Rating)

  return(
    <Box
      component="fieldset"
      border={1}
      borderColor="grey.400"
      borderRadius={1}
      style={{margin: 0, marginTop: 8}}
    >
      <Typography component="legend" sx={{fontSize: 12}}>{legend}</Typography>
      <StyledRating
        name="ratingGreen"
        //defaultValue={2}
        precision={0.5}
        max={5}
        readOnly={readOnly || false}
        style={{fontSize: '1.2rem'}}
        icon={<Stars fontSize="inherit" />}
        value={rating}
        onChange={(event, newValue) => {
          setValue(newValue)
          setRating(newValue)
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover)
        }}
      />
      &nbsp; {value !== null && <small>{labels[hover !== -1 ? hover : value]}</small>}
    </Box>
  )
}

export default RatingInputGreen

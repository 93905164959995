import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET
 */
export const getCity = () => {
  return dispatch => {
    dispatch(getCityStart())
    axios.get('/horus/city')
      .then ( response => {
        dispatch(getCitySuccess(response.data))
      })
      .catch(error => {
        dispatch(getCityFail(error))
      })
  }
}

export const getCityStart = () => {
  return {
    type: actionTypes.GET_CITY_START,
  }
}

export const getCitySuccess = (res) => {
  return {
    type: actionTypes.GET_CITY_SUCCESS,
    res: res,
  }
}

export const getCityFail = (error) => {
  return {
    type: actionTypes.GET_CITY_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postCity = (payload) => {
  return dispatch => {
    dispatch(postCityStart())
    axios.post('/horus/city', payload)
      .then ( response => {
        dispatch(postCitySuccess(response.data))
        dispatch(getCity())
      })
      .catch(error => {
        dispatch(postCityFail(error))
      })
  }
}

export const postCityStart = () => {
  return {
    type: actionTypes.POST_CITY_START,
  }
}

export const postCitySuccess = (res) => {
  return {
    type: actionTypes.POST_CITY_SUCCESS,
    res: res,
  }
}

export const postCityFail = (error) => {
  return {
    type: actionTypes.POST_CITY_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putCity = (id, payload) => {
  return dispatch => {
    dispatch(putCityStart())
    axios.put('/horus/city/'+id, payload)
      .then ( response => {
        dispatch(putCitySuccess(response.data))
        dispatch(getCity())
      })
      .catch(error => {
        dispatch(putCityFail(error))
      })
  }
}

export const putCityStart = () => {
  return {
    type: actionTypes.PUT_CITY_START,
  }
}

export const putCitySuccess = (res) => {
  return {
    type: actionTypes.PUT_CITY_SUCCESS,
    res: res,
  }
}

export const putCityFail = (error) => {
  return {
    type: actionTypes.PUT_CITY_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteCity = (id) => {
  return dispatch => {
    dispatch(deleteCityStart())
    axios.delete('/horus/city/'+id)
      .then ( response => {
        dispatch(deleteCitySuccess(response.data))
        dispatch(getCity())
      })
      .catch(error => {
        dispatch(deleteCityFail(error))
      })
  }
}

export const deleteCityStart = () => {
  return {
    type: actionTypes.DELETE_CITY_START,
  }
}

export const deleteCitySuccess = (res) => {
  return {
    type: actionTypes.DELETE_CITY_SUCCESS,
    res: res,
  }
}

export const deleteCityFail = (error) => {
  return {
    type: actionTypes.DELETE_CITY_FAIL,
    error: error,
  }
}

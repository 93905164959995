import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET
 */
export const getCustomerBlacklist = () => {
  return dispatch => {
    dispatch(getCustomerBlacklistStart())
    axios.get('/horus/customerBlacklist')
      .then ( response => {
        dispatch(getCustomerBlacklistSuccess(response.data))
      })
      .catch(error => {
        dispatch(getCustomerBlacklistFail(error))
      })
  }
}

export const getCustomerBlacklistStart = () => {
  return {
    type: actionTypes.GET_CUSTOMER_BLACKLIST_START,
  }
}

export const getCustomerBlacklistSuccess = (res) => {
  return {
    type: actionTypes.GET_CUSTOMER_BLACKLIST_SUCCESS,
    res: res,
  }
}

export const getCustomerBlacklistFail = (error) => {
  return {
    type: actionTypes.GET_CUSTOMER_BLACKLIST_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postCustomerBlacklist = (payload) => {
  return dispatch => {
    dispatch(postCustomerBlacklistStart())
    axios.post('/horus/customerBlacklist', payload)
      .then ( response => {
        dispatch(postCustomerBlacklistSuccess(response.data))
        dispatch(getCustomerBlacklist())
      })
      .catch(error => {
        dispatch(postCustomerBlacklistFail(error))
      })
  }
}

export const postCustomerBlacklistStart = () => {
  return {
    type: actionTypes.POST_CUSTOMER_BLACKLIST_START,
  }
}

export const postCustomerBlacklistSuccess = (res) => {
  return {
    type: actionTypes.POST_CUSTOMER_BLACKLIST_SUCCESS,
    res: res,
  }
}

export const postCustomerBlacklistFail = (error) => {
  return {
    type: actionTypes.POST_CUSTOMER_BLACKLIST_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putCustomerBlacklist = (id, payload) => {
  return dispatch => {
    dispatch(putCustomerBlacklistStart())
    axios.put('/horus/customerBlacklist/'+id, payload)
      .then ( response => {
        dispatch(putCustomerBlacklistSuccess(response.data))
        dispatch(getCustomerBlacklist())
      })
      .catch(error => {
        dispatch(putCustomerBlacklistFail(error))
      })
  }
}

export const putCustomerBlacklistStart = () => {
  return {
    type: actionTypes.PUT_CUSTOMER_BLACKLIST_START,
  }
}

export const putCustomerBlacklistSuccess = (res) => {
  return {
    type: actionTypes.PUT_CUSTOMER_BLACKLIST_SUCCESS,
    res: res,
  }
}

export const putCustomerBlacklistFail = (error) => {
  return {
    type: actionTypes.PUT_CUSTOMER_BLACKLIST_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteCustomerBlacklist = (id) => {
  return dispatch => {
    dispatch(deleteCustomerBlacklistStart())
    axios.delete('/horus/customerBlacklist/'+id)
      .then ( response => {
        dispatch(deleteCustomerBlacklistSuccess(response.data))
        dispatch(getCustomerBlacklist())
      })
      .catch(error => {
        dispatch(deleteCustomerBlacklistFail(error))
      })
  }
}

export const deleteCustomerBlacklistStart = () => {
  return {
    type: actionTypes.DELETE_CUSTOMER_BLACKLIST_START,
  }
}

export const deleteCustomerBlacklistSuccess = (res) => {
  return {
    type: actionTypes.DELETE_CUSTOMER_BLACKLIST_SUCCESS,
    res: res,
  }
}

export const deleteCustomerBlacklistFail = (error) => {
  return {
    type: actionTypes.DELETE_CUSTOMER_BLACKLIST_FAIL,
    error: error,
  }
}

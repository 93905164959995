import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  rooms: [],
}

const startLoading = (state) => {
  return {
    ...state,
    isLoading: true,
  }
}

const setRooms = (state, action) => {
  return {
    ...state,
    rooms: action.res,
    isLoading: false,
  }
}

const setSingleRoom = (state,action) => {
  let newRoomList = []

  if(state.rooms.filter(x => x.id === action.res.id).length > 0) {
    /* room already loaded --> just do update */
    newRoomList = state.rooms.map(room =>
      room.id === action.res.id
        ? action.res
        : room
    )
  } else {
    /* room not loaded, start new list */
    newRoomList = [...newRoomList, action.res]
  }

  return {
    ...state,
    rooms: newRoomList,
    isLoading: false,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_ROOM_BY_HOTEL_START: return startLoading(state)
    case actionTypes.GET_ROOM_BY_HOTEL_SUCCESS: return setRooms(state, action)
    case actionTypes.GET_ROOM_BY_ID_START: return startLoading(state)
    case actionTypes.GET_ROOM_BY_ID_SUCCESS: return setSingleRoom(state, action)
    default: return state
  }
}

export default reducer

import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Autocomplete,
  Button,
  Checkbox,
  createFilterOptions,
  FormControlLabel,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import {Controller, useForm, useWatch} from 'react-hook-form';
import * as actions from '../../store/actions';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {
  formatCurrencyInline,
  formatDate,
  formatDecimalEuToUs,
  formatDecimalUsToEu,
  getDateAsCleanUTC
} from "../../services/unitFormater";
import {add} from "date-fns";

const SubscriptionSegmentForm = (props) => {

  const {selectedSubscription} = props

  const dispatch = useDispatch()

  const [filterInactiveItems, setFilterInactiveItems] = useState(true)

  /* redux states */
  const subscriptionMonthsStart = useSelector(state => state.subscription.evaluation.nextSubscriptionMonths)
  const subscriptionMonthsStartWithoutUnlimited = subscriptionMonthsStart.slice(0,-1)
  const startDateOptions = subscriptionMonthsStart.filter(m => m.possibleStart === true)
  const endDateOptions = subscriptionMonthsStart.filter(m => m.possibleEnd === true)

  const offerList = useSelector(state => state.offer.offers)
  const offerListFilterByInactive = filterInactiveItems
    ? offerList?.filter(offer => offer?.activeSale === true)
    : offerList
  const offerListFilteredBySubscription = selectedSubscription?.BookingHotel?.hotelId
    ? offerListFilterByInactive?.filter(offer => offer?.BookingRoom?.BookingHotel?.hotelId === selectedSubscription?.BookingHotel?.hotelId)
    : offerListFilterByInactive
  offerListFilteredBySubscription.sort((a,b) => a.BookingSubscriptionCategory.nightsPerMonth - b.BookingSubscriptionCategory.nightsPerMonth)

  const subscriptionStartDate = selectedSubscription?.startDate
  const subscriptionEndDate = selectedSubscription?.endDate
  const subscriptionID = selectedSubscription?.subscriptionID
  const segments = selectedSubscription?.BookingSubscriptionSegments || []
  const lastSegment = segments?.slice(-1)?.[0] || {startDate: subscriptionStartDate, endDate: subscriptionStartDate} /* if there is no last segment use subscription start */
  const startAfterLastSegment = (lastSegment?.endDate === subscriptionStartDate)
    ? subscriptionStartDate /* if this is first segment to a subscription use start date instead of adding one day */
    : add(new Date(lastSegment.endDate), {days: 1})

  const defaultValues = {
    startDateCustom: lastSegment?.endDate ? startAfterLastSegment : '',
    endDateMonth: {month: '∞', dateFrom: "3000-12-31T00:00:00.000Z", dateTo: "3000-12-31T00:00:00.000Z"},
    endDateCustom: lastSegment?.endDate || '',
    segmentType: 'subscription',
    bookingAllowed: false,
    nightsOffsetTransferCredit: 0,
    nightsOffsetManual: 0,
    nightsOffsetManualSalesPrice: 0,
    internalComment: '',
    BookingOffer: offerListFilteredBySubscription[0] || {},
  }

  /* react hook form */
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: defaultValues
  })

  const segmentTypeWatch = useWatch({
    control,
    name: "segmentType",
  })
  const startDateMonthWatch = useWatch({
    control,
    name: "startDateMonth",
  })
  const startDateCustomWatch = useWatch({
    control,
    name: "startDateCustom",
  })
  const endDateCustomWatch = useWatch({
    control,
    name: "endDateCustom",
  })

  const onSubmit = data => {

    /* with open end last segment, add end date to last segment according to start of new segment */
    if(lastSegment && lastSegment?.endDate === null) {
      let endDate = subscriptionMonthsStartWithoutUnlimited?.[startDateMonthWatch?.month-2]?.dateTo
      if(data.segmentType === 'termination') {
        endDate = data.endDateMonth.dateTo
      }
      dispatch(actions.putSubscriptionSegment(lastSegment.segmentID, {...lastSegment, endDate: endDate}, subscriptionID))
    }

    const payloadBaseline = {
      segmentType: data.segmentType,
      nightsOffsetTransferCredit: data.nightsOffsetTransferCredit,
      nightsOffsetManual: data.nightsOffsetManual,
      nightsOffsetManualSalesPrice: data.nightsOffsetManualSalesPrice,
      internalComment: data.internalComment,
      subscriptionID: selectedSubscription.subscriptionID,
    }

    const payloadOptions = {
      subscription: {
        startDate: lastSegment?.endDate ? data.startDateCustom : data.startDateMonth?.dateFrom,
        endDate: '',
        bookingAllowed: true,
        BookingOffer: {id: data.BookingOffer.id},
        ...payloadBaseline
      },
      breakMonth: {
        startDate: lastSegment?.endDate ? data.startDateCustom : data.startDateMonth?.dateFrom,
        endDate: '',
        bookingAllowed: data.bookingAllowed,
        BookingOffer: null,
        ...payloadBaseline
      },
      breakCustom: {
        startDate: lastSegment?.endDate ? data.startDateCustom : data.startDateMonth?.dateFrom,
        endDate: data.endDateCustom,
        bookingAllowed: data.bookingAllowed,
        BookingOffer: null,
        ...payloadBaseline
      },
      termination: {
        startDate: lastSegment?.endDate ? data.endDateCustom : data.endDateMonth?.dateTo,
        endDate: lastSegment?.endDate ? data.endDateCustom : data.endDateMonth?.dateTo,
        bookingAllowed: true,
        BookingOffer: null,
        ...payloadBaseline
      },
    }

    const payload = payloadOptions[data.segmentType]

    dispatch(actions.postSubscriptionSegment(payload, selectedSubscription.subscriptionID))

    /* termination segment also ends whole subscription */
    if(data.segmentType === 'termination') {
      dispatch(actions.putSubscription(selectedSubscription.id, {
        endDate: payload.endDate,
      }))
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{marginLeft: 0}}>

          <Controller
            name="segmentType"
            control={control}
            rules={{
              required: 'Please choose segment type',
            }}
            render={({ field,fieldState}) =>  (
              <ToggleButtonGroup
                color="primary"
                sx={{my:1, backgroundColor: '#FFF'}}
                size="small"
                value={field.value}
                exclusive
                onChange={(e) => {
                  field.onChange(e.target.value)
                  if(e.target.value === 'breakCustom') setValue('endDateCustom',null)
                  if(e.target.value === 'termination') {
                    setValue('endDateMonth',endDateOptions?.[0])
                    setValue('endDateCustom',defaultValues.endDateCustom)
                  }
                }}
                aria-label="SegmentType"
              >
                <ToggleButton value="subscription">Subscription</ToggleButton>
                <ToggleButton value="breakMonth">Break (month)</ToggleButton>
                <ToggleButton value="breakCustom">Break (custom)</ToggleButton>
                <ToggleButton value="termination">Termination</ToggleButton>
              </ToggleButtonGroup>
            )}
          />

          <br/>

          <div style={{display:'flex', alignItems:'center', fontSize:14, color:'#7991af', marginTop:6, marginBottom:6}}>
            <div style={{width: 115}}>Last segment</div>
            <div>{formatDate(lastSegment.startDate)} — {formatDate(lastSegment?.endDate) || '∞'}</div>
            <div style={{marginLeft: 40}}>{lastSegment?.endDate === null && segmentTypeWatch !== 'termination' && startDateMonthWatch?.month
              ? 'New end: ' + formatDate(subscriptionMonthsStartWithoutUnlimited?.[startDateMonthWatch?.month-2]?.dateTo)
                + ' (' + (startDateMonthWatch?.month - startDateOptions[0].month + 1) + ' months)'
              : ''
            }</div>
          </div>

          {['subscription','breakMonth','breakCustom'].includes(segmentTypeWatch) && lastSegment?.endDate
            ? <Controller
              name="startDateCustom"
              control={control}
              rules={{
                required: 'Please choose startDate',
              }}
              render={({field, fieldState}) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{fontSize: 14, width: 135}}>Start Date</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label=""
                      inputFormat="dd.MM.yyyy"
                      format="dd.MM.yyyy"
                      readOnly={true}
                      disabled={true}
                      clearable={false}
                      value={field.value ? new Date(field.value) : null}
                      //minDate={startOfNewSegment.date}
                      //maxDate={subscriptionEndDate}
                      onChange={field.onChange}
                      slotProps={{
                        textField: {
                          InputProps: {
                            className: "dateField",
                            size: "small",
                            error: !!fieldState.error,
                            style: {
                              width: 210,
                              backgroundColor: "#FFF",
                              fontSize: 14
                            },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
            />
            : ''
          }

          {['subscription','breakMonth','breakCustom'].includes(segmentTypeWatch) && lastSegment?.endDate === null
            ? <Controller
              name="startDateMonth"
              control={control}
              rules={{
                required: 'Please choose startDate',
              }}
              render={({field, fieldState}) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{fontSize: 14, width: 135}}>Start Date</div>
                  <Autocomplete
                    value={field.value || ''}
                    options={startDateOptions}
                    disableClearable={true}
                    getOptionLabel={(option) => formatDate(option.dateFrom) || ''}
                    isOptionEqualToValue={(option, value) => {
                      return option.dateFrom === value.dateFrom
                    }}
                    onChange={(e, value) => {
                      field.onChange(value)
                      dispatch(actions.getSubscriptionMonths(24,value.dateFrom,'end'))
                      setValue('endDateMonth', defaultValues.endDateMonth)
                      setValue('endDateCustom', value.dateFrom)
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div style={{fontSize: 13}}>{formatDate(option.dateFrom)}</div>
                      </li>
                    )}
                    size="small"
                    renderInput={(params) => {
                      params.InputProps.style = {fontSize: 14}
                      return(
                        <TextField
                          label=""
                          sx={{my:1, width:210, backgroundColor: "#FFF"}}
                          placeholder=""
                          variant="outlined"
                          {...params}
                          error={!!fieldState.error}
                        />
                      )
                    }}
                  />
                </div>
              )}
            />
            : ''
          }


          {['subscription','breakMonth'].includes(segmentTypeWatch)
            ? <Controller
                name="endDateMonth"
                control={control}
                rules={{
                  required: 'Please choose endDate',
                }}
                render={({ field,fieldState}) =>  (
                  <div style={{display:'flex', alignItems:"center"}}>
                    <div style={{ fontSize: 14, width: 135}}>End Date</div>
                    <TextField
                      sx={{my: 1, width: 210, backgroundColor: '#FFF'}}
                      size="small"
                      variant="outlined"
                      disabled={true}
                      value={'unlimited'}
                    />
                  </div>
                )}
              />
            : ''
          }


          {['termination'].includes(segmentTypeWatch) && lastSegment?.endDate === null
            ? <Controller
                name="endDateMonth"
                control={control}
                rules={{
                  required: 'Please choose endDate',
                }}
                render={({ field,fieldState}) =>  (
                  <div style={{display:'flex', alignItems:"center"}}>
                    <div style={{ fontSize: 14, width: 135}}>End Date</div>
                    <Autocomplete
                      value={field.value || ''}
                      options={endDateOptions}
                      disableClearable={true}
                      getOptionLabel={(option) => formatDate(option.dateTo) +'  ('
                        +(option?.month - endDateOptions[0].month + 1)+' months)' || '-'}
                      isOptionEqualToValue={(option, value) => {
                        return option.dateTo === value.dateTo
                      }}
                      onChange={(e, value) => {
                        field.onChange(value)
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <div style={{fontSize: 13}}>{formatDate(option.dateTo) +'  ('
                            +(option?.month - endDateOptions[0].month + 1)+' months)'}</div>
                        </li>
                      )}
                      size="small"
                      renderInput={(params) => {
                        params.InputProps.style = {fontSize: 14}
                        return(
                          <TextField
                            label=""
                            sx={{my:1, width:210, backgroundColor: "#FFF"}}
                            placeholder=""
                            variant="outlined"
                            {...params}
                            error={!!fieldState.error}
                          />
                        )
                      }}
                    />
                    <div style={{fontSize:11, width: 140, margin:5, color:'#344556'}}>This sets end date of this segment and terminates whole subscription</div>
                  </div>
                )}
              />
            : ''
          }

          {['termination'].includes(segmentTypeWatch) && lastSegment?.endDate
            ? <Controller
              name="endDateCustom"
              control={control}
              rules={{
                required: 'Please choose endDate',
              }}
              render={({field, fieldState}) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{fontSize: 14, width: 135}}>End Date</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label=""
                      inputFormat="dd.MM.yyyy"
                      format="dd.MM.yyyy"
                      readOnly={true}
                      disabled={true}
                      clearable={false}
                      value={field.value ? new Date(field.value) : null}
                      onChange={(e) => {
                        field.onChange(getDateAsCleanUTC(e))
                      }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            className: "dateField",
                            size: "small",
                            error: !!fieldState.error,
                            style: {
                              width: 210,
                              backgroundColor: "#FFF",
                              fontSize: 14
                            },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
            />
            : ''
          }

          {['breakCustom'].includes(segmentTypeWatch)
            ? <Controller
              name="endDateCustom"
              control={control}
              rules={{
                required: 'Please choose endDate',
              }}
              render={({ field,fieldState}) =>  (

                <div style={{display:'flex', alignItems:"center"}}>
                  <div style={{ fontSize: 14, width: 135}}>End Date</div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label=""
                      key="endDateCustom"
                      inputFormat="dd.MM.yyyy"
                      format="dd.MM.yyyy"
                      clearable={true}
                      value={field.value ? new Date(field.value) : null}
                      minDate={startDateMonthWatch?.dateFrom || startDateCustomWatch}
                      maxDate={subscriptionEndDate}
                      onChange={(e) => {
                        field.onChange(getDateAsCleanUTC(e))
                      }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            className: "dateField",
                            size: "small",
                            error: !!fieldState.error,
                            style: {
                              width: 210,
                              backgroundColor: "#FFF",
                              fontSize: 14
                            },
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>

                  <div style={{fontSize:11, width: 140, margin:5, color:'#344556'}}>Custom break starts a new subscription cycle at {
                    formatDate(add(new Date(endDateCustomWatch), {days: 1}))}</div>
                </div>
              )}
              />
            : ''
          }

          {['subscription'].includes(segmentTypeWatch)
            ? <Controller
              name="BookingOffer"
              control={control}
              rules={{
                required: 'Please choose an offer',
              }}
              render={({ field,fieldState}) =>  (
                <div style={{display:'flex', alignItems:"center"}}>
                  <div style={{ fontSize: 14, width: 135}}>Offer</div>
                  <div>
                    <div style={{textAlign: 'right'}}>
                      <FormControlLabel control={<Checkbox
                        sx={{margin: 0, padding: 1}}
                        checked={filterInactiveItems}
                        onChange={e => setFilterInactiveItems(e.target.checked)}
                      />} style={{color: '#999', margin: 0, paddingTop: 2}} label="Filter inactive offers"/>
                    </div>
                    <Autocomplete
                      id="BookingOffer"
                      options={offerListFilteredBySubscription}
                      disableClearable={true}
                      getOptionLabel={(option) => {
                        const thisOffer = offerList.filter(o => o.id === option.id)?.[0]
                        const category = thisOffer?.BookingSubscriptionCategory?.name || ''
                        const hotelName = thisOffer?.BookingRoom?.BookingHotel?.name || ''
                        const resultString = category + ' @ ' + hotelName + ' ('+option.offerID+')'
                        return(option.offerID ? resultString : '')
                      }}
                      value={field.value}
                      onChange={(e, value) => {
                        field.onChange({id:value?.id, offerID:value?.offerID})
                      }}
                      autoHighlight={true} // required to select entry without submitting entire material-table row
                      //onKeyDown={e => handleKey(e)}
                      isOptionEqualToValue={(option, value) => {
                        return option?.id === value?.id
                      }}
                      renderOption={(props, option) => {
                        const thisOffer = offerList.filter(o => o.id === option.id)?.[0]
                        const category = thisOffer?.BookingSubscriptionCategory?.name
                        const duration = thisOffer?.BookingSubscriptionDuration?.name
                        const hotelName = thisOffer?.BookingRoom?.BookingHotel?.name
                        const roomName = thisOffer?.BookingRoom?.name
                        const price = option.price?.toFixed(2)?.replace('.',',')
                        const pricePerMonth = thisOffer?.pricePerMonth?.toFixed(2)?.replace('.',',')
                        const activeSale = thisOffer?.activeSale
                        const shareable = thisOffer?.BookingSubscriptionCategory?.shareable
                        const discount = thisOffer?.discount
                        const year = thisOffer?.BookingSubscriptionCategory?.nightsScope === 'year'
                        return(
                          <div {...props}>
                            <div style={{fontSize: 13}}>
                              {category + ' @ ' + hotelName + ' ('+option.offerID+')' || '---'}
                              <div style={{fontSize: 10}}>{roomName} | {duration} Laufzeit </div>
                              <div style={{fontSize: 10}}>{price} €/Nacht | {pricePerMonth} €/Monat</div>
                              <div style={{fontSize: 10}}>{
                                shareable ? <span style={{color:"blue"}}>business</span> : 'single'
                              } | {
                                year ? <span style={{color:"purple"}}>year</span> : 'month'
                              } | {
                                activeSale ? 'active' : <span style={{color:"red"}}>inactive</span>
                              } | {
                                discount > 0 ? <span style={{color:"green"}}>{formatCurrencyInline(discount)} discount</span> : 'no discount'
                              }</div>
                            </div>
                          </div>
                        )
                      }}
                      filterOptions={createFilterOptions({
                        matchFrom: 'any',
                        stringify: (option) => {
                          const offerID = option?.offerID
                          const thisOffer = offerList.filter(o => o.id === option.id)?.[0]
                          const category = thisOffer?.BookingSubscriptionCategory?.name
                          const duration = thisOffer?.BookingSubscriptionDuration?.name
                          const hotelName = thisOffer?.BookingRoom?.BookingHotel?.name
                          const roomName = thisOffer?.BookingRoom?.name
                          const price = thisOffer?.price?.toFixed(2)?.replace('.',',')
                          const pricePerMonth = thisOffer?.pricePerMonth?.toFixed(2)?.replace('.',',')
                          const activeSale = thisOffer?.activeSale ? 'active' : 'inactive'
                          const shareable = thisOffer?.BookingSubscriptionCategory?.shareable ? 'business' : 'single'
                          return(offerID + ' ' + category + ' ' + duration + ' ' + hotelName + ' ' + roomName + ' '
                            + price+ ' ' + pricePerMonth + ' ' + activeSale + ' ' + shareable)
                        },
                      })}
                      size="small"
                      renderInput={(params) => {
                        params.InputProps.style = {fontSize: 14}
                        return(
                          <TextField
                            sx={{marginBottom: 1, width: 300, backgroundColor:'#FFF'}} size="small" variant="outlined"
                            label=""
                            {...params}
                            placeholder=""
                            error={!!fieldState.error}
                          />
                        )
                      }}
                    />
                  </div>
                </div>


              )}
            />
            : ''
          }

          {['breakMonth','breakCustom'].includes(segmentTypeWatch)
            ? <Controller
                name="bookingAllowed"
                control={control}
                render={({ field,fieldState}) =>  (
                  <FormControlLabel
                    control={<Checkbox
                      {...field}
                      checked={field.value}
                      sx={fieldState.error ? {color: 'rgb(185 28 28)',backgroundColor: '#FFF'} : {backgroundColor: '#FFF'}}
                    />}
                    label={
                      <div style={{fontSize: 14, marginLeft: 6}}>Booking allowed during break
                      </div>
                    }
                  />

                )}
              />
            : ''
          }

          {['subscription','breakMonth', 'breakCustom'].includes(segmentTypeWatch)
            ? <>
              <Controller
                name="nightsOffsetTransferCredit"
                control={control}
                rules={{
                  min: {value: -50, message: 'min. -50' },
                  max: {value: 50, message: 'max. 50' },
                }}
                render={({field, fieldState}) => (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontSize: 14, width: 135}}>Nights Offset<br/><i>(Transfer credits)</i></div>
                    <TextField
                      {...field}
                      label=""
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      sx={{my: 1, width: 210, backgroundColor: '#FFF'}}
                      inputProps={{style: {fontSize: 14}}}
                      InputLabelProps={{style: {fontSize: 14}}}
                      size="small"
                      variant="outlined"
                      type="number"
                    />
                    <div style={{fontSize:11, width: 140, margin:5, color:'#dc1111'}}>{fieldState.error ? fieldState.error.message : null}</div>
                  </div>
                )}
              />
              <Controller
                name="nightsOffsetManual"
                control={control}
                rules={{
                  min: {value: -50, message: 'min. -50' },
                  max: {value: 50, message: 'max. 50' },
                }}
                render={({field, fieldState}) => (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontSize: 14, width: 135}}>Nights Offset<br/><i>(Sell/ Refund)</i></div>
                    <TextField
                      {...field}
                      label=""
                      value={field.value}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      sx={{my: 1, width: 210, backgroundColor: '#FFF'}}
                      inputProps={{style: {fontSize: 14}}}
                      InputLabelProps={{style: {fontSize: 14}}}
                      size="small"
                      variant="outlined"
                      type="number"
                    />
                    <div style={{fontSize:11, width: 140, margin:5, color:'#dc1111'}}>{fieldState.error ? fieldState.error.message : null}</div>
                  </div>
                )}
              />
              <Controller
                name="nightsOffsetManualSalesPrice"
                control={control}
                render={({field, fieldState}) => (
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{fontSize: 14, width: 135}}>Nights Offset<br/>Sell/Refund Price</div>
                    <TextField
                      {...field}
                      label=""
                      value={formatDecimalUsToEu(field.value || '')}
                      //onChange={field.onChange}
                      onChange={(e, value) => {
                       field.onChange(formatDecimalEuToUs(e.target.value))
                      }}
                      error={!!fieldState.error}
                      //helperText={fieldState.error ? fieldState.error.message : null}
                      sx={{my: 1, width: 210, backgroundColor: '#FFF'}}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        style: {fontSize: 14}
                      }}
                      InputLabelProps={{style: {fontSize: 14}}}
                      size="small"
                      variant="outlined"
                    />
                    <div style={{fontSize:11, width: 140, margin:5, color:'#dc1111'}}>{fieldState.error ? fieldState.error.message : null}</div>
                  </div>
                )}
              />
              </>
            : ''
          }

          {['subscription', 'breakMonth', 'breakCustom'].includes(segmentTypeWatch)
            ? <Controller
              name="internalComment"
              control={control}
              rules={{}}
              render={({field, fieldState}) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{fontSize: 14, width: 135}}>Internal Comment</div>
                  <TextField
                    {...field}
                    label=""
                    value={field.value}
                    onChange={field.onChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    sx={{my: 1, width: 300, backgroundColor: '#FFF'}}
                    inputProps={{style: {fontSize: 14}}}
                    InputLabelProps={{style: {fontSize: 14}}}
                    size="small"
                    variant="outlined"
                    multiline
                    rows={3}
                  />
                </div>
              )}
            />
            : ''
          }

        </div>

        <Button type="submit" color="primary" variant="contained" sx={{mt:5}}>
          Save
        </Button>

      </form>
    </div>
  )
}

export default SubscriptionSegmentForm

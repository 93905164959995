import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  city: [],
  hotelAttribute: [],
  roomCategory: [],
  subscriptionCategory: [],
  subscriptionDuration: [],
  offerDiscount: [],
  cacheStats: {},
}

const setCity = (state, action) => {
  return {
    ...state,
    city: action.res,
  }
}

const setHotelAttribute = (state, action) => {
  return {
    ...state,
    hotelAttribute: action.res,
  }
}

const setRoomCategory = (state, action) => {
  return {
    ...state,
    roomCategory: action.res,
  }
}

const setSubscriptionCategory = (state, action) => {
  return {
    ...state,
    subscriptionCategory: action.res,
  }
}

const setSubscriptionDuration = (state, action) => {
  return {
    ...state,
    subscriptionDuration: action.res,
  }
}

const setOfferDiscount = (state, action) => {
  return {
    ...state,
    offerDiscount: action.res,
  }
}

const setCacheStats = (state, action) => {
  return {
    ...state,
    cacheStats: action.res,
  }
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_CITY_SUCCESS: return setCity(state, action)
    case actionTypes.GET_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS: return setHotelAttribute(state, action)
    case actionTypes.GET_ROOM_CATEGORY_SUCCESS: return setRoomCategory(state, action)
    case actionTypes.GET_SUBSCRIPTION_CATEGORY_SUCCESS: return setSubscriptionCategory(state, action)
    case actionTypes.GET_SUBSCRIPTION_DURATION_SUCCESS: return setSubscriptionDuration(state, action)
    case actionTypes.GET_OFFER_DISCOUNT_SUCCESS: return setOfferDiscount(state, action)
    case actionTypes.GET_CACHE_STATS_SUCCESS: return setCacheStats(state, action)
    default: return state
  }
}

export default reducer

import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {GridOn} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import DistrictTable from "../tables/DistrictTable";


const ViewDistrict = withRouter((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getDistrict())
    dispatch(actions.getHotel())
  },[dispatch])

  const districtsLoading = useSelector(state => state.district.districtsLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <GridOn fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">District Management</div>
        </div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {districtsLoading ? <Loading /> : <DistrictTable />}
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewDistrict

import {VisTimeline} from "./VisTimeline";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {formatDate} from "../../services/unitFormater";
import {add} from "date-fns";

const SubscriptionSegmentTable = () => {

  const offerList = useSelector(state => state.offer.offers)
  const evaluation = useSelector(state => state.subscription.evaluation)
  const selectedSubscription = evaluation?.subscriptionData
  const [timeline, setTimeline] = useState()

  const segmentItems = selectedSubscription?.BookingSubscriptionSegments?.map((segment, index) => {
    const offer = offerList.filter(o => o?.id === segment?.BookingOffer?.id)?.[0]
    return {
      id: index,
      start: segment?.startDate,
      end: segment?.endDate || add(new Date(segment?.startDate), {years: 5}),
      content: segment?.segmentType === 'subscription'
        ? offer?.BookingSubscriptionCategory?.name + '<br/>' + offer?.BookingRoom?.BookingHotel?.name
        : 'BREAK',
      group: 2,
      className: `tl-item ${segment.segmentType === 'subscription' ? '' : 'paused'}`
    }
  }) || []

  const subscriptionItems = [{
    id: 20,
    start: selectedSubscription?.startDate,
    end: selectedSubscription?.endDate || add(new Date(selectedSubscription?.startDate), {years: 5}),
    content: selectedSubscription?.subscriptionID
      + '<br/> Start: ' + formatDate(selectedSubscription?.startDate)
      + '<br/> End: ' + formatDate(selectedSubscription?.endDate),
    group: 1,
    className: `tl-item active`
  }] || []

  const timelineItems = [
    ...segmentItems,
    ...subscriptionItems
  ]

  const options = {
    start: new Date(selectedSubscription?.startDate),
    end: new Date(selectedSubscription?.endDate || "2024-01-01"),
    min: new Date("2020-01-01"),
    max: new Date("2026-01-01"),
    horizontalScroll: true,
    zoomKey: "ctrlKey",
    orientation: "both",
    zoomMin: 1000 * 60 * 60 * 240
  };

  const groups = [
    {
      id: 1,
      content: "Overall Subscription",
    },
    {
      id: 2,
      content: "Segements"
    },
  ]

  return(
    <div style={{backgroundColor:'#FFF'}}>
      <VisTimeline groups={groups} options={options} items={timelineItems} timeline={timeline} setTimeline={setTimeline} />
    </div>
  )
}

export default SubscriptionSegmentTable
import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET
 */
export const getRoomCategory = () => {
  return dispatch => {
    dispatch(getRoomCategoryStart())
    axios.get('/horus/roomCategory')
      .then ( response => {
        dispatch(getRoomCategorySuccess(response.data))
      })
      .catch(error => {
        dispatch(getRoomCategoryFail(error))
      })
  }
}

export const getRoomCategoryStart = () => {
  return {
    type: actionTypes.GET_ROOM_CATEGORY_START,
  }
}

export const getRoomCategorySuccess = (res) => {
  return {
    type: actionTypes.GET_ROOM_CATEGORY_SUCCESS,
    res: res,
  }
}

export const getRoomCategoryFail = (error) => {
  return {
    type: actionTypes.GET_ROOM_CATEGORY_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postRoomCategory = (payload) => {
  return dispatch => {
    dispatch(postRoomCategoryStart())
    axios.post('/horus/roomCategory', payload)
      .then ( response => {
        dispatch(postRoomCategorySuccess(response.data))
        dispatch(getRoomCategory())
      })
      .catch(error => {
        dispatch(postRoomCategoryFail(error))
      })
  }
}

export const postRoomCategoryStart = () => {
  return {
    type: actionTypes.POST_ROOM_CATEGORY_START,
  }
}

export const postRoomCategorySuccess = (res) => {
  return {
    type: actionTypes.POST_ROOM_CATEGORY_SUCCESS,
    res: res,
  }
}

export const postRoomCategoryFail = (error) => {
  return {
    type: actionTypes.POST_ROOM_CATEGORY_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putRoomCategory = (id, payload) => {
  return dispatch => {
    dispatch(putRoomCategoryStart())
    axios.put('/horus/roomCategory/'+id, payload)
      .then ( response => {
        dispatch(putRoomCategorySuccess(response.data))
        dispatch(getRoomCategory())
      })
      .catch(error => {
        dispatch(putRoomCategoryFail(error))
      })
  }
}

export const putRoomCategoryStart = () => {
  return {
    type: actionTypes.PUT_ROOM_CATEGORY_START,
  }
}

export const putRoomCategorySuccess = (res) => {
  return {
    type: actionTypes.PUT_ROOM_CATEGORY_SUCCESS,
    res: res,
  }
}

export const putRoomCategoryFail = (error) => {
  return {
    type: actionTypes.PUT_ROOM_CATEGORY_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteRoomCategory = (id) => {
  return dispatch => {
    dispatch(deleteRoomCategoryStart())
    axios.delete('/horus/roomCategory/'+id)
      .then ( response => {
        dispatch(deleteRoomCategorySuccess(response.data))
        dispatch(getRoomCategory())
      })
      .catch(error => {
        dispatch(deleteRoomCategoryFail(error))
      })
  }
}

export const deleteRoomCategoryStart = () => {
  return {
    type: actionTypes.DELETE_ROOM_CATEGORY_START,
  }
}

export const deleteRoomCategorySuccess = (res) => {
  return {
    type: actionTypes.DELETE_ROOM_CATEGORY_SUCCESS,
    res: res,
  }
}

export const deleteRoomCategoryFail = (error) => {
  return {
    type: actionTypes.DELETE_ROOM_CATEGORY_FAIL,
    error: error,
  }
}

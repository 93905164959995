import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";

/*
 * GET
 */
export const getReservation = (perPage= 100, page=1, cache = 'ok') => {
  return dispatch => {
    dispatch(getReservationStart())
    axios.get('/horus/reservation?perPage='+perPage+'&page='+page+'&cache='+cache)
      .then ( response => {
        dispatch(getReservationSuccess(response.data))
      })
      .catch(error => {
        dispatch(getReservationFail(error))
      })
  }
}

export const getReservationStart = () => {
  return {
    type: actionTypes.GET_RESERVATION_START,
  }
}

export const getReservationSuccess = (res) => {
  return {
    type: actionTypes.GET_RESERVATION_SUCCESS,
    res: res,
  }
}

export const getReservationFail = (error) => {
  return {
    type: actionTypes.GET_RESERVATION_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postReservation = (payload) => {
  return dispatch => {
    dispatch(postReservationStart())
    axios.post('/horus/reservation', payload)
      .then ( response => {
        dispatch(postReservationSuccess(response.data))
        //dispatch(getReservation())
      })
      .catch(error => {
        dispatch(postReservationFail(error))
      })
  }
}

export const postReservationStart = () => {
  return {
    type: actionTypes.POST_RESERVATION_START,
  }
}

export const postReservationSuccess = (res) => {
  return {
    type: actionTypes.POST_RESERVATION_SUCCESS,
    res: res,
  }
}

export const postReservationFail = (error) => {
  return {
    type: actionTypes.POST_RESERVATION_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putReservation = (id, payload) => {
  return dispatch => {
    dispatch(putReservationStart())
    axios.put('/horus/reservation/'+id, payload)
      .then ( response => {
        dispatch(putReservationSuccess(response.data))
        //dispatch(getReservation())
      })
      .catch(error => {
        dispatch(putReservationFail(error))
      })
  }
}

export const putReservationStart = () => {
  return {
    type: actionTypes.PUT_RESERVATION_START,
  }
}

export const putReservationSuccess = (res) => {
  return {
    type: actionTypes.PUT_RESERVATION_SUCCESS,
    res: res,
  }
}

export const putReservationFail = (error) => {
  return {
    type: actionTypes.PUT_RESERVATION_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteReservation = (id) => {
  return dispatch => {
    dispatch(deleteReservationStart())
    axios.delete('/horus/reservation/'+id)
      .then ( response => {
        dispatch(deleteReservationSuccess(response.data))
        //dispatch(getReservation())
      })
      .catch(error => {
        dispatch(deleteReservationFail(error))
      })
  }
}

export const deleteReservationStart = () => {
  return {
    type: actionTypes.DELETE_RESERVATION_START,
  }
}

export const deleteReservationSuccess = (res) => {
  return {
    type: actionTypes.DELETE_RESERVATION_SUCCESS,
    res: res,
  }
}

export const deleteReservationFail = (error) => {
  return {
    type: actionTypes.DELETE_RESERVATION_FAIL,
    error: error,
  }
}



/*
 * POST_MAIL_CONFIRMATION_REQUEST_HOTEL
 */
export const postMailConfirmationRequestHotel = (id, refreshHook) => {
  return dispatch => {
    dispatch(postMailConfirmationRequestHotelStart())
    axios.post('/horus/reservationAction/mail/confirmationRequestToHotel/'+id)
      .then ( response => {
        dispatch(showNotification('Request hotel confirmation successful','success'))
        dispatch(postMailConfirmationRequestHotelSuccess(response.data))
        refreshHook()
      })
      .catch(error => {
        dispatch(showNotification('Request hotel confirmation failed','error'))
        dispatch(postMailConfirmationRequestHotelFail(error))
      })
  }
}

export const postMailConfirmationRequestHotelStart = () => {
  return {
    type: actionTypes.POST_MAIL_CONFIRMATION_REQUEST_HOTEL_START,
  }
}

export const postMailConfirmationRequestHotelSuccess = (res) => {
  return {
    type: actionTypes.POST_MAIL_CONFIRMATION_REQUEST_HOTEL_SUCCESS,
    res: res,
  }
}

export const postMailConfirmationRequestHotelFail = (error) => {
  return {
    type: actionTypes.POST_MAIL_CONFIRMATION_REQUEST_HOTEL_FAIL,
    error: error,
  }
}



/*
 * POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER
 */
export const postMailHotelAssigmentCustomer = (id, refreshHook) => {
  return dispatch => {
    dispatch(postMailHotelAssigmentCustomerStart())
    axios.post('/horus/reservationAction/mail/hotelAssigmentToCustomer/'+id)
      .then ( response => {
        dispatch(showNotification('Confirm hotel assignment successful','success'))
        dispatch(postMailHotelAssigmentCustomerSuccess(response.data))
        refreshHook()
      })
      .catch(error => {
        dispatch(showNotification('Confirm hotel assignment failed','error'))
        dispatch(postMailHotelAssigmentCustomerFail(error))
      })
  }
}

export const postMailHotelAssigmentCustomerStart = () => {
  return {
    type: actionTypes.POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER_START,
  }
}

export const postMailHotelAssigmentCustomerSuccess = (res) => {
  return {
    type: actionTypes.POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER_SUCCESS,
    res: res,
  }
}

export const postMailHotelAssigmentCustomerFail = (error) => {
  return {
    type: actionTypes.POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER_FAIL,
    error: error,
  }
}



/*
 * POST_MAIL_REBOOK_INFORMATION_CUSTOMER
 */
export const postMailRebookInformationToCustomer = (id, refreshHook) => {
  return dispatch => {
    dispatch(postMailRebookInformationToCustomerStart())
    axios.post('/horus/reservationAction/mail/sendRebookInformationToCustomer/'+id)
      .then ( response => {
        dispatch(showNotification('Inform about rebooking successful','success'))
        dispatch(postMailRebookInformationToCustomerSuccess(response.data))
        refreshHook()
      })
      .catch(error => {
        dispatch(showNotification('Inform about rebooking failed','error'))
        dispatch(postMailRebookInformationToCustomerFail(error))
      })
  }
}

export const postMailRebookInformationToCustomerStart = () => {
  return {
    type: actionTypes.POST_MAIL_REBOOK_INFORMATION_CUSTOMER_START,
  }
}

export const postMailRebookInformationToCustomerSuccess = (res) => {
  return {
    type: actionTypes.POST_MAIL_REBOOK_INFORMATION_CUSTOMER_SUCCESS,
    res: res,
  }
}

export const postMailRebookInformationToCustomerFail = (error) => {
  return {
    type: actionTypes.POST_MAIL_REBOOK_INFORMATION_CUSTOMER_FAIL,
    error: error,
  }
}



/*
 * POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER
 */
export const postMailBookingConfirmationToCustomer = (id, refreshHook) => {
  return dispatch => {
    dispatch(postMailBookingConfirmationToCustomerStart())
    axios.post('/horus/reservationAction/mail/sendBookingConfirmationToCustomer/'+id)
      .then ( response => {
        if(response.data.status === 'OK') {
          dispatch(showNotification(response.data.message,'success'))
          dispatch(postMailBookingConfirmationToCustomerSuccess(response.data))
        } else {
          dispatch(showNotification(response.data.message,'error'))
          dispatch(postMailBookingConfirmationToCustomerFail(response.data.message))
        }
        refreshHook()
      })
      .catch(error => {
        dispatch(showNotification('Confirm reservation failed','error'))
        dispatch(postMailBookingConfirmationToCustomerFail(error))
      })
  }
}

export const postMailBookingConfirmationToCustomerStart = () => {
  return {
    type: actionTypes.POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER_START,
  }
}

export const postMailBookingConfirmationToCustomerSuccess = (res) => {
  return {
    type: actionTypes.POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER_SUCCESS,
    res: res,
  }
}

export const postMailBookingConfirmationToCustomerFail = (error) => {
  return {
    type: actionTypes.POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER_FAIL,
    error: error,
  }
}

export const subscriptionStatusLabel = {
  active: 'aktiv',
  terminated: 'beendet',
  paused: 'pausiert',
  //canceled: 'aktiv (gekündigt)',
}

export const statusLabels = ['active', 'terminated', 'paused']


export const getTripStatusColor = (status) => {
  if(status === 'new') return '#d5aa6d'
  else if (status === 'partially_confirmed') return '#d5aa6d'
  else if (status === 'confirmed' || status === 'confirmed_hotel' || status === 'confirmed_guarantee') return '#5d9b5a'
  else if (status === 'canceled_refunded') return '#a2a0a0'
  else if (status === 'partially_refunded') return '#a27474'
  else if (status === 'canceled_deducted') return '#a27474'
  else if (status === 'declined') return '#724242'
  else if (status === 'invalid_state') return '#be2f2f'
  else return '#000000'
}


export const getTripDetailsStatusColor = (status) => {
  if(status === 'canceled_refunded') return '#9d9595'
  else if (status === 'canceled_deducted') return '#a27474'
  else return '#000000'
}
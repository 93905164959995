import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from './mainWindow';
import {getHotelDetails} from './hotel';


/*
 * PUT
 */
export const putMedia = (uuid, payload, hotelId) => {
  return dispatch => {
    dispatch(putMediaStart())
    axios.put('/horus/media/'+uuid, payload)
      .then ( response => {
        dispatch(putMediaSuccess(response.data))
        dispatch(showNotification('Changes saved successfully','success'))
        dispatch(getHotelDetails(hotelId))
      })
      .catch(error => {
        dispatch(putMediaFail(error))
      })
  }
}

export const putMediaStart = () => {
  return {
    type: actionTypes.PUT_MEDIA_START,
  }
}

export const putMediaSuccess = (res) => {
  return {
    type: actionTypes.PUT_MEDIA_SUCCESS,
    res: res,
  }
}

export const putMediaFail = (error) => {
  return {
    type: actionTypes.PUT_MEDIA_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteMedia = (uuid, hotelId) => {
  return dispatch => {
    dispatch(deleteMediaStart())
    axios.delete('/horus/media/'+uuid)
      .then ( response => {
        dispatch(deleteMediaSuccess(response.data))
        dispatch(showNotification('Picture deleted successfully','success'))
        dispatch(getHotelDetails(hotelId))
      })
      .catch(error => {
        dispatch(deleteMediaFail(error))
      })
  }
}

export const deleteMediaStart = () => {
  return {
    type: actionTypes.DELETE_MEDIA_START,
  }
}

export const deleteMediaSuccess = (res) => {
  return {
    type: actionTypes.DELETE_MEDIA_SUCCESS,
    res: res,
  }
}

export const deleteMediaFail = (error) => {
  return {
    type: actionTypes.DELETE_MEDIA_FAIL,
    error: error,
  }
}

import {Tab, Tabs} from '@mui/material';
import React from 'react';
import {withRouter} from 'react-router-dom';

export const SettingsNavigation = withRouter((props) => {

  const trimmedUrl = props.location.pathname.replace(/\/$/, ''); // Remove trailing slash if present
  const [value, setValue] = React.useState(trimmedUrl.split('/').pop() || 'city')

  const handleChange = (event, newValue) => {
    setValue(newValue)
    props.history.push('/app/settings/'+newValue+'/')
  }

  return(
    <div style={{ marginBottom: 15 }}>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label="Cities" value="city" />
        <Tab label="Hotel Attributes" value="hotel" />
        <Tab label="Rooms" value="room" />
        <Tab label="Subscriptions" value="subscription" />
        <Tab label="Customer Blacklist" value="customer" />
        <Tab label="Offer Discounts" value="offerDiscount" />
        <Tab label="Cache" value="cache" />
      </Tabs>
    </div>
  )
})

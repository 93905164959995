import React, {useEffect, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Checkbox, IconButton, Tooltip, Typography} from '@mui/material';
import {formatCurrency, formatDate, formatDateTime,} from '../../services/unitFormater';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {useHistory, useLocation} from "react-router";
import {Check, Close, Description, Replay} from "@mui/icons-material";
import {FilterIcon} from "../../assets/SvgIcons";
import {updateCurrentUrlParams} from "../../services/url";
import lexofficeIcon from "../../assets/lexoffice.webp"

const LexofficeInvoiceTable = withRouter((props) => {

  const tableRef = React.useRef()
  const history = useHistory()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    resourceId: searchQuery.get('resourceId') || '',
    voucherNumber: searchQuery.get('voucherNumber') || '',
    orderBy: searchQuery.get('orderBy') || 'updatedDate',
    orderDirection: searchQuery.get('orderDirection') || 'desc',
  }

  const refreshHook = () => {
    tableRef?.current && tableRef?.current?.onQueryChange()
  }


  const AddressDisplay = ({ addressData }) => {
    return (
      <div>
        <div><Link to={'/app/lexoffice/contact/?resourceId=' + addressData?.contactId}>{addressData?.name}</Link></div>
        <div>{addressData?.street}</div>
        <div>{addressData?.zip} {addressData?.city} ({addressData?.countryCode})</div>
      </div>
    )
  }

  const resourceId = {
    title: 'Resource ID',
    field: 'resourceId',
    editable: 'never',
    defaultFilter: query.resourceId,
    render: rowData => <div style={{fontSize: 12, width: 180, wordBreak:'break-word', padding: 5}}>{rowData.resourceId}</div>,
  }

  const version = {
    title: 'Version',
    field: 'version',
    editable: 'never',
    filtering: false,
  }

  const address = {
    title: 'Recipient',
    field: 'address',
    render: rowData => <div style={{width: 180}}><AddressDisplay addressData={rowData.address} /></div>,
    editable: 'never',
    filtering: true,
  }

  const voucherDate = {
    title: 'Voucher Date',
    field: 'voucherDate',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDate(rowData.voucherDate)}</div>,
    editable: 'never',
    filtering: false,
  }

  const totalNetAmount = {
    title: 'Total Net Amount',
    field: 'totalNetAmount',
    render: rowData => <div style={{width: 80}}>{formatCurrency(rowData.totalNetAmount)}</div>,
    editable: 'never',
    filtering: false,
    defaultFilter: query.totalNetAmount,
  }

  const totalGrossAmount = {
    title: 'Total Gross Amount',
    field: 'totalGrossAmount',
    render: rowData => <div style={{width: 80}}>{formatCurrency(rowData.totalGrossAmount)}</div>,
    editable: 'never',
    filtering: false,
    defaultFilter: query.totalGrossAmount,
  }

  const voucherNumber = {
    title: 'Voucher Number',
    field: 'voucherNumber',
    editable: 'never',
    defaultFilter: query.voucherNumber,
  }

  const lexofficeLink = {
    title: 'Lexoffice Link + PDF',
    field: 'voucherNumber',
    render: rowData => <div style={{width: 120}}>
      <a href={`https://app.lexoffice.de/vouchers#!/VoucherView/Invoice/${rowData.resourceId}`} target="_blank"
         rel="noreferrer"><img src={lexofficeIcon} alt="Lexoffice" with={25} height={20}/></a>
      &nbsp;&nbsp;
      {rowData.files?.documentFileId
        ? <a href={'/api/horus/lexoffice/invoicePdf/' + rowData.files?.documentFileId} target="_blank"
             rel="noreferrer"><Description/></a>
        : ''}
    </div>,
    editable: 'never',
    filtering: false,
  }

  const voucherStatus = {
    title: 'Voucher Status',
    field: 'voucherStatus',
    editable: 'never',
    defaultFilter: query.voucherStatus,
  }

  const archived = {
    title: 'Archived',
    field: 'archived',
    render: rowData => (
      <div>{rowData.archived ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#636363'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData?.archived || false].includes(term.toLowerCase()))
    },
    filtering: false,
    defaultFilter: query.archived,
  }

  const createdDate = {
    title: 'Created',
    field: 'createdDate',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdDate)}</div>,
    editable: 'never',
    filtering: false,
  }

  const updatedDate = {
    title: 'Updated',
    field: 'updatedDate',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedDate)}</div>,
    editable: 'never',
    filtering: false,
    defaultSort: 'desc',
  }

  const columnSet = [
    voucherNumber,
    voucherStatus,
    address,
    totalNetAmount,
    totalGrossAmount,
    voucherDate,
    lexofficeLink,
    archived,
    version,
    resourceId,
    createdDate,
    updatedDate,
  ]

  const delay = t => new Promise(resolve => setTimeout(resolve, t))

  const [page, setPage] = useState(0)
  const handlePageChange = (page) => {
    setPage(page)
  }

  // Effect to update table filters based on URL query parameters
  useEffect(() => {
    if (tableRef?.current) {
      delay(500).then(() =>{
        tableRef.current.state.query.filters = [
          {
            column: { field: 'resourceId' },
            operator: '=',
            value: query.resourceId,
          },{
            column: { field: 'voucherNumber' },
            operator: '=',
            value: query.voucherNumber,
          }
        ]

        const defaultSortColumnName = tableRef.current.dataManager.columns[query.orderBy]?.field || ''
        const newOrderByCollection = []

        for (let i = 0; i < tableRef.current.dataManager.columns.length; i++) {
          const columnIsActive = (query.orderBy === (''+i)) // compare string to string
          newOrderByCollection.push({
            orderBy: i,
            orderDirection: columnIsActive ? query.orderDirection : '',
            sortOrder: columnIsActive ? 1 : null,
          })
        }

        tableRef.current.dataManager.orderByCollection = newOrderByCollection // the real thing - for table controls
        tableRef.current.state.query.orderBy = {field: defaultSortColumnName} // the real thing - for data fetching
        tableRef.current.state.query.orderDirection = query.orderDirection
        setPage(0) //reset paging to show filter results from page 0
        tableRef.current.onQueryChange()
      })

    }
  }, [query.resourceId, query.voucherNumber, query.orderBy, query.orderDirection, setPage])

  const onOrderCollectionChange = (orderByCollection) => {
    const orderBy = tableRef?.current?.state?.orderByCollection?.[0]?.orderBy ?? ''
    const orderDirection = tableRef?.current?.state?.orderByCollection?.[0]?.orderDirection ?? ''
    updateCurrentUrlParams(history, {orderBy, orderDirection})
  }

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={<div>
          <Tooltip title={"Reload"} enterTouchDelay={0}>
            <IconButton aria-label="Reload" onClick={refreshHook}><Replay /></IconButton>
          </Tooltip>
          <Tooltip title={"Clear Filter"} enterTouchDelay={0}>
            <IconButton aria-label="Clear Filter" onClick={() => {
              props.history.push('/app/lexoffice/invoice/?resourceId=&voucherNumber=')
            }}><FilterIcon color={'#757575'} size={22} /></IconButton>
          </Tooltip>
        </div>}
        tableRef={tableRef}
        columns={columnSet}
        onPageChange={handlePageChange}
        page={page}
        data={qry =>
          new Promise((resolve, reject) => {
            //console.log(qry)
            let url = "/api/horus/lexoffice/invoices?cache=clear&"
            url += `page=${qry.page + 1}`
            url += `&perPage=${qry.pageSize}`
            if (qry.search) {
              url += `&q=${qry.search}`
            }
            if (qry.orderBy) {
              url += `&sort=${qry.orderBy.field}&order=${qry.orderDirection}`
            }
            if (qry.filters.length) {
              const filter = qry.filters.map((filter) => {
                return `&${filter.column.field}${filter.operator}${filter.value}`
              })
              url += filter.join("")
            }
            fetch(url)
              .then((resp) => resp.json())
              .then((resp) => {
                resolve({
                  data: resp.data,
                  page: resp.page - 1,
                  totalCount: resp.total,
                })
              })
          })
        }
        icons={tableIcons}
        detailPanel={null}
        editable={null}
        onOrderCollectionChange={onOrderCollectionChange}
        options={{
          debounceInterval: 700,
          search: false,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          thirdSortClick: false,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 50, 500, 5000],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          //tableLayout: 'fixed',
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '3px 3px 3px 3px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default LexofficeInvoiceTable

import React from 'react';
import {withRouter} from 'react-router-dom';
import {DocumentScanner} from '@mui/icons-material';

const ViewReports = withRouter((props) => {

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <DocumentScanner fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Reports</div>
        </div>
        <div></div>
      </div>

      <ol>
        <li>
          <div style={{fontWeight: 'bold', fontSize: 18}}>All Reservations Report</div>
          <div style={{fontSize: 14, marginTop: 4}}>All reservations and expired nights with sell cycle, sell price, booking number, margin and more details.</div>
          <div style={{fontSize: 14, color: '#64788f'}}>Output: XLSX, ~3 MB, time to create ~1min</div>
          <div style={{marginTop: 10, marginBottom: 30}}><a href={'/api/horus/report/allReservations'} target="_blank" rel="noreferrer" className="ahrefButton">Download</a></div>
        </li>
        <li>
          <div style={{fontWeight: 'bold', fontSize: 18}}>Active Reservations Report</div>
          <div style={{fontSize: 14, marginTop: 4}}>Active Reservations (status = 'new' or 'confirmed_hotel') plus expired nights.</div>
          <div style={{fontSize: 14, color: '#64788f'}}>Output: XLSX, ~2 MB, time to create ~1min</div>
          <div style={{marginTop: 10, marginBottom: 30}}><a href={'/api/horus/report/activeReservations'} target="_blank" rel="noreferrer" className="ahrefButton">Download</a></div>
        </li>
        <li>
          <div style={{fontWeight: 'bold', fontSize: 18}}>PremierInn Saving Potential</div>
          <div style={{fontSize: 14, marginTop: 4}}>Get all active and future PI reservations and compare existing buy rate vs. today's prices.</div>
          <div style={{fontSize: 14, color: '#64788f'}}>Output: XLSX, ~1 MB, time to create ~1min</div>
          <div style={{marginTop: 10, marginBottom: 30}}><a href={'/api/horus/report/savingPotential'} target="_blank" rel="noreferrer" className="ahrefButton">Download</a></div>
        </li>
      </ol>

    </div>
  )
})


export default ViewReports

import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Checkbox, FormControl, MenuItem, Select, Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import * as actions from '../../store/actions'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {Check, Close} from "@mui/icons-material";

const SubscriptionCategoryTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.settings.subscriptionCategory)

  const name = {
    title: 'Name',
    field: 'name',
    render: rowData => <div style={{fontWeight: 'bold'}}>{rowData.name}</div>,
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const nightsScope = {
    title: 'Nights Scope',
    field: 'nightsScope',
    render: rowData => <div style={{textTransform: 'capitalize'}}>{rowData.nightsScope}</div>,
    editComponent: props => (
      <FormControl
        variant="standard"
        sx={{ m: 1, minWidth: 120 }}
        onKeyDown={e => handleKey(e)}
      >
        <Select
          id="nightsScopeSelect"
          value={props.value}
          label="Scope"
          onChange={e => props.onChange(e.target.value)}
          onKeyDown={e => handleKey(e)}
        >
          <MenuItem value={''}></MenuItem>
          <MenuItem value={'month'}>Month</MenuItem>
          <MenuItem value={'year'}>Year</MenuItem>
        </Select>
      </FormControl>
    )
  }

  const nightsPerMonth = {
    title: 'Nights Per Month',
    field: 'nightsPerMonth',
    defaultSort: 'asc',
    editComponent: props => (
      <input
        value={props.value ?? ''}
        onChange={e => props.onChange(e.target.value)}
        style={props.rowData.nightsScope !== 'month' ? {display: 'none'} : null}
      />
    ),
  }

  const nightsPerYear = {
    title: 'Nights Per Year',
    field: 'nightsPerYear',
    editComponent: props => (
      <input
        value={props.value ?? ''}
        onChange={e => props.onChange(e.target.value)}
        style={props.rowData.nightsScope !== 'year' ? {display: 'none'} : null}
      />
    )
  }

  const description = {
    title: 'Description',
    field: 'description',
    render: rowData => <div style={{maxHeight: 100, overflow: 'hidden'}} className="fadeOutText" dangerouslySetInnerHTML={{__html: rowData.description}} />,
    editComponent: props => (
      <textarea
        rows="5" cols="35"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const visible = {
    title: 'Visible Search',
    field: 'visibleSearch',
    render: rowData => (
      <div>{rowData.visibleSearch ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    )
  }

  const shareable = {
    title: 'Business (Shareable)',
    field: 'shareable',
    render: rowData => (
      <div>{rowData.shareable ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    )
  }

  const directLink = {
    title: 'Direct Link',
    field: 'directLink',
    render: rowData => (
      <div>{rowData.directLink ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    )
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <span>{formatDateTime(rowData.createdAt)}</span>,
    cellStyle: {
      width: 40,
    },
    headerStyle: {
      width: 40,
    },
    editable: 'never',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <span>{formatDateTime(rowData.updatedAt)}</span>,
    cellStyle: {
      width: 40,
    },
    headerStyle: {
      width: 40,
    },
    editable: 'never',
  }

  const columnSet = [
    name,
    nightsScope,
    nightsPerMonth,
    nightsPerYear,
    description,
    visible,
    directLink,
    shareable,
    createdAt,
    updatedAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={"Subscription Categories"}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postSubscriptionCategory(newData))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              // set unused month/year field to null
              if (newData.nightsScope === 'month') {
                newData.nightsPerYear = null
              } else {
                newData.nightsPerMonth = null
              }
              dispatch(actions.putSubscriptionCategory(oldData.id, newData))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteSubscriptionCategory(oldData.id))
              resolve()
            }),
        }}
        detailPanel={null}
        options={{
          search: false,
          filtering: false,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#fff" : "#f9fcff",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default SubscriptionCategoryTable

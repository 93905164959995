import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET
 */
export const getCacheStats = () => {
  return dispatch => {
    dispatch(getCacheStatsStart())
    axios.get('/horus/cacheControl/stats')
      .then ( response => {
        dispatch(getCacheStatsSuccess(response.data))
      })
      .catch(error => {
        dispatch(getCacheStatsFail(error))
      })
  }
}

export const getCacheStatsStart = () => {
  return {
    type: actionTypes.GET_CACHE_STATS_START,
  }
}

export const getCacheStatsSuccess = (res) => {
  return {
    type: actionTypes.GET_CACHE_STATS_SUCCESS,
    res: res,
  }
}

export const getCacheStatsFail = (error) => {
  return {
    type: actionTypes.GET_CACHE_STATS_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postCacheFlushData = (payload) => {
  return dispatch => {
    dispatch(postCacheFlushDataStart())
    axios.post('/horus/cacheControl/flushData', payload)
      .then ( response => {
        dispatch(postCacheFlushDataSuccess(response.data))
        dispatch(getCacheStats())
      })
      .catch(error => {
        dispatch(postCacheFlushDataFail(error))
      })
  }
}

export const postCacheFlushDataStart = () => {
  return {
    type: actionTypes.POST_CACHE_FLUSH_START,
  }
}

export const postCacheFlushDataSuccess = (res) => {
  return {
    type: actionTypes.POST_CACHE_FLUSH_SUCCESS,
    res: res,
  }
}

export const postCacheFlushDataFail = (error) => {
  return {
    type: actionTypes.POST_CACHE_FLUSH_FAIL,
    error: error,
  }
}

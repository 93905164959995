import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  reservations: [],
}

const startLoading = (state) => {
  return {
    ...state,
    isLoading: true,
  }
}

const setReservations = (state, action) => {
  return {
    ...state,
    reservations: action.res,
    isLoading: false,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_RESERVATION_START: return startLoading(state)
    case actionTypes.GET_RESERVATION_SUCCESS: return setReservations(state, action)
    default: return state
  }
}

export default reducer

import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {InputAdornment, TextField, Typography} from '@mui/material';
import {formatCurrency, formatDate, formatDecimalEuToUs, formatDecimalUsToEu} from '../../services/unitFormater';
import * as actions from '../../store/actions'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {GermanDatePicker} from "../components/LocalDateInput";
import {isBefore} from "date-fns";

const SpecialDateSurchargeTable = withRouter((props) => {

  const {room} = props
  const dispatch = useDispatch()
  const specialDateData = useSelector(state => state.hotel.specialDates)

  const data = specialDateData.map(specialDate => {
    const surchargeArray = specialDate.BookingSpecialDateSurcharges

    if(surchargeArray.length) {
      const roomSurcharge = surchargeArray.filter(arr => arr.BookingRoomId === room.id)
      if (roomSurcharge.length > 0) {
        return ({
          id: specialDate.id,
          surchargeID: roomSurcharge[0].surchargeID,
          dateFrom: specialDate.dateFrom,
          dateTo: specialDate.dateTo,
          description: specialDate.description,
          stripeProduct: roomSurcharge[0].stripeProduct,
          stripePrice: roomSurcharge[0].stripePrice,
          surcharge: roomSurcharge[0].surcharge,
          roomId: roomSurcharge[0].BookingRoomId,
        })
      }
    }
    /* fallback */
    return({
      id: specialDate.id,
      surchargeID: '',
      dateFrom: specialDate.dateFrom,
      dateTo: specialDate.dateTo,
      description: specialDate.description,
      stripeProduct: '',
      stripePrice: '',
      surcharge: 0,
      roomId: room.id,
    })
  })

  const surchargeID = {
    title: 'SurchargeID',
    field: 'surchargeID',
    editable: 'never',
    filtering: false,
  }

  const dateFrom = {
    title: 'Date From',
    field: 'dateFrom',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.dateFrom)}</div>,
    editComponent: props => <GermanDatePicker data={props} />,
    defaultSort: 'asc',
    customFilterAndSearch: (term, rowData) => {
      return isBefore(new Date(term),new Date(rowData.dateFrom))
    },
    defaultFilter: '01/01/' + new Date().getFullYear()
  }

  const dateTo = {
    title: 'Date To',
    field: 'dateTo',
    render: rowData => <div>{formatDate(rowData.dateTo)}</div>,
    editable: 'never',
    filtering: false,
  }

  const surcharge = {
    title: 'Surcharge',
    field: 'surcharge',
    filtering: false,
    render: rowData => formatCurrency(rowData.surcharge || ''),
    editComponent: props => (
      <TextField
        size={'small'}
        value={formatDecimalUsToEu(props.value || '')}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
          style: {fontSize: 13}
        }}
        onChange={e => props.onChange(formatDecimalEuToUs(e.target.value))}
      />
    )
  }

  const description = {
    title: 'Description',
    field: 'description',
    render: rowData => <div style={{maxHeight: 100, overflow: 'hidden'}} className="fadeOutText" dangerouslySetInnerHTML={{__html: rowData.description}} />,
    editable: 'never',
  }

  const stripe = {
    title: 'Stripe',
    field: 'stripeProduct',
    editable: 'never',
    render: rowData => <div>
      <div>{rowData.stripeProduct}</div>
      <div>{rowData.stripePrice}</div>
    </div>,
    filtering: false,
  }

  const columnSet = [
    surchargeID,
    dateFrom,
    dateTo,
    description,
    surcharge,
    stripe,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={"Special Date Surcharge"}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putSpecialDateSurcharge({
                surcharge: newData.surcharge,
                roomId: room.id,
                dateId: newData.id,
              }))
              resolve()
            }),
        }}
        detailPanel={null}
        options={{
          search: false,
          filtering: true,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#fff" : "#f9fcff",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})

export default SpecialDateSurchargeTable

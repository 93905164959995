import React, {useEffect} from 'react'
import {useParams, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import {Grid, Button} from '@mui/material';
import {ArrowBack, Hotel} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import HotelDetailsForm from '../components/HotelDetailsForm'


const ViewHotelDetails = withRouter((props) => {
  const dispatch = useDispatch()
  const { hotelId } = useParams()

  const hotelList = useSelector(state => state.hotel?.hotels)
  const hotelAttributeList = useSelector(state => state.settings?.hotelAttribute)

  useEffect(() => {
    dispatch(actions.getHotelDetails(hotelId))
    dispatch(actions.getSettingsHotelAttribute())
    dispatch(actions.getRoomByHotel(hotelId))
    dispatch(actions.getRoomCategory())
    dispatch(actions.getSpecialDateByHotel(hotelId))
    dispatch(actions.getDistrict())
  },[dispatch, hotelId])

  const selectedHotel = hotelList?.filter(hotel => {
    return hotel?.id === parseInt(hotelId)
  })?.[0] || null

  //const hotelsLoading = useSelector(state => state.hotel.hotelsLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <Button onClick={() => { props.history.push('/app/hotels'); }}><ArrowBack /></Button>
        <div className="headlineIconBox">
          <Hotel fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Hotel Details</div>
        </div>
        <div>{selectedHotel?.name}</div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {selectedHotel !== null ? <HotelDetailsForm hotel={selectedHotel} attributeList={hotelAttributeList} /> : <Loading />}
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewHotelDetails

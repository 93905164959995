import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import {setDefaultOptions} from "date-fns";
import deLocale from "date-fns/locale/de";

export const GermanDatePicker = (props) => {

  setDefaultOptions({ locale: deLocale })

  return(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label=""
        key=""
        inputFormat="dd.MM.yyyy"
        format="dd.MM.yyyy"
        clearable={true}
        value={props?.data?.value ? new Date(props.data.value) : null}
        onChange={(value) => {
          props.data.onChange(value)
        }}
        minDate={props?.minDate}
        slotProps={{
          textField: {
            InputProps: {
              className: "dateField",
              size: "small",
              //error: !!fieldState.error,
              style: {
                width: 130,
                backgroundColor: "#FFF",
                fontSize: 13,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  )
}
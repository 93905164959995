import {Autocomplete, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField} from "@mui/material";
import {
  formatCurrency,
  formatCurrencyInline,
  formatDecimalEuToUs,
  formatDecimalUsToEu
} from "../../services/unitFormater";
import React, {useCallback, useEffect, useState} from "react";
import axios from "../../axios.config";
import {Cancel, CheckBox, HelpCenter, RemoveRedEye} from "@mui/icons-material";
import {debounce} from '@mui/material/utils';
import * as actions from "../../store/actions";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";


export const ReservationDetailsInput = ({night, fieldName, subscription, businessUser, refreshHook}) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(formatDecimalUsToEu(night[fieldName] ?? ''))
  const [loading, setLoading] = useState(false)
  const [timer, setTimer] = useState(null)

  const updateValue = (e, refresh=true) => {
    const newValue = e?.replaceAll('.','') || ''
    setValue(newValue)
    setLoading(true)
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      const payload = {
        [fieldName]: formatDecimalEuToUs(newValue),
        arrivalDate: night.arrivalDate,
        BookingSubscription: {id: subscription.id},
        BusinessUserInvitation: businessUser,
      }
      axios.put('/horus/reservation/'+night.id, payload)
        .then ( response => {
          setLoading(false)
          if(refresh) refreshHook()
        })
        .catch(error => {
          dispatch(actions.showNotification('Update reservation details error','error'))
          setLoading(false)
        })
    }, 800)
    setTimer(newTimer)
  }

  useEffect(() => {
    setValue(formatDecimalUsToEu(night[fieldName] ?? ''))
  }, [night, fieldName])

  useEffect(() => {
    return () => {
      clearTimeout(timer) // Cleanup timeout on unmount to prevent memory leaks
    }
  }, [timer])

  const LookupButton = () => {
    if(['surchargePrice'].includes(fieldName)) {
      return(<IconButton onClick={() => updateValue('?', true)}>
        <RemoveRedEye />
      </IconButton>)
    } else {
      return('')
    }
  }

  return(
    <div>
      <TextField
        size={'small'}
        value={value}
        InputProps={{
          endAdornment: <InputAdornment position="end">{loading ? '↑' : '€'}</InputAdornment>,
          style: {fontSize: 13, width: 120, backgroundColor: loading ? '#fdf6f2' : '#ffffff'}
        }}
        onChange={e => updateValue(e.target.value)}
      />
      <LookupButton />
    </div>
  )
}


export const TripDetailsInput = ({fieldName, rowData, refreshHook}) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(rowData[fieldName] || '')
  const [loading, setLoading] = useState(false)
  const [timer, setTimer] = useState(null)

  const updateValue = (e, refresh=true) => {
    const newValue = e?.replaceAll('.','') || ''
    setValue(newValue)
    setLoading(true)
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      const payload = {
        [fieldName]: newValue,
        BookingSubscription: {id: rowData.BookingSubscription.id},
        BusinessUserInvitation: rowData.BusinessUserInvitation,
      }
      axios.put('/horus/trip/'+rowData.id, payload)
        .then ( response => {
          setLoading(false)
          if(refresh) refreshHook()
        })
        .catch(error => {
          dispatch(actions.showNotification('Update trip details error','error'))
          setLoading(false)
        })
    }, 800)
    setTimer(newTimer)
  }

  useEffect(() => {
    setValue(rowData[fieldName] || '')
  }, [rowData, fieldName])

  useEffect(() => {
    return () => {
      clearTimeout(timer) // Cleanup timeout on unmount to prevent memory leaks
    }
  }, [timer])

  return(
    <div>
      <TextField
        size={'small'}
        value={value}
        InputProps={{
          endAdornment: <InputAdornment position="end">{loading ? '↑' : ''}</InputAdornment>,
          style: {fontSize: 13, width: 200, backgroundColor: loading ? '#fdf6f2' : '#ffffff'}
        }}
        onChange={e => updateValue(e.target.value)}
      />
    </div>
  )
}


export const TripInvoiceDetails = ({tripId, rowData, refreshHook}) => {

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)

  const [uploading, setUpLoading] = useState(false)
  const [timer, setTimer] = useState(null)

  const [filterUsedItems, setFilterUsedItems] = useState(true)

  const handleSoftOkChange = (value) => {
    dispatch(actions.putTrip(tripId, {
      BookingSubscription: rowData?.BookingSubscription || null,
      BusinessUserInvitation: rowData?.BusinessUserInvitation || null,
      hotelInvoiceDeltaSoftOk: value,
    }, refreshHook))
  }

  const getValue = useCallback(() => {
    setLoading(true)
    axios.get('/horus/trip/lexofficeVoucher/'+tripId)
      .then ( response => {
        setSelected(response?.data?.data?.LexofficeVouchers || [])
        refreshHook()
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(actions.showNotification('Get invoice data error','error'))
      })
  }, [dispatch, refreshHook, tripId])

  useEffect(() => {
    getValue()
  },[getValue, tripId])

  const postValue = (value) => {
    setUpLoading(true)
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      axios.put('/horus/trip/lexofficeVoucher/'+tripId, value)
        .then ( response => {
          setUpLoading(false)
          getValue()
        })
        .catch(error => {
          setUpLoading(false)
          dispatch(actions.showNotification('Update invoice association error','error'))
        })
    }, 800)
    setTimer(newTimer)
  }

  const fetchOptions = (searchString, callback) => {
    // Example API call with parameter
      fetch(`/api/horus/lexoffice/voucherAutocomplete/?filterUsedItems=${filterUsedItems}&q=${searchString}`)
      .then((response) => response.json())
      .then((data) => callback(data))
      .catch((error) => {
        console.error('Error fetching data:', error)
        dispatch(actions.showNotification('Get invoice list error','error'))
      })
  }

  const handleInputChange = debounce((searchString='') => {
      fetchOptions(searchString, (data) => {
        setOptions(data?.data)
        setLoading(false)
      })
  },500)

  const HighlightSearchResults = ({ result='', searchInputText='', keypart='' }) => {
    // Ensure result and searchInputText are strings
    if (typeof result !== 'string' || typeof searchInputText !== 'string') {
      console.error('Invalid result or searchInputText')
      return null
    }

    const keywords = searchInputText.toLowerCase().split(' ')
    const regex = new RegExp(`(${keywords.join('|')})`, 'gi')

    const formattedResult = result.split(regex).map((part, index) =>
      keywords.includes(part.toLowerCase()) ? <span style={{backgroundColor: '#fff782'}} key={keypart+index}>{part}</span> : part
    )

    return (
      <>
        {formattedResult}
      </>
    )
  }

  return(
    <div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8}}>
        <div style={{fontWeight: 'bold'}}>Trip Hotel Invoice</div>
        <FormControlLabel control={<Checkbox
          checked={rowData.hotelInvoiceDeltaSoftOk}
          onChange={e => handleSoftOkChange(e.target.checked)}
        />} style={{color: '#999'}} label="Invoice Delta Soft-OK"/>
        <FormControlLabel control={<Checkbox
          checked={filterUsedItems}
          onChange={e => setFilterUsedItems(e.target.checked)}
        />} style={{color: '#999'}} label="Filter assigned invoices"/>
      </div>

      <Autocomplete
        multiple
        id="tripInvoiceNumbers"
        open={open}
        freeSolo
        onOpen={() => {
          handleInputChange();
          setOpen(true)
        }}
        onClose={() => setOpen(false)}
        options={options}
        loading={loading}
        disableCloseOnSelect
        //groupBy={(option) => option.status}
        getOptionLabel={(option) => <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{
            fontSize: 12,
            backgroundColor: (option?.BookingTrip_lexofficeVoucher?.autoMatch ? '#f3e9c9' : '#f3dbc9'),
            borderRadius: 5,
            padding: '1px 6px',
            marginRight: 4
          }}>
            <Link to={`/app/lexoffice/${option?.type}/?voucherNumber=${option?.voucherNumber}`}>{option?.voucherNumber}</Link>
            {option?.BookingTrip_lexofficeVoucher?.autoMatch
              ? <span title="Auto Match">💡</span>
              : ' '
            }
          </div>
          <div
            style={{fontSize: 12, fontWeight: 'bold', marginRight: 8}}>{formatCurrency(option.totalGrossAmount)}</div>
          <div style={{fontSize: 12, marginRight: 8}}>{option.remark}</div>
        </div>}
        value={selected}
        onChange={(e, value) => {
          //dispatch(actions.putHotelSuperAttribute(hotel.id, value))
          //console.log('value', {e, value})
          setSelected(value)
          postValue(value)
        }}
        onInputChange={(e, newInputValue) => {
          //setInputValue(newInputValue)
          //console.log('inputvalue', {e, newInputValue})
          setLoading(true)
          handleInputChange(newInputValue)
        }}
        isOptionEqualToValue={(option, value) => {
          return option.resourceId === value.resourceId
        }} //required for loading
        renderOption={(props, option, {selected, inputValue}) => (
          <li {...props} key={option.resourceId}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div
                style={{fontSize: 12, backgroundColor: '#f3dbc9', borderRadius: 5, padding: '3px 6px', marginRight: 4}}>
                <HighlightSearchResults result={option?.voucherNumber} searchInputText={inputValue} keypart={'A'}/>
              </div>
              <div style={{
                fontSize: 12,
                fontWeight: 'bold',
                marginRight: 8
              }}>{formatCurrency(option.totalGrossAmount)}</div>
              <div style={{fontSize: 12}}>
                <HighlightSearchResults result={option?.remark} searchInputText={inputValue} keypart={'B'}/>
              </div>
            </div>
          </li>
        )}
        filterOptions={(options, params) => {
          return (options)
        }} // disable internal filtering due to serverside filter

        size="small"
        renderInput={(params) => (
          <TextField {...params} InputProps={{
            ...params.InputProps,
            style: {backgroundColor: uploading ? '#fdf6f2' : '#ffffff'}
          }} variant="outlined" label="Invoice Numbers" placeholder=""/>
        )}
      />
      <div style={{marginTop: 8, display: 'flex', alignItems: 'center'}}>
        <div style={{marginRight: 5}}>{
          rowData?.hotelInvoiceDelta === null
            ? <div style={{display: 'flex', alignItems: 'center', backgroundColor: '#fff', borderRadius: 5, padding: '2px 6px'}}>
              <HelpCenter style={{color: '#d8b116'}} title="missing"/>
              <span style={{color: '#d8b116'}}>missing</span>
            </div>
            : rowData?.hotelInvoiceDelta === 0
              ? <div style={{display: 'flex', alignItems: 'center', backgroundColor: '#fff', borderRadius: 5, padding: '2px 6px'}}>
                <CheckBox style={{color: '#0fa14a'}} title="ok"/>
                <span style={{color: '#0fa14a'}}>ok</span>
              </div>
              : rowData?.hotelInvoiceDeltaSoftOk
                ? <div style={{display: 'flex', alignItems: 'center', backgroundColor: '#fff', borderRadius: 5, padding: '2px 6px'}}>
                    <Cancel style={{color: '#0fa14a'}} title="soft ok"/>
                    <span style={{color: '#0fa14a'}}>soft ok</span>
                  </div>
                : <div style={{display: 'flex', alignItems: 'center', backgroundColor: '#fff', borderRadius: 5, padding: '2px 6px'}}>
                    <Cancel style={{color: '#ae5108'}} title="not ok"/>
                    <span style={{color: '#ae5108'}}>not ok</span>
                  </div>
        }</div>

        <div>
          <span>Delta {rowData?.hotelInvoiceDelta > 0 ? '+' : ''}{formatCurrencyInline(rowData?.hotelInvoiceDelta || 0)}</span> &nbsp;
          <span style={{color: '#777'}}>(Invoice {formatCurrencyInline(rowData?.hotelInvoiceSum || 0)} vs. Purchase Rate {formatCurrencyInline(rowData?.hotelBookingRateSum || 0)})</span>
        </div>
      </div>
    </div>
  )
}
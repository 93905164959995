import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  formatCurrency, formatCurrencyInline,
  formatDate,
  formatDateTime,
  formatDecimalEuToUs,
  formatDecimalUsToEu
} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {shortenText} from "../../services/stringHelper";
import {Check, Close, PauseCircleFilled, PlayCircleFilled, StopCircle} from "@mui/icons-material";
import {HelpIcon} from "../../assets/SvgIcons";

const SubscriptionSegmentTable = withRouter((props) => {

  const dispatch = useDispatch()
  const evaluation = useSelector(state => state.subscription.evaluation)
  const subscriptionID = evaluation?.subscriptionData?.subscriptionID
  const data = evaluation?.subscriptionData?.BookingSubscriptionSegments || []
  const offerList = useSelector(state => state.offer.offers)

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const startDate = {
    title: 'Start Date',
    field: 'startDate',
    type: 'date',
    render: rowData => <div>{formatDate(rowData.startDate)}</div>,
    headerStyle: {
      minWidth: 165,
    },
    editable: 'never',
    defaultSort: 'asc',
  }

  const endDate = {
    title: 'End Date',
    field: 'endDate',
    render: rowData => <div>{formatDate(rowData.endDate)}</div>,
    type: 'date',
    editable: 'never',
    headerStyle: {
      minWidth: 165,
    },
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const segmentType = {
    title: 'Type',
    field: 'segmentType',
    render: rowData => <div>
      {rowData.segmentType === 'subscription' ? <PlayCircleFilled sx={{color: '#25a9d5'}} titleAccess="Subscription" /> : ''}
      {rowData.segmentType === 'breakMonth' ? <PauseCircleFilled sx={{color: '#90d954'}} titleAccess="Month Break" /> : ''}
      {rowData.segmentType === 'breakCustom' ? <PauseCircleFilled sx={{color: '#d9b054'}} titleAccess="Custom Break - change in subscription cycle" /> : ''}
      {rowData.segmentType === 'termination' ? <StopCircle sx={{color: '#b01b37'}} titleAccess="Termination" /> : ''}
    </div>,
    editable: 'never',
  }

  const segmentID = {
    title: 'SegmentID',
    field: 'segmentID',
    editable: 'never',
  }

  const BookingOfferId = {
    title: 'Offer',
    field: 'BookingOffer',
    render: rowData => {
      const offer = rowData?.BookingOffer
      const offerDetails = offerList.filter(o => o.offerID === offer?.offerID)?.[0]
      const category = offerDetails?.BookingSubscriptionCategory?.name
      const shareable = offerDetails?.BookingSubscriptionCategory?.shareable
      const year = offerDetails?.BookingSubscriptionCategory?.nightsScope === 'year'
      const activeSale = offerDetails?.activeSale
      const discount = offerDetails?.discount
      const duration = offerDetails?.BookingSubscriptionDuration?.name
      const roomName = offerDetails?.BookingRoom?.name
      const hotelName = offerDetails?.BookingRoom?.BookingHotel?.name
      const price = offer?.price?.toFixed(2)?.replace('.',',')
      const pricePerMonth = offerDetails?.pricePerMonth?.toFixed(2)?.replace('.',',')

      return(rowData?.BookingOffer?.offerID
        ? <div style={{fontSize: 13, width: 220, color: (rowData.segmentType === 'subscription') ? '#000000' : '#8da0a9'}}>
            <div>{category} @ {hotelName}</div>
            <div style={{fontSize: 10}}>{roomName} | {duration} Laufzeit</div>
            <div style={{fontSize: 10}}>
              <Link to={'/app/offers/'+offer?.offerID}>{offer?.offerID}</Link> | <a
              href={'https://dashboard.stripe.com/products/'+offerDetails?.stripeProduct} target="_blank" rel="noreferrer">{offerDetails?.stripeProduct}</a>
            </div>
            <div style={{fontSize: 10}}>{price} €/Nacht | {pricePerMonth} €/Monat</div>
            <div style={{fontSize: 10}}>{
              shareable ? <span style={{color:"blue"}}>business</span> : 'single'
            } | {
              year ? <span style={{color:"purple"}}>year</span> : 'month'
            } | {
              activeSale ? 'active' : <span style={{color:"red"}}>inactive</span>
            } | {
              discount > 0 ? <span style={{color:"green"}}>{formatCurrencyInline(discount)} discount</span> : 'no discount'
            }</div>
          </div>
        : ''
      )
    },

    customFilterAndSearch: (term, rowData) => {
        const offerID = rowData?.BookingOffer?.offerID || ''
        const offerDetails = offerList.filter(o => o.offerID === rowData?.BookingOffer?.offerID)?.[0]
        const category = offerDetails?.BookingSubscriptionCategory?.name || ''
        const hotelName = offerDetails?.BookingRoom?.BookingHotel?.name || ''
        const price = rowData?.BookingOffer?.price?.toFixed(2)?.replace('.',',') || ''
        const pricePerMonth = offerDetails?.pricePerMonth?.toFixed(2)?.replace('.',',') || ''
        const searchString = offerID + ' ' + category + ' ' + hotelName + ' ' + price + ' ' + pricePerMonth
        return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
    editComponent: props => {
      const offerListFilteredBySubscription =
        offerList?.filter(o =>
          (o?.BookingRoom?.BookingHotel?.hotelId === props.rowData?.BookingOffer?.BookingRoom?.BookingHotel?.hotelId)
          && (o?.BookingSubscriptionCategory?.shareable === props.rowData?.BookingOffer?.BookingSubscriptionCategory?.shareable)
        ) || offerList
      return(
      <div style={{width: 220}}>
        { props.rowData.segmentType === 'subscription'
          ? <Autocomplete
              id="BookingOfferId"
              options={offerListFilteredBySubscription}
              getOptionLabel={(option) => {
                return(option?.offerID || '')
              }}
              value={props.value}
              onChange={(e, value) => {
                props.onChange({id:value?.id, offerID:value?.offerID})
              }}
              autoHighlight={true} // required to select entry without submitting entire material-table row
              onKeyDown={e => handleKey(e)}
              isOptionEqualToValue={(option, value) => {
                return option?.offerID === value?.offerID
              }}
              renderOption={(props, option) => {
                const offerDetails = offerList.filter(o => o.offerID === option.offerID)?.[0]
                const category = offerDetails?.BookingSubscriptionCategory?.name
                const shareable = offerDetails?.BookingSubscriptionCategory?.shareable
                const year = offerDetails?.BookingSubscriptionCategory?.nightsScope === 'year'
                const activeSale = offerDetails?.activeSale
                const discount = offerDetails?.discount
                const duration = offerDetails?.BookingSubscriptionDuration?.name
                const roomName = offerDetails?.BookingRoom?.name
                const hotelName = offerDetails?.BookingRoom?.BookingHotel?.name
                const price = option?.price?.toFixed(2)?.replace('.',',') || 'n/a'
                const pricePerMonth = offerDetails?.pricePerMonth?.toFixed(2)?.replace('.',',')
                return(
                  <div {...props}>
                    <div style={{fontSize: 13}}>
                      <div>{category} @ {hotelName}</div>
                      <div style={{fontSize: 10}}>{roomName} | {duration} Laufzeit</div>
                      <div style={{fontSize: 10}}>{option.offerID}</div>
                      <div style={{fontSize: 10}}>{price} €/Nacht | {pricePerMonth} €/Monat</div>
                      <div style={{fontSize: 10}}>{
                        shareable ? <span style={{color:"blue"}}>business</span> : 'single'
                      } | {
                        year ? <span style={{color:"purple"}}>year</span> : 'month'
                      } | {
                        activeSale ? 'active' : <span style={{color:"red"}}>inactive</span>
                      } | {
                        discount > 0 ? <span style={{color:"green"}}>{formatCurrencyInline(discount)} discount</span> : 'no discount'
                      }</div>
                    </div>
                  </div>
                )
              }}
              filterOptions={createFilterOptions({
                matchFrom: 'any',
                stringify: (option) => {
                  const offerID = option?.offerID
                  const category = offerList.filter(o => o.id === option.id)[0]?.BookingSubscriptionCategory?.name
                  const hotelName = offerList.filter(o => o.id === option.id)[0]?.BookingRoom?.BookingHotel?.name
                  return(offerID + ' ' + category + ' ' + hotelName)
                },
              })}
              size="small"
              renderInput={(params) => {
                params.InputProps.style = {fontSize: 13}
                return(
                  <TextField
                    {...params}
                    placeholder=""
                  />
                )
              }}
            />
          : <div style={{color:"gray",fontSize: 12}}>Break and termination segments inherit the offer of the last subscription segment.</div>
        }

      </div>
    )}
  }

  const offerDiscount = {
    title: 'Discount',
    field: 'BookingOfferDiscount',
    render: rowData => {
      const offer = offerList.filter(o => o.offerID === rowData?.BookingOffer?.offerID)?.[0]
      const offerDiscount = offer?.BookingOfferDiscount

      if(offerDiscount) {
        return(<div>
          <div style={{textDecoration: "underline", cursor: "pointer", color: "#045ebe"}}
               onClick={() => {
                 props.history.push(`/app/settings/offerDiscount`)
               }}>{offerDiscount?.offerDiscountID || ''}</div>
          <div style={{fontSize: 10}}>{offerDiscount?.name}</div>
          <br/>
          <div style={{fontSize: 10}}><b>Months:</b> {offerDiscount?.numberOfMonths}</div>
          <div style={{fontSize: 10}}><b>PricePerMonth:</b> {formatCurrencyInline(offer?.pricePerMonthWithDiscount)}
          </div>
          <div style={{fontSize: 10}}><b>Discount:</b> {formatCurrencyInline(offer?.discount)}</div>
        </div>)
      } else {
        return(null)
      }

    },
    headerStyle: {
      maxWidth: 210,
    },
    customFilterAndSearch: (term, rowData) => {
      const searchString = offerDiscount?.offerDiscountID + ' ' + offerDiscount?.name
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
  }

  const internalComment = {
    title: 'Internal Comment',
    field: 'internalComment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 190}}>{shortenText(rowData.internalComment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const nightsOffsetTransferCredit = {
    title: <Tooltip title={"Transfer nights between subscriptions or cycles. This allows customer to book additional nights, but doesn't change money flow and doesn't affect the average per night price."}>
      <div>Nights Offset (Transfer credits) <HelpIcon /></div>
    </Tooltip>,
    field: 'nightsOffsetTransferCredit',
  }

  const nightsOffsetManual = {
    title: <Tooltip title={"Sell or refund additional nights. This has impact on cycles per night sell price and requires a sell or refund price."}>
      <div>Nights Offset (Sell/ Refund) <HelpIcon /></div>
    </Tooltip>,
    field: 'nightsOffsetManual',
  }

  const nightsOffsetManualSalesPrice = {
    title: <Tooltip title={"Positive number = sold nights. Negative number = refund."}>
      <div>Offset Sell/Refund Price <HelpIcon /></div>
    </Tooltip>,
    field: 'nightsOffsetManualSalesPrice',
    render: rowData => formatCurrency(rowData.nightsOffsetManualSalesPrice || ''),
    editComponent: props => (
      <TextField
        size={'small'}
        value={formatDecimalUsToEu(props.value || '')}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
          style: {fontSize: 13, width: 100}
        }}
        onChange={e => props.onChange(formatDecimalEuToUs(e.target.value))}
      />)
  }

  const bookingAllowed = {
    title: 'Booking Allowed',
    field: 'bookingAllowed',
    render: rowData => (
      <div>{rowData.bookingAllowed ? <Check style={{ color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</div>
    ),
    editComponent: props => (
      <Checkbox
        checked={props.value}
        color="primary"
        onChange={e => {props.onChange(!props.value)}}
      />
    ),
    filtering: false,
  }

  const columnSet = [
    segmentID,
    segmentType,
    startDate,
    endDate,
    bookingAllowed,
    BookingOfferId,
    offerDiscount,
    internalComment,
    nightsOffsetTransferCredit,
    nightsOffsetManual,
    nightsOffsetManualSalesPrice,
    createdAt,
    updatedAt,
  ]
  const tableRef = useRef()

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        tableRef={tableRef}
        icons={tableIcons}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putSubscriptionSegment(oldData.segmentID, {
                ...newData,
                //startDate: getDateAsCleanUTC(newData.startDate),
                //cancellationDate: getDateAsCleanUTC(newData.cancellationDate),
                //endDate: getDateAsCleanUTC(newData.endDate),
              },subscriptionID))
              resolve()
            }),
          //onRowDelete: oldData =>
          //  new Promise(resolve => {
          //    dispatch(actions.deleteSubscriptionSegment(oldData.id))
          //    resolve()
          //  }),
        }}
        options={{
          search: true,
          filtering: false,
          filterCellStyle: {
            padding: 3,
          },
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          idSynonym: 'segmentID',
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default SubscriptionSegmentTable

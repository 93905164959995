import {VisTimeline} from "./VisTimeline";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../../store/actions';
import {add, eachDayOfInterval, sub} from "date-fns";
import {shortenText} from "../../services/stringHelper";
import {
  addTimezoneOffset,
  compareDateToArray,
  formatDateISO,
  getDateAsUTC,
  getStartAndEndFromConsecutiveDates,
  groupConsecutiveDates,
  mergeMultipleDateRangesToArray
} from "../../services/dateHelper";

const SpecialDateTimeline = () => {

  const dispatch = useDispatch()

  const specialDatesByCity = useSelector(state => state.hotel.specialDatesByCity)
  const visTimelineWindow = useSelector(state => state.mainWindow.visTimelineWindow)

  const timelineStartDate = visTimelineWindow?.start || getDateAsUTC(add(new Date(), {days: 1}))
  const timelineEndDate = visTimelineWindow?.end || getDateAsUTC(add(new Date(),{days: 14}))
  const timelineMinDate = getDateAsUTC(sub(new Date(),{days: 365}))
  const timelineMaxDate = getDateAsUTC(add(new Date(),{days: 365}))
  const timelineDays = eachDayOfInterval({ start: getDateAsUTC(new Date()), end: timelineMaxDate })
  const [timeline, setTimeline] = useState()

  const customOrder = (a, b) => {
    return a.content > b.content ? 1 : 0
  }

  const options = {
    start: timelineStartDate,
    end: timelineEndDate,
    min: timelineMinDate,
    max: timelineMaxDate,
    horizontalScroll: true,
    verticalScroll: true,
    zoomKey: "ctrlKey",
    orientation: "both",
    zoomMin: 1000 * 60 * 60 * 250,
    zoomMax: 1000 * 60 * 60 * 800,
    multiselect: true,
    stack: true,
    groupHeightMode: 'fixed',
    //height: 400,
    //maxHeight: 700,
    order: customOrder,
    maxHeight: window?.screen?.availHeight > 1100 ? 950 : 700,
  }

  const groups = specialDatesByCity?.groups?.map(groupItem => {
    return({
      ...groupItem,
      content: '<a href="/app/hotelDetails/'+groupItem.id+'" title="'+groupItem.content+'">'
        +shortenText(groupItem.content, 40, true)
        +'</a>'
    })
  }) || []

  const generateFillGaps = (timelineItems) => {
    const fillGaps = []

    //one hotel per swimlane (group)
    specialDatesByCity?.groups?.forEach(groupItem => {
      // get all special date ranges per group
      const rangeItemsInGroup = timelineItems.filter(ti => ti.group === groupItem.id)
      // extract all single days from this time ranges to calculate delta
      const daysInGroup = mergeMultipleDateRangesToArray(rangeItemsInGroup)

      // there should be special 'gap items' to select single surcharge days and mark them as fully booked or black out
      // therefore extract all range items
      const surchargeRanges = timelineItems.filter(ti => ti.group === groupItem.id && ti.specialDateType === 'surcharge')
      const hotelFullyBookedRanges = timelineItems.filter(ti => ti.group === groupItem.id && ti.specialDateType === 'hotel_fullyBooked')
      const horusFullyBookedRanges = timelineItems.filter(ti => ti.group === groupItem.id && ti.specialDateType === 'horus_fullyBooked')
      const blackOutRanges = timelineItems.filter(ti => ti.group === groupItem.id && ti.specialDateType === 'blackOut')
      const surchargeDays = mergeMultipleDateRangesToArray(surchargeRanges)
      const hotelFullyBookedDays = mergeMultipleDateRangesToArray(hotelFullyBookedRanges)
      const horusFullyBookedDays = mergeMultipleDateRangesToArray(horusFullyBookedRanges)
      const blackOutDays = mergeMultipleDateRangesToArray(blackOutRanges)

      // iterate over the full calendar timeline
      timelineDays.forEach((day) => {

        // push all surcharge days which are not overlaped by fullyBooked or blackOut to select them in addition to the full range
        if((compareDateToArray(day, surchargeDays) || compareDateToArray(day, hotelFullyBookedDays))
          && !compareDateToArray(day, horusFullyBookedDays)
          && !compareDateToArray(day, blackOutDays)
        )
        {
          fillGaps.push({
            id: groupItem?.id + '_' + formatDateISO(getDateAsUTC(day)),
            start: add(day, {hours: 2}),
            end: add(day, {hours: 22}),
            content: '',
            group: groupItem?.id,
            className: `tl-item available`,
          })
        }

        // push all days which are not already in a blackout or fully booked group in the fillGaps list as 'available'
        if(!compareDateToArray(day, daysInGroup)) {
          fillGaps.push({
            id: groupItem?.id + '_' + formatDateISO(getDateAsUTC(day)),
            start: add(day, {hours: 2}),
            end: add(day, {hours: 22}),
            content: '&nbsp;',
            group: groupItem?.id,
            className: `tl-item available`,
          })
        }
      })

    })

    return fillGaps
  }

  const timelineItems = specialDatesByCity?.timelineItems || []

  const timelineItemsWithTimelineEndDateFix = timelineItems.map(ti => {
    return({
      ...ti,
      start: add(addTimezoneOffset(new Date(ti?.start)), {hours: 2}),
      end: add(addTimezoneOffset(new Date(ti?.end)), {hours: 22}),
    })
  }) || []
  const fillGapItems = generateFillGaps(timelineItems)

  const items = [
    ...timelineItemsWithTimelineEndDateFix,
    ...fillGapItems,
  ]

  const handleButtonAdd = (action) => {
    const selection = timeline.getSelection()
    const visTimelineWindow = timeline.getWindow()
    const hotelDateMap = new Map()

    selection.forEach(i => {
      //split string to hotelId and date, e.g. "5_2023-07-13"
      const s = i.toString().split('_') || undefined
      if(s.length === 2) {
        const hotelId = s[0]
        const date = s[1]
        if(hotelDateMap.has(hotelId)) {
          //hotel id exists --> add date to existing array
          const dates = hotelDateMap.get(hotelId)
          dates.push(date)
          hotelDateMap.set(hotelId, dates)
        } else {
          //create new hotel entry in map
          const dates = []
          dates.push(date)
          hotelDateMap.set(hotelId, dates)
        }
      }
    })

    const hotelRangeArray = []
    hotelDateMap.forEach((dateArray, hotelId) => {
      const groups = groupConsecutiveDates(dateArray)
      groups.forEach(group => {
        const range = getStartAndEndFromConsecutiveDates(group)
        hotelRangeArray.push({id:hotelRangeArray.length+1, hotelId:Number(hotelId), start:range.start, end:range.end})
      })
    })

    dispatch(actions.showSpecialDateModal({action:action, hotelRangeArray:hotelRangeArray, visTimelineWindow:visTimelineWindow}))
  }

  const handleButtonDelete = (action) => {
    const selection = timeline.getSelection()
    const visTimelineWindow = timeline.getWindow()
    const hotelRangeArray = []

    selection.forEach(specialDateId => {
      const selectedSpecialDate = specialDatesByCity?.timelineItems?.filter(item  => item.id === specialDateId)?.[0]
      if(selectedSpecialDate?.id) {
        hotelRangeArray.push({id:selectedSpecialDate.id, hotelId:selectedSpecialDate.group, start:selectedSpecialDate.start, end:selectedSpecialDate.end})
      }
    })
    dispatch(actions.showSpecialDateModal({action:action, hotelRangeArray:hotelRangeArray, visTimelineWindow:visTimelineWindow}))
  }


  if(specialDatesByCity) {
    return(
      <div>
        <div style={{backgroundColor: '#ECECEC', padding: '8px'}}>
          Color Code:&nbsp;
          <span style={{fontSize: 14}}>
            <span className="buttonAvailable timelineLabel">Available</span>&nbsp;
            <span className="buttonSurcharge timelineLabel">Surcharge</span>&nbsp;
            <span className="buttonHorusFullyBooked timelineLabel">Fully Booked (manually)</span>&nbsp;
            <span className="buttonHotelFullyBooked timelineLabel">Fully Booked (Hotel)</span>&nbsp;
            <span className="buttonBlackOut timelineLabel">BlackOut</span>&nbsp;
            <span className="buttonSelected timelineLabel">Selected (ctrl/cmd to multiselect)</span>&nbsp;
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Actions:&nbsp;
          <button className="" onClick={() => handleButtonAdd('tradeFair')}>Trade Fair with Surcharge</button>&nbsp;
          <button className="" onClick={() => handleButtonAdd('horusFullyBooked')}>Fully Booked</button>&nbsp;
          <button className="" onClick={() => handleButtonAdd('blackOut')}>Black-Out</button>&nbsp;
          <button className="" onClick={() => handleButtonDelete('delete')}>Delete</button>&nbsp;
        </div>

        <br/>

        <div style={{backgroundColor:'#FFF', width:'100%'}}>
          <VisTimeline groups={groups} options={options} items={items} timeline={timeline} setTimeline={setTimeline} />
        </div>
      </div>
    )
  }
}

export default SpecialDateTimeline
export const OPEN_DRAWER = 'OPEN_DRAWER'
export const CLOSE_DRAWER = 'CLOSE_DRAWER'
export const NAVIGATE_PAGE = 'NAVIGATE_PAGE'

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT_START = 'LOGOUT_START'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const RESTORE_SESSION_START = 'RESTORE_SESSION_START'
export const RESTORE_SESSION_SUCCESS = 'RESTORE_SESSION_SUCCESS'
export const RESTORE_SESSION_FAIL = 'RESTORE_SESSION_FAIL'

export const UPLOAD_START = 'UPLOAD_START'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_FAIL = 'UPLOAD_FAIL'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'

export const GET_VERSION_START = 'GET_VERSION_START'
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS'
export const GET_VERSION_FAIL = 'GET_VERSION_FAIL'

export const GET_NEWSLETTER_START = 'GET_NEWSLETTER_START'
export const GET_NEWSLETTER_SUCCESS = 'GET_NEWSLETTER_SUCCESS'
export const GET_NEWSLETTER_FAIL = 'GET_NEWSLETTER_FAIL'

export const GET_SEARCH_ANALYTICS_START = 'GET_SEARCH_ANALYTICS_START'
export const GET_SEARCH_ANALYTICS_SUCCESS = 'GET_SEARCH_ANALYTICS_SUCCESS'
export const GET_SEARCH_ANALYTICS_FAIL = 'GET_SEARCH_ANALYTICS_FAIL'

export const GET_PRICE_ANALYTICS_START = 'GET_PRICE_ANALYTICS_START'
export const GET_PRICE_ANALYTICS_SUCCESS = 'GET_PRICE_ANALYTICS_SUCCESS'
export const GET_PRICE_ANALYTICS_FAIL = 'GET_PRICE_ANALYTICS_FAIL'

export const GET_QR_SETTINGS_START = 'GET_QR_SETTINGS_START'
export const GET_QR_SETTINGS_FAIL = 'GET_QR_SETTINGS_FAIL'
export const GET_QR_SETTINGS_SUCCESS = 'GET_QR_SETTINGS_SUCCESS'
export const POST_QR_SETTINGS_START = 'POST_QR_SETTINGS_START'
export const POST_QR_SETTINGS_FAIL = 'POST_QR_SETTINGS_FAIL'
export const POST_QR_SETTINGS_SUCCESS = 'POST_QR_SETTINGS_SUCCESS'
export const PUT_QR_SETTINGS_START = 'PUT_QR_SETTINGS_START'
export const PUT_QR_SETTINGS_FAIL = 'PUT_QR_SETTINGS_FAIL'
export const PUT_QR_SETTINGS_SUCCESS = 'PUT_QR_SETTINGS_SUCCESS'
export const DELETE_QR_SETTINGS_START = 'DELETE_QR_SETTINGS_START'
export const DELETE_QR_SETTINGS_FAIL = 'DELETE_QR_SETTINGS_FAIL'
export const DELETE_QR_SETTINGS_SUCCESS = 'DELETE_QR_SETTINGS_SUCCESS'

export const GET_LINK_SETTINGS_START = 'GET_LINK_SETTINGS_START'
export const GET_LINK_SETTINGS_FAIL = 'GET_LINK_SETTINGS_FAIL'
export const GET_LINK_SETTINGS_SUCCESS = 'GET_LINK_SETTINGS_SUCCESS'
export const POST_LINK_SETTINGS_START = 'POST_LINK_SETTINGS_START'
export const POST_LINK_SETTINGS_FAIL = 'POST_LINK_SETTINGS_FAIL'
export const POST_LINK_SETTINGS_SUCCESS = 'POST_LINK_SETTINGS_SUCCESS'
export const PUT_LINK_SETTINGS_START = 'PUT_LINK_SETTINGS_START'
export const PUT_LINK_SETTINGS_FAIL = 'PUT_LINK_SETTINGS_FAIL'
export const PUT_LINK_SETTINGS_SUCCESS = 'PUT_LINK_SETTINGS_SUCCESS'
export const DELETE_LINK_SETTINGS_START = 'DELETE_LINK_SETTINGS_START'
export const DELETE_LINK_SETTINGS_FAIL = 'DELETE_LINK_SETTINGS_FAIL'
export const DELETE_LINK_SETTINGS_SUCCESS = 'DELETE_LINK_SETTINGS_SUCCESS'

export const GET_HOTEL_START = 'GET_HOTEL_START'
export const GET_HOTEL_FAIL = 'GET_HOTEL_FAIL'
export const GET_HOTEL_SUCCESS = 'GET_HOTEL_SUCCESS'
export const GET_HOTEL_DETAILS_START = 'GET_HOTEL_DETAILS_START'
export const GET_HOTEL_DETAILS_FAIL = 'GET_HOTEL_DETAILS_FAIL'
export const GET_HOTEL_DETAILS_SUCCESS = 'GET_HOTEL_DETAILS_SUCCESS'
export const POST_HOTEL_START = 'POST_HOTEL_START'
export const POST_HOTEL_FAIL = 'POST_HOTEL_FAIL'
export const POST_HOTEL_SUCCESS = 'POST_HOTEL_SUCCESS'
export const PUT_HOTEL_START = 'PUT_HOTEL_START'
export const PUT_HOTEL_FAIL = 'PUT_HOTEL_FAIL'
export const PUT_HOTEL_SUCCESS = 'PUT_HOTEL_SUCCESS'
export const DELETE_HOTEL_START = 'DELETE_HOTEL_START'
export const DELETE_HOTEL_FAIL = 'DELETE_HOTEL_FAIL'
export const DELETE_HOTEL_SUCCESS = 'DELETE_HOTEL_SUCCESS'

export const GET_DISTRICT_START = 'GET_DISTRICT_START'
export const GET_DISTRICT_FAIL = 'GET_DISTRICT_FAIL'
export const GET_DISTRICT_SUCCESS = 'GET_DISTRICT_SUCCESS'
export const GET_DISTRICT_DETAILS_START = 'GET_DISTRICT_DETAILS_START'
export const GET_DISTRICT_DETAILS_FAIL = 'GET_DISTRICT_DETAILS_FAIL'
export const GET_DISTRICT_DETAILS_SUCCESS = 'GET_DISTRICT_DETAILS_SUCCESS'
export const POST_DISTRICT_START = 'POST_DISTRICT_START'
export const POST_DISTRICT_FAIL = 'POST_DISTRICT_FAIL'
export const POST_DISTRICT_SUCCESS = 'POST_DISTRICT_SUCCESS'
export const PUT_DISTRICT_START = 'PUT_DISTRICT_START'
export const PUT_DISTRICT_FAIL = 'PUT_DISTRICT_FAIL'
export const PUT_DISTRICT_SUCCESS = 'PUT_DISTRICT_SUCCESS'
export const DELETE_DISTRICT_START = 'DELETE_DISTRICT_START'
export const DELETE_DISTRICT_FAIL = 'DELETE_DISTRICT_FAIL'
export const DELETE_DISTRICT_SUCCESS = 'DELETE_DISTRICT_SUCCESS'

export const GET_DISTRICT_HOTEL_START = 'GET_DISTRICT_HOTEL_START'
export const GET_DISTRICT_HOTEL_FAIL = 'GET_DISTRICT_HOTEL_FAIL'
export const GET_DISTRICT_HOTEL_SUCCESS = 'GET_DISTRICT_HOTEL_SUCCESS'
export const POST_DISTRICT_HOTEL_START = 'POST_DISTRICT_HOTEL_START'
export const POST_DISTRICT_HOTEL_FAIL = 'POST_DISTRICT_HOTEL_FAIL'
export const POST_DISTRICT_HOTEL_SUCCESS = 'POST_DISTRICT_HOTEL_SUCCESS'
export const PUT_DISTRICT_HOTEL_START = 'PUT_DISTRICT_HOTEL_START'
export const PUT_DISTRICT_HOTEL_FAIL = 'PUT_DISTRICT_HOTEL_FAIL'
export const PUT_DISTRICT_HOTEL_SUCCESS = 'PUT_DISTRICT_HOTEL_SUCCESS'
export const DELETE_DISTRICT_HOTEL_START = 'DELETE_DISTRICT_HOTEL_START'
export const DELETE_DISTRICT_HOTEL_FAIL = 'DELETE_DISTRICT_HOTEL_FAIL'
export const DELETE_DISTRICT_HOTEL_SUCCESS = 'DELETE_DISTRICT_HOTEL_SUCCESS'

export const PUT_HOTEL_BASIC_ATTRIBUTE_START = 'PUT_HOTEL_BASIC_ATTRIBUTE_START'
export const PUT_HOTEL_BASIC_ATTRIBUTE_FAIL = 'PUT_HOTEL_BASIC_ATTRIBUTE_FAIL'
export const PUT_HOTEL_BASIC_ATTRIBUTE_SUCCESS = 'PUT_HOTEL_BASIC_ATTRIBUTE_SUCCESS'
export const PUT_HOTEL_SUPER_ATTRIBUTE_START = 'PUT_HOTEL_SUPER_ATTRIBUTE_START'
export const PUT_HOTEL_SUPER_ATTRIBUTE_FAIL = 'PUT_HOTEL_SUPER_ATTRIBUTE_FAIL'
export const PUT_HOTEL_SUPER_ATTRIBUTE_SUCCESS = 'PUT_HOTEL_SUPER_ATTRIBUTE_SUCCESS'

export const GET_SETTINGS_SETTINGS_HOTEL_ATTRIBUTE_START = 'GET_SETTINGS_SETTINGS_HOTEL_ATTRIBUTE_START'
export const GET_SETTINGS_HOTEL_ATTRIBUTE_FAIL = 'GET_SETTINGS_HOTEL_ATTRIBUTE_FAIL'
export const GET_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS = 'GET_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS'
export const POST_SETTINGS_HOTEL_ATTRIBUTE_START = 'POST_SETTINGS_HOTEL_ATTRIBUTE_START'
export const POST_SETTINGS_HOTEL_ATTRIBUTE_FAIL = 'POST_SETTINGS_HOTEL_ATTRIBUTE_FAIL'
export const POST_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS = 'POST_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS'
export const PUT_SETTINGS_HOTEL_ATTRIBUTE_START = 'PUT_SETTINGS_HOTEL_ATTRIBUTE_START'
export const PUT_SETTINGS_HOTEL_ATTRIBUTE_FAIL = 'PUT_SETTINGS_HOTEL_ATTRIBUTE_FAIL'
export const PUT_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS = 'PUT_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS'
export const DELETE_SETTINGS_HOTEL_ATTRIBUTE_START = 'DELETE_SETTINGS_HOTEL_ATTRIBUTE_START'
export const DELETE_SETTINGS_HOTEL_ATTRIBUTE_FAIL = 'DELETE_SETTINGS_HOTEL_ATTRIBUTE_FAIL'
export const DELETE_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS = 'DELETE_SETTINGS_HOTEL_ATTRIBUTE_SUCCESS'

export const GET_ROOM_START = 'GET_ROOM_START'
export const GET_ROOM_FAIL = 'GET_ROOM_FAIL'
export const GET_ROOM_SUCCESS = 'GET_ROOM_SUCCESS'
export const GET_ROOM_BY_ID_START = 'GET_ROOM_BY_ID_START'
export const GET_ROOM_BY_ID_FAIL = 'GET_ROOM_BY_ID_FAIL'
export const GET_ROOM_BY_ID_SUCCESS = 'GET_ROOM_BY_ID_SUCCESS'
export const GET_ROOM_BY_HOTEL_START = 'GET_ROOM_BY_HOTEL_START'
export const GET_ROOM_BY_HOTEL_FAIL = 'GET_ROOM_BY_HOTEL_FAIL'
export const GET_ROOM_BY_HOTEL_SUCCESS = 'GET_ROOM_BY_HOTEL_SUCCESS'
export const POST_ROOM_START = 'POST_ROOM_START'
export const POST_ROOM_FAIL = 'POST_ROOM_FAIL'
export const POST_ROOM_SUCCESS = 'POST_ROOM_SUCCESS'
export const PUT_ROOM_START = 'PUT_ROOM_START'
export const PUT_ROOM_FAIL = 'PUT_ROOM_FAIL'
export const PUT_ROOM_SUCCESS = 'PUT_ROOM_SUCCESS'
export const DELETE_ROOM_START = 'DELETE_ROOM_START'
export const DELETE_ROOM_FAIL = 'DELETE_ROOM_FAIL'
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS'

export const GET_ROOM_CATEGORY_START = 'GET_ROOM_CATEGORY_START'
export const GET_ROOM_CATEGORY_FAIL = 'GET_ROOM_CATEGORY_FAIL'
export const GET_ROOM_CATEGORY_SUCCESS = 'GET_ROOM_CATEGORY_SUCCESS'
export const POST_ROOM_CATEGORY_START = 'POST_ROOM_CATEGORY_START'
export const POST_ROOM_CATEGORY_FAIL = 'POST_ROOM_CATEGORY_FAIL'
export const POST_ROOM_CATEGORY_SUCCESS = 'POST_ROOM_CATEGORY_SUCCESS'
export const PUT_ROOM_CATEGORY_START = 'PUT_ROOM_CATEGORY_START'
export const PUT_ROOM_CATEGORY_FAIL = 'PUT_ROOM_CATEGORY_FAIL'
export const PUT_ROOM_CATEGORY_SUCCESS = 'PUT_ROOM_CATEGORY_SUCCESS'
export const DELETE_ROOM_CATEGORY_START = 'DELETE_ROOM_CATEGORY_START'
export const DELETE_ROOM_CATEGORY_FAIL = 'DELETE_ROOM_CATEGORY_FAIL'
export const DELETE_ROOM_CATEGORY_SUCCESS = 'DELETE_ROOM_CATEGORY_SUCCESS'

export const GET_SUBSCRIPTION_CATEGORY_START = 'GET_SUBSCRIPTION_CATEGORY_START'
export const GET_SUBSCRIPTION_CATEGORY_FAIL = 'GET_SUBSCRIPTION_CATEGORY_FAIL'
export const GET_SUBSCRIPTION_CATEGORY_SUCCESS = 'GET_SUBSCRIPTION_CATEGORY_SUCCESS'
export const POST_SUBSCRIPTION_CATEGORY_START = 'POST_SUBSCRIPTION_CATEGORY_START'
export const POST_SUBSCRIPTION_CATEGORY_FAIL = 'POST_SUBSCRIPTION_CATEGORY_FAIL'
export const POST_SUBSCRIPTION_CATEGORY_SUCCESS = 'POST_SUBSCRIPTION_CATEGORY_SUCCESS'
export const PUT_SUBSCRIPTION_CATEGORY_START = 'PUT_SUBSCRIPTION_CATEGORY_START'
export const PUT_SUBSCRIPTION_CATEGORY_FAIL = 'PUT_SUBSCRIPTION_CATEGORY_FAIL'
export const PUT_SUBSCRIPTION_CATEGORY_SUCCESS = 'PUT_SUBSCRIPTION_CATEGORY_SUCCESS'
export const DELETE_SUBSCRIPTION_CATEGORY_START = 'DELETE_SUBSCRIPTION_CATEGORY_START'
export const DELETE_SUBSCRIPTION_CATEGORY_FAIL = 'DELETE_SUBSCRIPTION_CATEGORY_FAIL'
export const DELETE_SUBSCRIPTION_CATEGORY_SUCCESS = 'DELETE_SUBSCRIPTION_CATEGORY_SUCCESS'

export const GET_SUBSCRIPTION_DURATION_START = 'GET_SUBSCRIPTION_DURATION_START'
export const GET_SUBSCRIPTION_DURATION_FAIL = 'GET_SUBSCRIPTION_DURATION_FAIL'
export const GET_SUBSCRIPTION_DURATION_SUCCESS = 'GET_SUBSCRIPTION_DURATION_SUCCESS'
export const POST_SUBSCRIPTION_DURATION_START = 'POST_SUBSCRIPTION_DURATION_START'
export const POST_SUBSCRIPTION_DURATION_FAIL = 'POST_SUBSCRIPTION_DURATION_FAIL'
export const POST_SUBSCRIPTION_DURATION_SUCCESS = 'POST_SUBSCRIPTION_DURATION_SUCCESS'
export const PUT_SUBSCRIPTION_DURATION_START = 'PUT_SUBSCRIPTION_DURATION_START'
export const PUT_SUBSCRIPTION_DURATION_FAIL = 'PUT_SUBSCRIPTION_DURATION_FAIL'
export const PUT_SUBSCRIPTION_DURATION_SUCCESS = 'PUT_SUBSCRIPTION_DURATION_SUCCESS'
export const DELETE_SUBSCRIPTION_DURATION_START = 'DELETE_SUBSCRIPTION_DURATION_START'
export const DELETE_SUBSCRIPTION_DURATION_FAIL = 'DELETE_SUBSCRIPTION_DURATION_FAIL'
export const DELETE_SUBSCRIPTION_DURATION_SUCCESS = 'DELETE_SUBSCRIPTION_DURATION_SUCCESS'

export const GET_CITY_START = 'GET_CITY_START'
export const GET_CITY_FAIL = 'GET_CITY_FAIL'
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS'
export const POST_CITY_START = 'POST_CITY_START'
export const POST_CITY_FAIL = 'POST_CITY_FAIL'
export const POST_CITY_SUCCESS = 'POST_CITY_SUCCESS'
export const PUT_CITY_START = 'PUT_CITY_START'
export const PUT_CITY_FAIL = 'PUT_CITY_FAIL'
export const PUT_CITY_SUCCESS = 'PUT_CITY_SUCCESS'
export const DELETE_CITY_START = 'DELETE_CITY_START'
export const DELETE_CITY_FAIL = 'DELETE_CITY_FAIL'
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS'

export const GET_CUSTOMER_START = 'GET_CUSTOMER_START'
export const GET_CUSTOMER_FAIL = 'GET_CUSTOMER_FAIL'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const POST_CUSTOMER_START = 'POST_CUSTOMER_START'
export const POST_CUSTOMER_FAIL = 'POST_CUSTOMER_FAIL'
export const POST_CUSTOMER_SUCCESS = 'POST_CUSTOMER_SUCCESS'
export const PUT_CUSTOMER_START = 'PUT_CUSTOMER_START'
export const PUT_CUSTOMER_FAIL = 'PUT_CUSTOMER_FAIL'
export const PUT_CUSTOMER_SUCCESS = 'PUT_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_START = 'DELETE_CUSTOMER_START'
export const DELETE_CUSTOMER_FAIL = 'DELETE_CUSTOMER_FAIL'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const POST_CUSTOMER_NEW_BUSINESS_START = 'POST_CUSTOMER_NEW_BUSINESS_START'
export const POST_CUSTOMER_NEW_BUSINESS_FAIL = 'POST_CUSTOMER_NEW_BUSINESS_FAIL'
export const POST_CUSTOMER_NEW_BUSINESS_SUCCESS = 'POST_CUSTOMER_NEW_BUSINESS_SUCCESS'

export const GET_CUSTOMER_BLACKLIST_START = 'GET_CUSTOMER_BLACKLIST_START'
export const GET_CUSTOMER_BLACKLIST_FAIL = 'GET_CUSTOMER_BLACKLIST_FAIL'
export const GET_CUSTOMER_BLACKLIST_SUCCESS = 'GET_CUSTOMER_BLACKLIST_SUCCESS'
export const POST_CUSTOMER_BLACKLIST_START = 'POST_CUSTOMER_BLACKLIST_START'
export const POST_CUSTOMER_BLACKLIST_FAIL = 'POST_CUSTOMER_BLACKLIST_FAIL'
export const POST_CUSTOMER_BLACKLIST_SUCCESS = 'POST_CUSTOMER_BLACKLIST_SUCCESS'
export const PUT_CUSTOMER_BLACKLIST_START = 'PUT_CUSTOMER_BLACKLIST_START'
export const PUT_CUSTOMER_BLACKLIST_FAIL = 'PUT_CUSTOMER_BLACKLIST_FAIL'
export const PUT_CUSTOMER_BLACKLIST_SUCCESS = 'PUT_CUSTOMER_BLACKLIST_SUCCESS'
export const DELETE_CUSTOMER_BLACKLIST_START = 'DELETE_CUSTOMER_BLACKLIST_START'
export const DELETE_CUSTOMER_BLACKLIST_FAIL = 'DELETE_CUSTOMER_BLACKLIST_FAIL'
export const DELETE_CUSTOMER_BLACKLIST_SUCCESS = 'DELETE_CUSTOMER_BLACKLIST_SUCCESS'

export const GET_OFFER_DISCOUNT_START = 'GET_OFFER_DISCOUNT_START'
export const GET_OFFER_DISCOUNT_FAIL = 'GET_OFFER_DISCOUNT_FAIL'
export const GET_OFFER_DISCOUNT_SUCCESS = 'GET_OFFER_DISCOUNT_SUCCESS'
export const POST_OFFER_DISCOUNT_START = 'POST_OFFER_DISCOUNT_START'
export const POST_OFFER_DISCOUNT_FAIL = 'POST_OFFER_DISCOUNT_FAIL'
export const POST_OFFER_DISCOUNT_SUCCESS = 'POST_OFFER_DISCOUNT_SUCCESS'
export const PUT_OFFER_DISCOUNT_START = 'PUT_OFFER_DISCOUNT_START'
export const PUT_OFFER_DISCOUNT_FAIL = 'PUT_OFFER_DISCOUNT_FAIL'
export const PUT_OFFER_DISCOUNT_SUCCESS = 'PUT_OFFER_DISCOUNT_SUCCESS'
export const DELETE_OFFER_DISCOUNT_START = 'DELETE_OFFER_DISCOUNT_START'
export const DELETE_OFFER_DISCOUNT_FAIL = 'DELETE_OFFER_DISCOUNT_FAIL'
export const DELETE_OFFER_DISCOUNT_SUCCESS = 'DELETE_OFFER_DISCOUNT_SUCCESS'

export const GET_RESERVATION_START = 'GET_RESERVATION_START'
export const GET_RESERVATION_FAIL = 'GET_RESERVATION_FAIL'
export const GET_RESERVATION_SUCCESS = 'GET_RESERVATION_SUCCESS'
export const POST_RESERVATION_START = 'POST_RESERVATION_START'
export const POST_RESERVATION_FAIL = 'POST_RESERVATION_FAIL'
export const POST_RESERVATION_SUCCESS = 'POST_RESERVATION_SUCCESS'
export const PUT_RESERVATION_START = 'PUT_RESERVATION_START'
export const PUT_RESERVATION_FAIL = 'PUT_RESERVATION_FAIL'
export const PUT_RESERVATION_SUCCESS = 'PUT_RESERVATION_SUCCESS'
export const DELETE_RESERVATION_START = 'DELETE_RESERVATION_START'
export const DELETE_RESERVATION_FAIL = 'DELETE_RESERVATION_FAIL'
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS'

export const POST_TRIP_START = 'POST_TRIP_START'
export const POST_TRIP_FAIL = 'POST_TRIP_FAIL'
export const POST_TRIP_SUCCESS = 'POST_TRIP_SUCCESS'
export const PUT_TRIP_START = 'PUT_TRIP_START'
export const PUT_TRIP_FAIL = 'PUT_TRIP_FAIL'
export const PUT_TRIP_SUCCESS = 'PUT_TRIP_SUCCESS'
export const DELETE_TRIP_START = 'DELETE_TRIP_START'
export const DELETE_TRIP_FAIL = 'DELETE_TRIP_FAIL'
export const DELETE_TRIP_SUCCESS = 'DELETE_TRIP_SUCCESS'

export const POST_MAIL_CONFIRMATION_REQUEST_HOTEL_START = 'POST_MAIL_CONFIRMATION_REQUEST_HOTEL_START'
export const POST_MAIL_CONFIRMATION_REQUEST_HOTEL_FAIL = 'POST_MAIL_CONFIRMATION_REQUEST_HOTEL_FAIL'
export const POST_MAIL_CONFIRMATION_REQUEST_HOTEL_SUCCESS = 'POST_MAIL_CONFIRMATION_REQUEST_HOTEL_SUCCESS'
export const POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER_START = 'POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER_START'
export const POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER_FAIL = 'POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER_FAIL'
export const POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER_SUCCESS = 'POST_MAIL_HOTEL_ASSIGMENT_CUSTOMER_SUCCESS'
export const POST_MAIL_REBOOK_INFORMATION_CUSTOMER_START = 'POST_MAIL_REBOOK_INFORMATION_CUSTOMER_START'
export const POST_MAIL_REBOOK_INFORMATION_CUSTOMER_FAIL = 'POST_MAIL_REBOOK_INFORMATION_CUSTOMER_FAIL'
export const POST_MAIL_REBOOK_INFORMATION_CUSTOMER_SUCCESS = 'POST_MAIL_REBOOK_INFORMATION_CUSTOMER_SUCCESS'
export const POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER_START = 'POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER_START'
export const POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER_FAIL = 'POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER_FAIL'
export const POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER_SUCCESS = 'POST_MAIL_BOOKING_CONFIRMATION_CUSTOMER_SUCCESS'

export const POST_MAIL_TRIP_CONFIRMATION_CUSTOMER_START = 'POST_MAIL_TRIP_CONFIRMATION_CUSTOMER_START'
export const POST_MAIL_TRIP_CONFIRMATION_CUSTOMER_FAIL = 'POST_MAIL_TRIP_CONFIRMATION_CUSTOMER_FAIL'
export const POST_MAIL_TRIP_CONFIRMATION_CUSTOMER_SUCCESS = 'POST_MAIL_TRIP_CONFIRMATION_CUSTOMER_SUCCESS'

export const GET_SUBSCRIPTION_START = 'GET_SUBSCRIPTION_START'
export const GET_SUBSCRIPTION_FAIL = 'GET_SUBSCRIPTION_FAIL'
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS'
export const POST_SUBSCRIPTION_START = 'POST_SUBSCRIPTION_START'
export const POST_SUBSCRIPTION_FAIL = 'POST_SUBSCRIPTION_FAIL'
export const POST_SUBSCRIPTION_SUCCESS = 'POST_SUBSCRIPTION_SUCCESS'
export const PUT_SUBSCRIPTION_START = 'PUT_SUBSCRIPTION_START'
export const PUT_SUBSCRIPTION_FAIL = 'PUT_SUBSCRIPTION_FAIL'
export const PUT_SUBSCRIPTION_SUCCESS = 'PUT_SUBSCRIPTION_SUCCESS'
export const DELETE_SUBSCRIPTION_START = 'DELETE_SUBSCRIPTION_START'
export const DELETE_SUBSCRIPTION_FAIL = 'DELETE_SUBSCRIPTION_FAIL'
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS'

export const GET_SUBSCRIPTION_EVALUATION_START = 'GET_SUBSCRIPTION_EVALUATION_START'
export const GET_SUBSCRIPTION_EVALUATION_FAIL = 'GET_SUBSCRIPTION_EVALUATION_FAIL'
export const GET_SUBSCRIPTION_EVALUATION_SUCCESS = 'GET_SUBSCRIPTION_EVALUATION_SUCCESS'

export const GET_SUBSCRIPTION_MONTHS_START = 'GET_SUBSCRIPTION_MONTHS_START'
export const GET_SUBSCRIPTION_MONTHS_FAIL = 'GET_SUBSCRIPTION_MONTHS_FAIL'
export const GET_SUBSCRIPTION_MONTHS_SUCCESS = 'GET_SUBSCRIPTION_MONTHS_SUCCESS'

export const POST_SUBSCRIPTION_SEGMENT_START = 'POST_SUBSCRIPTION_SEGMENT_START'
export const POST_SUBSCRIPTION_SEGMENT_FAIL = 'POST_SUBSCRIPTION_SEGMENT_FAIL'
export const POST_SUBSCRIPTION_SEGMENT_SUCCESS = 'POST_SUBSCRIPTION_SEGMENT_SUCCESS'
export const PUT_SUBSCRIPTION_SEGMENT_START = 'PUT_SUBSCRIPTION_SEGMENT_START'
export const PUT_SUBSCRIPTION_SEGMENT_FAIL = 'PUT_SUBSCRIPTION_SEGMENT_FAIL'
export const PUT_SUBSCRIPTION_SEGMENT_SUCCESS = 'PUT_SUBSCRIPTION_SEGMENT_SUCCESS'
export const DELETE_SUBSCRIPTION_SEGMENT_START = 'DELETE_SUBSCRIPTION_SEGMENT_START'
export const DELETE_SUBSCRIPTION_SEGMENT_FAIL = 'DELETE_SUBSCRIPTION_SEGMENT_FAIL'
export const DELETE_SUBSCRIPTION_SEGMENT_SUCCESS = 'DELETE_SUBSCRIPTION_SEGMENT_SUCCESS'

export const GET_SUBSCRIPTION_STRIPE_START = 'GET_SUBSCRIPTION_STRIPE_START'
export const GET_SUBSCRIPTION_STRIPE_FAIL = 'GET_SUBSCRIPTION_STRIPE_FAIL'
export const GET_SUBSCRIPTION_STRIPE_SUCCESS = 'GET_SUBSCRIPTION_STRIPE_SUCCESS'
export const GET_SUBSCRIPTION_LEXOFFICE_START = 'GET_SUBSCRIPTION_LEXOFFICE_START'
export const GET_SUBSCRIPTION_LEXOFFICE_FAIL = 'GET_SUBSCRIPTION_LEXOFFICE_FAIL'
export const GET_SUBSCRIPTION_LEXOFFICE_SUCCESS = 'GET_SUBSCRIPTION_LEXOFFICE_SUCCESS'

export const GET_OFFER_START = 'GET_OFFER_START'
export const GET_OFFER_FAIL = 'GET_OFFER_FAIL'
export const GET_OFFER_SUCCESS = 'GET_OFFER_SUCCESS'
export const GET_OFFER_BY_ROOM_START = 'GET_OFFER_BY_ROOM_START'
export const GET_OFFER_BY_ROOM_FAIL = 'GET_OFFER_BY_ROOM_FAIL'
export const GET_OFFER_BY_ROOM_SUCCESS = 'GET_OFFER_BY_ROOM_SUCCESS'
export const POST_OFFER_START = 'POST_OFFER_START'
export const POST_OFFER_FAIL = 'POST_OFFER_FAIL'
export const POST_OFFER_SUCCESS = 'POST_OFFER_SUCCESS'
export const PUT_OFFER_START = 'PUT_OFFER_START'
export const PUT_OFFER_FAIL = 'PUT_OFFER_FAIL'
export const PUT_OFFER_SUCCESS = 'PUT_OFFER_SUCCESS'
export const DELETE_OFFER_START = 'DELETE_OFFER_START'
export const DELETE_OFFER_FAIL = 'DELETE_OFFER_FAIL'
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS'

export const PUT_MEDIA_START = 'PUT_MEDIA_START'
export const PUT_MEDIA_FAIL = 'PUT_MEDIA_FAIL'
export const PUT_MEDIA_SUCCESS = 'PUT_MEDIA_SUCCESS'
export const DELETE_MEDIA_START = 'DELETE_MEDIA_START'
export const DELETE_MEDIA_FAIL = 'DELETE_MEDIA_FAIL'
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS'

export const GET_SPECIAL_DATE_START = 'GET_SPECIAL_DATE_START'
export const GET_SPECIAL_DATE_FAIL = 'GET_SPECIAL_DATE_FAIL'
export const GET_SPECIAL_DATE_SUCCESS = 'GET_SPECIAL_DATE_BY_HOTEL_SUCCESS'
export const GET_SPECIAL_DATE_BY_HOTEL_START = 'GET_SPECIAL_DATE_BY_HOTEL_START'
export const GET_SPECIAL_DATE_BY_HOTEL_FAIL = 'GET_SPECIAL_DATE_BY_HOTEL_FAIL'
export const GET_SPECIAL_DATE_BY_HOTEL_SUCCESS = 'GET_SPECIAL_DATE_BY_HOTEL_SUCCESS'
export const GET_SPECIAL_DATE_BY_ROOM_START = 'GET_SPECIAL_DATE_BY_ROOM_START'
export const GET_SPECIAL_DATE_BY_ROOM_FAIL = 'GET_SPECIAL_DATE_BY_ROOM_FAIL'
export const GET_SPECIAL_DATE_BY_ROOM_SUCCESS = 'GET_SPECIAL_DATE_BY_ROOM_SUCCESS'
export const POST_SPECIAL_DATE_START = 'POST_SPECIAL_DATE_START'
export const POST_SPECIAL_DATE_FAIL = 'POST_SPECIAL_DATE_FAIL'
export const POST_SPECIAL_DATE_SUCCESS = 'POST_SPECIAL_DATE_SUCCESS'
export const PUT_SPECIAL_DATE_START = 'PUT_SPECIAL_DATE_START'
export const PUT_SPECIAL_DATE_FAIL = 'PUT_SPECIAL_DATE_FAIL'
export const PUT_SPECIAL_DATE_SUCCESS = 'PUT_SPECIAL_DATE_SUCCESS'
export const DELETE_SPECIAL_DATE_START = 'DELETE_SPECIAL_DATE_START'
export const DELETE_SPECIAL_DATE_FAIL = 'DELETE_SPECIAL_DATE_FAIL'
export const DELETE_SPECIAL_DATE_SUCCESS = 'DELETE_SPECIAL_DATE_SUCCESS'

export const PUT_SPECIAL_DATE_SURCHARGE_START = 'PUT_SPECIAL_DATE_SURCHARGE_START'
export const PUT_SPECIAL_DATE_SURCHARGE_FAIL = 'PUT_SPECIAL_DATE_SURCHARGE_FAIL'
export const PUT_SPECIAL_DATE_SURCHARGE_SUCCESS = 'PUT_SPECIAL_DATE_SURCHARGE_SUCCESS'
export const DELETE_SPECIAL_DATE_SURCHARGE_START = 'DELETE_SPECIAL_DATE_SURCHARGE_START'
export const DELETE_SPECIAL_DATE_SURCHARGE_FAIL = 'DELETE_SPECIAL_DATE_SURCHARGE_FAIL'
export const DELETE_SPECIAL_DATE_SURCHARGE_SUCCESS = 'DELETE_SPECIAL_DATE_SURCHARGE_SUCCESS'

export const GET_SPECIAL_DATE_BY_CITY_START = 'GET_SPECIAL_DATE_BY_CITY_START'
export const GET_SPECIAL_DATE_BY_CITY_FAIL = 'GET_SPECIAL_DATE_BY_CITY_FAIL'
export const GET_SPECIAL_DATE_BY_CITY_SUCCESS = 'GET_SPECIAL_DATE_BY_CITY_SUCCESS'

export const POST_MULTIPLE_SPECIAL_DATES_START = 'POST_MULTIPLE_SPECIAL_DATES_START'
export const POST_MULTIPLE_SPECIAL_DATES_FAIL = 'POST_MULTIPLE_SPECIAL_DATES_FAIL'
export const POST_MULTIPLE_SPECIAL_DATES_SUCCESS = 'POST_MULTIPLE_SPECIAL_DATES_SUCCESS'
export const DELETE_MULTIPLE_SPECIAL_DATES_START = 'DELETE_MULTIPLE_SPECIAL_DATES_START'
export const DELETE_MULTIPLE_SPECIAL_DATES_FAIL = 'DELETE_MULTIPLE_SPECIAL_DATES_FAIL'
export const DELETE_MULTIPLE_SPECIAL_DATES_SUCCESS = 'DELETE_MULTIPLE_SPECIAL_DATES_SUCCESS'

export const SHOW_SUBSCRIPTION_SEGMENT_MODAL = 'SHOW_SUBSCRIPTION_SEGMENT_MODAL'
export const HIDE_SUBSCRIPTION_SEGMENT_MODAL = 'HIDE_SUBSCRIPTION_SEGMENT_MODAL'
export const SHOW_DISTRICT_ASSIGN_HOTEL_MODAL = 'SHOW_DISTRICT_ASSIGN_HOTEL_MODAL'
export const HIDE_DISTRICT_ASSIGN_HOTEL_MODAL = 'HIDE_DISTRICT_ASSIGN_HOTEL_MODAL'
export const SHOW_BUSINESS_GUEST_CHANGE_MODAL = 'SHOW_BUSINESS_GUEST_CHANGE_MODAL'
export const HIDE_BUSINESS_GUEST_CHANGE_MODAL = 'HIDE_BUSINESS_GUEST_CHANGE_MODAL'
export const SHOW_SPECIAL_DATE_MODAL = 'SHOW_SPECIAL_DATE_MODAL'
export const HIDE_SPECIAL_DATE_MODAL = 'HIDE_SPECIAL_DATE_MODAL'

export const GET_CACHE_STATS_START = 'GET_CACHE_STATS_START'
export const GET_CACHE_STATS_FAIL = 'GET_CACHE_STATS_FAIL'
export const GET_CACHE_STATS_SUCCESS = 'GET_CACHE_STATS_SUCCESS'
export const POST_CACHE_FLUSH_START = 'POST_CACHE_FLUSH_START'
export const POST_CACHE_FLUSH_FAIL = 'POST_CACHE_FLUSH_FAIL'
export const POST_CACHE_FLUSH_SUCCESS = 'POST_CACHE_FLUSH_SUCCESS'

export const GET_COMPANY_START = 'GET_COMPANY_START'
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_DETAILS_START = 'GET_COMPANY_DETAILS_START'
export const GET_COMPANY_DETAILS_FAIL = 'GET_COMPANY_DETAILS_FAIL'
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS'
export const POST_COMPANY_START = 'POST_COMPANY_START'
export const POST_COMPANY_FAIL = 'POST_COMPANY_FAIL'
export const POST_COMPANY_SUCCESS = 'POST_COMPANY_SUCCESS'
export const PUT_COMPANY_START = 'PUT_COMPANY_START'
export const PUT_COMPANY_FAIL = 'PUT_COMPANY_FAIL'
export const PUT_COMPANY_SUCCESS = 'PUT_COMPANY_SUCCESS'
export const DELETE_COMPANY_START = 'DELETE_COMPANY_START'
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL'
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS'

export const GET_BUSINESS_USER_INVITATION_BY_COMPANY_START = 'GET_BUSINESS_USER_INVITATION_BY_COMPANY_START'
export const GET_BUSINESS_USER_INVITATION_BY_COMPANY_FAIL = 'GET_BUSINESS_USER_INVITATION_BY_COMPANY_FAIL'
export const GET_BUSINESS_USER_INVITATION_BY_COMPANY_SUCCESS = 'GET_BUSINESS_USER_INVITATION_BY_COMPANY_SUCCESS'

export const POST_BUSINESS_USER_INVITATION_START = 'POST_BUSINESS_USER_INVITATION_START'
export const POST_BUSINESS_USER_INVITATION_FAIL = 'POST_BUSINESS_USER_INVITATION_FAIL'
export const POST_BUSINESS_USER_INVITATION_SUCCESS = 'POST_BUSINESS_USER_INVITATION_SUCCESS'
export const PUT_BUSINESS_USER_INVITATION_START = 'PUT_BUSINESS_USER_INVITATION_START'
export const PUT_BUSINESS_USER_INVITATION_FAIL = 'PUT_BUSINESS_USER_INVITATION_FAIL'
export const PUT_BUSINESS_USER_INVITATION_SUCCESS = 'PUT_BUSINESS_USER_INVITATION_SUCCESS'
export const DELETE_BUSINESS_USER_INVITATION_START = 'DELETE_BUSINESS_USER_INVITATION_START'
export const DELETE_BUSINESS_USER_INVITATION_FAIL = 'DELETE_BUSINESS_USER_INVITATION_FAIL'
export const DELETE_BUSINESS_USER_INVITATION_SUCCESS = 'DELETE_BUSINESS_USER_INVITATION_SUCCESS'

export const PUT_LEXOFFICE_VOUCHER_START = 'PUT_LEXOFFICE_VOUCHER_START'
export const PUT_LEXOFFICE_VOUCHER_FAIL = 'PUT_LEXOFFICE_VOUCHER_FAIL'
export const PUT_LEXOFFICE_VOUCHER_SUCCESS = 'PUT_LEXOFFICE_VOUCHER_SUCCESS'
import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from './mainWindow';

/*
 *   LOGIN
 */

export const login = (payload) => {
  return dispatch => {
    dispatch(loginStart());
    axios.post('/horus/login', payload)
      .then ( response => {
        dispatch(showNotification('Login successful','success'))
        dispatch(loginSuccess(response.data));
        //dispatch(getVersion());
      })
      .catch(error => {
        dispatch(showNotification('Login failed', 'error'))
        dispatch(loginFail(error))
      });
  }
};

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  };
};

export const loginSuccess = (res) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    res: res,
  };
};

export const loginFail = (error) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: error,
  };
};

/*
 *   LOGOUT
 */

export const logout = () => {
  return dispatch => {
    dispatch(logoutStart());
    axios.post('/horus/logout?t='+ new Date().getTime())
      .then ( response => {
        dispatch(logoutSuccess(response.data));
      })
      .catch(error => {
        dispatch(logoutFail(error))
      });
  }
};

export const logoutStart = () => {
  return {
    type: actionTypes.LOGOUT_START,
  };
};

export const logoutSuccess = (res) => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    res: res,
  };
};

export const logoutFail = (error) => {
  return {
    type: actionTypes.LOGOUT_FAIL,
    error: error,
  };
};

/*
 *   RESTORE SESSION
 */

export const restoreSession = () => {
  return dispatch => {
    dispatch(restoreSessionStart());
    axios.post('/horus/login/reconnect')
      .then ( response => {
        dispatch(restoreSessionSuccess(response.data))
        //dispatch(getVersion())
        dispatch(showNotification('Reconnect successful','info'))
      })
      .catch(error => {
        dispatch(restoreSessionFail(error))
      });
  }
};

export const restoreSessionStart = () => {
  return {
    type: actionTypes.RESTORE_SESSION_START,
  };
};

export const restoreSessionSuccess = (res) => {
  return {
    type: actionTypes.RESTORE_SESSION_SUCCESS,
    res: res,
  };
};

export const restoreSessionFail = (error) => {
  return {
    type: actionTypes.RESTORE_SESSION_FAIL,
    error: error,
  };
};

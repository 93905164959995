import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import * as actions from "./index";

/*
 * GET
 */
export const getCustomer = () => {
  return dispatch => {
    dispatch(getCustomerStart())
    axios.get('/horus/customer')
      .then ( response => {
        dispatch(getCustomerSuccess(response.data))
      })
      .catch(error => {
        dispatch(getCustomerFail(error))
      })
  }
}

export const getCustomerStart = () => {
  return {
    type: actionTypes.GET_CUSTOMER_START,
  }
}

export const getCustomerSuccess = (res) => {
  return {
    type: actionTypes.GET_CUSTOMER_SUCCESS,
    res: res,
  }
}

export const getCustomerFail = (error) => {
  return {
    type: actionTypes.GET_CUSTOMER_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postCustomer = (payload) => {
  return dispatch => {
    dispatch(postCustomerStart())
    axios.post('/horus/customer', payload)
      .then ( response => {
        dispatch(postCustomerSuccess(response.data))
        dispatch(getCustomer())
      })
      .catch(error => {
        dispatch(postCustomerFail(error))
      })
  }
}

export const postCustomerStart = () => {
  return {
    type: actionTypes.POST_CUSTOMER_START,
  }
}

export const postCustomerSuccess = (res) => {
  return {
    type: actionTypes.POST_CUSTOMER_SUCCESS,
    res: res,
  }
}

export const postCustomerFail = (error) => {
  return {
    type: actionTypes.POST_CUSTOMER_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putCustomer = (id, payload) => {
  return dispatch => {
    dispatch(putCustomerStart())
    axios.put('/horus/customer/'+id, payload)
      .then ( response => {
        dispatch(putCustomerSuccess(response.data))
        dispatch(getCustomer())
      })
      .catch(error => {
        dispatch(putCustomerFail(error))
      })
  }
}

export const putCustomerStart = () => {
  return {
    type: actionTypes.PUT_CUSTOMER_START,
  }
}

export const putCustomerSuccess = (res) => {
  return {
    type: actionTypes.PUT_CUSTOMER_SUCCESS,
    res: res,
  }
}

export const putCustomerFail = (error) => {
  return {
    type: actionTypes.PUT_CUSTOMER_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteCustomer = (id) => {
  return dispatch => {
    dispatch(deleteCustomerStart())
    axios.delete('/horus/customer/'+id)
      .then ( response => {
        dispatch(deleteCustomerSuccess(response.data))
        dispatch(getCustomer())
      })
      .catch(error => {
        dispatch(deleteCustomerFail(error))
      })
  }
}

export const deleteCustomerStart = () => {
  return {
    type: actionTypes.DELETE_CUSTOMER_START,
  }
}

export const deleteCustomerSuccess = (res) => {
  return {
    type: actionTypes.DELETE_CUSTOMER_SUCCESS,
    res: res,
  }
}

export const deleteCustomerFail = (error) => {
  return {
    type: actionTypes.DELETE_CUSTOMER_FAIL,
    error: error,
  }
}



/*
 * POST create customer with business account
 */
export const postCustomerNewBusiness = (payload) => {
  return dispatch => {
    dispatch(postCustomerNewBusinessStart())
    axios.post('/horus/customer/businessAccount/', payload)
      .then ( response => {
        dispatch(postCustomerNewBusinessSuccess(response.data))
        if(response.status === 202) {
          dispatch(actions.showNotification(response?.data?.message || 'Fehler','error'))
        } else if(response.status === 200) {
          dispatch(actions.showNotification('New account created','success'))
        }
      })
      .catch(error => {
        dispatch(postCustomerNewBusinessFail(error))
        dispatch(actions.showNotification('New account error','error'))
      })
  }
}

export const postCustomerNewBusinessStart = () => {
  return {
    type: actionTypes.POST_CUSTOMER_NEW_BUSINESS_START,
  }
}

export const postCustomerNewBusinessSuccess = (res) => {
  return {
    type: actionTypes.POST_CUSTOMER_NEW_BUSINESS_SUCCESS,
    res: res,
  }
}

export const postCustomerNewBusinessFail = (error) => {
  return {
    type: actionTypes.POST_CUSTOMER_NEW_BUSINESS_FAIL,
    error: error,
  }
}
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  offers: [],
}

const startLoading = (state) => {
  return {
    ...state,
    isLoading: true,
  }
}

const setOffers = (state, action) => {
  return {
    ...state,
    offers: action.res,
    isLoading: false,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_OFFER_BY_ROOM_START: return startLoading(state)
    case actionTypes.GET_OFFER_BY_ROOM_SUCCESS: return setOffers(state, action)
    case actionTypes.GET_OFFER_START: return startLoading(state)
    case actionTypes.GET_OFFER_SUCCESS: return setOffers(state, action)
    default: return state
  }
}

export default reducer

import React from 'react'
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import {localization, tableIcons} from '../components/TableSharedConfig'

const NewsletterTable = withRouter((props) => {

  const data = useSelector(state => state.analytics.newsletter)

  const email = {
    title: 'E-Mail',
    field: 'email',
    render: rowData => <div style={{fontWeight: 'bold'}}>{rowData.email}</div>,
  }

  const name = {
    title: 'Name',
    field: 'name',
  }

  const city = {
    title: 'City',
    field: 'city',
  }

  const source = {
    title: 'Source',
    field: 'source',
  }

  const betaUser = {
    title: 'Beta-User (New City)',
    field: 'betaUser',
    render: rowData => <div>{rowData.betaUser ? 'yes' : 'no'}</div>,
  }

  const newsletter = {
    title: 'Newsletter',
    field: 'newsletter',
    render: rowData => <div>{rowData.newsletter ? 'yes' : 'no'}</div>,
  }

  const updateOnNewHotelsInCity = {
    title: 'Update On New Hotels',
    field: 'updateOnNewHotelsInCity',
    render: rowData => <div>{rowData.updateOnNewHotelsInCity ? 'yes' : 'no'}</div>,
  }

  const hotelRequested = {
    title: 'Hotel Requested',
    field: 'hotelRequested',
    render: rowData => <div>{rowData.hotelRequested ? 'yes' : 'no'}</div>,
  }

  const hotelRequestText = {
    title: 'Request Hotel',
    field: 'hotelRequestText',
  }

  const hotelRequestComment = {
    title: 'Request Comment',
    field: 'hotelRequestComment',
  }

  const region = {
    title: 'Region by IP',
    field: 'ipCity',
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <span>{formatDateTime(rowData.createdAt)}</span>,
    defaultSort: 'desc',
    editable: 'never',
  }

  const columnSet = [
    email,
    name,
    city,
    source,
    newsletter,
    betaUser,
    updateOnNewHotelsInCity,
    hotelRequested,
    hotelRequestText,
    hotelRequestComment,
    region,
    createdAt,
  ]

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={""}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={null}
        detailPanel={null}
        options={{
          search: true,
          filtering: false,
          paging: false,
          pageSize: 20,
          pageSizeOptions: [50, 500, 5000],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#fff" : "#f9fcff",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default NewsletterTable

import {showNotification} from "./mainWindow";
import * as actionTypes from "./actionTypes";
import axios from '../../axios.config';


/*
 * PUT
 */
export const putLexofficeVoucher = (id, payload, refreshHook=()=>{}) => {
  return dispatch => {
    dispatch(putLexofficeVoucherStart())
    axios.put('/horus/lexoffice/voucher/'+id, payload)
      .then ( response => {
        dispatch(showNotification('Changes saved successfully','success'))
        dispatch(putLexofficeVoucherSuccess(response.data))
        refreshHook()
      })
      .catch(error => {
        dispatch(showNotification('Saving changes failed','error'))
        dispatch(putLexofficeVoucherFail(error))
      })
  }
}

export const putLexofficeVoucherStart = () => {
  return {
    type: actionTypes.PUT_LEXOFFICE_VOUCHER_START,
  }
}

export const putLexofficeVoucherSuccess = (res) => {
  return {
    type: actionTypes.PUT_LEXOFFICE_VOUCHER_SUCCESS,
    res: res,
  }
}

export const putLexofficeVoucherFail = (error) => {
  return {
    type: actionTypes.PUT_LEXOFFICE_VOUCHER_FAIL,
    error: error,
  }
}
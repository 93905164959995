import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from "./mainWindow";


/*
 * POST
 */
export const postTrip = (payload, refreshHook) => {
  return dispatch => {
    dispatch(postTripStart())
    axios.post('/horus/trip', payload)
      .then ( response => {
        dispatch(showNotification('Changes saved successfully','success'))
        dispatch(postTripSuccess(response.data))
        refreshHook()
      })
      .catch(error => {
        dispatch(showNotification('Saving changes failed','error'))
        dispatch(postTripFail(error))
      })
  }
}

export const postTripStart = () => {
  return {
    type: actionTypes.POST_TRIP_START,
  }
}

export const postTripSuccess = (res) => {
  return {
    type: actionTypes.POST_TRIP_SUCCESS,
    res: res,
  }
}

export const postTripFail = (error) => {
  return {
    type: actionTypes.POST_TRIP_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
//refresh hook default (empty function) is needed because of small update calls without proper fresh hook. e.g, assignment modal
export const putTrip = (id, payload, refreshHook=()=>{}) => {
  return dispatch => {
    dispatch(putTripStart())
    axios.put('/horus/trip/'+id, payload)
      .then ( response => {
        dispatch(showNotification('Changes saved successfully','success'))
        dispatch(putTripSuccess(response.data))
        refreshHook()
      })
      .catch(error => {
        dispatch(showNotification('Saving changes failed','error'))
        dispatch(putTripFail(error))
      })
  }
}

export const putTripStart = () => {
  return {
    type: actionTypes.PUT_TRIP_START,
  }
}

export const putTripSuccess = (res) => {
  return {
    type: actionTypes.PUT_TRIP_SUCCESS,
    res: res,
  }
}

export const putTripFail = (error) => {
  return {
    type: actionTypes.PUT_TRIP_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteTrip = (id, refreshHook) => {
  return dispatch => {
    dispatch(deleteTripStart())
    axios.delete('/horus/trip/'+id)
      .then ( response => {
        dispatch(showNotification('Trip deleted successfully','success'))
        dispatch(deleteTripSuccess(response.data))
        refreshHook()
      })
      .catch(error => {
        dispatch(showNotification('Deleting trip failed','error'))
        dispatch(deleteTripFail(error))
      })
  }
}

export const deleteTripStart = () => {
  return {
    type: actionTypes.DELETE_TRIP_START,
  }
}

export const deleteTripSuccess = (res) => {
  return {
    type: actionTypes.DELETE_TRIP_SUCCESS,
    res: res,
  }
}

export const deleteTripFail = (error) => {
  return {
    type: actionTypes.DELETE_TRIP_FAIL,
    error: error,
  }
}



/*
 * POST_MAIL_TRIP_CONFIRMATION_CUSTOMER
 */
export const postMailTripConfirmationToCustomer = (id, refreshHook) => {
  return dispatch => {
    dispatch(postMailTripConfirmationToCustomerStart())
    axios.post('/horus/reservationAction/mail/sendTripConfirmationToCustomer/'+id)
      .then ( response => {
        if(response.data.status === 'OK') {
          dispatch(showNotification(response.data.message,'success'))
          dispatch(postMailTripConfirmationToCustomerSuccess(response.data))
        } else {
          dispatch(showNotification(response.data.message,'error'))
          dispatch(postMailTripConfirmationToCustomerFail(response.data.message))
        }
        refreshHook()
      })
      .catch(error => {
        dispatch(showNotification('Confirm trip failed','error'))
        dispatch(postMailTripConfirmationToCustomerFail(error))
      })
  }
}

export const postMailTripConfirmationToCustomerStart = () => {
  return {
    type: actionTypes.POST_MAIL_TRIP_CONFIRMATION_CUSTOMER_START,
  }
}

export const postMailTripConfirmationToCustomerSuccess = (res) => {
  return {
    type: actionTypes.POST_MAIL_TRIP_CONFIRMATION_CUSTOMER_SUCCESS,
    res: res,
  }
}

export const postMailTripConfirmationToCustomerFail = (error) => {
  return {
    type: actionTypes.POST_MAIL_TRIP_CONFIRMATION_CUSTOMER_FAIL,
    error: error,
  }
}

import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import * as actions from '../../store/actions';
import {Autocomplete, Box, Button, IconButton, Modal, TextField, Typography} from "@mui/material";
import {Close, LocationCity} from "@mui/icons-material";
import {Link, withRouter} from "react-router-dom";

const BusinessGuestChangeModal = withRouter((props) => {

  const dispatch = useDispatch()
  const showBusinessGuestChangeModal = useSelector(state => state.mainWindow.showBusinessGuestChangeModal)
  const businessGuestData = useSelector(state => state.mainWindow.businessGuestData)
  const businessUserInvitationsByCompany = useSelector(state => state.company.businessUserInvitationsByCompany) || []

  useEffect(() => {
    setAssignGuest(null)
    if(businessGuestData?.companyId > 0) {
      dispatch(actions.getBusinessUserInvitationByCompanyId(businessGuestData.companyId))
    }
  },[dispatch, businessGuestData])

  const [assignGuest, setAssignGuest] = useState(null)

  const handleSave = () => {
    dispatch(actions.putReservation(businessGuestData.reservation, {BusinessUserInvitationId:assignGuest?.id || null}))
    businessGuestData.refreshHook()
    dispatch(actions.hideBusinessGuestChangeModal())
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgColor: 'background.paper',
    border: '1px solid #666',
    boxShadow: 24,
    backgroundColor: '#f6f4f0',
    p: 4,
  }

  return (
    <Modal
      open={showBusinessGuestChangeModal}
      onClose={() => dispatch(actions.hideBusinessGuestChangeModal())}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Guest
          </Typography>
          <IconButton aria-label="delete" onClick={() => dispatch(actions.hideBusinessGuestChangeModal())}>
            <Close />
          </IconButton>
        </div>

        <div style={{fontSize: 13}}>Company: <b>{businessGuestData?.companyName}</b></div>

        <Autocomplete
          id="AssignGuestIdModal"
          options={businessUserInvitationsByCompany}
          disableClearable={true}
          getOptionLabel={(option) => {
            if(option?.invitationID) {
              return(option?.firstName + ' ' + option?.lastName + ' (' + option?.invitationID + ')')
            } else {
              return('Admin')
            }
          }}
          value={assignGuest || ''}
          onChange={(e, value) => {
            setAssignGuest(value)
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{fontSize: 13}}>{option?.firstName} {option?.lastName} ({option?.invitationID})</div>
            </li>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />

        <br/>
        <br/>

        <div style={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
          <Button type="button" color="primary" variant="contained" onClick={handleSave}>
            Save
          </Button>
          <Link to={'/app/companyDetails/'+businessGuestData?.companyId}>
            <LocationCity sx={{color: '#627f85', width: 14, height: 14, marginRight: 1}} />
            Add/edit invited users for this company
          </Link>
        </div>

      </Box>
    </Modal>
  )
})

export default BusinessGuestChangeModal

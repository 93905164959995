import React, {useEffect} from 'react'
import {useParams, withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Business} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import CompanyBusinessUserTable from "../tables/CompanyBusinessUserTable";


const ViewCompanyDetails = withRouter((props) => {
  const dispatch = useDispatch()
  const { companyId } = useParams()

  useEffect(() => {
    dispatch(actions.getBusinessUserInvitationByCompanyId(companyId))
    dispatch(actions.getCompany())
  },[dispatch, companyId])

  const isLoading = useSelector(state => state.company.businessUserInvitationsByCompanyIsLoading)
  const companies = useSelector(state => state.company.companies)
  const thisCompany = companies?.filter(x => x.id === Number(companyId))?.[0] || null

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Business fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Company Details</div>
        </div>
        <div>Manage company details and invited users</div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> :
            <div>
              <div style={{ margin: 8, fontSize: 14, fontWeight: 'bold'}}>{thisCompany?.name}</div>
              <CompanyBusinessUserTable />
            </div>
          }
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewCompanyDetails

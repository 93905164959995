import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Business} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import CompanyTable from "../tables/CompanyTable";


const ViewCompany = withRouter((props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getCompany())
  },[dispatch])

  const isLoading = useSelector(state => state.company.companiesIsLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Business fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Company</div>
        </div>
        <div>Company Management. Edit business details</div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading /> : <CompanyTable />}
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewCompany

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import './css/app.css';
import Header from './pages/components/Header';
import Navigation from './pages/components/Navigation';
import ViewLogin from './pages/views/ViewLogin';
import Notifications from './pages/components/Notifications';
import * as actions from './store/actions';
import ViewStart from './pages/views/ViewStart';
import ViewQrSettings from './pages/views/ViewQrSettings';
import ViewHotel from './pages/views/ViewHotel';
import ViewSettingsCity from './pages/views/ViewSettingsCity';
import ViewSettingsHotel from './pages/views/ViewSettingsHotel';
import ViewSettingsRoom from './pages/views/ViewSettingsRoom';
import ViewSettingsSubscription from './pages/views/ViewSettingsSubscription';
import ViewHotelDetails from './pages/views/ViewHotelDetails';
import ViewLinkSettings from './pages/views/ViewLinkSettings';
import ViewNewsletter from './pages/views/ViewNewsletter';
import ViewSearchAnalytics from './pages/views/ViewSearchAnalytics';
import ViewProfile from './pages/views/ViewProfile';
import ViewRoomDetails from './pages/views/ViewRoomDetails';
import ViewCustomer from './pages/views/ViewCustomer';
import ViewPriceAnalytics from './pages/views/ViewPriceAnalytics';
import ViewSubscription from "./pages/views/ViewSubscription";
import ViewReservations from "./pages/views/ViewReservations";
import ViewOffer from "./pages/views/ViewOffer";
import ViewSubscriptionSegment from "./pages/views/ViewSubscriptionSegment";
import ViewSettingsCustomer from "./pages/views/ViewSettingsCustomer";
import ViewSettingsOfferDiscount from "./pages/views/ViewSettingsOfferDiscount";
import ViewSettingsCache from "./pages/views/ViewSettingsCache";
import ViewDistrict from "./pages/views/ViewDistrict";
import ViewDistrictDetails from "./pages/views/ViewDistrictDetails";
import ViewSpecialDatesByCity from "./pages/views/ViewSpecialDatesByCity";
import ViewCompany from "./pages/views/ViewCompany";
import ViewCompanyDetails from "./pages/views/ViewCompanyDetails";
import ViewNewBusinessAccount from "./pages/views/ViewNewBusinessAccount";
import ViewTrips from "./pages/views/ViewTrips";
import ViewLexoffice from "./pages/views/ViewLexoffice";
import ViewLexofficeContact from "./pages/views/ViewLexofficeContact";
import ViewLexofficeInvoice from "./pages/views/ViewLexofficeInvoice";
import ViewLexofficeVoucher from "./pages/views/ViewLexofficeVoucher";
import ViewLexofficeCreditNote from "./pages/views/ViewLexofficeCreditNote";
import ViewReports from "./pages/views/ViewReports";


function App({ history }) {
  const dispatch = useDispatch()

  const muiTheme = createTheme({
    palette: {
      primary: {
        light: '#2489af',
        main: '#1b6a88',
        dark: '#12485d',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#8c949a',
        main: '#6e7379',
        dark: '#4a4b52',
        contrastText: '#ffffff',
      },
    },
    typography: {
      fontSize: 12,
    },
    //spacing: 4,
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontSize: '0.8rem',
          },
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '6px 6px 6px 12px',
          }
        },
      }
    },
  })

  const checkAuth = {
    isAuthenticated: useSelector(state => state.login.isLoggedIn),
  }

  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          checkAuth.isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    )
  }

  const LoginRoute = ({ children, location, ...rest }) => {
    dispatch(actions.restoreSession())
    return (
      <Route
        {...rest}
        render={({ location }) =>
          checkAuth.isAuthenticated ? (
            <Redirect to={{
              pathname: location?.state?.from?.pathname || '/horus',
              search: location?.state?.from?.search || ''
            }} />
          ) : (
            <ViewLogin />
          )
        }
      />
    )
  }

  return (
    <ConnectedRouter history={history}>
      <ThemeProvider theme={muiTheme}>
        <div style={{ flexGrow: 1 }}>
          <Switch>
            <LoginRoute path="/login/" />
            <PrivateRoute path="/app/">
              <Header />
              <div style={{display: 'flex', padding: 15, paddingTop: 0}}>
                <Navigation/>
                <Switch>
                  <Route path="/app/hotels/">
                    <ViewHotel />
                  </Route>
                  <Route path="/app/hotelDetails/:hotelId/">
                    <ViewHotelDetails />
                  </Route>
                  <Route path="/app/districts/">
                    <ViewDistrict />
                  </Route>
                  <Route path="/app/districtDetails/:districtId/">
                    <ViewDistrictDetails />
                  </Route>
                  <Route path="/app/offers/:offerID/">
                    <ViewOffer />
                  </Route>
                  <Route path="/app/offers/">
                    <ViewOffer />
                  </Route>
                  <Route path="/app/users/:customerID/">
                    <ViewCustomer />
                  </Route>
                  <Route path="/app/users/">
                    <ViewCustomer />
                  </Route>
                  <Route path="/app/companies/">
                    <ViewCompany />
                  </Route>
                  <Route path="/app/companyDetails/:companyId">
                    <ViewCompanyDetails />
                  </Route>
                  <Route path="/app/reservations/:reservationID/">
                    <ViewReservations />
                  </Route>
                  <Route path="/app/reservationFilter*/">
                    <ViewReservations />
                  </Route>
                  <Route path="/app/reservations/">
                    <ViewReservations />
                  </Route>
                  <Route path="/app/trips/">
                    <ViewTrips />
                  </Route>
                  <Route path="/app/subscriptions/">
                    <ViewSubscription />
                  </Route>
                  <Route path="/app/subscriptionSegments/:subscriptionID/">
                    <ViewSubscriptionSegment />
                  </Route>
                  <Route path="/app/roomDetails/:roomId/">
                    <ViewRoomDetails />
                  </Route>
                  <Route path="/app/newsletter/">
                    <ViewNewsletter />
                  </Route>
                  <Route path="/app/searchAnalytics/">
                    <ViewSearchAnalytics />
                  </Route>
                  <Route path="/app/qr/">
                    <ViewQrSettings />
                  </Route>
                  <Route path="/app/links/">
                    <ViewLinkSettings />
                  </Route>
                  <Route path="/app/priceAnalytics/">
                    <ViewPriceAnalytics />
                  </Route>
                  <Route path="/app/lexoffice/contact/">
                    <ViewLexofficeContact />
                  </Route>
                  <Route path="/app/lexoffice/invoice/">
                    <ViewLexofficeInvoice />
                  </Route>
                  <Route path="/app/lexoffice/purchaseinvoice/">
                    <ViewLexofficeVoucher type={'purchaseinvoice'}/>
                  </Route>
                  <Route path="/app/lexoffice/purchasecreditnote/">
                    <ViewLexofficeVoucher type={'purchasecreditnote'}/>
                  </Route>
                  <Route path="/app/lexoffice/creditNote/">
                    <ViewLexofficeCreditNote />
                  </Route>
                  <Route path="/app/lexoffice/voucher/">
                    <ViewLexofficeVoucher />
                  </Route>
                  <Route path="/app/lexoffice/">
                    <ViewLexoffice />
                  </Route>
                  <Route path="/app/settings/city/">
                    <ViewSettingsCity />
                  </Route>
                  <Route path="/app/settings/hotel/">
                    <ViewSettingsHotel />
                  </Route>
                  <Route path="/app/settings/room/">
                    <ViewSettingsRoom />
                  </Route>
                  <Route path="/app/settings/subscription/">
                    <ViewSettingsSubscription />
                  </Route>
                  <Route path="/app/settings/customer/">
                    <ViewSettingsCustomer />
                  </Route>
                  <Route path="/app/settings/offerDiscount/">
                    <ViewSettingsOfferDiscount />
                  </Route>
                  <Route path="/app/settings/cache/">
                    <ViewSettingsCache />
                  </Route>
                  <Route path="/app/settings/">
                    <Redirect to={{ pathname: "/app/settings/city/" }} />
                  </Route>
                  <Route path="/app/profile/">
                    <ViewProfile />
                  </Route>
                  <Route path="/app/specialDatesByCity/">
                    <ViewSpecialDatesByCity />
                  </Route>
                  <Route path="/app/newBusinessAccount/">
                    <ViewNewBusinessAccount />
                  </Route>
                  <Route path="/app/reports/">
                    <ViewReports />
                  </Route>
                  <Route path="/app/">
                    <ViewStart />
                  </Route>
                </Switch>
              </div>
            </PrivateRoute>
            <Route path="/">
              <Redirect to={{ pathname: "/app" }} />
            </Route>
          </Switch>
          <Notifications />
        </div>
      </ThemeProvider>
    </ConnectedRouter>
  )
}


export default App

import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  linkSettings: [],
}


const setLinkSettings = (state, action) => {
  return {
    ...state,
    linkSettings: action.res,
  }
}


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_LINK_SETTINGS_SUCCESS: return setLinkSettings(state, action)
    default: return state
  }
}

export default reducer

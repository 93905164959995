import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {format} from "date-fns";

/*
 * GET all subscriptions
 */
export const getSubscription = () => {
  return dispatch => {
    dispatch(getSubscriptionStart())
    axios.get('/horus/subscription')
      .then ( response => {
        dispatch(getSubscriptionSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSubscriptionFail(error))
      })
  }
}

export const getSubscriptionStart = () => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_START,
  }
}

export const getSubscriptionSuccess = (res) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_SUCCESS,
    res: res,
  }
}

export const getSubscriptionFail = (error) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_FAIL,
    error: error,
  }
}


/*
 * GET subscription evaluation by subscriptionID
 */
export const getSubscriptionEvaluation = (subscriptionID) => {
  return dispatch => {
    dispatch(getSubscriptionEvaluationStart())
    axios.get('/horus/subscription/evaluation/'+subscriptionID)
      .then ( response => {
        dispatch(getSubscriptionEvaluationSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSubscriptionEvaluationFail(error))
      })
  }
}

export const getSubscriptionEvaluationStart = () => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_EVALUATION_START,
  }
}

export const getSubscriptionEvaluationSuccess = (res) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_EVALUATION_SUCCESS,
    res: res,
  }
}

export const getSubscriptionEvaluationFail = (error) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_EVALUATION_FAIL,
    error: error,
  }
}


/*
 * GET subscription months for x number of months by startDate
 */
export const getSubscriptionMonths = (x, startDate, target='') => {
  const cleanStartDate = format(new Date(startDate), 'yyyy-MM-dd')
  return dispatch => {
    dispatch(getSubscriptionMonthsStart())
    axios.get('/horus/subscription/months/'+x+'/'+cleanStartDate)
      .then ( response => {
        dispatch(getSubscriptionMonthsSuccess(response.data, target))
      })
      .catch(error => {
        dispatch(getSubscriptionMonthsFail(error))
      })
  }
}

export const getSubscriptionMonthsStart = () => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_MONTHS_START,
  }
}

export const getSubscriptionMonthsSuccess = (res, target) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_MONTHS_SUCCESS,
    res: res,
    target: target,
  }
}

export const getSubscriptionMonthsFail = (error) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_MONTHS_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postSubscription = (payload) => {
  return dispatch => {
    dispatch(postSubscriptionStart())
    axios.post('/horus/subscription', payload)
      .then ( response => {
        dispatch(postSubscriptionSuccess(response.data))
        dispatch(getSubscription())
      })
      .catch(error => {
        dispatch(postSubscriptionFail(error))
      })
  }
}

export const postSubscriptionStart = () => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_START,
  }
}

export const postSubscriptionSuccess = (res) => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_SUCCESS,
    res: res,
  }
}

export const postSubscriptionFail = (error) => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putSubscription = (id, payload) => {
  return dispatch => {
    dispatch(putSubscriptionStart())
    axios.put('/horus/subscription/'+id, payload)
      .then ( response => {
        dispatch(putSubscriptionSuccess(response.data))
        dispatch(getSubscription())
      })
      .catch(error => {
        dispatch(putSubscriptionFail(error))
      })
  }
}

export const putSubscriptionStart = () => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_START,
  }
}

export const putSubscriptionSuccess = (res) => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_SUCCESS,
    res: res,
  }
}

export const putSubscriptionFail = (error) => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteSubscription = (id) => {
  return dispatch => {
    dispatch(deleteSubscriptionStart())
    axios.delete('/horus/subscription/'+id)
      .then ( response => {
        dispatch(deleteSubscriptionSuccess(response.data))
        dispatch(getSubscription())
      })
      .catch(error => {
        dispatch(deleteSubscriptionFail(error))
      })
  }
}

export const deleteSubscriptionStart = () => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_START,
  }
}

export const deleteSubscriptionSuccess = (res) => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_SUCCESS,
    res: res,
  }
}

export const deleteSubscriptionFail = (error) => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_FAIL,
    error: error,
  }
}




/*
 * GET subscription infos from stripe by subscriptionID
 */
export const getSubscriptionStripe = (subscriptionID) => {
  return dispatch => {
    dispatch(getSubscriptionStripeStart())
    axios.get('/horus/subscription/stripe/'+subscriptionID)
      .then ( response => {
        dispatch(getSubscriptionStripeSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSubscriptionStripeFail(error))
      })
  }
}

export const getSubscriptionStripeStart = () => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_STRIPE_START,
  }
}

export const getSubscriptionStripeSuccess = (res, target) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_STRIPE_SUCCESS,
    res: res,
    target: target,
  }
}

export const getSubscriptionStripeFail = (error) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_STRIPE_FAIL,
    error: error,
  }
}


/*
 * GET invoice list from lexoffice by subscriptionID
 */
export const getSubscriptionLexoffice = (subscriptionID) => {
  return dispatch => {
    dispatch(getSubscriptionLexofficeStart())
    axios.get('/horus/lexoffice/invoiceList/'+subscriptionID)
      .then ( response => {
        dispatch(getSubscriptionLexofficeSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSubscriptionLexofficeFail(error))
      })
  }
}

export const getSubscriptionLexofficeStart = () => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_LEXOFFICE_START,
  }
}

export const getSubscriptionLexofficeSuccess = (res, target) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_LEXOFFICE_SUCCESS,
    res: res,
    target: target,
  }
}

export const getSubscriptionLexofficeFail = (error) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_LEXOFFICE_FAIL,
    error: error,
  }
}

import React, {useEffect} from 'react';
import {useParams, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Grid, Button} from '@mui/material';
import {ArrowBack, Hotel} from '@mui/icons-material';
import * as actions from '../../store/actions';
import {Loading} from '../components/Loading';
import RoomDetailsTable from '../tables/RoomDetailsTable';
import SpecialDateSurchargeTable from '../tables/SpecialDateSurchargeTable';
import {updateCurrentUrlParams} from "../../services/url";
import {useHistory, useLocation} from "react-router";


const ViewRoomDetails = withRouter((props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { roomId } = useParams()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    activeSale: searchQuery.get('activeSale') ?? '',
    category: searchQuery.get('category') ?? '',
    countSegments: searchQuery.get('countSegments') ?? '',
  }

  const roomList = useSelector(state => state.room.rooms)
  const hotelList = useSelector(state => state.hotel.hotels)


  const selectedRoom = roomList.filter(room => {
      return room.id === parseInt(roomId)
  })[0] || {}

  const selectedHotel = hotelList.filter(hotel => {
    return hotel.id === selectedRoom.BookingHotelId
  })[0] || {}

  useEffect(() => {
    dispatch(actions.getHotel())
    dispatch(actions.getRoomById(roomId))
    dispatch(actions.getOfferByRoom(roomId))
    dispatch(actions.getSubscriptionCategory())
    dispatch(actions.getSubscriptionDuration())
    dispatch(actions.getSpecialDateByRoom(roomId))
    dispatch(actions.getOfferDiscount())
  },[dispatch, roomId])

  const isLoading = useSelector(state => state.offer.isLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <Button onClick={() => {
          props.history.push('/app/hotelDetails/' + selectedRoom.BookingHotelId);
        }}><ArrowBack/></Button>
        <div className="headlineIconBox">
          <Hotel fontSize={'small'} className="headlineIcon"/>
          <div className="headlineIconText">Room Details</div>
        </div>
        <div>{selectedHotel.name} &nbsp; &mdash; &nbsp; {selectedRoom.name}</div>
        <div style={{marginLeft: 30, padding: 6, backgroundColor: '#f1f5ec'}}>
          <span style={{fontWeight: 'bold', color: '#5b8328'}}>FILTER:</span>
          <span style={{marginLeft: 10}}>Sales &nbsp;
            <button style={query.activeSale === '1' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {activeSale: '1'})}>active</button>
            <button style={query.activeSale === '0' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {activeSale: '0'})}>inactive</button>
            <button style={query.activeSale === '' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {activeSale: ''})}>all</button>
          </span>
          <span style={{marginLeft: 10}}>Category &nbsp;
            <button style={query.category === 'business' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {category: 'business'})}>business</button>
            <button style={query.category === 'single' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {category: 'single'})}>single</button>
            <button style={query.category === '' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {category: ''})}>all</button>
          </span>
          <span style={{marginLeft: 10}}>Usage &nbsp;
            <button style={query.countSegments === '!0' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {countSegments: '!0'})}>in use</button>
            <button style={query.countSegments === '0' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {countSegments: '0'})}>none</button>
            <button style={query.countSegments === '' ? {fontWeight: 'bold'} : {}}
                    onClick={() => updateCurrentUrlParams(history, {countSegments: ''})}>all</button>
          </span>
        </div>
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {isLoading ? <Loading/> : <RoomDetailsTable room={selectedRoom}/>}
        </Grid>
        <Grid item xs={12} style={{marginTop: 25}}>
          {isLoading ? <Loading/> : <SpecialDateSurchargeTable room={selectedRoom}/>}
        </Grid>
      </Grid>
    </div>
  )
})


export default ViewRoomDetails

import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom'
import MaterialTable from '@material-table/core'
import {Typography} from '@mui/material';
import {formatDateTime} from '../../services/unitFormater'
import * as actions from '../../store/actions'
import {localization, tableIcons} from '../components/TableSharedConfig'
import {shortenText} from '../../services/stringHelper';
import UniversalUploadField from '../components/UniversalUploadField';

const HotelAttributeTable = withRouter((props) => {

  const dispatch = useDispatch()
  const data = useSelector(state => state.settings.hotelAttribute)
  const [antiCacheCounter, setAntiCacheCounter] = useState(0)

  const icon = {
    title: 'Icon',
    field: 'name',
    editable: 'never',
    sorting: false,
    render: rowData => <div style={{width: 60}}>
      <img
        src={`/api/horus/hotelAttribute/svg/${rowData.id}?c=${antiCacheCounter}`}
        alt={rowData.name}
        style={{width: 40, height: 40}}
      />
    </div>,
}

  const name = {
    title: 'Name',
    field: 'name',
    render: rowData => <div style={{fontWeight: 'bold'}}>{rowData.name}</div>,
    defaultSort: 'asc',
  }

  const attributeId = {
    title: 'Shortcode',
    field: 'shortcode',
  }

  const description = {
    title: 'Description',
    field: 'description',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 270}}>{shortenText(rowData.description, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="5" cols="35"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const createdAt = {
    title: 'CreatedAt',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <span>{formatDateTime(rowData.createdAt)}</span>,
    cellStyle: {
      width: 40,
    },
    headerStyle: {
      width: 40,
    },
    editable: 'never',
  }

  const updatedAt = {
    title: 'UpdatedAt',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <span>{formatDateTime(rowData.updatedAt)}</span>,
    cellStyle: {
      width: 40,
    },
    headerStyle: {
      width: 40,
    },
    editable: 'never',
  }

  const columnSet = [
    icon,
    name,
    attributeId,
    description,
    createdAt,
    updatedAt,
  ]

  const DetailsPanel = (props) => {
    const setUpload = (e) => {
      setAntiCacheCounter(Math.random())
    }
    return(
      <div style={{ padding: 10, paddingLeft: 50, borderLeft: '10px solid #f3f3f3' }}>
        <div style={{ width: 300 }}>
          <span>Upload new icon:</span>
          <UniversalUploadField
            uploadFieldType={'icon'}
            uploadFieldNumber={'1'}
            uploadSetState={setUpload}
            apiUrl={'/api/horus/hotelAttribute/svg/'+props.rowData.rowData.id}
            mimeType={'image/svg+xml'}
            text1="SVG Icon hochladen"
            text2="Klick oder Drag&Drop"
          />
        </div>
      </div>
    )
  }

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={"Hotel Attributes"}
        columns={columnSet}
        data={data}
        icons={tableIcons}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postSettingsHotelAttribute(newData))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putSettingsHotelAttribute(oldData.id, newData))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteSettingsHotelAttribute(oldData.id))
              resolve()
            }),
        }}
        options={{
          search: true,
          filtering: false,
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#fff" : "#f9fcff",
          }),
          cellStyle:{ padding: '6px 6px 6px 12px'},
        }}
        localization={localization}
        detailPanel={[
          {
            tooltip: 'Show Details',
            render: rowData => <DetailsPanel rowData={rowData} />,
          }
        ]}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </Typography>
  )
})


export default HotelAttributeTable

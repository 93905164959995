import * as actionTypes from './actionTypes'
import axios from '../../axios.config'

export const getNewsletter = () => {
  return dispatch => {
    dispatch(getNewsletterStart())
    axios.get('/horus/newsletter/')
      .then ( response => {
        dispatch(getNewsletterSuccess(response.data))
      })
      .catch(error => {
        dispatch(getNewsletterFail(error))
      })
  }
}

export const getNewsletterStart = () => {
  return {
    type: actionTypes.GET_NEWSLETTER_START,
  }
}

export const getNewsletterSuccess = (res) => {
  return {
    type: actionTypes.GET_NEWSLETTER_SUCCESS,
    res: res,
  }
}

export const getNewsletterFail = (error) => {
  return {
    type: actionTypes.GET_NEWSLETTER_FAIL,
    error: error,
  }
}

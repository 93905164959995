import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import MaterialTable from '@material-table/core';
import {Autocomplete, createFilterOptions, IconButton, TextField, Tooltip, Typography} from '@mui/material';
import {
  formatCurrency,
  formatCurrencyInline,
  formatDate,
  formatDateTime,
  getDateAsCleanUTC,
} from '../../services/unitFormater';
import * as actions from '../../store/actions';
import {localization, tableIcons} from '../components/TableSharedConfig';
import {shortenText} from "../../services/stringHelper";
import {GermanDatePicker} from "../components/LocalDateInput";
import {useHistory, useLocation} from "react-router";
import {
  Cancel,
  Check,
  CheckBox,
  Close,
  GridOn,
  HelpCenter,
  InfoOutlined,
  Replay,
  WarningAmber
} from "@mui/icons-material";
import {DrillDown, FilterIcon} from "../../assets/SvgIcons";
import AutocompleteAssignHotel from "../components/AutocompleteAssignHotel";
import AutocompleteBusinessUserInvite from "../components/AutocompleteBusinessUserInvite";
import {add} from "date-fns";
import {updateCurrentUrlParams} from "../../services/url";
import {ReservationDetailsInput, TripDetailsInput, TripInvoiceDetails} from "../components/TripDetailsForm";
import {getTripStatusColor, getTripDetailsStatusColor} from "../../services/enum";

const TripTable = withRouter((props) => {

  const dispatch = useDispatch()
  const tableRef = React.useRef()
  const history = useHistory()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    trip: searchQuery.get('trip') || '',
    status: searchQuery.get('status') || '',
    subscription: searchQuery.get('subscription') || '',
    offer: searchQuery.get('offer') || '',
    guest: searchQuery.get('guest') || '',
    //defaultSort: searchQuery.get('defaultSort') || 'createdAt',
    orderBy: searchQuery.get('orderBy') || '',
    orderDirection: searchQuery.get('orderDirection') || '',
  }

  const subscriptionList = useSelector(state => state.subscription.subscriptions)
  const offerList = useSelector(state => state.offer.offers)
  const hotelList = useSelector(state => state.hotel.hotels) || []

  const refreshHook = () => {
    tableRef?.current && tableRef?.current?.onQueryChange()
  }

  const createdAt = {
    title: 'Created',
    field: 'createdAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.createdAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const updatedAt = {
    title: 'Updated',
    field: 'updatedAt',
    type: 'datetime',
    render: rowData => <div style={{width: 80}}>{formatDateTime(rowData.updatedAt)}</div>,
    editable: 'never',
    filtering: false,
  }

  const CopyToClipboard = ({ tripID }) => {
    const [copied, setCopied] = useState(false)

    const handleCopy = () => {
      navigator.clipboard.writeText(tripID)
      setCopied(true)
      setTimeout(() => setCopied(false), 400)
    }

    const containerStyle = {
      fontSize: 12,
      width: 60,
      wordBreak: 'break-word',
      padding: 5,
      cursor: 'pointer',
      transition: 'background-color 0.3s, border 0.3s',
      backgroundColor: copied ? '#efeab3' : '',
    }

    const defaultStyle = {
      //backgroundColor: '#b0dbea',
      //border: '1px solid #ccc',
    }

    return (
      <Tooltip title="Click to copy" enterTouchDelay={0}><div
        style={{ ...containerStyle, ...(copied ? {} : defaultStyle) }}
        onClick={handleCopy}
      >
        {tripID}
      </div></Tooltip>
    )
  }

  const tripID = {
    title: 'Trip ID',
    field: 'tripID',
    editable: 'never',
    defaultFilter: query.trip,
    render: rowData => <CopyToClipboard tripID={rowData.tripID} />,
  }

  const startDate = {
    title: <div>Arrival️</div>,
    field: 'startDate',
    type: 'date',
    validate: (rowData) => (rowData.startDate || "").length !== 0,
    render: rowData => <div>{formatDate(rowData.startDate)}</div>,
    editComponent: props => <GermanDatePicker data={props} />,
  }

  const endDate = {
    title: <div>Departure</div>,
    field: 'endDate',
    type: 'date',
    validate: (rowData) => (rowData.endDate || "").length !== 0,
    render: rowData => <div>{formatDate(rowData.endDate)}</div>,
    editComponent: props => {
      const minDate = add(new Date(props.rowData.startDate), {days: 1})
      return(<GermanDatePicker data={props} minDate={minDate} />)
    },
  }

  const nights = {
    title: 'Nights',
    field: 'nights',
    render: rowData => <div>
        <Link to={'/app/reservations/?tripID='+rowData?.tripID}>
          <span style={{display:'flex', alignItems: 'center'}}>
            <span style={{marginRight: 4}}>{rowData?.nights}</span>
            <DrillDown />
          </span>
        </Link>
    </div>,
    editable: 'never',
  }

  const surchargeSum = {
    title: 'Surcharge Sum',
    field: 'surchargePriceSum',
    headerStyle: {
      maxWidth: 140,
    },
    render: rowData => formatCurrency(rowData?.surchargePriceSum || 0),
    editable: 'never',
  }

  const hotelRateSum = {
    title: 'Hotel Rate Sum',
    field: 'hotelBookingRateSum',
    headerStyle: {
      maxWidth: 140,
    },
    render: rowData => formatCurrency(rowData?.hotelBookingRateSum || 0),
    editable: 'never',
  }

  const surchargeDescription= {
    title: 'Surcharge Description Summary',
    field: 'surchargeDescriptionSummary',
    render: rowData => <div style={{fontSize: 12}}>
      {JSON?.parse(rowData?.surchargeDescriptionSummary)?.join(', ') || ''}
    </div>,
    editable: 'never',
  }

  const handleKey = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
    }
  }

  const status = {
    title: 'Status',
    field: 'status',
    render: rowData => <div>
      <span style={{backgroundColor: getTripStatusColor(rowData.status), color: '#FFFFFF', padding: '3px 5px', borderRadius: 5, flex: 1}}>{rowData.status}</span>
    </div>,
    editable: 'onUpdate',
    editComponent: props => (
      <div style={{width: 160}}>
        <Autocomplete
          id="status"
          options={[
            'new',
            'canceled_refunded',
            'canceled_deducted',
            'confirmed',
            'partially_confirmed',
            'declined',
          ]}
          disableClearable={true}
          //disableCloseOnSelect
          getOptionLabel={(option) => option || '(required)'}
          value={props.value}
          defaultValue={'new'}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          onChange={(e, value) => {
            props.onChange(value)
          }}
          isOptionEqualToValue={(option, value) => {
            return option === value
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{fontSize: 13}}>{option}</div>
            </li>
          )}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    defaultFilter: query.status,
  }


  const actionButton = {
    title: 'Action',
    field: '',
    render: rowData => <div>
      {(rowData.status === 'new' || rowData.status === 'partially_confirmed')
        ? <div>
          <Tooltip title={"Send e-mail confirmation with hotel details to customer. Set trip status to 'confirmed'"} enterTouchDelay={0} placement="top">
            <div>
              <button disabled={isSelfCheckInDataMissing(rowData) || isHotelAssigmentMissing(rowData)} onClick={() => {
                dispatch(actions.postMailTripConfirmationToCustomer(rowData.id, refreshHook))
              }} style={{marginTop: 5, marginBottom: 5, cursor: 'pointer'}}>
                Confirm
              </button>
            </div>
          </Tooltip>
        </div>
        : ''
      }
      {isHotelAssigmentMissing(rowData)
        ? <HotelAssigmentMissingNotice rowData={rowData}/>
        : ''
      }
      {isSelfCheckInDataMissing(rowData) && !['canceled_refunded','canceled_deducted'].includes(rowData.status)
        ? <SelfCheckInMissingNotice rowData={rowData}/>
        : ''
      }
      {isRebookCustomerInformationMissing(rowData)
        ? <RebookInformationMissingNotice rowData={rowData} />
        : ''
      }
    </div>,
    editable: 'never',
  }

  const bookingSubscription = {
    title: 'Subscription',
    field: 'BookingSubscription',
    validate: (rowData) => rowData?.BookingSubscription?.subscriptionID?.length > 0,
    render: rowData => {
      const subscriptionID = rowData.BookingSubscription?.subscriptionID
      const subscriptionDetails = subscriptionList.filter(o => o.id === rowData?.BookingSubscription?.id)?.[0] || {}
      const startDate = subscriptionDetails?.startDate
      const endDate = subscriptionDetails?.endDate
      const customer = subscriptionDetails?.BookingCustomer
      return(
        <div>
          <Link to={`/app/subscriptionSegments/${subscriptionID}`}>{subscriptionID}</Link>
          <Link to={'/app/trips/?subscription=' + subscriptionID} title={"Filter by subscription ID"}>
            <FilterIcon />
          </Link>
          <div style={{fontSize: 11}}>{subscriptionID
            ? <>{formatDate(startDate)} - {endDate ? formatDate(endDate) : '∞'}</>
            : ''
          }</div>
          <Link to={`/app/users/${customer?.customerID}`}>{customer?.customerID}</Link>
          <Link to={'/app/trips/?subscription=' + customer?.customerID} title={"Filter by customer ID"}>
            <FilterIcon />
          </Link>
          <div style={{fontSize: 11}}>{customer?.firstName} {customer?.lastName}</div>
          <div style={{fontSize: 11}}><Link to={'/app/companies/?companyID='+customer?.BusinessCompany?.companyID}>{customer?.BusinessCompany?.name}</Link></div>
        </div>
      )},
    customFilterAndSearch: (term, rowData) => {
      const subscriptionID = rowData.BookingSubscription?.subscriptionID
      const customer = subscriptionList.filter(o => o.id === rowData.BookingSubscription.id)[0]?.BookingCustomer
      const bookingOffer = subscriptionList.filter(o => o.id === rowData.BookingSubscription.id)[0]?.BookingOffer
      const nightsName = offerList.filter(o => o.id === bookingOffer?.id)[0]?.BookingSubscriptionCategory?.name
      const hotelName = offerList.filter(o => o.id === bookingOffer?.id)[0]?.BookingRoom?.BookingHotel?.name
      const pricePerNight = bookingOffer?.price?.toFixed(2)?.replace('.',',')
      const searchString = subscriptionID + ' ' + customer?.firstName + ' ' + customer?.lastName + ' '
        + bookingOffer + ' ' + nightsName + ' ' + hotelName + ' ' + pricePerNight
      return (searchString.toLowerCase().includes(term.toLowerCase()))
    },
    editComponent: props => (
      <div style={{width: 210}}>
        <Autocomplete
          id="BookingSubscriptionId"
          options={subscriptionList}
          disableClearable={true}
          getOptionLabel={(option) => {
            return(option.subscriptionID || '(required)')
          }}
          value={props.value || ''}
          onChange={(e, value) => {
            let data = { ...props.rowData }
            data.AssignHotel = null
            data.BookingSubscription = value
            data.BusinessUserInvitation = null
            props.onRowDataChange(data)
          }}
          autoHighlight={true} // required to select entry without submitting entire material-table row
          onKeyDown={e => handleKey(e)}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id
          }}
          renderOption={(props, option) => {
            const subscription = subscriptionList.filter(o => o.id === option.id)?.[0]
            const customer = subscription?.BookingCustomer || ''
            const companyID = subscription?.BookingCustomer?.BusinessCompany?.companyID || ''
            const companyName = subscription?.BookingCustomer?.BusinessCompany?.name || ''
            const hotelName = subscription?.hotel?.name || ''
            const roomName = subscription?.room?.name || ''
            return(
              <div {...props}>
                <div style={{fontSize: 13}}>
                  {option.subscriptionID || '---'}
                  <div style={{fontSize: 10}}>{customer?.firstName} {customer?.lastName} ({customer?.customerID})</div>
                  {companyID ? <div style={{fontSize: 10}}>{companyName} ({companyID})</div> : ''}
                  {hotelName ? <div style={{fontSize: 10}}>{hotelName} | {roomName}</div> : ''}
                  <div style={{fontSize: 10}}>
                    {formatDate(subscription.startDate)} - {subscription.endDate
                    ? formatDate(subscription.endDate)
                    : '∞'} ({subscription?.status})
                  </div>
                </div>
              </div>
            )
          }}
          filterOptions={createFilterOptions({
            matchFrom: 'any',
            stringify: (option) => {
              const subscription = subscriptionList.filter(o => o.id === option.id)?.[0]
              const subscriptionID = subscription?.subscriptionID
              const customer = subscription?.BookingCustomer || ''
              const hotelName = subscription?.hotel?.name || ''
              const roomName = subscription?.room?.name || ''
              const companyID = subscription?.BookingCustomer?.BusinessCompany?.companyID || ''
              const companyName = subscription?.BookingCustomer?.BusinessCompany?.name || ''
              return(subscriptionID + ' ' + customer.customerID + ' ' + customer.firstName + ' '+ customer.lastName
                + ' ' + hotelName + ' ' + roomName + ' ' + companyID + ' ' + companyName)
            },
          })}
          size="small"
          renderInput={(params) => {
            params.InputProps.style = {fontSize: 13}
            return(
              <TextField
                {...params}
                placeholder=""
              />
            )
          }}
        />
      </div>
    ),
    defaultFilter: query.subscription,
  }

  const offer = {
    title: 'Offer',
    field: 'offerID', //this field doesn't exist in response and is just as filter parameter
    render: rowData => {
      const subscriptionID = rowData.BookingSubscription?.subscriptionID
      const segment = rowData.BookingSubscription?.BookingSubscriptionSegments
      const offerID = segment?.BookingOffer?.offerID
      const offer = offerList.filter(o => o.offerID === offerID)?.[0] || {}
      const category = offer?.BookingSubscriptionCategory?.name
      const shareable = offer?.BookingSubscriptionCategory?.shareable
      const year = offer?.BookingSubscriptionCategory?.nightsScope === 'year'
      const activeSale = offer?.activeSale
      const discount = offer?.discount
      const duration = offer?.BookingSubscriptionDuration?.name
      const roomName = offer?.BookingRoom?.name
      const hotelName = offer?.BookingRoom?.BookingHotel?.name
      const price = offer?.price?.toFixed(2)?.replace('.',',')
      const pricePerMonth = offer?.pricePerMonth?.toFixed(2)?.replace('.',',')
      const hotelOfferType = offer?.BookingRoom?.BookingHotel?.hotelOfferType
      const districtId = segment?.BookingOffer?.BookingRoom?.BookingHotel?.BookingDistrictId
      //const districtName = districts.filter(d => d.id === districtId)?.[0]?.name || ''
      return(<>
        {['breakMonth','breakCustom'].includes(segment?.segmentType) ?
          <div style={{marginBottom: 3, fontSize: 12, color: '#8c6614', display: 'flex', alignItems: 'center' }}>
            <InfoOutlined fontSize="small" />
            <span style={{marginLeft: 3}}>Subscription paused</span>
          </div>
          : ''
        }
        {['termination'].includes(segment?.segmentType) ?
          <div style={{marginBottom: 3, fontSize: 12, color: '#8c6614', display: 'flex', alignItems: 'center' }}>
            <InfoOutlined fontSize="small" />
            <span style={{marginLeft: 3}}>Subscription terminated</span>
          </div>
          : ''
        }
        {(!segment) ?
          <div style={{marginBottom: 3, fontSize: 12, color: '#8c1414', display: 'flex', alignItems: 'center' }}>
            <WarningAmber />
            <span style={{marginLeft: 3}}>No segment found. Subscription not fully initialized.</span>
          </div>
          : ''
        }
        {offerID ?
          <div style={{fontSize: 13, width: 180}}>
            <div>
              {category} @ <Link to={'/app/hotels/?name='+hotelName} title="Jump to hotel config">{hotelName}</Link>
              {hotelOfferType === 'district' ? <span> <Link to={'/app/districtDetails/'+districtId} title="Jump to district config"><GridOn sx={{color: '#627f85', width: 16, height: 16}}/></Link>
              </span> : ''}
            </div>

            <div style={{fontSize: 10}}>{roomName} | {duration} Laufzeit</div>
            <div style={{fontSize: 10}}>
              <Link to={`/app/offers/${offerID}`}>{offerID}</Link>&nbsp;|&nbsp;
              <Link to={`/app/subscriptionSegments/${subscriptionID}`}>{segment?.segmentID}</Link>
            </div>
            <div style={{fontSize: 10}}>{price} €/Nacht | {pricePerMonth} €/Monat</div>
            <div style={{fontSize: 10}}>{
              shareable ? <span style={{color:"blue"}}>business</span> : 'single'
            } | {
              year ? <span style={{color:"purple"}}>year</span> : 'month'
            } | {
              activeSale ? 'active' : <span style={{color:"red"}}>inactive</span>
            } | {
              discount > 0 ? <span style={{color:"green"}}>{formatCurrencyInline(discount)} discount</span> : 'no discount'
            }</div>
          </div>
        : ''}

      </>)

    },
    editable: 'never',
    defaultFilter: query.offer,
  }

  const businessUserInvitation = {
    title: 'Guest (Business)',
    field: 'BusinessUserInvitation',
    render: rowData => {
      const customer = rowData.BookingSubscription?.BookingCustomer
      const segment = rowData.BookingSubscription?.BookingSubscriptionSegments
      const shareable = segment?.BookingOffer?.BookingSubscriptionCategory?.shareable
      //const companyName = rowData?.BookingSubscription?.BookingCustomer?.BusinessCompany?.name || null
      const companyId = rowData?.BookingSubscription?.BookingCustomer?.BusinessCompany?.id || null
      const invitationID = rowData?.BusinessUserInvitation?.invitationID || null

      if(shareable) {
        if(invitationID) {
          return(
            <div style={{fontSize: 11}}>
              <div>
                <Link to={'/app/companyDetails/' + companyId}>{invitationID}</Link>
              </div>
              <div style={{fontSize: 11}}>
                {rowData.BusinessUserInvitation?.firstName} {rowData.BusinessUserInvitation?.lastName}
              </div>
              {/*
              <Link to={'/app/trips/?subscription=' + rowData.BookingSubscription?.subscriptionID + '&guest=' + invitationID} title={"Filter by guest"}>
                <FilterIcon />
              </Link>
              */}
            </div>
          )
        } else {
          return (
            <div>
              <div style={{fontSize: 11}}>
                {customer?.firstName} {customer?.lastName} (Admin)
              </div>
            </div>
          )
        }
      } else {
        return (<div></div>)
      }
    },
    editComponent: props => {
      return(<div style={{width: 200}}>
        <AutocompleteBusinessUserInvite data={props} />
      </div>)
    },
    defaultFilter: query.guest,
  }

  const internalComment = {
    title: 'Internal Comment',
    field: 'internalComment',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 140}}>{shortenText(rowData.internalComment, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  /* javascript function to extract domain name from url */
  const getDomainName = (url) => {
    // remove 'http://' or 'https://' from the beginning of the URL
    url = url.replace(/^https?:\/\//,'')
    // remove everything after the domain name, if present
    url = url.replace(/\/.*$/,'')
    // return the domain name
    return url
  }

  /* return true if current hotel (default, assigned or rebooked) requires self check-in and the necessary url is missing */
  const isSelfCheckInDataMissing = (rowData) => {
    const hotel = rowData?.BookingSubscription?.BookingSubscriptionSegments?.BookingOffer?.BookingRoom?.BookingHotel
    const assignHotel = hotelList.filter(hotel => hotel.id === rowData.AssignHotel?.id)?.[0] || null
    const rebookHotel = hotelList.filter(hotel => hotel.id === rowData.RebookHotel?.id)?.[0] || null
    const hotelRequiresSelfCheckIn = (hotel?.selfCheckIn && !assignHotel && !rebookHotel)
      || (assignHotel?.selfCheckIn && !rebookHotel)
      || (rebookHotel?.selfCheckIn)
    return hotelRequiresSelfCheckIn && !rowData.selfCheckInUrl
  }

  /* return true if current reservation is part of a district subscription and hotel assignment is missing */
  const isHotelAssigmentMissing = (rowData) => {
    const currentReservationIsPartOfDistrictSubscription = rowData?.BookingDistrictId > 0
    const assignHotel = hotelList.filter(hotel => hotel.id === rowData.AssignHotel?.id)?.[0]
    const statusIsUnconfirmed = ['new','confirmed','partially_confirmed'].includes(rowData.status)
    return currentReservationIsPartOfDistrictSubscription && !assignHotel && statusIsUnconfirmed
  }

  /* return true if current reservation was rebooked and customer information mail is missing */
  const isRebookCustomerInformationMissing = (rowData) => {
    const rebookHotel = hotelList.filter(hotel => hotel.id === rowData.RebookHotel?.id)?.[0]
    const informed = ((rowData.rebookHotelSetAt <= rowData.confirmedByBackofficeAt) || (rowData.rebookHotelSetAt <= rowData.hotelResponseDate) || rowData.rebookHotelSetAt <= rowData.rebookConfirmToCustomerAt)
    return rebookHotel && !informed
  }

  const SelfCheckInMissingNotice = (props) => {
    return(
      <div style={{color: '#8c1414', display: 'flex', alignItems: 'center' }}>
        <WarningAmber />
        <span style={{marginLeft: 3, fontSize: 11}}>{props?.text ? props.text : 'Self Check-In Url missing'}</span>
      </div>
    )
  }

  const HotelAssigmentMissingNotice = (props) => {
    return(
      <div style={{color: '#8c1414', display: 'flex', alignItems: 'center' }}>
        <WarningAmber />
        <span style={{marginLeft: 3, fontSize: 11}}>Hotel Assigment missing</span>
      </div>
    )
  }

  const RebookInformationMissingNotice = (props) => {
    return(
      <div style={{color: '#8c1414', display: 'flex', alignItems: 'center' }}>
        <WarningAmber />
        <span style={{marginLeft: 3, fontSize: 11}}>Customer not informed about rebooking</span>
      </div>
    )
  }

  const selfCheckInUrl = {
    title: 'Self CheckIn Url',
    field: 'selfCheckInUrl',
    render: rowData => {
      return(
        rowData.selfCheckInUrl
        ? <div><a href={rowData.selfCheckInUrl}>{getDomainName(rowData.selfCheckInUrl)}</a></div>
        : isSelfCheckInDataMissing(rowData)
          ? <div style={{fontSize: 11, color: '#8c1414'}}>Check-In URL required</div>
          : ''
      )
    },
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const messageToCustomer = {
    title: 'Message To Customer',
    field: 'messageToCustomer',
    render: rowData => <div style={{whiteSpace: 'pre-wrap', width: 140}}>{shortenText(rowData.messageToCustomer, 160)}</div>,
    editComponent: props => (
      <textarea
        rows="3" cols="20"
        style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif', fontSize: '13px'}}
        value={props.value || ''}
        onChange={e => props.onChange(e.target.value)}
      />
    ),
  }

  const bookingChannel = {
    title: 'Hotel Booking Channel',
    field: 'bookingChannel',
    editComponent: props => (
      <TextField
        size={'small'}
        value={props.value || ''}
        placeholder="Booking Channel"
        InputProps={{
          style: {fontSize: 13}
        }}
        variant={'standard'}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const hotelBookingNumber = {
    title: 'Hotel Booking Number',
    field: 'hotelBookingNumber',
    editComponent: props => (
      <TextField
        size={'small'}
        value={props.value || ''}
        placeholder="Booking Number"
        InputProps={{
          style: {fontSize: 13}
        }}
        variant={'standard'}
        onChange={e => props.onChange(e.target.value)}
      />
    )
  }

  const hotelInvoiceSum = {
    title: 'Hotel Invoice Sum',
    field: 'hotelInvoiceSum',
    headerStyle: {
      maxWidth: 140,
    },
    render: rowData => formatCurrency(rowData?.hotelInvoiceSum || 0),
    editable: 'never',
  }

  const tripHasNullRates = {
    title: 'Hotel Rate Status',
    field: 'tripHasNullRates',
    render: rowData => <div>{rowData.tripHasNullRates
      ? <Tooltip title="Rates missing" enterTouchDelay={0}><HelpCenter style={{color: '#d8b116'}} /></Tooltip>
      : <Tooltip title="Rates entered" enterTouchDelay={0}><CheckBox style={{color: '#0fa14a'}} /></Tooltip>
    }</div>,
    customFilterAndSearch: (term, rowData) => {
      const searchString = {
        true: 'yes ja true 1',
        false: 'no nein false 0',
      }
      return (searchString[rowData.tripHasNullRates].includes(term.toLowerCase()))
    },
    editable: 'never',
    sorting: false,
  }

  const hotelInvoiceDelta = {
    title: 'Hotel Invoice Delta',
    field: 'hotelInvoiceDelta',
    headerStyle: {
      maxWidth: 140,
    },
    render: rowData => {
      if(rowData?.status !== 'canceled_refunded') {
        return(<div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{marginRight: 5}}>{
            rowData?.hotelInvoiceDelta === null
              ? <Tooltip title="Invoice missing" enterTouchDelay={0}><HelpCenter style={{color: '#d8b116'}} /></Tooltip>
              : rowData?.hotelInvoiceDelta === 0
                ? <Tooltip title="OK" enterTouchDelay={0}><CheckBox style={{color: '#0fa14a'}} /></Tooltip>
                : rowData?.hotelInvoiceDeltaSoftOk
                  ? <Tooltip title="SOFT OK" enterTouchDelay={0}><CheckBox style={{color: '#0fa14a'}} /></Tooltip>
                  : <Tooltip title="NOT OK - Invoice and purchase price do not match" enterTouchDelay={0}><Cancel style={{color: '#ae5108'}} /></Tooltip>
          }</div>
          <div>{rowData?.hotelInvoiceDelta > 0 ? '+':''}{rowData?.hotelInvoiceDelta !== null
            ? formatCurrencyInline(rowData?.hotelInvoiceDelta)
            : 'missing'
          }</div>
        </div>)
      } else {
        return ('')
      }
    },
    editable: 'never',
  }

  const assignHotel = {
    title: 'Assign Hotel',
    field: 'AssignHotel',
    //editable: 'never',
    render: rowData => {
      const assignHotel = hotelList.filter(hotel => hotel.id === rowData.AssignHotel?.id)
      const assignHotelName = assignHotel?.[0]?.name || ''
      const assignHotelBookingMail = assignHotel?.[0]?.bookingServiceEMail || ''
      return(
        <div>{
          rowData?.BookingDistrictId > 0 //= 'district'
            ? (rowData?.AssignHotel?.id > 0)
              ? <div>
                  <Link to={'/app/hotels/?name='+assignHotelName}>{assignHotelName}</Link>

                  <Tooltip title={"Send e-mail with booking request to hotel '"+assignHotelName+"' ("+assignHotelBookingMail+")"} enterTouchDelay={0} placement="top">
                    <button onClick={() => dispatch(actions.postMailConfirmationRequestHotel(rowData.id, refreshHook))} style={{marginTop:5, cursor:'pointer'}}>
                      Request hotel confirmation
                    </button>
                  </Tooltip>
                  {rowData.assignHotelRequestConfirmationAt
                    ? <div style={{fontSize: 10}}>Requested mail: {formatDateTime(rowData.assignHotelRequestConfirmationAt)}</div>
                    : <div style={{fontSize: 11, color: '#8c1414'}}>Not yet requested</div>
                  }

                </div>
              : <div>
                {rowData?.status === 'new'
                  ? <>
                    <button onClick={() => dispatch(actions.showDistrictAssignHotelModal({
                        trip: rowData.id,
                        districtId: rowData?.BookingDistrictId,
                        refreshHook: refreshHook,
                        BookingSubscription: rowData?.BookingSubscription,
                        BusinessUserInvitation: rowData?.BusinessUserInvitation,
                      }
                    ))} style={{cursor: 'pointer'}}>
                      Assign Hotel
                    </button>
                    <div style={{fontSize: 11, color: '#8c1414'}}>required</div>
                  </>
                  : ''}
              </div>
            : ''
        }
        </div>
      )
    },
    editComponent: props => {
      const isNewRow = props.rowData?.tableData?.id === undefined;
      return (
        <div style={{width: 140}}>
          <div>
            {
              props.rowData?.BookingDistrictId > 0
                ? <AutocompleteAssignHotel data={props} />
                : ''
            }
          </div>
          <div style={{color: '#999999', fontSize: 11}}>{isNewRow ? 'Trip needs to be saved to check for district subscriptions.':''}</div>
        </div>
      )
    }
  }

  const rebookHotel = {
    title: 'Rebook Hotel',
    field: 'RebookHotel',
    render: rowData => {
      const rebookHotelName = hotelList.filter(hotel => hotel.id === rowData.RebookHotel?.id)?.[0]?.name || ''
      const rebookHotelBookingMail = hotelList.filter(hotel => hotel.id === rowData.RebookHotel?.id)?.[0]?.bookingServiceEMail || ''
      const informButtonDisabled = rowData.status !== 'confirmed' || isSelfCheckInDataMissing(rowData)
      if(rebookHotelName) {
        return(<div>
          <Link to={'/app/hotels/?name=' + rebookHotelName}>{rebookHotelName}</Link>
          <br/>
          <Tooltip title={informButtonDisabled ? 'Status "confirmed" and Self-CheckIn data required for subsequent information on rebooking.': ''}>
            <div>
              <button
                disabled={informButtonDisabled}
                onClick={() => dispatch(actions.postMailRebookInformationToCustomer(rowData.id, refreshHook))}
                style={{cursor: 'pointer'}}>
                Inform customer
              </button>
            </div>
          </Tooltip>
          <br/>
          <div style={{fontSize: 10}}>{rowData.rebookConfirmToCustomerAt
            ? <div>Last rebook mail: {formatDateTime(rowData.rebookConfirmToCustomerAt)}</div>
            : ((rowData.rebookHotelSetAt <= rowData.confirmedByBackofficeAt) || (rowData.rebookHotelSetAt <= rowData.hotelResponseDate))
              ? <div>Informed with initial confirmation</div>
              : <div style={{fontSize: 11, color: '#8c1414'}}>Not yet informed</div>
          }</div>


              <Tooltip title={"Send e-mail with booking request to hotel '"+rebookHotelName+"' ("+rebookHotelBookingMail+")"} enterTouchDelay={0} placement="top">
                <button onClick={() => dispatch(actions.postMailConfirmationRequestHotel(rowData.id, refreshHook))} style={{marginTop:5, cursor:'pointer'}}>
                  Request hotel confirmation
                </button>
              </Tooltip>
              {rowData.rebookHotelRequestConfirmationAt
                ? <div style={{fontSize: 10}}>Requested mail: {formatDateTime(rowData.rebookHotelRequestConfirmationAt)}</div>
                : <div style={{fontSize: 11, color: '#8c1414'}}>Not yet requested</div>
              }

        </div>)
      } else { return ('') }
    },
    editComponent: props => {
      /* rebook not available for district reservations which are not confirmed yet */
      if(props?.rowData?.BookingDistrictId > 0 && props?.rowData?.status === 'new' && props?.rowData?.RebookHotel === null) {
        return(<div style={{color: '#999999', fontSize: 11}}>In district subscriptions, rebooking is only possible after confirmation.</div>)
      } else {
        return (
          <div style={{width: 140}}>
            <Autocomplete
              id="RebookHotelId"
              options={hotelList}
            getOptionLabel={(option) => option.name || '-'}
            value={props.value || ''}
            onChange={(e, value) => {
              props.onChange({id:value?.id, name:value?.name})
            }}
            autoHighlight={true} // required to select entry without submitting entire material-table row
            onKeyDown={e => handleKey(e)}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <div style={{fontSize: 13}}>{option.name || '---'}</div>
              </li>
            )}
            size="small"
            renderInput={(params) => {
              params.InputProps.style = {fontSize: 13}
              return(
                <TextField
                  {...params}
                  placeholder=""
                />
              )
            }}
          />
        </div>
        )
      }
    }
  }

  const columnSet = [
    tripID,
    startDate,
    endDate,
    nights,
    status,
    actionButton,
    bookingSubscription,
    businessUserInvitation,
    offer,
    internalComment,
    assignHotel,
    rebookHotel,
    selfCheckInUrl,
    messageToCustomer,
    surchargeSum,
    surchargeDescription,
    bookingChannel,
    hotelBookingNumber,
    hotelInvoiceSum,
    hotelInvoiceDelta,
    hotelRateSum,
    tripHasNullRates,
    createdAt,
    updatedAt,
  ]

  const delay = t => new Promise(resolve => setTimeout(resolve, t))

  const [page, setPage] = useState(0)
  const handlePageChange = (page) => {
    setPage(page)
  }

  // Effect to update table filters based on URL query parameters
  useEffect(() => {
    if (tableRef?.current) {
      delay(500).then(() =>{
        tableRef.current.state.query.filters = [
          {
            column: { field: 'tripID' },
            operator: '=',
            value: query.trip,
          },{
            column: { field: 'BookingSubscription' },
            operator: '=',
            value: query.subscription,
          },{
            column: { field: 'status' },
            operator: '=',
            value: query.status,
          },{
            column: { field: 'offerID' },
            operator: '=',
            value: query.offer,
          },{
            column: { field: 'BusinessUserInvitation' },
            operator: '=',
            value: query.guest,
          }
        ]

        const defaultSortColumnName = tableRef.current.dataManager.columns[query.orderBy]?.field || ''
        const newOrderByCollection = []

        for (let i = 0; i < tableRef.current.dataManager.columns.length; i++) {
          const columnIsActive = (query.orderBy === (''+i)) // compare string to string
          newOrderByCollection.push({
            orderBy: i,
            orderDirection: columnIsActive ? query.orderDirection : '',
            sortOrder: columnIsActive ? 1 : null,
          })
        }

        tableRef.current.dataManager.orderByCollection = newOrderByCollection // the real thing - for table controls
        tableRef.current.state.query.orderBy = {field: defaultSortColumnName} // the real thing - for data fetching
        tableRef.current.state.query.orderDirection = query.orderDirection
        setPage(0) //reset paging to show filter results from page 0
        tableRef.current.onQueryChange()
      })

    }
  }, [query.trip, query.subscription, query.status, query.offer, query.guest, query.orderBy, query.orderDirection, setPage])

  const onOrderCollectionChange = (orderByCollection) => {
    const orderBy = tableRef?.current?.state?.orderByCollection?.[0]?.orderBy ?? ''
    const orderDirection = tableRef?.current?.state?.orderByCollection?.[0]?.orderDirection ?? ''
    updateCurrentUrlParams(history, {orderBy, orderDirection})
  }

  return (
    <Typography variant="body2" component="div">
      <MaterialTable
        title={<div>
          <Tooltip title={"Reload"} enterTouchDelay={0}>
            <IconButton aria-label="Reload" onClick={refreshHook}><Replay /></IconButton>
          </Tooltip>
          <Tooltip title={"Clear Filter"} enterTouchDelay={0}>
            <IconButton aria-label="Clear Filter" onClick={() => {
              props.history.push('/app/trips/') }}><FilterIcon color={'#757575'} size={22} /></IconButton>
          </Tooltip>
        </div>}
        tableRef={tableRef}
        columns={columnSet}
        onPageChange={handlePageChange}
        page={page}
        data={qry =>
          new Promise((resolve, reject) => {
            //console.log(qry)
            let url = "/api/horus/trip?cache=clear&"
            url += `page=${qry.page + 1}`
            url += `&perPage=${qry.pageSize}`
            if (qry.search) {
              url += `&q=${qry.search}`
            }
            if (qry.orderBy) {
              url += `&sort=${qry.orderBy.field}&order=${qry.orderDirection}`
            }
            if (qry.filters.length) {
              const filter = qry.filters.map((filter) => {
                return `&${filter.column.field}${filter.operator}${filter.value}`
              })
              url += filter.join("")
            }
            fetch(url)
              .then((resp) => resp.json())
              .then((resp) => {
                resolve({
                  data: resp.data,
                  page: resp.page - 1,
                  totalCount: resp.total,
                })
              })
          })
        }
        icons={tableIcons}
        detailPanel={({ rowData }) => {
          const auditLog = rowData?.auditLog || []
          return (
            <div
              style={{
                fontSize: 14,
                textAlign: "left",
                padding: 5,
              }}
              className="cleanTable"
            >
              <div style={{display: 'inline-flex', padding: 8, marginBottom: 16, backgroundColor: '#f6f6f5', width: 'auto', border: '1px solid #b7b7b7'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <span style={{fontWeight: 'bold', marginRight: 4}}>Hotel Booking Number</span>
                  <TripDetailsInput fieldName={'hotelBookingNumber'} rowData={rowData} refreshHook={refreshHook}/>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginLeft: 40}}>
                  <span style={{fontWeight: 'bold', marginRight: 4}}>Hotel Booking Channel</span>
                  <TripDetailsInput fieldName={'bookingChannel'} rowData={rowData} refreshHook={refreshHook}/>
                </div>
              </div>

              <table style={{marginBottom: 16, backgroundColor: '#f6f6f5'}}>
                <thead>
                <tr>
                <th>ReservationID</th>
                  <th>Arrival</th>
                  <th>Status</th>
                  <th>Hotel Purchase Rate</th>
                  <th>Commission</th>
                  <th>Surcharge</th>
                  <th>Force Start Of New Trip</th>
                </tr>
                </thead>
                <tbody>
                {rowData.BookingReservations.map(night => <tr key={night.id} style={{color: getTripDetailsStatusColor(night.status)}}>
                  <td><Link to={'/app/reservations/?reservationID='+night.reservationID}>{night.reservationID}</Link></td>
                  <td>{formatDate(night.arrivalDate)}</td>
                  <td>{night.status}</td>
                  <td><ReservationDetailsInput fieldName={'hotelBookingRate'} night={night} subscription={rowData.BookingSubscription} businessUser={rowData.BusinessUserInvitation} refreshHook={refreshHook} /></td>
                  <td><ReservationDetailsInput fieldName={'commission'} night={night} subscription={rowData.BookingSubscription} businessUser={rowData.BusinessUserInvitation} refreshHook={refreshHook} /></td>
                  <td><ReservationDetailsInput fieldName={'surchargePrice'} night={night} subscription={rowData.BookingSubscription} businessUser={rowData.BusinessUserInvitation} refreshHook={refreshHook}/></td>
                  <td>{night.forceNewTripStart ? <Check style={{color: '#5c9846'}} /> : <Close style={{ color: '#862626'}}/>}</td>
                </tr>)}
                </tbody>
              </table>

              <div style={{display: 'inline-flex', padding: 8, marginBottom: 16, backgroundColor: '#f6f6f5', width: 'auto', border: '1px solid #b7b7b7'}}>
                <div style={{width: 750}}>
                  <TripInvoiceDetails tripId={rowData.id} rowData={rowData} refreshHook={refreshHook} />
                </div>
              </div>

              <table style={{marginBottom: 16, backgroundColor: '#fdfdfd'}}>
                <thead>
                <tr>
                  <th>Version</th>
                  <th>Log Time</th>
                  <th>Changes</th>
                  <th>New Arrival</th>
                  <th>New Departure</th>
                  <th>Nights</th>
                  <th>Predecessor Trips</th>
                  <th>Successor Trips</th>
                  <th>Changed By</th>
                </tr>
                </thead>
                <tbody>
                {auditLog.map(logRow => <tr key={logRow.version}>
                  <td>{logRow.version}</td>
                  <td>{formatDateTime(logRow.time)}</td>
                  <td>
                    <ul style={{margin: 0, paddingLeft: 20}}>{logRow.changes.map((change, index) =>
                      <li key={'chg'+index}>{change}</li>)}</ul>
                  </td>
                  <td>{formatDate(logRow.newStart)}</td>
                  <td>{formatDate(logRow.newEnd)}</td>
                  <td style={{textAlign: 'center'}}>{logRow.nights}</td>
                  <td>
                    <ul style={{margin: 0, paddingLeft: 20}}>{logRow?.predecessorTrips?.map((trip, index) =>
                      <li key={'ptr'+index}><Link to={'/app/trips/?trip='+trip.tripID}>{trip.tripID}</Link></li>)}
                    </ul>
                  </td>
                  <td>
                    <ul style={{margin: 0, paddingLeft: 20}}>{logRow?.successorTrips?.map((trip, index) =>
                      <li key={'ptr'+index}><Link to={'/app/trips/?trip='+trip.tripID}>{trip.tripID}</Link></li>)}
                    </ul>
                  </td>
                  <td>
                    {logRow?.user?.system}<br/>
                    {logRow?.user?.email}
                  </td>
                </tr>)}
                </tbody>
              </table>
            </div>
          )
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              dispatch(actions.postTrip({
                ...newData,
                startDate: getDateAsCleanUTC(newData?.startDate),
                endDate: getDateAsCleanUTC(newData?.endDate),
              }, refreshHook))
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              dispatch(actions.putTrip(oldData.id, {
                ...newData,
                startDate: getDateAsCleanUTC(newData?.startDate),
                endDate: getDateAsCleanUTC(newData?.endDate),
              }, refreshHook))
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              dispatch(actions.deleteTrip(oldData.id, refreshHook))
              resolve()
            }),
        }}
        onOrderCollectionChange={onOrderCollectionChange}
        options={{
          debounceInterval: 700,
          search: true,
          filtering: true,
          filterCellStyle: {
            padding: 3,
          },
          thirdSortClick: false,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 50, 500, 5000],
          actionsColumnIndex: 0,
          addRowPosition: 'first',
          exportButton: false,
          //tableLayout: 'fixed',
          headerStyle: {
            backgroundColor: '#f0f2f3',
            fontWeight: 'bold',
          },
          rowStyle: (rowData, index ) => ({
            backgroundColor:
              index % 2 === 0 ? "#e9f0f6" : "#f4f9fc",
          }),
          cellStyle:{ padding: '3px 3px 3px 3px'},
        }}
        localization={localization}
      />
    </Typography>
  )
})


export default TripTable

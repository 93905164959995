import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Grid} from '@mui/material';
import {Segment} from '@mui/icons-material';
import {Loading} from '../components/Loading'
import * as actions from '../../store/actions'
import "vis-timeline/styles/vis-timeline-graph2d.css";
import SpecialDateTimeline from "../components/SpecialDateTimeline";
import SpecialDateModal from "../components/SpecialDateModal";
import CitySelector from "../components/CitySelector";
import {useLocation} from "react-router";


const ViewSpecialDatesByCity = withRouter((props) => {
  const dispatch = useDispatch()

  let location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const query = {
    cityId: searchQuery.get('cityId') || '',
  }

  useEffect(() => {
    dispatch(actions.getSpecialDateByCityId(query.cityId))
    dispatch(actions.getCity())
    dispatch(actions.getHotel())
  },[dispatch, query.cityId])

  const specialDatesLoading = useSelector(state => state.hotel.specialDatesLoading)

  return (
    <div className="contentWindow">

      <div className="headlineBox">
        <div className="headlineIconBox">
          <Segment fontSize={'small'} className="headlineIcon" />
          <div className="headlineIconText">Special dates and surcharges by city and hotel</div>
        </div>
        <CitySelector
          selectedCityId={query.cityId}
          redirectQueryParam={'cityId'}
          addAllOption={false}
        />
      </div>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          {specialDatesLoading ? <Loading /> : <div>
            <SpecialDateTimeline />
          </div>}
        </Grid>
      </Grid>
      <SpecialDateModal cityId={query.cityId} />
    </div>
  )
})


export default ViewSpecialDatesByCity

import * as actionTypes from './actionTypes';
import axios from '../../axios.config';

/*
 * GET
 */
export const getSubscriptionDuration = () => {
  return dispatch => {
    dispatch(getSubscriptionDurationStart())
    axios.get('/horus/subscriptionDuration')
      .then ( response => {
        dispatch(getSubscriptionDurationSuccess(response.data))
      })
      .catch(error => {
        dispatch(getSubscriptionDurationFail(error))
      })
  }
}

export const getSubscriptionDurationStart = () => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_DURATION_START,
  }
}

export const getSubscriptionDurationSuccess = (res) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_DURATION_SUCCESS,
    res: res,
  }
}

export const getSubscriptionDurationFail = (error) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_DURATION_FAIL,
    error: error,
  }
}


/*
 * POST
 */
export const postSubscriptionDuration = (payload) => {
  return dispatch => {
    dispatch(postSubscriptionDurationStart())
    axios.post('/horus/subscriptionDuration', payload)
      .then ( response => {
        dispatch(postSubscriptionDurationSuccess(response.data))
        dispatch(getSubscriptionDuration())
      })
      .catch(error => {
        dispatch(postSubscriptionDurationFail(error))
      })
  }
}

export const postSubscriptionDurationStart = () => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_DURATION_START,
  }
}

export const postSubscriptionDurationSuccess = (res) => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_DURATION_SUCCESS,
    res: res,
  }
}

export const postSubscriptionDurationFail = (error) => {
  return {
    type: actionTypes.POST_SUBSCRIPTION_DURATION_FAIL,
    error: error,
  }
}


/*
 * PUT
 */
export const putSubscriptionDuration = (id, payload) => {
  return dispatch => {
    dispatch(putSubscriptionDurationStart())
    axios.put('/horus/subscriptionDuration/'+id, payload)
      .then ( response => {
        dispatch(putSubscriptionDurationSuccess(response.data))
        dispatch(getSubscriptionDuration())
      })
      .catch(error => {
        dispatch(putSubscriptionDurationFail(error))
      })
  }
}

export const putSubscriptionDurationStart = () => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_DURATION_START,
  }
}

export const putSubscriptionDurationSuccess = (res) => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_DURATION_SUCCESS,
    res: res,
  }
}

export const putSubscriptionDurationFail = (error) => {
  return {
    type: actionTypes.PUT_SUBSCRIPTION_DURATION_FAIL,
    error: error,
  }
}


/*
 * DELETE
 */
export const deleteSubscriptionDuration = (id) => {
  return dispatch => {
    dispatch(deleteSubscriptionDurationStart())
    axios.delete('/horus/subscriptionDuration/'+id)
      .then ( response => {
        dispatch(deleteSubscriptionDurationSuccess(response.data))
        dispatch(getSubscriptionDuration())
      })
      .catch(error => {
        dispatch(deleteSubscriptionDurationFail(error))
      })
  }
}

export const deleteSubscriptionDurationStart = () => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_DURATION_START,
  }
}

export const deleteSubscriptionDurationSuccess = (res) => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_DURATION_SUCCESS,
    res: res,
  }
}

export const deleteSubscriptionDurationFail = (error) => {
  return {
    type: actionTypes.DELETE_SUBSCRIPTION_DURATION_FAIL,
    error: error,
  }
}
